import React, { Component } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import { Link } from 'react-router-dom';
import { QuestionBank } from '../../router/Roles';
import { Field, Formik, Form, FieldArray } from "formik";
import { Button, MenuItem, FormGroup } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { get } from "lodash";
import { fetchSuperuserLicenseDetailsGet, editLicensePartition ,fetchLicensePartitionGet, fetchLicenseSchoolListGet } from '../../store/license/Actions';
import { getYearData, stopYearData } from '../../store/profile/Actions'
import {getAllSchoolNameList} from '../../store/usermanage/Actions';
import { RouteComponentProps } from 'react-router';
import { editSchema } from "./LicensePartitionValidation";
import SpinnerLoader from '../../components/spinner/SpinnerLoader';


interface OwnPropsParams {
    id: string;
}
  
interface Props extends RouteComponentProps<OwnPropsParams> {
    getYear?: any;
    stopYear?: any;
    licenseModuleDetails?: any;
    getSchoolList?: any;
    licensePartitionValue?: any;
    licenseSchoolList?: any;
    loading?: any;
    getYearData: (postvalue?: any) => any;
    stopYearData: () => any;
    fetchSuperuserLicenseDetailsGet: (getData: any) => any;
    getAllSchoolNameList: () => any;
    fetchLicenseSchoolListGet: () => any;
    editLicensePartition: (postQuestion: any) => any;
    fetchLicensePartitionGet: (getData: any) => any;
}

interface StateTypes {
    academicYear?: any;
    allLicenseModule?: any;
    allSchoolList?: any;
    partitionValues?: any;
}


export class EditLicensePartition extends Component<Props, StateTypes> {
    formik: any;
    constructor(props: any) {
        super(props);
        this.state = {
            allSchoolList:[],
            academicYear:"",
            allLicenseModule:[]
        }
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.getUpdateYear()
        console.log(this.props.match.params.id)
        this.props.fetchLicenseSchoolListGet().then((res:any)=>{
            const { licenseSchoolList } = this.props;
            console.log(licenseSchoolList)
            this.setState({ allSchoolList: licenseSchoolList })
        });
    }

   
      componentWillUpdate(nextProps: any, nextState: any) {
        const { stopYear } = this.props;
        if (stopYear === true) {
          this.getUpdateYear(this.props.getYear)
        }
      }
      componentDidUpdate() {
        const { stopYear } = this.props;
        if (stopYear === true) {
          this.props.stopYearData()
        }
      }
      getUpdateYear(getYear?: any) {
        if (getYear && getYear !== undefined && getYear !== null) {
          this.setState({ academicYear: getYear })
          this.getfetchSuperuserLicenseDetailsData(getYear)
        } else {
          this.setState({ academicYear: this.props.getYear })
          this.getfetchSuperuserLicenseDetailsData(this.props.getYear)
        }
      }
      getfetchSuperuserLicenseDetailsData(getYear?: any) {
          this.props.fetchSuperuserLicenseDetailsGet({academic_year: getYear}).then((res: any) => {
            const { licenseModuleDetails } = this.props;
            this.setState({ allLicenseModule: licenseModuleDetails.data })
          });

          const value: any = {
            uid: this.props.match.params.id,
            academic_year: getYear
          }

          this.props.fetchLicensePartitionGet(value).then((res: any) => {
       
            const { licensePartitionValue } = this.props;
            
            this.setState({ partitionValues: licensePartitionValue})
            
          });

      }

    render() {
        const { allSchoolList, allLicenseModule, partitionValues } = this.state;
        const { loading } = this.props;
        const loadingFull = { display: loading || (allLicenseModule && allLicenseModule.length < 0) ? "block" : "none" };
        let question_list: any = [
            {
                module_name: "",
            },
        ];
        let schoolId : any
        if(allLicenseModule && partitionValues){
            

            const updatedFetch = allLicenseModule.map((fetchItem:any) => {
                const foundItem = partitionValues.license_details.find((item:any) => item.module_name === fetchItem.module_name);
                if (foundItem) {
                  fetchItem.license_count = foundItem.license_count;
                  fetchItem.student_count = foundItem.student_count;
                  fetchItem.tot_license_count = foundItem.license_count + fetchItem.partition_remaining_count;
                }
                return fetchItem;
              });

            question_list = updatedFetch;
            schoolId = partitionValues.school_id;
        }

        const initialValuesData: any = {
            license_details: question_list,
            school_id:schoolId
        }   
        return (
            <div className="page-wrapper">
                <div className="page-content-wrapper">
                    <div className="page-content pt-3">
                        <BreadCrumb
                            titleName={['Edit Partition']}
                            homeName={['Home']}
                            url={['dashboard']}
                            mainPageTitle={['Edit Partition']} />
 {
                                     allLicenseModule && allLicenseModule.length > 0 && partitionValues ? (
                        <div className="row">
                            <div className="col-md-12">
                                <Formik
                                    ref={node => this.formik = node}
                                    initialValues={initialValuesData}
                                    validationSchema={editSchema}
                                    onSubmit={(values, { setSubmitting }) => {
                                        
                                        let getQuestionValue: any = [];
                                        getQuestionValue = {
                                            school_id: values.school_id,
                                            license_details: values.license_details,
                                            uid:this.props.match.params.id
                                           
                                          };
                                        this.props.editLicensePartition(getQuestionValue)
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        isSubmitting,
                                        isValidating,
                                        dirty,
                                        touched,
                                        handleReset,
                                        handleSubmit,
                                        handleChange,
                                        setFieldValue,
                                    }) => {

                                        return (
                                            <Form>

                                                <div className="row">

                                                    <div className="col-md-4">
                                                        <FormGroup className="w-100 mb-4">
                                                            <Field
                                                                label={<><span>School Name</span><span className='formmandatorycolor'> *</span></>}
                                                                name="school_id"
                                                                select
                                                                component={TextField}
                                                                disabled={true}
                                                                onChange={(e: any, value: any) => {
                                                                    setFieldValue(
                                                                        "school_id",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            >
                                                                {allSchoolList && allSchoolList.length > 0 && allSchoolList.map((item: any) => (
                                                                    <MenuItem value={item.id}>
                                                                        {item.school_name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </FormGroup>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <FieldArray
                                                            name="license_details"
                                                            render={({ insert, remove, push }) => (
                                                                <>
                                                                    {values.license_details.length > 0 &&
                                                                        values.license_details.map(
                                                                            (question: any, index: any) => (
                                                                                <div className="row" key={index}>
                                                                                    <div className="col-md-12">
                                                                                        <div className="card-box mt-4">
                                                                                            <div className="card-body pb-5 row">
                                                                                                <div className="col-md-6">
                                                                                                    <FormGroup className="w-100">
                                                                                                        <Field
                                                                                                            label={<><span>Module</span><span className='formmandatorycolor'> *</span></>}
                                                                                                            name={`license_details.${index}.module_name`}
                                                                                                            component={TextField}
                                                                                                            className="textfield__input"
                                                                                                            disabled={true}        
                                                                                                        >

                                                                                                        </Field>
                                                                                                    </FormGroup>
                                                                                                </div>

                                                                                                <div className="col-md-6">
                                                                                                    <FormGroup className="w-100">
                                                                                                        <Field
                                                                                                            label={<><span>License Count</span><span className='formmandatorycolor'> *</span></>}
                                                                                                            name={`license_details.${index}.license_count`}
                                                                                                            type="number"
                                                                                                            component={TextField}
                                                                                                            className="textfield__input"
                                                       
                                                                                                            disabled={false}
                                                                                                        />
                                                                                                    </FormGroup>
                                                                                                </div>



                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        )}

                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="text-right mb-3 mr-2 mt-4">

                                                <Button
                              className="btn btn-pink mr-1 ml-1"
                              type="submit"
                           
                            
                            >
                              Submit
                            </Button>
                            
                                                   
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>


                            </div>
                        </div>
                         ) : null}
                    </div>
                </div>
                <div style={loadingFull}><SpinnerLoader /></div>
            </div>
        )
    }
}



const mapStateToProps = (state: any) => {
    return {
        loading: state.license.loading,
        getYear: state.profile.getYear,
        stopYear: state.profile.yearStatusFails,
        licenseModuleDetails: state.license.licenseModuleDetails,
        license: state.license.license,
        getSchoolList:state.userManage.category,
        licensePartitionValue:state.license.licensePartitionValue,
        licenseSchoolList:state.license.licenseSchoolList,
    }
}

export default connect(mapStateToProps, {
    fetchSuperuserLicenseDetailsGet,
    stopYearData,
    getYearData,
    getAllSchoolNameList,
    editLicensePartition,
    fetchLicensePartitionGet,
    fetchLicenseSchoolListGet
})(EditLicensePartition)