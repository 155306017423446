import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { RootState } from '../store/Index';
import { StudentImageUpdate, RemovedImageUpdate } from '../store/student/Actions';

interface ImageUploadState {
  images?: any,
  setImages?: any,
  maxNumber?: number | undefined,
  getImage?: boolean
}
export type OwnUpdateImageFormProps = {
  StudentImageUpdate: (student: any, url: any) => void;
  RemovedImageUpdate: () => any;
  postImage?: any;
  URLLink?: any;
  setImages: any[],
  setImagesState: (images: any[]) => void,
}
export class ImageUpload extends Component<OwnUpdateImageFormProps, ImageUploadState> {
  constructor(props: any) {
    super(props);
    this.state = {
      images: [],
      setImages: [],
      maxNumber: 65,
      getImage: false
    }
  }
  componentDidMount(): void {
    this.props.RemovedImageUpdate()
  }
  onChange = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {
    const { URLLink, setImagesState } = this.props
    this.setState({ setImages: imageList, getImage: true })
    setImagesState(imageList)
    if(imageList && imageList !==undefined && imageList !== null){
      if (imageList.length > 0) {
        if (imageList[0].file) {
          const file = imageList[0].file
          const formData = new FormData();
          formData.append('file', file);
          this.props.StudentImageUpdate(formData, URLLink);
        }
      } else {
        this.props.RemovedImageUpdate();
      }
    }
  };

  render() {
    const { images, maxNumber, getImage } = this.state;
    const { setImages } = this.props;
    return (
      <div className="pull-left">
        <ImageUploading
          value={setImages}
          onChange={this.onChange}
          maxNumber={maxNumber}
          dataURLKey="data_url"
          acceptType={['jpg', 'jpeg' , 'png']}
          maxFileSize={6000000}
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
            errors
          }) => (
            // write your building UI
            <div className="upload__image-wrapper ml-2">
              <div>
                <div className="ml-3">
                  {getImage ?
                    <span>
                      {setImages.map((image: any, index: any) => (
                        <div key={index} className="image-item">
                          <div className="d-flex flex-wrap ml-3">
                            <div>
                              <img src={image['data_url']} alt="" className="multiImage" />
                            </div>
                            <div className="image-item__btn-wrapper">
                              <Link to={'#'} onClick={() => onImageRemove(index)} className='imageUploadRemove'>
                                <i className="fa fa-trash-o" aria-hidden="true"></i></Link>
                            </div>
                          </div>
                        </div>

                      ))}
                    </span>
                    :
                    null
                  }
                </div>
              </div>
              <Link to={'#'} title="Upload"
                className="btn btn-circle btn-info mb-3"
                style={isDragging ? { color: 'red' } : undefined}
                onClick={onImageUpload}
                {...dragProps}
              >
                <i className="fa fa-upload" aria-hidden="true"></i> Upload Image
              </Link>
              {
                  errors && <div className='formmandatorycolor'>
                  {errors.maxNumber && <span>Number of selected images exceed maxNumber</span>}
                  {errors.acceptType && <span>Your selected file type is not allow</span>}
                  {errors.maxFileSize && <span>Selected file size exceed maxFileSize</span>}
                  {errors.resolution && <span>Selected file is not match your desired resolution</span>}
                </div>
              }
            </div>
            
          )}
        </ImageUploading>
      </div>
    )
  }
}

const mapStateToProps = ({ student, classes }: RootState) => {
  return {
    loading: student.loading,
    errorMessage: student.errors
  };
};

export default connect(mapStateToProps, { StudentImageUpdate, RemovedImageUpdate })(ImageUpload)
