import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Bar } from 'react-chartjs-2';
import { fetchAttendanceUsage } from '../../store/dashboard/Actions';
import {optionBarChart} from '../../components/chartoption/ChartCommonFun';
import { stopYearData, stopSubComponentYear } from '../../store/profile/Actions'
import CommonLoader from '../../components/CommonLoader';

export type PostsAttendanceBarProps = {	
	getChangeYearData?:any;
	stopYearDate?:any;
	stopYearData:()=>any;
	stopSubComponentYear:(postValue:any) => any;
	fetchAttendanceUsage:(postValue:any) => any;
	getAttendanceDetails:any;
	getDurationList:any;
	loading?:any;
	isAdminAttendandeUsage?:boolean
}
interface StateTypes {
	attendFilter:any,
	getAttendanceRecord?:any,
	academicYear?:any
}
export class AttendanceUsage extends Component<PostsAttendanceBarProps, StateTypes> {
	constructor(props: any) {
        super(props);
        this.state = {
            attendFilter:'This Month',
			getAttendanceRecord:[],
			academicYear:null
        }
    }
	componentDidMount(): void {
		window.scrollTo(0, 0);
		this.getCurrectYear();

		
	}
	componentWillUpdate(nextProps:any, nextState:any) {
		
		const {stopYearDate} = this.props;
		if(stopYearDate === true){
			this.getCurrectYear(this.props.getChangeYearData)
			const postValue:any = {
				status:true
			}
			this.props.stopSubComponentYear(postValue)
		}
	}
	componentDidUpdate() {
		const {stopYearDate} = this.props;
		if(stopYearDate === true){
			this.props.stopYearData()
		}
	}
	getCurrectYear(getYear?:any){
		if(getYear && getYear !== undefined && getYear !== null){
			this.setState({academicYear:getYear})
			this.getAttendanceChartBar(getYear)
		}else {
			this.setState({academicYear:this.props.getChangeYearData})
			this.getAttendanceChartBar(this.props.getChangeYearData)
		}
	}
	getAttendanceChartBar(getYear?:any){
			const loadMoreType = {
				filter: 'This Month',
				academic_year: getYear
			  }
			  this.props.fetchAttendanceUsage(loadMoreType).then((res:any)=>{
				  const {getAttendanceDetails} = this.props;
				  if(getAttendanceDetails && getAttendanceDetails !== undefined && getAttendanceDetails!== null){
					this.getAttendance(getAttendanceDetails)
					this.setState({attendFilter:'This Month'})
				  }else {
					this.setState({getAttendanceRecord:[]})
					this.setState({attendFilter:'This Month'})
				  }
			  });
	}
	FilterAttendance = (e:any)=> {
        const { value } = e.target;
		const {academicYear} = this.state;
		if(value){
			const loadMoreType = {
				filter: value,
				academic_year: academicYear
			}
			this.props.fetchAttendanceUsage(loadMoreType).then((res:any)=>{
				const {getAttendanceDetails} = this.props;
				if(getAttendanceDetails && getAttendanceDetails !== undefined && getAttendanceDetails!== null){
				  this.getAttendance(getAttendanceDetails)
				}else {
				  this.setState({getAttendanceRecord:[]})
				}
			});
			this.setState({attendFilter:value})
		}
	}
	getAttendance(getAttendanceDetails:any){
		let getSchoolName:any = [];
		let getColorCode:any = [];
		let getAttendance:any = [];
		if(getAttendanceDetails){
			getSchoolName = [];
			getColorCode = [];
			getAttendance = [];
			getAttendanceDetails.forEach((attendance:any, index:any)=>{
				getSchoolName.push(attendance.school_name);
				getColorCode.push(attendance.color_code);
				getAttendance.push(attendance.attendance);
			})
			const postValue:any = {
				getSchoolName:getSchoolName,
				getColorCode:getColorCode,
				getAttendance:getAttendance
			}
			if(postValue){
				this.setState({getAttendanceRecord:postValue})
			}
		}
	}
	refreshAttendanceUsageTable = () => {
		const {academicYear} = this.state;
		this.getAttendanceChartBar(academicYear);
	}
	
    render() {
		const { getDurationList, isAdminAttendandeUsage } = this.props;
		const { getAttendanceRecord } = this.state;
		const loadingDashboard = { display: isAdminAttendandeUsage ? "block" : "none" };
		
        return (
            <div>
                <div className="row">
					<div className="col-lg-12 col-md-12 col-sm-12 col-12">
						<div className="card card-topline-red">
						<div className="overlay" style={loadingDashboard}><div className='custom-spinner-individual'></div></div>
							<div className="card-head">
								<header>Attendance Usage</header>
								{getDurationList ?
								<div className="tools">
									 <div className="d-flex">
										<div>
										{getDurationList?                                      
									<select name="classlist" value={this.state.attendFilter} className="form-control" onChange={this.FilterAttendance}>
										{getDurationList.map((items: any) => (
											<option value={items.value}> {items.value} </option>
										))}
									</select>
                                    :null}
										</div>
										<div className="ml-2 mt-2">
											<span className="fa fa-repeat btn-color box-refresh pointer" onClick={this.refreshAttendanceUsageTable}></span>
											<span className="t-collapse btn-color fa fa-chevron-up pointer" ></span>
										</div>
									</div>                              
								</div>
								:null}
							</div>
							<div className="card-body no-padding height-9">
								<div className="row">
									
									{getAttendanceRecord && getAttendanceRecord !== null && getAttendanceRecord.length !== 0?
										<Bar
										data={{
											labels: getAttendanceRecord.getSchoolName,
											datasets: [
												{
													backgroundColor: getAttendanceRecord.getColorCode,
													borderColor: getAttendanceRecord.getColorCode,
													borderWidth: 2,
													data: getAttendanceRecord.getAttendance
												}
											]
										}}
										height={90}
										options={optionBarChart}
									/>
									:<CommonLoader/>}
								
								</div>
							</div>
						</div>
					</div>
                    </div>
				{/* <div style={loadingDashboard}><SpinnerLoader /></div>                 */}
            </div>
        )
    }
}
export const option = {
    legend:{
		display:false
 },
	title: {
		display: true,
		text: ''
	},
	scales : {
		yAxes: [{
			ticks: {
			  beginAtZero: true,
			  steps: 10,
			  stepValue: 5,
			  userCallback: function(label:any, index:any, labels:any) {
				  if (Math.floor(label) === label) {
					  return label;
				  }
			  }
			}
		  }],
		xAxes : [ {
			barPercentage: 0.5,
			gridLines : {
				display : false
			}
		} ]
	},
    tooltips: {
      callbacks: {
        label: function(tooltipItem:any, data:any) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var currentValue = dataset.data[tooltipItem.index];
          return currentValue;
        },
        title: function(tooltipItem:any, data:any) {
          return data.labels[tooltipItem[0].index];
        }
      }
    }
  }
const mapStateToProps = (state:any) => {
	return {
		getChangeYearData:state.profile.getYear,
		stopYearDate:state.profile.yearStatusFails,
		getAttendanceDetails:state.dashboard.attendanceUsage,
		getDurationList:state.profile.getDuration,
		isAdminAttendandeUsage: state.dashboard.isAdminAttendandeUsage
	}
}


export default connect(mapStateToProps,{fetchAttendanceUsage, stopYearData, stopSubComponentYear})(AttendanceUsage)
