import React, { Component } from 'react'
import Pages from './router/Pages'
import "toasted-notes/src/styles.css"; 
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { CookiesProvider } from 'react-cookie';

export class App extends Component {    
    render() {
        return (
            <div>
                <CookiesProvider>
                 <Pages />  
                 </CookiesProvider>
            </div>
        )
    }
}
