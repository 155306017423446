import React, { Component } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import { onGetValue } from "../../services/Config";
import { onGetData } from "../../store/classes/Actions";
import SpinnerLoader from "../../components/spinner/SpinnerLoader";

interface PropsTypes {
  onGetData: (postValue: any) => any;
  loading: any;
  getARTagList: any;
}
interface StateTypes {
  ARTagList?: any;
}
export class ArTagDownload extends Component<PropsTypes, StateTypes> {
  constructor(props: any) {
    super(props);
    this.state = {
      ARTagList: [],
    };
  }
  componentDidMount(): void {
    window.scrollTo(0, 0);
    this.getARTag();
  }
  getARTag() {
    const getData: any = {
      data: undefined,
      URL: onGetValue.ArTagDownload,
    };
    this.props.onGetData(getData).then((res: any) => {
      const { getARTagList } = this.props;
      this.setState({ ARTagList: getARTagList });
    });
  }
  openInNewTab = (url: any) => {
    const newWindow = window.open(
      `${process.env.REACT_APP_API_URL}${url}`,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) {
      newWindow.opener = null;
    }
  };
  render() {
    const { loading } = this.props;
    const { ARTagList } = this.state;
    const loadingTeacher = { display: loading ? "block" : "none" };
    return (
      <div>
        <div className="page-wrapper">
          <div className="page-content-wrapper">
            <BreadCrumb
              titleName={["AR Tags"]}
              homeName={["Home"]}
              url={["dashboard"]}
              mainPageTitle={["AR Tags"]}
            />
            <div className="row">
              {ARTagList && ARTagList.length > 0 
                ? ARTagList.map((items: any) => (
                    <div className="col-md-2">
                      <div className="card card-topline-red">
                        <div className="m-3 mt-0 mb-0">
                          <div className="p-4">
                            <img
                              className="width100"
                              alt=""
                              src="../../assets/img/ARTAGs.png"
                            />
                          </div>
                          <div className="doctor-profile">
                            <div className="width100 text-center mb-3">
                              <h5>{items.filename}</h5>
                            </div>
                            <div onClick={() => this.openInNewTab(items.URL)}>
                              <button
                                className="mdl-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-pink"
                                onClick={() => this.openInNewTab(items.url)}
                              >
                                Download
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : 
                <div className="col-md-12">
                <div className="card card-topline-red">
                  <h4 className="p-3">No Data Found</h4>
                  </div>
                  </div>
                  }
            </div>
          </div>
        </div>
        <div style={loadingTeacher}>
          <SpinnerLoader />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    loading: state.classes.loading,
    getARTagList: state.classes.OnGetData,
  };
};

export default connect(mapStateToProps, { onGetData })(ArTagDownload);
