import * as yup from 'yup';

export const schema = yup.object().shape({
  school_id: yup.string().required('Please select school'),
  license_details: yup.array().of(
    yup.object().shape({
      license_count: yup.number()
        .max(yup.ref('partition_remaining_count'), ({ max }) => `License Count cannot more than ${max}`)
        .when('module_name', {
          is: (val: any) => val == 'student_module',
          then: yup.number().transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .min(1, 'Value must be greater than 0')
            .required('Value is required')
            .integer('Value must be an integer'),
          otherwise: yup.number().nullable()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .required('Value is required')
            .min(0, 'Value must be greater than or equal 0')
            .integer('Value must be an integer'),
        })
    })
  ),
});

export const editSchema = yup.object().shape({
  school_id: yup.string().required('Please select school'),
  license_details: yup.array().of(
    yup.object().shape({
      license_count: yup.number()
        .max(yup.ref('tot_license_count'), ({ max }) => `License Count cannot more than ${max}`)
        .when('module_name', {
          is: (val: any) => val == 'student_module',
          then: yup.number().transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .min(yup.ref('student_count') ? yup.ref('student_count') : 1 , ({ min }) => `Value must be greater than ${min}`)
            .required('Value is required')
            .integer('Value must be an integer'),
          otherwise: yup.number().nullable()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .required('Value is required')
            .min(yup.ref('student_count'), 'Value must be greater than or equal 0')
            .integer('Value must be an integer'),
        })
    })
  ),
});