import React, {Component} from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import { RouteComponentProps } from "react-router";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { TextField } from "formik-material-ui";
import { HomeStudySchema } from "./HomeStudyValidation";
import { FormGroup, MenuItem, Button } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Link } from "react-router-dom";
import SpinnerLoader from "../../components/spinner/SpinnerLoader";
import { internalGradleGet, fetchBluePrintQuestionTypeDropdownGet, fetchBluePrintDifficultyLevelDropdownGet } from "../../store/QuestionBank/Actions";
import { fetchControlPanelByIdGet, editControlPanel } from "../../store/HomeStudy/Actions";

interface OwnPropsParams{
    id:string;
}

export interface Props extends RouteComponentProps<OwnPropsParams>{
    loading: boolean;
    getChangeYearData?: any;
    stopYearDate?: any;
    getInternalGradle?: any;
    bluePrintQuestionTypeDropdown?: any;
    bluePrintDifficultyLevelDropdown?: any;
    controlPanelById?: any;
    stopYearData: () => any;
    internalGradleGet: () => any;
    addControlPanelget: (data: any)  => any;
    fetchBluePrintQuestionTypeDropdownGet: () => any;
    fetchBluePrintDifficultyLevelDropdownGet: () => any;
    fetchControlPanelByIdGet: (data: any) => any;
    editControlPanel: (data:any) => any;
}

interface StateTypes {
    academicYear: any;
    internalGradle: any;
    grade?: any;
    grade_id?: any;
    question_type?: any;
    question_type_marks?: any;
    difficulty_level?: any;
    autocompleteValues?: any;
    controlpanel_details?: any;
    editControlPanelValues?: any;
}

export class EditControlPanel extends Component<Props, StateTypes>{
    formik: any;
    constructor(props:any) {
        super(props);
        this.state = {
            academicYear: null,
            internalGradle:[],
            grade_id: "",
            question_type: [],
            question_type_marks: [],
            difficulty_level: [],
            autocompleteValues: {
                grade_id: null
            },
            controlpanel_details:[],
            editControlPanelValues:{}
        }
    }

    componentDidMount(): void {
        window.scrollTo(0,0);
        this.getCurrectYear();
       
    }

    getInternalGradleList(gradeId: any) {
        this.props.internalGradleGet().then((res: any)=>{
            const {getInternalGradle} = this.props
            const gradle_internal = getInternalGradle.find((grade: any) => grade.id === gradeId)
            this.setState({
                internalGradle: getInternalGradle,
                autocompleteValues: {                    
                    grade_id:  gradle_internal,
                  },
            })
        })
    }   

    componentWillUpdate(nextProps: any, nextState: any) {
        const { stopYearDate } = this.props;
        if (stopYearDate === true) {
            this.getCurrectYear(this.props.getChangeYearData);
        }
    }
    componentDidUpdate() {
        const { stopYearDate } = this.props;
        if (stopYearDate === true) {
            this.props.stopYearData();
        }
    }

    getCurrectYear(getYear?: any) {
        if (getYear && getYear !== undefined && getYear !== null) {
            this.getBluePrintdetails(getYear);
            this.setState({ academicYear: getYear });
        } else {
            this.getBluePrintdetails(this.props.getChangeYearData);
            this.setState({ academicYear: this.props.getChangeYearData });
        }
    }

    getBluePrintdetails(getYear?: any) {
        const getData = {
            academic_year: getYear,
        }
        const getControlPanelData={
            academic_year: getYear,
            uid: this.props.match.params.id
        }
        this.props.fetchControlPanelByIdGet(getControlPanelData).then((res: any)=>{
            const { controlPanelById } = this.props;            
            const controlpanel_detailsData = controlPanelById.controlpanel_details.map((item: any) => ({
                question_type: item.question_type_id,
                question_type_marks: item.question_type_marks,
                difficulty_level: item.difficulty_level_id,
                no_of_question: item.no_of_question
            }));
            this.setState({
                editControlPanelValues: controlPanelById,
                grade_id: controlPanelById.grade_id,
                controlpanel_details:controlpanel_detailsData
            })
            this.getInternalGradleList(controlPanelById.grade_id);
        });
        this.props.fetchBluePrintQuestionTypeDropdownGet().then((res: any) => {
            const { bluePrintQuestionTypeDropdown } = this.props;
            this.setState({ question_type: bluePrintQuestionTypeDropdown });
        });
        this.props.fetchBluePrintDifficultyLevelDropdownGet().then((res: any) => {
            const { bluePrintDifficultyLevelDropdown } = this.props;
            this.setState({ difficulty_level: bluePrintDifficultyLevelDropdown });
        });        
    };

    getMarkOptions = (typeName: any) => {
        const { question_type } = this.state;
        const questionType = question_type.find((type: any) => type.id === typeName);
        return questionType ? questionType.marks : [];
    };

    handleAutocompleteChange (key:any, newValue:any) {
        this.setState((prevState) => ({
            autocompleteValues: {
            ...prevState.autocompleteValues,
            [key]: newValue ? newValue : ''
          }
        }));
    };

    render() {
        const { academicYear, internalGradle, grade, grade_id, question_type, question_type_marks, difficulty_level, autocompleteValues, controlpanel_details} = this.state;
        const { loading } = this.props;
        const loadingQuestionFull = {display: loading? "block": "none"};

        const initialValues = {
            grade_id: grade_id,            
            controlpanel_details: controlpanel_details,
        };

        return (
            <div className="page-wrapper">
               <div className="page-content-wrapper">
                    <div className="page-content pt-3">
                        <BreadCrumb titleName={['Edit ControlPanel']} homeName={['Home']} url={['dashboard']} mainPageTitle={['Edit ControlPanel']} />
                            {autocompleteValues&&autocompleteValues.grade_id !== null && internalGradle&& internalGradle.length > 0 && grade_id && controlpanel_details ?
                                <div className="row">
                                    <div className="col-md-12">
                                        <Formik
                                            ref={node => this.formik = node}
                                            initialValues={initialValues}
                                            validationSchema={HomeStudySchema}
                                            validate={(values) => {
                                                const errors: { [key: string]: any } = {};
                                                    return errors;
                                            }}

                                            onSubmit={(values, {setSubmitting}) => {
                                                const getControlPanelValue: any = {
                                                    uid: this.props.match.params.id,
                                                    grade_id: parseInt(values.grade_id),
                                                    controlpanel_details:values.controlpanel_details.map((item:any)=>({
                                                        "question_type_id": item.question_type,
                                                        "question_type_marks": item.question_type_marks,
                                                        "difficulty_level_id": item.difficulty_level,
                                                        "no_of_question": parseInt(item.no_of_question)
                                                    }))
                                                };
                                                this.props.editControlPanel(getControlPanelValue).then((res:any)=>{

                                                })
                                            }}
                                        >
                                            {({
                                                values,
                                                setFieldValue, handleChange
                                            }) => {
                                                const controlPanelDetailsIsEmpty = values.controlpanel_details.length === 0;
                                                return(
                                                    <Form>
                                                        <div className="row">
                                                            <div className="col-md-6 p-t-20">
                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                    <FormGroup>
                                                                        <Autocomplete
                                                                            fullWidth
                                                                            id="checkboxes-tags-demo"
                                                                            options={internalGradle}
                                                                            noOptionsText={'No matching records found.'}
                                                                            value={autocompleteValues.grade_id}
                                                                            getOptionLabel={(option)=> option ? option.grade: ''}
                                                                            onChange={(e:any,value:any)=>{
                                                                                this.handleAutocompleteChange('grade_id',value)
                                                                                setFieldValue('grade_id', value ? value.id : '');
                                                                            }}
                                                                            onInputChange={(event, newInputValue) => {
                                                                                if (!newInputValue) {
                                                                                    this.handleAutocompleteChange('grade_id','')                                                                                                                                                                                       
                                                                                }
                                                                                }}
                                                                            disableClearable
                                                                            className="mb-0 mt-1"
                                                                            renderInput={(params) =>(
                                                                                <Field
                                                                                    component={TextField}
                                                                                    name="grade_id"
                                                                                    {...params} label={<><span>Grade</span><span className="formmandatorycolor"> *</span></>}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <FieldArray
                                                                    name="controlpanel_details"
                                                                    render={({insert, remove, push})=>(
                                                                        <> 
                                                                        {values.controlpanel_details.length > 0 && 
                                                                            values.controlpanel_details.map(
                                                                                (controlpanel:any, index: any) => (
                                                                                    <div className="row" key={index}>
                                                                                        <div className="col-md-12">
                                                                                            <div className="card-box mt-4">
                                                                                                <div className="card-body pb-5 row">
                                                                                                    <div className="col-md-12 text-right">
                                                                                                        <button className="" type="button" title="Delete Question" onClick={() =>{
                                                                                                                        remove(index)
                                                                                                            }}>
                                                                                                            <span className="deleteIcon"><i className="fa fa-trash" aria-hidden="true"></i></span>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                    <div className="col-md-3">
                                                                                                        <FormGroup className="w-100">
                                                                                                            <Field
                                                                                                                label={<><span>Question Type</span><span className="formmandatorycolor"> *</span></>}                                                                                                
                                                                                                                name={`controlpanel_details.${index}.question_type`}
                                                                                                                select
                                                                                                                component={TextField}
                                                                                                                className="textfield__input"
                                                                                                                disabled={false}
                                                                                                                onChange={(e:any) => {
                                                                                                                    setFieldValue(`controlpanel_details.${index}.question_type_marks`, '');                                                                                                    
                                                                                                                    setFieldValue(`controlpanel_details.${index}.question_type`, e.target.value);                                                                                                    
                                                                                                                    }}
                                                                                                            >
                                                                                                                {question_type && question_type.length > 0 && question_type.map((item: any) => (
                                                                                                                    <MenuItem value={item.id} >
                                                                                                                        {item.name}
                                                                                                                    </MenuItem>
                                                                                                                ))}

                                                                                                            </Field>
                                                                                                        </FormGroup>
                                                                                                    </div>
                                                                                                    <div className="col-md-3">
                                                                                                        <FormGroup className="w-100">
                                                                                                            <Field
                                                                                                                label={<><span>Marks</span><span className="formmandatorycolor"> *</span></>} 
                                                                                                                name={`controlpanel_details.${index}.question_type_marks`}     
                                                                                                                select
                                                                                                                component={TextField}
                                                                                                                className="textfield__input"
                                                                                                                onChange={(e:any)=> {
                                                                                                                    setFieldValue(`controlpanel_details.${index}.question_type_marks`, e.target.value);
                                                                                                                    handleChange(e);
                                                                                                                    }}        
                                                                                                            >
                                                                                                                {this.getMarkOptions(controlpanel.question_type).map((item: any) => (
                                                                                                                    <MenuItem value={item.value} >
                                                                                                                        {item.label}
                                                                                                                    </MenuItem>
                                                                                                                    ))}
                                                                                                            </Field>
                                                                                                        </FormGroup>
                                                                                                    </div>
                                                                                                    <div className="col-md-3">
                                                                                                        <FormGroup className="w-100">
                                                                                                            <Field
                                                                                                                label={<><span>Difficulty Level</span><span className="formmandatorycolor"> *</span></>}
                                                                                                                select
                                                                                                                name={`controlpanel_details.${index}.difficulty_level`}
                                                                                                                component={TextField}
                                                                                                                className="textfield__input"
                                                                                                                disabled={false}
                                                                                                                >
                                                                                                                {difficulty_level && difficulty_level.length > 0 && difficulty_level.map((item: any) => (
                                                                                                                    <MenuItem value={item.uid} >
                                                                                                                        {item.name}
                                                                                                                    </MenuItem>
                                                                                                                ))}
                                                                                                            </Field>
                                                                                                        </FormGroup>
                                                                                                    </div>
                                                                                                    <div className="col-md-3">
                                                                                                        <FormGroup>
                                                                                                            <Field
                                                                                                                label={<><span>No. of Question</span><span className="formmandatorycolor"> *</span></>}
                                                                                                                name={`controlpanel_details.${index}.no_of_question`}
                                                                                                                type="text"
                                                                                                                component={TextField}
                                                                                                                className="textfield__input"
                                                                                                                disabled={false}
                                                                                                                onChange={(e:any) =>{
                                                                                                                    setFieldValue(`controlpanel_details.${index}.no_of_question`, e.target.value);
                                                                                                                    handleChange(e);
                                                                                                                }}
                                                                                                                ></Field>
                                                                                                        </FormGroup>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>  
                                                                                )
                                                                            )} 
                                                                                    <div className="col-md-12 text-center">
                                                                                            <Link
                                                                                                to={'#'}
                                                                                                title="Add Question"
                                                                                                className="mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab mdl-button--colored margin-right-10"
                                                                                                data-upgraded=",MaterialButton"
                                                                                                onClick={()=> 
                                                                                                    push({
                                                                                                        question_type: "",
                                                                                                        question_type_marks: "",
                                                                                                        difficulty_level: "",
                                                                                                        no_of_question: ""
                                                                                                        })}
                                                                                                > <i className="material-icons">add</i>
                                                                                            </Link>                                                                        
                                                                                        </div>                                                         
                                                                                    </>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="text-right mb-3 mr-2 mt-4">
                                                            <ErrorMessage name="total_errors" component="div" className="errorcolor"/>
                                                            <Button className="btn btn-pink mr-1 ml-1" type="submit" disabled={controlPanelDetailsIsEmpty}>Submit</Button>
                                                        </div>
                                                    </Form>
                                                )
                                            }}                                
                                        </Formik>
                                    </div>
                                </div>
                                : <div><SpinnerLoader /></div>}
                    </div>
                </div>
                <div style={loadingQuestionFull}><SpinnerLoader /></div>
            </div>            
        );
    }
}

const mapStateToProps =(state:any) => {
    return{
        loading: state.HomeStudy.loading,
        getChangeYearData: state.profile.getYear,
        stopYearDate: state.profile.yearStatusFails,
        getInternalGradle: state.QuestionBank.getInternalGradle,
        bluePrintQuestionTypeDropdown: state.QuestionBank.bluePrintQuestionTypeDropdown,
        bluePrintDifficultyLevelDropdown: state.QuestionBank.bluePrintDifficultyLevelDropdown,
        controlPanelById: state.HomeStudy.controlPanelById
    }
}
export default connect(mapStateToProps, {
    internalGradleGet,
    fetchBluePrintQuestionTypeDropdownGet,
    fetchBluePrintDifficultyLevelDropdownGet,
    fetchControlPanelByIdGet,
    editControlPanel    
})
(EditControlPanel)