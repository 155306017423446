export const tableHeading:any = [
    {
        name:'Grade',
        id:'grade'
    },
    {
        name:'Section',
        id:'standard'
    },
    {
        name:'Actions',
        id:''
    }
]
