import React, { Component } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import { Link } from 'react-router-dom';
import { QuestionBank } from '../../router/Roles';
import { Field, Formik, Form, FieldArray } from "formik";
import "./QuestionBankStyle.scss";
import { Button, MenuItem, FormGroup } from "@material-ui/core";
import { TextField } from "formik-material-ui";


const initialValues = {
    question_name: "",
    select_class: "",
    select_subject: "",
    questionlist: [
        {
            question: "",
            add_topics: "",
            question_name_A: "",
            question_name_B: "",
            question_name_C: "",
            question_name_D: "",
            answer: "",
        },
    ],
};


export type Props = {

}

interface StateTypes {
    academicYear?: any;
    questions?: any;
    editableId?: any;
}
export class ViewQuestionPaper extends Component<Props, StateTypes> {
    formik: any;
    constructor(props: any) {
        super(props);
        this.state = {
            academicYear: '',
            questions: {},
            editableId: '',
        }
    }
    componentDidMount(): void {
        const question = {
            'school_name': 'SRKV Test School',
            'class': '1st std',
            'subject_name': 'Maths',
            'total_marks': '100 marks',
            'question_records': [
                {
                    'title': 'Multiple Choice Questions',
                    'marks': '5 x 1 = 5 Marks',
                    'question': [
                        {
                            'name': 'What is the capital of France?',
                            's_no': '1',
                            'id': '1',
                            'is_options': true,
                            "options": [
                                {
                                    "option": "A",
                                    "value": "Central processing unit",
                                },
                                {
                                    "option": "B",
                                    "value": "Common user",
                                },
                                {
                                    "option": "C",
                                    "value": "Common processing unit",
                                },
                                {
                                    "option": "D",
                                    "value": "Central process unit",
                                }
                            ],
                        },
                        {
                            'name': 'What is the capital of France?',
                            's_no': '2',
                            'id': '2',
                            'is_options': true,
                            "options": [
                                {
                                    "option": "A",
                                    "value": "Central processing unit",
                                },
                                {
                                    "option": "B",
                                    "value": "Common user",
                                },
                                {
                                    "option": "C",
                                    "value": "Common processing unit",
                                },
                                {
                                    "option": "D",
                                    "value": "Central process unit",
                                }
                            ],
                        },
                    ]
                },
                {
                    'title': 'Fill in the Blanks',
                    'marks': '5 x 1 = 5 Marks',
                    'question': [
                        {
                            's_no': '1',
                            'id': '3',
                            'name': 'The process by which green plants make their own food using sunlight, carbon dioxide, and water is called ____________.',
                        },
                        {
                            's_no': '2',
                            'id': '4',
                            'name': "The study of the Earth's atmosphere and weather patterns is known as ____________.",
                        }
                    ]
                }
            ]
        }
        this.setState({ questions: question })
        window.scrollTo(0, 0);
    }

    private renderQuestionSectionView(records: any) {
        const { editableId } = this.state;
        return (
            <div>
                {records?.question_records && records.question_records.length > 0 ?
                    records.question_records.map((items: any) => (
                        <div className="question_section">
                            <div className="question_section_title">
                                <div>
                                    {items.title}
                                </div>
                                <div>
                                    {items.marks}
                                </div>
                            </div>
                            {items.question.length > 0 && items.question !== undefined ?
                                items.question.map((ques: any, index: any) => (
                                    <>
                                        {editableId === ques.id ? (
                                            <>
                                                <div className="questions_block">
                                                    {ques.s_no}.
                                                    <FormGroup>
                                                        <Field
                                                            label={<><span>Question Name</span><span className='formmandatorycolor'> *</span></>}
                                                            type="text"
                                                            name="question_name"
                                                            component={TextField}
                                                            className="textfield__input"
                                                            disabled={false}
                                                        />
                                                    </FormGroup>
                                                    {ques.is_options === true && (
                                                        <div>
                                                            {ques.options.map((option: any, index: any) => (
                                                                 
                                                                <div key={index}>
                                                                    <div className="d-flex mt-3">
                                                                    <div className="mr-3 mt-4">
                                                            {option.option}.
                                                        </div>
                                                        <FormGroup className="w-50">
                                                                    <Field
                                                                        label={<><span>Option</span><span className='formmandatorycolor'> *</span></>}
                                                                        type="text"
                                                                        name={`questionlist.${index}.question_name_A`}
                                                                        component={TextField}
                                                                        className="textfield__input"
                                                                        disabled={false}
                                                                    />
                                                                      </FormGroup>
                                                                </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                    <div className="text-right mb-3 mr-2 mt-4" >
                                                        <Button className="btn red mr-1 ml-1">
                                                            Submit
                                                        </Button>
                                                        <Button className="btn red mr-1 ml-1" onClick={() => this.handleCancel(ques.id)}>
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="questions_block">
                                                <div className="question_name">{ques.s_no}.    {ques.name}</div >
                                                {
                                                    ques.is_options ? (
                                                        <>
                                                            {ques.options.length > 0 ? (
                                                                ques.options.map((option: any, index: any) => (
                                                                    <>
                                                                        <div>{option.option}.    {option.value}</div>
                                                                    </>
                                                                ))
                                                            ) : null}
                                                        </>) : null

                                                }
                                            </div>
                                        )}
                                    </>
                                ))
                                : null}
                        </div >

                    ))
                    : null}
            </div >)
    }

    handleEdit = (id: any) => {
        this.setState({ editableId: id })
    };

    handleCancel = (id: any) => {
        this.setState({ editableId: '' })
    };
    render() {
        const { questions } = this.state;
        return (
            <div className="page-wrapper">
                <div className="page-content-wrapper">
                    <div className="page-content pt-3">
                        <BreadCrumb
                            titleName={['View Questions Paper']}
                            homeName={['Home']}
                            url={['dashboard']}
                            mainPageTitle={['View Questions Paper']} />

                        <div className="row">
                            <div className="col-md-12">
                                <Formik
                                    ref={node => this.formik = node}
                                    initialValues={initialValues}
                                    onSubmit={(values, { setSubmitting }) => {

                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        isSubmitting,
                                        isValidating,
                                        dirty,
                                        touched,
                                        handleReset,
                                        handleSubmit,
                                        setFieldValue,
                                    }) => {

                                        return (
                                            <Form>

                                                <div className="row question_paper_row">
                                                    
                                                    <div className="col-md-8 question_paper">
                                                        <div className="question_header">
                                                            <div className="question_header_name">
                                                                <h3>{questions.school_name}</h3>
                                                            </div>
                                                            <div className="question_header_details">
                                                                <div>
                                                                    <h5>{questions.class}</h5>
                                                                </div>
                                                                <div>
                                                                    <h5>{questions.subject_name}</h5>
                                                                </div>
                                                                <div>
                                                                    <h5>{questions.total_marks}</h5>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            {this.renderQuestionSectionView(questions)}

                                                        </div>


                                                    </div>


                                                </div>


                                            </Form>
                                        );
                                    }}
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}



const mapStateToProps = (state: any) => {
    return {

    }
}

export default connect(mapStateToProps, {

})(ViewQuestionPaper)