import React, { Component } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import { stopYearData } from "../../store/profile/Actions";
import { fetchAgeGet, getQuestionnaireByAge, editQuestionnaireMapping, fetchQuestionnaireMappingById, fetchActiveClassTeacher, fetchStudentListwithQuestionnaire } from "../../store/psychometric/Actions";
import { ModalView } from './ModalView';
import { ModalAlert } from './ModalAlert';
import { Field, Formik, Form } from "formik";
import { Button, MenuItem, FormGroup, Checkbox } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import Switch from '@material-ui/core/Switch';
import CommonLoader from '../../components/CommonLoader';
import { FormControlLabel } from '@material-ui/core';
import {
  fetchGetAllClassList,
  fetchGetStudent,
} from '../../store/diary/Actions';
import { PsychometricStudentList } from '../../components/PsychometricStudentList';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { fetchTeachersPost } from '../../store/teacher/Actions';
import { LoadMoreType } from '../../components/type';
import { fetchMyClassListGet, fetchMySectionListGet } from '../../store/classes/Actions';
import { Link } from "react-router-dom";
import { RouteComponentProps } from 'react-router';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import { getTeacherActiveNameList } from '../../store/teacher/Actions';
import history from '../../History';
import { schema } from "./PsychometricValidation";
import { fetchLicenseBySchoolGet } from '../../store/license/Actions';

interface OwnPropsParams {
  id: string;
}

interface OwnQuestSetProps extends RouteComponentProps<OwnPropsParams> {
  getYear?: any;
  getChangeYearData?: any;
  stopYearDate?: any;
  stopYearData: () => any;
  fetchGetAllClassList: (postPage: any) => any;
  fetchGetStudent: (getStudentList: any) => any;
  fetchTeachersPost: (getData: LoadMoreType) => any;
  fetchMyClassListGet: () => any;
  fetchMySectionListGet: (grade: any) => any;
  getQuestionnaireByAge: (getData: any) => any;
  fetchAgeGet: () => any;
  editQuestionnaireMapping: (postQuestion: any, edit: any, params: any) => any;
  fetchActiveClassTeacher: (postQuestion: any) => any;
  fetchQuestionnaireMappingById: (postValue: any) => any;
  getTeacherActiveNameList: () => any;
  fetchStudentListwithQuestionnaire: (getStudentList: any) => any;
  fetchLicenseBySchoolGet: (getData: any) => any;
  getProfile: any;
  ListGrade: any;
  ListSection: any;
  AllClassList: any;
  AllStudentList: any;
  classTeacherData: any;
  records: any;
  ListAge: any;
  questionnaireitems: any;
  questionnaireMappingDetails: any;
  loading: any;
  classLoading: any;
  loadingStudent?: any;
  teacherNameList?: any;
  activeClassTeacher?: any;
  addQuestionnaireMapping?: any;
  questionnareLoading?: any;
  license?: any;
  licenseBySchool?: any;
};


const initialValues = {
  question_name: "",
  is_parent_enable: false,
  is_otherteacher_enable: false,
  search_teacher_name: [],
};
interface StateTypes {
  academicYear?: any;
  modalShow: boolean;
  categoryQuestionList: any;
  categoryQuestionName: any;
  categoryQuestionDescription: any;
  selectedItems: any;
  selectedStudentList?: any,
  standardlist?: any,
  currentCheckboxAllStudent?: boolean,
  currentSelectedStandard?: any,
  isParentActive?: any,
  isClassTeacherActive?: any,
  isOtherTeacherActive?: any,
  addClassMapping?: any,
  selectedSection?: any,
  otherTeacherIds?: any,
  selectedAge?: any,
  selectedClass?: any,
  formDisable?: boolean,
  questionnaireMappingValues?: any,
  filterTeacherIds?: any,
  searchAutoCompleteTeacher?: any,
  formIsSubmitting?: boolean,
  remainingLicense?: any,
  defaultClassStudentLength?: any,
  defaultAgeRange?: any,
  licenseBySchool?: any,
  modalAlertShow?: boolean,
  alertMessage?: string,
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export class EditPscyometric extends Component<OwnQuestSetProps, StateTypes> {
  formik: any;
  getStudentList: any;
  postStudentList: any = [];
  getEditClassList: any = [];
  constructor(props: any) {
    super(props);
    this.state = {
      academicYear: '',
      modalShow: false,
      categoryQuestionList: [],
      categoryQuestionName:"",
      categoryQuestionDescription:"",
      selectedItems: [],
      selectedStudentList: [],
      standardlist: [],
      currentCheckboxAllStudent: false,
      currentSelectedStandard: [],
      isParentActive: false,
      isClassTeacherActive: false,
      isOtherTeacherActive: false,
      addClassMapping: [],
      selectedSection: [],
      otherTeacherIds: [],
      selectedAge: null,
      selectedClass: null,
      formDisable: true,
      questionnaireMappingValues: [],
      filterTeacherIds: [],
      searchAutoCompleteTeacher: [],
      formIsSubmitting: false,
      remainingLicense: 0,
      defaultClassStudentLength: 0,
      defaultAgeRange:"",
      licenseBySchool: {},
      modalAlertShow:false,
      alertMessage:''
    };
    this.handleCheckStudent = this.handleCheckStudent.bind(this);
  }
  componentDidMount(): void {
    window.scrollTo(0, 0);
    this.getCurrectYear();

  }



  // This is life cycle is initial load  component Will Update
  componentWillUpdate(nextProps: any, nextState: any) {
    const { stopYearDate } = this.props;
    if (stopYearDate === true) {
      this.getCurrectYear(this.props.getChangeYearData);
    }
  }
  // This is life cycle is initial load  component Did Update
  componentDidUpdate() {
    const { stopYearDate } = this.props;
    if (stopYearDate === true) {
      this.props.stopYearData();
    }
  }
  // This is function is used get correct year
  getCurrectYear(getYear?: any) {
    if (getYear && getYear !== undefined && getYear !== null) {
      this.getPsychometricDetails(getYear)
      this.setState({ academicYear: getYear });
    } else {
      this.getPsychometricDetails(this.props.getYear)
      this.setState({ academicYear: this.props.getChangeYearData });
    }
  }

  getPsychometricDetails(getYear?: any) {

    const getLicenseBySchool = {
      academic_year: getYear,
      module_name:'psychometric_module'
    }
    this.props.fetchLicenseBySchoolGet(getLicenseBySchool).then((res: any) => {
      const { licenseBySchool } = this.props
      const psychometricLicense =  licenseBySchool;
      this.setState({ licenseBySchool: psychometricLicense, remainingLicense: psychometricLicense.remaining_license });
    })



    const loadMoreType = {
      academic_year: getYear
    }

    this.props.fetchAgeGet();

    const value: any = {
      unique_id: this.props.match.params.id,
      academic_year: getYear
    }
    this.props.getTeacherActiveNameList();
    this.props.fetchQuestionnaireMappingById(value).then((res: any) => {
      const { questionnaireMappingDetails, teacherNameList } = this.props
      let SubjectUpdateList: any = [];
      SubjectUpdateList = [];

      questionnaireMappingDetails.additional_teacher_ids.forEach((classList: any) => {
        let getClassId = teacherNameList.find((item: any) => {
          if (item.ldap_id === classList) {
            return true
          }
          return false
        })

        if (getClassId) {
          SubjectUpdateList.push(getClassId);
        }
      })
      this.setState({
        otherTeacherIds: questionnaireMappingDetails.additional_teacher_ids,
        isParentActive: questionnaireMappingDetails.is_parent,
        categoryQuestionList: questionnaireMappingDetails.question_details,
        categoryQuestionName: questionnaireMappingDetails.name,
        categoryQuestionDescription: questionnaireMappingDetails.description,
        isClassTeacherActive: questionnaireMappingDetails.is_class_teacher,
        questionnaireMappingValues: questionnaireMappingDetails,
        isOtherTeacherActive: questionnaireMappingDetails.additional_teacher_ids.length > 0,
        addClassMapping: questionnaireMappingDetails.additional_teacher_ids,
        searchAutoCompleteTeacher: SubjectUpdateList,
        defaultClassStudentLength:questionnaireMappingDetails.class_student_details.length,
        defaultAgeRange:questionnaireMappingDetails.age_range,
      });

      if (questionnaireMappingDetails.class_student_details.length > 0) {

        const getTestStatus = questionnaireMappingDetails.class_student_details.some((item: any) => item.test_status === 'notstarted');
        if (getTestStatus) {
          this.setState({ formDisable: false });
        }
        const classIds = Array.from(new Set(questionnaireMappingDetails.class_student_details.map((item: any) => item.int_class_id)));
        const { academicYear } = this.state;
        if (classIds && classIds.length > 0 && academicYear) {
          const getData = {
            class_id_list: classIds,
            academic_year: academicYear.toString(),
          }
          this.props.fetchActiveClassTeacher(getData).then((res: any) => {
            const { activeClassTeacher } = this.props;
            if(questionnaireMappingDetails.is_class_teacher){
              this.setState({ filterTeacherIds: activeClassTeacher });
            }
            const organizedStudents = questionnaireMappingDetails.class_student_details.reduce((acc: any, student: any) => {
              const { int_class_id, class_name, student_name, student_id, class_id, is_all, ...studentData } = student;
              // Check if there is an existing entry for the classId
              const existingEntry = acc.find((entry: any) => entry.int_class_id === int_class_id);
              if (existingEntry) {
                // If an entry already exists, push the student into the Studentlist
                existingEntry.studentslist.push({ id: student_id, student_name: student_name, ...studentData });
              } else {
                // If no entry exists, create a new entry for the classId
                const teacherByClass = activeClassTeacher.find((teacher:any) => teacher.class === class_id);
                acc.push({
                  id: int_class_id,
                  all: is_all,
                  grade_standard: class_name,
                  int_class_id,
                  class_teacher:teacherByClass ? [teacherByClass] : [],
                  studentslist: [{ id: student_id, student_name: student_name, ...studentData }],
                });
              }
    
              return acc;
            }, []);
            this.setState({ selectedStudentList: organizedStudents });
            // const { isClassTeacherActive } = this.state;
            // this.filterTeacherBySelectedStudent('', isClassTeacherActive);
          });
        }


      }

      window.scrollTo(0, 0);
    });
    this.props.fetchGetAllClassList(loadMoreType).then((res: any) => {
      const { AllClassList } = this.props
      if (AllClassList.length > 0) {
        this.setState({ standardlist: AllClassList });
      }
    })
  }

  standardonSelect(e: any, data: any) {
    const { academicYear } = this.state;
    const { item } = data;
    const { questionnaireitems, questionnaireMappingDetails } = this.props
    const standardlistele: any = document.getElementById("sectionlist");
    if (standardlistele.querySelector('.btn-info')) {
      standardlistele.querySelector('.btn-info').classList.remove('btn-info');
    }
    this.setState({ currentSelectedStandard: item });
    e.target.classList.add('btn-info')
    const getStudent = {
      class_id: item.id,
      academic_year: academicYear,
      age_range: questionnaireitems.age_range ? questionnaireitems.age_range : questionnaireMappingDetails.age_range,
      unique_id: this.props.match.params.id,
    }

    e.target.classList.add('active')
    const { selectedStudentList, isClassTeacherActive } = this.state;
    this.props.fetchStudentListwithQuestionnaire(getStudent).then((res: any) => {
      this.unSelectAll();
      const findDiariesList = selectedStudentList.find((item: any) => item.id === getStudent.class_id);
      if (findDiariesList) {
        this.selectPreviousSelectedStudents(findDiariesList);
      }
      this.getRemainingLicense()
      const { classTeacherData } = this.props;
      if((isClassTeacherActive && classTeacherData && classTeacherData.length == 0)){
        this.activeDisableStudentCheckbox(true)
      }
    });
    //this.filterTeacherBySelectedStudent(item.id, isClassTeacherActive);
  }
  filterTeacherBySelectedStudent(id: any = null, isClassTeacherActive: any) {
    const { academicYear, selectedStudentList } = this.state;
    if (isClassTeacherActive && id) {
      const filteredData = selectedStudentList.filter((item: any) => item.studentslist.length > 0);
      const classIds = filteredData.map((item: any) => item.id);
      const updatedClassIdList = [...classIds, id];
      if (updatedClassIdList && updatedClassIdList.length > 0 && academicYear) {
        const getData = {
          class_id_list: updatedClassIdList,
          academic_year: academicYear.toString(),
        }
        this.props.fetchActiveClassTeacher(getData).then((res: any) => {
          const { activeClassTeacher } = this.props;
          this.setState({ filterTeacherIds: activeClassTeacher });
          const allTeacherLdapIds = activeClassTeacher.map((teacher: any) => teacher.teacher_id);
          const filteredTeacher = this.state.searchAutoCompleteTeacher.filter((teacher: any) => !allTeacherLdapIds.includes(teacher.ldap_id));
          this.formik.setFieldValue(`search_teacher_name`,filteredTeacher)
          this.setState({ searchAutoCompleteTeacher: filteredTeacher });

        });
      }

    }
    else if (isClassTeacherActive) {
      const filteredData = selectedStudentList.filter((item: any) => item.studentslist.length > 0);
      const classIds = filteredData.map((item: any) => item.id);
      if (classIds && classIds.length > 0 && academicYear) {
        const getData = {
          class_id_list: classIds,
          academic_year: academicYear.toString(),
        }
        this.props.fetchActiveClassTeacher(getData).then((res: any) => {
          const { activeClassTeacher } = this.props;
          this.setState({ filterTeacherIds: activeClassTeacher });
          const allTeacherLdapIds = activeClassTeacher.map((teacher: any) => teacher.teacher_id);
          const filteredTeacher = this.state.searchAutoCompleteTeacher.filter((teacher: any) => !allTeacherLdapIds.includes(teacher.ldap_id));
         this.formik.setFieldValue(`search_teacher_name`,filteredTeacher)
          this.setState({ searchAutoCompleteTeacher: filteredTeacher });
        });
      }else{

      const allTeacherLdapIds = this.props.activeClassTeacher.map((teacher: any) => teacher.teacher_id);
      const filteredTeacher = this.state.searchAutoCompleteTeacher.filter((teacher: any) => !allTeacherLdapIds.includes(teacher.ldap_id));
      this.formik.setFieldValue(`search_teacher_name`,filteredTeacher)
      this.setState({ searchAutoCompleteTeacher: filteredTeacher });
      }
    } else {
      this.setState({ filterTeacherIds: [] });
    }
  }

  filterTeacherBySelectStudent(){
    const { classTeacherData } = this.props;
    const { academicYear, selectedStudentList, isClassTeacherActive } = this.state;
    if(isClassTeacherActive){
      const gradeStandardsWithTeachers = selectedStudentList
      .filter((item:any) => item.class_teacher?.length > 0 && item.studentslist?.length > 0)
      //.filter((item:any) => item.studentslist.length !== 0)
      .map((item:any) => item.class_teacher[0]);

      const gradeStandardsWithTeachersNoStudent = selectedStudentList
      .filter((item:any) => item.class_teacher?.length !== 0)
      .filter((item:any) => item.studentslist.length === 0)
      .map((item:any) => item.class_teacher[0]);

      const teacherIds = gradeStandardsWithTeachersNoStudent.map((teacher: any) => teacher.teacher_id);
      const filtered = classTeacherData.filter(
        (teacher: any) => !teacherIds.includes(teacher.teacher_id)
      );
      const updatedClassIdList = [...gradeStandardsWithTeachers, ...filtered];  
         this.setState({ filterTeacherIds: updatedClassIdList });
         const allTeacherLdapIds = updatedClassIdList.map((teacher: any) => teacher.teacher_id);
         const filteredTeacher = this.state.searchAutoCompleteTeacher.filter((teacher: any) => !allTeacherLdapIds.includes(teacher.ldap_id));
         this.formik.setFieldValue(
          `search_teacher_name`,
          filteredTeacher
      )
         this.setState({ searchAutoCompleteTeacher: filteredTeacher });
    }
  }

  selectPreviousSelectedStudents(data: any) {
    const { AllStudentList } = this.props;
    const { all } = data;
    const selectAllCheckbox: any = document.getElementById('student_all');
    if (all) {

      AllStudentList.forEach((item: any, index: any) => {
        const checkbox: any = document.getElementById('student_' + item.id);
        checkbox['checked'] = true;
      })

      selectAllCheckbox['checked'] = true;
      this.setState({ currentCheckboxAllStudent: true })
    } else {

      data.studentslist.forEach((item: any, index: any) => {
        const checkbox: any = document.getElementById('student_' + item.id);
        checkbox['checked'] = true;
      })
      if (data.studentslist.length === AllStudentList.length) {
        const selectAllCheckbox: any = document.getElementById('student_all');
        selectAllCheckbox['checked'] = true;
        this.setState({ currentCheckboxAllStudent: true })
      }
    }

  }

  getSectionList(e: any, getValue: any) {
    const value = getValue;
    const getGrade = {
      grade: value
    }

    const standardlistele: any = document.getElementById("standardlist");
    if (standardlistele.querySelector('.btn-pink')) {
      standardlistele.querySelector('.btn-pink').classList.remove('btn-pink');
    }
    e.target.classList.add('btn-pink')

    const sectionlistele: any = document.getElementById("sectionlist");
    if (sectionlistele.querySelector('.btn-info')) {
      sectionlistele.querySelector('.btn-info').classList.remove('btn-info');
    }

    this.setState({ currentSelectedStandard: [], selectedClass: value });

    this.props.fetchMySectionListGet(getGrade).then((res: any) => {
      const { ListSection } = this.props;
      this.setState({ selectedSection: ListSection });

    });
  }


  selectAllStudents() {
    const { AllStudentList } = this.props;
    const { currentSelectedStandard, selectedStudentList } = this.state;
    if (!this.state.currentCheckboxAllStudent) {
      if (AllStudentList.length === 0) {
        this.unSelectAll();
      }
      else {
        // Selected All
        this.SelectAll();
        const selectedAllStandardStudents: any = currentSelectedStandard;
        const trueOverallStatusIds = AllStudentList
          .filter((student: any) => !student.overall_status)
          .map((student: any) => student.id);

        if (trueOverallStatusIds && trueOverallStatusIds.length <= 0) {
          selectedAllStandardStudents['all'] = true;
          selectedAllStandardStudents['class_teacher'] = this.props.classTeacherData;
          selectedAllStandardStudents['studentslist'] = AllStudentList; // if you want to add all studentlist in add AllStudent array
        } else {
          selectedAllStandardStudents['class_teacher'] = this.props.classTeacherData;
          selectedAllStandardStudents['studentslist'] = AllStudentList.filter((item: any) => item.overall_status);
        }
        
        const filteredPeople = selectedStudentList.filter((item: any) => item.id !== currentSelectedStandard.id);
        //this.setState({ selectedStudentList: [...filteredPeople, selectedAllStandardStudents] }, this.getRemainingLicense)
        this.setState({
          selectedStudentList: [...filteredPeople, selectedAllStandardStudents]
      }, () => {
        this.getRemainingLicense()
        this.filterTeacherBySelectStudent()
      });
      }
    } else {
      // unseleted all
      this.unSelectAll();
      const filteredPeople = selectedStudentList.filter((item: any) => item.id !== currentSelectedStandard.id);
     // this.setState({ selectedStudentList: filteredPeople }, this.getRemainingLicense)
      this.setState({
        selectedStudentList: filteredPeople
    }, () => {
      this.getRemainingLicense()
      this.filterTeacherBySelectStudent()
    });
    }
  }

  getRemainingLicense() {
    const { selectedStudentList,defaultClassStudentLength,currentSelectedStandard, licenseBySchool } = this.state;
    const { license } = this.props;
  
    const totalStudentsCount = selectedStudentList.reduce((total: any, item: any) => total + item.studentslist.length, 0);
    const remainingLicense = (licenseBySchool.remaining_license + defaultClassStudentLength) - totalStudentsCount;
   
    if (remainingLicense > 0) {
      const allCheckboxes = document.querySelectorAll('ul.autoselection li.list-group-item input[type="checkbox"][data-disabled="false"]');
      allCheckboxes.forEach((item: any, index: any) => {
        item['disabled'] = false;
      })

      this.setState({ remainingLicense: remainingLicense })
    } else {
      const allCheckboxes = document.querySelectorAll('ul.autoselection li.list-group-item input[type="checkbox"][data-disabled="false"]');
      allCheckboxes.forEach((item: any, index: any) => {
        if (!item.checked) {
          item['disabled'] = true;
        } else {
          item['disabled'] = false;
        }

      })

      this.setState({ remainingLicense: remainingLicense })
    }

    const item = selectedStudentList.find((item: any) => item.id === currentSelectedStandard?.id);
    const  currentSelectedStandardStudentslist = item ? item.studentslist.length : 0;
    const { AllStudentList } = this.props;
      let selectAllCheckbox: any = document.getElementById('student_all');
      if(selectAllCheckbox){
      if (remainingLicense < (AllStudentList.length - currentSelectedStandardStudentslist)) {
        selectAllCheckbox['disabled'] = true;
      }else{
        selectAllCheckbox['disabled'] = false;
      }}


  }

  activeDisableStudentCheckbox(value?:any){
    const allCheckboxes = document.querySelectorAll('ul.autoselection li.list-group-item input[type="checkbox"][data-disabled="false"]');
      allCheckboxes.forEach((item: any, index: any) => {
          item['disabled'] = value;
      })
      let selectAllCheckbox: any = document.getElementById('student_all');
      if(selectAllCheckbox){
        selectAllCheckbox['disabled'] = value;
      }
  }

  unSelectAll() {
    // unseleted all
    const { AllStudentList } = this.props;
    let selectAllCheckbox: any = document.getElementById('student_all');
    AllStudentList.forEach((item: any, index: any) => {
      const checkbox: any = document.getElementById('student_' + item.id);
      checkbox['checked'] = false;
    })
    this.setState({ currentCheckboxAllStudent: false })
    if (selectAllCheckbox) {
      selectAllCheckbox['checked'] = false;
    }
  }

  SelectAll() {
    const { AllStudentList } = this.props;
    const selectAllCheckbox: any = document.getElementById('student_all');
    AllStudentList.forEach((item: any, index: any) => {
      let checkbox: any = document.getElementById('student_' + item.id);
      checkbox['checked'] = true;
    })
    selectAllCheckbox['checked'] = true;
    this.setState({ currentCheckboxAllStudent: true })
  }

  getEditClassMappingList(getValue?: any, getData?: any, resonse?: any) {
    this.getEditClassList = [];
    const teacherList: any = [];
    if (getData) {
      getData.forEach((items: any) => {
        const getClass = String(items.ldap_id);
        this.getEditClassList.push(getClass)
        teacherList.push(items)
      })
      this.setState({ addClassMapping: this.getEditClassList, searchAutoCompleteTeacher: teacherList })
    }
  }

  handleCheckStudent(e: any) {
    const { selectedStudentList, currentSelectedStandard } = this.state;
    const { AllStudentList, classTeacherData } = this.props;
    const standardId: any = currentSelectedStandard;
    const checkbox: any = document.getElementById('student_' + e.currentTarget.dataset.id);
    if (checkbox.checked) {
      checkbox['checked'] = true;
      const availableDiareslist = selectedStudentList.filter((item: any) => item.id === standardId.id);
      if (availableDiareslist.length) {
        const findDiariesList = selectedStudentList.map((item: any) => {
          if (item.id === standardId.id) {
            item.studentslist.push(AllStudentList.filter((item: any) => item.id === parseInt(e.currentTarget.dataset.id))[0])
            if (item.studentslist.length === AllStudentList.length) {
              const selectAllCheckbox: any = document.getElementById('student_all');
              selectAllCheckbox['checked'] = true;
              item.all = true;
              checkbox['checked'] = true;
            }
          }
          return item;
        });
       // this.setState({ selectedStudentList: findDiariesList }, this.getRemainingLicense);
        this.setState({ selectedStudentList: findDiariesList }, 
          () => {
        this.getRemainingLicense()
        this.filterTeacherBySelectStudent()
      });
      }
      else {
        const selectedAllStandardStudents: any = currentSelectedStandard;
        selectedAllStandardStudents['all'] = false;
        selectedAllStandardStudents['studentslist'] = AllStudentList.filter((item: any) => item.id === parseInt(e.currentTarget.dataset.id));
        if(AllStudentList.length == 1){
          selectedAllStandardStudents['all'] = true;
          const selectAllCheckbox: any = document.getElementById('student_all');
          selectAllCheckbox['checked'] = true;
        }
        selectedAllStandardStudents['class_teacher'] = classTeacherData;
        //this.setState({ selectedStudentList: [...selectedStudentList, selectedAllStandardStudents] }, this.getRemainingLicense)
        this.setState({
          selectedStudentList: [...selectedStudentList, selectedAllStandardStudents]
      }, () => {
        this.getRemainingLicense()
        this.filterTeacherBySelectStudent()
      });
      }
     // this.filterTeacherBySelectStudent()
    } else {
      const findDiariesList = selectedStudentList.map((item: any) => {
        if (item.id === standardId.id) {
          let studentslist;
          if (item.all) {
            studentslist = AllStudentList.filter((item: any) => item.id !== parseInt(e.currentTarget.dataset.id))
          } else {
            studentslist = item.studentslist.filter((item: any) => item.id !== parseInt(e.currentTarget.dataset.id))
          }
          item.studentslist = studentslist;
          item.all = false;
        }
        return item;
      })
     // this.setState({ selectedStudentList: findDiariesList }, this.getRemainingLicense);
      this.setState({
        selectedStudentList: findDiariesList
    }, () => {
      this.getRemainingLicense()
      this.filterTeacherBySelectStudent()
    });

      this.setState({ currentCheckboxAllStudent: false });
      const selectAllCheckbox: any = document.getElementById('student_all');
      selectAllCheckbox['checked'] = false;
      checkbox['checked'] = false;
    }
  }

  isParentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ isParentActive: event.target.checked });
  }

  // isClassTeacherChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   this.setState({ isClassTeacherActive: event.target.checked });
  //   this.filterTeacherBySelectedStudent('', event.target.checked);

  // }
  
  isClassTeacherChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.checked){
     const { selectedStudentList } = this.state;
     const { classTeacherData } = this.props;
     const gradeStandardsWithoutTeachers = selectedStudentList
       .filter((item:any) => item.class_teacher?.length === 0)
       .filter((item:any) => item.studentslist.length !== 0)
       .map((item:any) => item.grade_standard);
 
       if(gradeStandardsWithoutTeachers.length > 0) {
        
         const message = `No class teacher following classes ${gradeStandardsWithoutTeachers.join(', ')}`;
         this.setState({ modalAlertShow: true,alertMessage:message });
       }
       else{
     this.setState({ isClassTeacherActive: event.target.checked });
         this.filterTeacherBySelectedStudent('', event.target.checked);
         if((classTeacherData && classTeacherData.length == 0)){
           this.activeDisableStudentCheckbox(true)
         }
       }
     }else{
       this.setState({ isClassTeacherActive: event.target.checked });
       this.filterTeacherBySelectedStudent('', event.target.checked);
       this.activeDisableStudentCheckbox(false)
     }
   }

  isOtherTeacherChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ isOtherTeacherActive: event.target.checked });

  }



  onConfirm = (getValue: any) => {

  }

  onCancel = (getValue: any) => {
    this.setState({ modalShow: false, modalAlertShow: false });
  }

  openModel = () => {
    this.setState({ modalShow: true });
  }

  handleToggleItem = (item: any) => {
    const { selectedItems } = this.state
    const updatedItems = selectedItems.includes(item)
      ? selectedItems.filter((selectedItem: any) => selectedItem !== item)
      : [...selectedItems, item];

    this.setState({ selectedItems: updatedItems });
  }

  getPostSubjectList = (e: any, getValue?: any) => {
    const loadMoreType = {
      id: getValue
    }
    if (getValue) {
      this.props.getQuestionnaireByAge(loadMoreType).then((res: any) => {
        const { questionnaireitems } = this.props
        this.setState({ 
          categoryQuestionList: questionnaireitems.question_details,
          categoryQuestionName:questionnaireitems.name,
          categoryQuestionDescription:questionnaireitems.description
         });

      })
    }
    this.setState({ defaultAgeRange: getValue });
  };

  render() {

    const { getProfile, ListAge, questionnaireitems, questionnaireMappingDetails, loading, classLoading, loadingStudent, questionnareLoading, teacherNameList, license } = this.props;
    const spinnerLoading = { display: loading || classLoading || loadingStudent || questionnareLoading ? "block" : "none" };

    const getTeacherList = this.props.records;
    const {
      modalShow,
      categoryQuestionList,
      selectedItems,
      selectedStudentList,
      standardlist,
      isParentActive,
      isClassTeacherActive,
      isOtherTeacherActive,
      currentSelectedStandard,
      selectedSection,
      otherTeacherIds,
      selectedAge,
      selectedClass,
      questionnaireMappingValues,
      filterTeacherIds,
      searchAutoCompleteTeacher,
      formIsSubmitting,
      remainingLicense,
      defaultAgeRange,
      categoryQuestionName,
      categoryQuestionDescription,
      modalAlertShow,
      alertMessage
    } = this.state;
    // let SubjectUpdateList: any = [];
    // SubjectUpdateList = [];

    let gradeList: any;
    if (this.props.ListGrade) {
      gradeList = this.props.ListGrade;
    }

    let age: any;
    if (this.props.ListAge) {
      age = this.props.ListAge;
    }


    let teacherList: any;
    teacherList = teacherNameList;
    const teacherfilteredData = teacherList.filter((student: any) => !filterTeacherIds.some((teacher: any) => teacher.teacher_id === student.ldap_id));

    let getStudentList: any;
    if (this.props.AllStudentList) {
      getStudentList = this.props.AllStudentList;
    }
    return (
      <div>
        <ModalView
          onState={modalShow}
          onConfirm={this.onConfirm}
          onCancel={this.onCancel}
          categoryQuestionList={categoryQuestionList}
          handleToggleItem={this.handleToggleItem}
          selectedItems={selectedItems}
        />
         <ModalAlert
          onState={modalAlertShow}
          onCancel={this.onCancel}
          message={alertMessage}
        />
        <div className="page-wrapper">
          <div className="page-content-wrapper">
            <div className="page-content pt-3">
              <BreadCrumb
                titleName={["Edit Psychometric Test"]}
                homeName={["Home"]}
                url={["dashboard"]}
                mainPageTitle={["Edit Psychometric Test"]}
                baseName={["psychometric"]}
                baseURL={["psychometric"]}
              />
 {
                                     defaultAgeRange ? (
              <div className="row">
                <div className="col-md-12">
                  <Formik
                    ref={node => this.formik = node}
                    initialValues={{
                      select_age: defaultAgeRange,
                      is_parent_enable: isParentActive,
                      is_otherteacher_enable: isOtherTeacherActive,
                      search_teacher_name: searchAutoCompleteTeacher,
                    }}
                    validationSchema={schema}
                    onSubmit={(values, { setSubmitting }) => {
                      this.setState({ formIsSubmitting: true })
                      let submitForm = true;
                      if(isClassTeacherActive){
                        const gradeStandardsWithoutTeachers = selectedStudentList
                        .filter((item:any) => item.class_teacher?.length === 0)
                        .filter((item:any) => item.studentslist.length !== 0)
                        .map((item:any) => item.grade_standard);
                        if(gradeStandardsWithoutTeachers.length > 0) {
                          const message = `No class teacher following classes ${gradeStandardsWithoutTeachers.join(', ')}`;
                          this.setState({ modalAlertShow: true,alertMessage:message });
                            submitForm = false;
                        }
                      }
                      let getQuestionValue: any = [];
                      let submitValue: any = [];
                      this.postStudentList = []
                      if (submitForm && values && (isParentActive || isClassTeacherActive || isOtherTeacherActive)) {
                        let studentList: any;
                        selectedStudentList.forEach((items: any, index: any) => {
                          items.studentslist.forEach((students: any) => {
                            studentList = {
                              student_id: students.id,
                              class_id: items.id,
                              user_id: students.user_id,
                              test_status: students.test_status
                            }
                            this.postStudentList.push(studentList)
                          })
                        })
                        let questionnairename: any;
                        let questionnairedescription: any;
                        let questionnaireagerange: any;
                        if (questionnaireitems && questionnaireitems.length > 0) {
                          questionnairename = questionnaireitems.name
                          questionnairedescription = questionnaireitems.description
                          questionnaireagerange = questionnaireitems.age_range
                        } else {
                          questionnairename = questionnaireMappingDetails.name
                          questionnairedescription = questionnaireMappingDetails.description
                          questionnaireagerange = questionnaireMappingDetails.age_range
                        }
                        const otherTeacherIds = this.state.searchAutoCompleteTeacher.map((teacher:any) => teacher.ldap_id);
                        getQuestionValue = {
                          school_id: getProfile.school_id,
                          class_student_details: this.postStudentList,
                          is_class_teacher: this.state.isClassTeacherActive,
                          is_parent: this.state.isParentActive,
                          additional_teacher_ids: this.state.isOtherTeacherActive ? otherTeacherIds : [],
                          academic_year: this.state.academicYear,
                          name: categoryQuestionName,
                          description: categoryQuestionDescription,
                          age_range: defaultAgeRange,
                          question_details: categoryQuestionList,
                        };

                        this.props.editQuestionnaireMapping(getQuestionValue, true, this.props.match.params.id).then((res: any) => {
                          //history.push("/psychometric");
                          const { addQuestionnaireMapping } = this.props
                          if (addQuestionnaireMapping.status == true && !addQuestionnaireMapping?.error_list) {
                            history.push("/psychometric");
                          }

                        });
                        setSubmitting(false);
                      }
                    }}
                  >
                    {({
                      values,
                      errors,
                      isSubmitting,
                      isValidating,
                      dirty,
                      touched,
                      handleReset,
                      handleSubmit,
                      setFieldValue,
                    }) => {
                      return (
                        <Form>
                          <div className="row">

                            <div className="col-md-6">

                              <div className="col-md-12">
                                <FormGroup className="w-100 mb-4">
                                  {
                                    getProfile.usertype === 2 && defaultAgeRange ? (
                                      <Field
                                        label={<><span>Select Age</span><span className='formmandatorycolor'> *</span></>}
                                        name="select_age"
                                        select
                                        component={TextField}
                                        disabled={false}
                                        value={defaultAgeRange}
                                        onChange={(e: any, value: any) => {
                                          setFieldValue(
                                            "select_age",
                                            e.target.value
                                          );
                                          this.getPostSubjectList(e, e.target.value);
                                        }}
                                      >
                                        {age && age.length > 0 && age.map((item: any) => (
                                          <MenuItem value={item.id}>
                                            {item.name}
                                          </MenuItem>
                                        ))}
                                      </Field>
                                    ) : null}
                                </FormGroup>
                              </div>
                              {categoryQuestionName ?
                                (<div className="col-md-12">
                                  <div className="docListWindow small-slimscroll-style autoselection" style={{ padding: '0', margin: '0' }}>
                                    <div className="title-sticky zindex0 list-group-item">
                                      <label><strong>Name :</strong> {categoryQuestionName}</label>
                                      <label><strong>Description :</strong> {categoryQuestionDescription}</label>
                                      <Button className="btn btn-pink mr-1 ml-1" onClick={this.openModel}>
                                        View Question
                                      </Button>
                                    </div>
                                  </div>
                                </div>) : null}

                              <div style={{ margin: "20px", marginTop: "20px" }}>Exam invigilator</div>
                              <div className="row col-md-12" style={{ marginTop: '20px' }}>

                                <FormControlLabel
                                  value="end"
                                  control={<Switch
                                    checked={isParentActive}
                                    onChange={
                                      (e) => {
                                        this.isParentChange(e)
                                      }
                                    }

                                    name="is_parent_enable"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    title={isParentActive ? "is parent Active" : "is parent disable"}
                                  />}
                                  className="col-md-3"
                                  label="Is parent"
                                  labelPlacement="end"
                                />

                                <FormControlLabel
                                  value="end"
                                  control={<Switch
                                    checked={isClassTeacherActive}
                                    onChange={this.isClassTeacherChange}
                                    name="is_classteacher_enable"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    title="Activate Student"
                                  />}
                                  className="col-md-4"
                                  label="Is Class teacher"
                                  labelPlacement="end"
                                />

                                <FormControlLabel
                                  value="end"
                                  control={<Switch
                                    checked={isOtherTeacherActive}
                                    onChange={(e) => {
                                      setFieldValue('is_otherteacher_enable', e.target.checked)
                                      this.isOtherTeacherChange(e)
                                    }}
                                    name="is_otherteacher_enable"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    title="Activate Student"
                                  />}
                                  className="col-md-4"
                                  label="Other teacher"
                                  labelPlacement="end"
                                />

                                {questionnaireMappingValues && questionnaireMappingValues?.unique_id ? ((isOtherTeacherActive && teacherfilteredData && otherTeacherIds && otherTeacherIds.length > 0) ? <div className="col-md-12" style={{ marginTop: "20px" }}>
                                  <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0 pb-0">
                                    <FormGroup>
                                      <Autocomplete
                                        fullWidth
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={teacherfilteredData}
                                        disableCloseOnSelect
                                        noOptionsText={'No matching records found.'}
                                        value={searchAutoCompleteTeacher}
                                        getOptionLabel={(option: any) => option.firstname}
                                        onChange={(e, value, resonse) => {
                                          setFieldValue('search_teacher_name', value);
                                          this.getEditClassMappingList(e, value, resonse)
                                        }}
                                        renderOption={(option, { selected }) => {
                                          return (
                                            <React.Fragment>
                                              <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                              />
                                              {option.firstname}
                                            </React.Fragment>
                                          )
                                        }}
                                        className="mb-0 mt-1"
                                        renderInput={(params) => (
                                          <Field component={TextField}
                                            {...params}
                                            label={<><span>Search Teacher Name</span><span className='formmandatorycolor'> *</span></>}
                                            name="search_teacher_name"
                                            onChange={(e: any) => {
                                              //setFieldValue('EditclassNameId', e.target.value)
                                            }}
                                          />
                                        )}
                                      />

                                    </FormGroup>
                                  </div>
                                </div>
                                  : (isOtherTeacherActive && teacherfilteredData && otherTeacherIds && otherTeacherIds.length === 0) ? <div className="col-md-12" style={{ marginTop: "20px" }}>
                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0 pb-0">
                                      <FormGroup>
                                        <Autocomplete
                                          fullWidth
                                          multiple
                                          id="checkboxes-tags-demo"
                                          options={teacherfilteredData}
                                          value={searchAutoCompleteTeacher}
                                          disableCloseOnSelect
                                          noOptionsText={'No matching records found.'}
                                          getOptionLabel={(option: any) => option.firstname}
                                          onChange={(e, value, resonse) => {
                                            setFieldValue('search_teacher_name', value);
                                            this.getEditClassMappingList(e, value, resonse)
                                          }}
                                          renderOption={(option, { selected }) => {
                                            return (
                                              <React.Fragment>
                                                <Checkbox
                                                  icon={icon}
                                                  checkedIcon={checkedIcon}
                                                  style={{ marginRight: 8 }}
                                                  checked={selected}
                                                />
                                                {option.firstname}
                                              </React.Fragment>
                                            )
                                          }}
                                          className="mb-0 mt-1"
                                          renderInput={(params) => (
                                            <Field component={TextField}
                                              {...params}
                                              label={<><span>Search Teacher Name</span><span
                                                className='formmandatorycolor'> *</span></>}
                                              name="search_teacher_name"
                                              onChange={(e: any) => {
                                                //setFieldValue('EditclassNameId', e.target.value)
                                              }}
                                            />
                                          )}
                                        />

                                      </FormGroup>
                                    </div>
                                  </div>
                                    : null)
                                  : null}
                              </div>

                              <>
                                {formIsSubmitting && !isParentActive && !isClassTeacherActive && !isOtherTeacherActive ? (
                                  <div className="col-md-12 text-center mt-3">

                                    <div className="errorcolor">
                                      Please Select any of the Exam Invigilator
                                    </div>

                                  </div>
                                ) : null}
                              </>

                              {license &&
                              <div className="col-md-12" style={{ marginTop: '20px' }}>
                                <div className="row">
                                  <div className="col-lg-4">
                                    <div className="overview-panel purple
                                            text-center mt-2 mb-1">
                                      <div className="value white">
                                        <p className="mb-1 mt-1"> Total License :</p>
                                        <h5 className="mt-1 color-pink">
                                        {remainingLicense}</h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div> }

                            </div>

                            <div className="col-md-6">
                              <div style={{ margin: "10px", marginTop: "20px", marginBottom: "10px" }}>Select Class</div>
                              <div className="row col-md-12">
                                {/* List the class details list */}
                                <div id="standardlist" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                  {gradeList ?
                                    <>
                                      {gradeList.map((item: any, index: any) => (
                                        <span className="btn btn-circle" style={{ marginLeft: '10px' }} key={index} onClick={(e) => this.getSectionList(e, item.grade_id)}>{item.value}</span>
                                      ))}
                                    </>
                                    : <CommonLoader />}
                                </div>

                                <div id="sectionlist" className="col-md-12" style={{ marginTop: '20px', marginBottom: '20px', padding: '0' }}>
                                  {selectedClass && selectedSection && selectedSection.length ?
                                    <>
                                      <div style={{ margin: "10px", marginTop: "0", marginBottom: "10px" }}>Select Section</div>
                                      {selectedSection.map((item: any, index: any) => (
                                        <span className="btn btn-squre" style={{ marginLeft: '10px' }} key={index} onClick={(e) => this.standardonSelect(e, { item, index })}>{item.value}</span>
                                      ))}
                                    </>
                                    : <div className="m-3"> <h5>Please Select Class</h5></div>}
                                </div>

                                <div className="col-md-12" style={{ padding: '0', margin: '0' }}>
                                  <ul className="docListWindow small-slimscroll-style autoselection">
                                    {currentSelectedStandard?.id && getStudentList && getStudentList.length > 0 ?
                                      <>
                                        <li className="title-sticky list-group-item">
                                          <div className="form-check">
                                            <input type="checkbox" className="form-check-input" defaultChecked={this.state.currentCheckboxAllStudent} id={'student_all'} onClick={() => this.selectAllStudents()} />
                                            <label className="form-check-label" htmlFor="exampleCheck1"><strong>Select All</strong></label>
                                          </div>
                                        </li>
                                        <PsychometricStudentList studentlist={getStudentList} handleCheckStudent={this.handleCheckStudent} />
                                      </>
                                      : currentSelectedStandard?.id ? <div className="col-md-12"> No Student Selected Class </div> : null}
                                  </ul>
                                </div>

                                {/* {this.getAllStudentDetails ? */}
                                <div className="col-12" style={{ marginTop: '20px', marginBottom: '10px' }}>
                                  {selectedStudentList ?
                                    <>
                                      {selectedStudentList.map((item: any, index: any) => (
                                        <>
                                          {item.all ?
                                            <span className="btn btn-circle btn-circle btn-info disabled mr-2 mb-3" key={index}>{item.grade_standard} </span>
                                            : <>{item.studentslist.length > 0 && item.studentslist !== undefined ?

                                              item.studentslist.map((slist: any, index: any) => (
                                                <span className="btn btn-circle btn-circle btn-danger disabled mr-2 mb-3" key={index}>
                                                  <span className="userNameTitle">{slist.student_name} ({item.grade_standard})</span></span>
                                              ))
                                              : null}</>
                                          }
                                        </>
                                      ))}
                                    </> : 'No Selected'}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="text-right mb-3 mr-2 mt-4">
                            <Button
                              className="btn btn-pink mr-1 ml-1"
                              type="submit"
                              disabled={
                                this.state.formDisable}
                            >
                              Submit
                            </Button>
                            <Link to={"/psychometric"}>
                              <Button className="btn btn-default mr-1 ml-1 ">
                                Cancel
                              </Button>
                            </Link>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
             ) : null}
            </div>
          </div >
        </div >
        <div style={spinnerLoading}><SpinnerLoader /></div>
      </div >
    );
  }
}

const mapStateToProps = (state: any, ownProps: RouteComponentProps<OwnPropsParams>) => {
  return {
    loading: state.psychometric.loading,
    classLoading: state.classes.loading,
    loadingStudent: state.diary.loading,
    getChangeYearData: state.profile.getYear,
    stopYearDate: state.profile.yearStatusFails,
    getProfile: state.profile.profileData,
    ListGrade: state.classes.my_class,
    ListSection: state.classes.my_section,
    AllClassList: state.diary.gradelist,
    AllStudentList: state.psychometric.student_list,
    classTeacherData: state.psychometric.class_teacher_data,
    getYear: state.profile.getYear,
    records: state.teacher.records,
    teacherNameList: state.teacher.GetTeacherActiveName,
    ListAge: state.psychometric.ageList,
    questionnaireitems: state.psychometric.questionnaireitems,
    questionnaireMappingDetails: state.psychometric.questionnaireMappingDetails,
    activeClassTeacher: state.psychometric.activeClassTeacher,
    addQuestionnaireMapping: state.psychometric.addQuestionnaireMapping,
    questionnareLoading: state.psychometric.questionnareLoading,
    license: state.license.license,
    licenseBySchool: state.license.licenseBySchool
  };
};

export default connect(mapStateToProps, {
  stopYearData,
  fetchGetAllClassList,
  fetchGetStudent,
  fetchTeachersPost,
  fetchMyClassListGet,
  fetchMySectionListGet,
  fetchAgeGet,
  getQuestionnaireByAge,
  editQuestionnaireMapping,
  getTeacherActiveNameList,
  fetchQuestionnaireMappingById,
  fetchStudentListwithQuestionnaire,
  fetchActiveClassTeacher,
  fetchLicenseBySchoolGet
})(EditPscyometric);
