import React, { Component } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import { DashboardAPI } from "../../services/Config";
import { reqUserGuideDownloadFile } from "../../store/usermanage/Actions";
import SpinnerLoader from "../../components/spinner/SpinnerLoader";
import { Button } from "@material-ui/core";
import * as FileSaver from 'file-saver';

export type PropsTypes = {
  loading: boolean;
  reqUserGuideDownloadFile: any;
  getDownloadFile:any;
  getProfile:any;
  guideList?: any;
}
interface StateTypes {
  userguideList?: any;
}
export class HelpguideDownload extends Component<PropsTypes, StateTypes> {
  constructor(props: any) {
    super(props);
    this.state = {
      userguideList: [],
    };
  }
 
  getguides() {
    this.props.reqUserGuideDownloadFile().then((res: any) => {
      // const { guideList } = this.props.getDownloadFile.data;
      this.setState({ userguideList: this.props.getDownloadFile.data });
      // console.log("userguideList:",userguideList);
    });
   
  }
  componentDidMount(): void {
    window.scrollTo(0, 0);
    this.getguides();
  }

  openInNewTab = (url: any) => {
    // console.log("items",url);
    const newWindow = window.open(
      `${process.env.REACT_APP_API_URL}${url}`,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  openInNewTabPDF = (url: any) => {
    // console.log("items in pdf",url);
    const newWindow = window.open(
      `${process.env.REACT_APP_API_URL}${url}`,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) {
      newWindow.opener = null;
    }
  };
  onDownloadFile = (items:any) =>{
    // console.log("items",items);
    let file:any;
    // this.props.reqUserGuideDownloadFile(file).then(()=>{
    //   console.log("file:",file);
    //   var blob = new Blob([this.props.getDownloadFile], { type: 'application/vnd.ms-word' });
    //     FileSaver.saveAs(blob, 'USER_MANUAL.docx');
    // })
    
         
  }


  
  
  render() {
    const { loading } = this.props;
    const { userguideList } = this.state;
    const loadingTeacher = { display: loading ? "block" : "none" };
    const getUserType:any = this.props.getProfile.usertype;
    let academicAdminUserTypes:any = false;
    // console.log("usertype in download:",getUserType);
      if(getUserType === 2)
      {
          academicAdminUserTypes = true;
      // console.log("usertype in download:",getUserType);
      }
      else{
        // console.log("usertype in download:",getUserType);
      }
    return (
      <div>
        <div className="page-wrapper">
        <div className="page-content-wrapper">
          <BreadCrumb
              titleName={["Help"]}
              homeName={["Home"]}
              url={['dashboard']}
              mainPageTitle={["Help"]}
            />
            <div className="row">
              {userguideList && userguideList.length > 0 
                ? userguideList.map((items: any) => (
                    <div className="col-md-2">
                      <div className="card card-topline-red">
                        <div className="m-3 mt-0 mb-0">
                          <div className="p-4">
                            { 
                              items.file_type === 'pdf' ?(
                            <img
                              className="width100"
                              alt=""
                              src="../../assets/img/pdf1.png"
                            />
                            ) : (
                              <img
                              className="width100"
                              alt=""
                              src="../../assets/img/word.png"
                            />
                            )
                            }
                          </div>
                          <div className="doctor-profile">
                            <div className="width50 mb-4">
                              <h5>{items.filename}</h5>
                            </div>
                            <div>
                              <button
                                className="px-2 btn-circle btn-pink"
                                onClick={() => this.openInNewTabPDF(items.url)}>
                                <i className="fa fa-download"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : 
                <div className="col-md-12">
                <div className="card card-topline-red">
                  <h4 className="p-3">No Data Found</h4>
                  </div>
                  </div>
                  }
            </div>
          </div>
          
        </div>
        <div style={loadingTeacher}>
          <SpinnerLoader />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    loading: state.userManage.loading,
    getDownloadFile:state.userManage.downloadUserGuideFiles,
    getProfile:state.profile.profileData,
  };
};

export default connect(mapStateToProps, { reqUserGuideDownloadFile })(HelpguideDownload);
