import React, { Component } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import { Link } from 'react-router-dom';
import { QuestionBank } from '../../router/Roles';
import { Field, Formik, Form, FieldArray, ErrorMessage } from "formik";
import { Button, MenuItem, FormGroup } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { get } from "lodash";
import { fetchSuperuserLicenseDetailsGet, CreateLicensePartition, fetchLicenseSchoolListGet } from '../../store/license/Actions';
import { getYearData, stopYearData } from '../../store/profile/Actions'
import { getAllSchoolNameList } from '../../store/usermanage/Actions';
import { schema } from "./LicensePartitionValidation";
import SpinnerLoader from '../../components/spinner/SpinnerLoader';


export type Props = {
    getYear?: any;
    stopYear?: any;
    licenseModuleDetails?: any;
    getSchoolList?: any;
    licenseSchoolList?: any;
    loading?: any;
    getYearData: (postvalue?: any) => any;
    stopYearData: () => any;
    fetchSuperuserLicenseDetailsGet: (getData: any) => any;
    getAllSchoolNameList: () => any;
    fetchLicenseSchoolListGet: () => any;
    CreateLicensePartition: (postQuestion: any) => any;
}

interface StateTypes {
    academicYear?: any;
    allLicenseModule?: any;
    allSchoolList?: any;
}


export class AddLicensePartition extends Component<Props, StateTypes> {
    formik: any;
    constructor(props: any) {
        super(props);
        this.state = {
            allSchoolList: [],
            academicYear: "",
            allLicenseModule: []
        }
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.getUpdateYear()
        this.props.fetchLicenseSchoolListGet().then((res: any) => {
            const { licenseSchoolList } = this.props;
            const filteredSchools = licenseSchoolList.filter((school: any) => !school.created);
            this.setState({ allSchoolList: filteredSchools })
        });
    }


    componentWillUpdate(nextProps: any, nextState: any) {
        const { stopYear } = this.props;
        if (stopYear === true) {
            this.getUpdateYear(this.props.getYear)
        }
    }
    componentDidUpdate() {
        const { stopYear } = this.props;
        if (stopYear === true) {
            this.props.stopYearData()
        }
    }
    getUpdateYear(getYear?: any) {
        if (getYear && getYear !== undefined && getYear !== null) {
            this.setState({ academicYear: getYear })
            this.getfetchSuperuserLicenseDetailsData(getYear)
        } else {
            this.setState({ academicYear: this.props.getYear })
            this.getfetchSuperuserLicenseDetailsData(this.props.getYear)
        }
    }
    getfetchSuperuserLicenseDetailsData(getYear?: any) {
        this.props.fetchSuperuserLicenseDetailsGet({ academic_year: getYear }).then((res: any) => {
            const { licenseModuleDetails } = this.props;
            const newData = licenseModuleDetails.data.map((module: any) => ({ ...module, license_count: 0 }));
            this.setState({ allLicenseModule: newData })
        });
    }

    render() {
        const { allSchoolList, allLicenseModule } = this.state;
        const { loading } = this.props;
        const loadingFull = { display: loading || (allLicenseModule && allLicenseModule.length < 0) ? "block" : "none" };
        let question_list: any = [
            {
                module_name: "",
            },
        ];
        if (allLicenseModule) {

            question_list = allLicenseModule;
        }

        const initialValuesData: any = {
            school_id: "",
            license_details: question_list
        }


        return (
            <div className="page-wrapper">
                <div className="page-content-wrapper">
                    <div className="page-content pt-3">
                        <BreadCrumb
                            titleName={['Create Partition']}
                            homeName={['Home']}
                            url={['dashboard']}
                            mainPageTitle={['Create Partition']} />
                        {
                            allLicenseModule && allLicenseModule.length > 0 ? (
                                <div className="row">
                                    <div className="col-md-12">
                                        <Formik
                                            ref={node => this.formik = node}
                                            initialValues={initialValuesData}
                                            validationSchema={schema}
                                            onSubmit={(values, { setSubmitting }) => {
                                                
                                                let getQuestionValue: any = [];
                                                getQuestionValue = {
                                                    school_id: values.school_id,
                                                    license_details: values.license_details,

                                                };

                                                this.props.CreateLicensePartition(getQuestionValue)
                                            }}
                                        >
                                            {({
                                                values,
                                                errors,
                                                isSubmitting,
                                                isValidating,
                                                dirty,
                                                touched,
                                                handleReset,
                                                handleSubmit,
                                                handleChange,
                                                setFieldValue,
                                            }) => {

                                                return (
                                                    <Form>

                                                        <div className="row">

                                                            <div className="col-md-4">
                                                                <FormGroup className="w-100 mb-4">
                                                                    <Field
                                                                        label={<><span>School Name</span><span className='formmandatorycolor'> *</span></>}
                                                                        name="school_id"
                                                                        select
                                                                        component={TextField}
                                                                        onChange={(e: any, value: any) => {
                                                                            setFieldValue(
                                                                                "school_id",
                                                                                e.target.value
                                                                            );
                                                                        }}
                                                                    >
                                                                        {allSchoolList && allSchoolList.length > 0 && allSchoolList.map((item: any) => (
                                                                            <MenuItem value={item.id}>
                                                                                {item.school_name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Field>
                                                                </FormGroup>
                                                            </div>

                                                            <div className="col-md-12">
                                                                <FieldArray
                                                                    name="license_details"
                                                                    render={({ insert, remove, push }) => (
                                                                        <>
                                                                            {values.license_details.length > 0 &&
                                                                                values.license_details.map(
                                                                                    (license: any, index: any) => (

                                                                                        <div className="row" key={index}>
                                                                                            <div className="col-md-12">
                                                                                                <div className="card-box mt-4">
                                                                                                    <div className="card-body pb-5 row">
                                                                                                        <div className="col-md-6">
                                                                                                            <FormGroup className="w-100">
                                                                                                                <Field
                                                                                                                    label={<><span>Module</span><span className='formmandatorycolor'> *</span></>}
                                                                                                                    name={`license_details.${index}.module_name`}
                                                                                                                    component={TextField}
                                                                                                                    className="textfield__input"
                                                                                                                    disabled={true}
                                                                                                                >

                                                                                                                </Field>
                                                                                                            </FormGroup>
                                                                                                        </div>

                                                                                                        <div className="col-md-6">
                                                                                                            <FormGroup className="w-100">
                                                                                                                <Field
                                                                                                                    label={<><span>License Count</span><span className='formmandatorycolor'> *</span></>}
                                                                                                                    name={`license_details.${index}.license_count`}
                                                                                                                    type="number"
                                                                                                                    component={TextField}
                                                                                                                    className="textfield__input"
                                                                                                                    max={100}
                                                                                                                    disabled={false}
                                                                                                                />
                                                                                                            </FormGroup>
                                                                                                        </div>



                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                )}

                                                                        </>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="text-right mb-3 mr-2 mt-4">

                                                            <Button
                                                                className="btn btn-pink mr-1 ml-1"
                                                                type="submit"


                                                            >
                                                                Submit
                                                            </Button>


                                                        </div>
                                                    </Form>
                                                );
                                            }}
                                        </Formik>


                                    </div>
                                </div>
                            ) : null}
                    </div>
                </div>
                <div style={loadingFull}><SpinnerLoader /></div>
            </div>
        )
    }
}



const mapStateToProps = (state: any) => {
    return {
        loading: state.license.loading,
        getYear: state.profile.getYear,
        stopYear: state.profile.yearStatusFails,
        licenseModuleDetails: state.license.licenseModuleDetails,
        license: state.license.license,
        getSchoolList: state.userManage.category,
        licenseSchoolList: state.license.licenseSchoolList,
    }
}

export default connect(mapStateToProps, {
    fetchSuperuserLicenseDetailsGet,
    stopYearData,
    getYearData,
    getAllSchoolNameList,
    CreateLicensePartition,
    fetchLicenseSchoolListGet
})(AddLicensePartition)