import React from 'react'
import Modal from 'react-bootstrap/Modal'

export const CommonModel = (props:any) => {
        return (
            <div>
                <Modal show={props.onState}>
                    <Modal.Header className="pb-0 pt-0">
                        <Modal.Title>{props.currentState.title}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {props.currentState.type === 'logout'?
                        <p className="mb-2">{props.currentState.action_status}</p>
                        :
                        <p className="mb-2">Are you sure you want to {props.currentState.action_status} the {props.currentState.action_name}?</p>
                        }
                        
                    </Modal.Body>

                    <Modal.Footer className="modelboxbutton">
                        <button className="btn btn-danger mr-1 ml-1 w-15"  onClick={props.onConfirm}>OK</button>
                        <button className="btn btn-default mr-1 ml-1 w-15" onClick={props.onCancel}>Cancel</button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
}
