import React from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import { Field, FieldArray, Form, Formik } from "formik";
import { Button, FormGroup, MenuItem } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { fetchGetAllClassList } from "../../store/diary/Actions";
import { studentPromotion, fetchGetStudentByAcademicYear, fetchGetStudentNextClass } from "../../store/student/Actions";
import SpinnerLoader from "../../components/spinner/SpinnerLoader";
import { stopYearData } from '../../store/profile/Actions'
import * as yup from 'yup';

export type OwnStudentPromotionProps = {
    loading?: boolean
    DairyLoading?: boolean
    studentLoading?: boolean
    getYear?: any;
    stopYearDate?: any;
    fetchGetAllClassList: (postPage: any) => any;
    fetchGetStudentByAcademicYear: (postPage: any) => any;
    fetchGetStudentNextClass: (postPage: any) => any;
    studentPromotion: (postPage: any) => any;
    stopYearData: () => any;
    getAllClassList?: any;
    AllStudentList?: any;
    getStudentNextClass?: any;
}

interface StateTypes {
    academicYear: null;
    grade?: any;
    studentList?: any;
    nextClass?: any;
    sort_by: string;
    sort_class_name: string;
    sort_title_name: string;
    page: number;
    acsOrder: boolean;
    search: string;
    hasMore: boolean;
    SortOrderData: string;
    OrderNameData: string;
    selectAll: boolean
}

const schema = yup.object({
    new_class_id: yup.string()
        .required('Value is required'),
        previous_class_id: yup.string()
        .required('Value is required'),
})

class StudentPromotionGrid extends React.Component<OwnStudentPromotionProps, StateTypes> {
    formik: any;
    constructor(props: any) {
        super(props)
        this.state = {
            academicYear: null,
            grade: [],
            studentList: [],
            nextClass: [],
            page: 1,
            sort_by: 'desc',
            sort_class_name: 'fa fa-sort-amount-asc',
            sort_title_name: 'Ascending order',
            acsOrder: true,
            search: '',
            hasMore: true,
            SortOrderData: '',
            OrderNameData: '',
            selectAll: false
        };
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.getCurrectYear()
    }
    // This is life cycle is initial load  component Will Update
    componentWillUpdate(nextProps: any, nextState: any) {
        const { stopYearDate } = this.props;
        if (stopYearDate === true) {
            this.getCurrectYear(this.props.getYear)
        }
    }

    componentDidUpdate() {
        const { stopYearDate } = this.props;
        if (stopYearDate === true) {
            this.props.stopYearData()
        }
    }

    getCurrectYear(getYear?: any) {
        if (getYear && getYear !== undefined && getYear !== null) {
            this.getClassList(getYear)
            this.setState({ academicYear: getYear })
        } else {
            this.getClassList(this.props.getYear)
            this.setState({ academicYear: this.props.getYear })
        }
    }

    getClassList(getYear?: any) {
        const postData = {
            academic_year: getYear
        }
        this.props.fetchGetAllClassList(postData).then((res: any) => {
            const { getAllClassList } = this.props;
            this.setState({ grade: getAllClassList });
        })
    }

    handleClassChange = (e: any) => {
        const { value } = e.target;
        this.setState({ grade: value })
    }

    GetStudentList(e: any, getValue?: any, setFieldValue?: any) {
        const { academicYear } = this.state
        const postData = {
            class_id: getValue,
            academic_year: academicYear,
        }
        if (getValue && academicYear) {
            this.props.fetchGetStudentByAcademicYear(postData).then((res: any) => {
                const { AllStudentList } = this.props
                this.setState({ studentList: AllStudentList, selectAll:false})

                const studentListPromotion = AllStudentList && AllStudentList.map((item: any) => ({
                    name: item.student_name,
                    is_passed: false,
                    card_id: item.studentclass_details[0].card_id
                }))
                setFieldValue("StudentPromoteList", studentListPromotion)
                setFieldValue("new_class_id", '')
            })
        }
    }

    showFilterData(getValues: any,previous_class_id:any,setFieldValue?: any ) {
        const { academicYear, page } = this.state;
        if (getValues.sort_by === 'desc') {
            this.setState({
                acsOrder: false,
                SortOrderData: getValues.sort_by,
                OrderNameData: getValues.order_by
            })
            const postValue = {
                // page_no: 1,
                class_id:previous_class_id,
                academic_year: academicYear,
                // search: this.state.search,
                sort_by: getValues.sort_by,
                order_by: getValues.order_by
            }
            this.props.fetchGetStudentByAcademicYear(postValue).then((res: any) => {
                const { AllStudentList } = this.props    
                const studentList = AllStudentList && AllStudentList.map((item: any) => ({
                    name: item.student_name,
                    is_passed: false,
                    card_id: item.studentclass_details[0].card_id
                }))   
                setFieldValue('StudentPromoteList',studentList)       
                this.setState({ studentList: AllStudentList,selectAll: false })
            });
            this.setState({
                hasMore: true,
                sort_by: 'asc',
                sort_class_name: 'fa fa-sort-amount-desc',
                sort_title_name: 'Descending order'
            })
        } else {
            this.setState({
                acsOrder: true,
                SortOrderData: getValues.sort_by,
                OrderNameData: getValues.order_by,
            })
            const postValue = {
                academic_year: academicYear,
                class_id:previous_class_id,
                sort_by: getValues.sort_by,
                order_by: getValues.order_by
            }
            this.setState({
                hasMore: true,
                SortOrderData: getValues.sort_by,
                OrderNameData: getValues.order_by,
                page: 1,
                sort_by: 'desc',
                sort_class_name: 'fa fa-sort-amount-asc',
                sort_title_name: 'Ascending order'
            })
            this.props.fetchGetStudentByAcademicYear(postValue).then((res: any) => {
                const { AllStudentList } = this.props
                const studentList = AllStudentList && AllStudentList.map((item: any) => ({
                    name: item.student_name,
                    is_passed: false,
                    card_id: item.studentclass_details[0].card_id
                }))   
                setFieldValue('StudentPromoteList',studentList)  
                this.setState({ studentList: AllStudentList, selectAll: false })
            });
        }
    }

    getNewClass(e: any, classId: number, setFieldValue?: any) {
        const postData = {
            class_id: classId,
        }
        if (classId) {
            this.props.fetchGetStudentNextClass(postData).then((res: any) => {
                const { getStudentNextClass } = this.props
                this.setState({ nextClass: getStudentNextClass })
                if(getStudentNextClass.length > 0){
                    this.GetStudentList(e, e.target.value, setFieldValue);
                }else{
                    this.setState({ studentList: [], selectAll:false})  
                    const studentListPromotion:any = []
                    setFieldValue("StudentPromoteList", studentListPromotion)
                    setFieldValue("new_class_id", '')
                }
                
            })
        }
    }

    handleSelectAllChange = (e: any, values: any, setFieldValue: any) => {
        const isChecked = e.target.checked;
        // setSelectAll(isChecked);
        this.setState({selectAll: isChecked})
        values.StudentPromoteList.map((item: any, index: number) => {
            setFieldValue(`StudentPromoteList[${index}].is_passed`, isChecked);
        });
    };

    handleSelectChange = (e: any, values: any, card_id: any) => {
        const isChecked = e.target.checked;       
        const result =values.StudentPromoteList
        .filter((item:any) => item.card_id !== card_id)
        .every((item:any) => item.is_passed === true);
        if(isChecked && result){
            this.setState({selectAll: isChecked})

        }else{
            this.setState({selectAll: false})
        }
    };

    render() {
        const { loading, DairyLoading, studentLoading } = this.props
        const loadingData = { display: loading || DairyLoading || studentLoading ? "block" : "none" };
        const { grade, studentList, nextClass, academicYear, sort_by, sort_class_name, sort_title_name } = this.state;
      
        const initialListValues = {
            new_class_id: '',
            previous_class_id: '',
            StudentPromoteList: studentList && studentList.map((item: any) => ({
                name: item.student_name,
                is_passed: false,
                card_id: item.studentclass_details[0].card_id
            }))
        }
        return (
            <div>
                <div className="page-wrapper">
                    <div className="page-content-wrapper">
                        <div className="page-content pt-3">
                            <BreadCrumb
                                titleName={['Students Promotion']}
                                homeName={['Home']}
                                url={['dashboard']}
                                mainPageTitle={['Students Promotion']}
                            />
                            {grade && studentList && nextClass ?
                                <div className="col-md-12">
                                    <Formik
                                        ref={node => this.formik = node}
                                        initialValues={initialListValues}
                                        validationSchema={schema}
                                        onSubmit={(values, actions) => {
                                            const getPromotionDetails = {
                                                school_id: studentList[0].school_id,
                                                new_class_id: values.new_class_id,
                                                previous_class_id: values.previous_class_id,
                                                previous_academic_year: academicYear,
                                                students_list_to_promote: values.StudentPromoteList.map((item: any) => ({
                                                    "card_id": item.card_id,
                                                    "is_passed": item.is_passed,
                                                }))
                                            }
                                             
                                           this.props.studentPromotion(getPromotionDetails).then((res: any) => {
                                            this.setState({ studentList: [], selectAll:false, nextClass:[]})  
                                            const studentListPromotion:any = []
                                            this.formik.setFieldValue("StudentPromoteList", studentListPromotion)
                                            this.formik.setFieldValue("new_class_id", '')
                                            this.formik.setFieldTouched('new_class_id', false);
                                            this.formik.setFieldValue("previous_class_id", '')
                                            this.formik.setFieldTouched('previous_class_id', false);

                                           });
                                        }}
                                    >
                                        {({ values, errors, isSubmitting, isValidating, handleChange, dirty, touched, setFieldValue }) => (
                                            <Form>
                                                <div className="row">
                                                    <div className="col-md-6 col-sm-6 col-6 mb-4">
                                                        <FormGroup>
                                                            <Field
                                                                label={<><span>Previous Class</span><span className='formmandatorycolor'> *</span></>}
                                                                name="previous_class_id"
                                                                select component={TextField}
                                                                disabled={false}
                                                                onChange={(e: any, value: any) => {
                                                                    setFieldValue(
                                                                        "previous_class_id",
                                                                        e.target.value
                                                                    )
                                                                   // this.GetStudentList(e, e.target.value, setFieldValue);
                                                                    this.getNewClass(e, e.target.value, setFieldValue);
                                                                }}
                                                            >
                                                                {grade.map((item: any) => (
                                                                    <MenuItem value={item.id}>{item.grade_standard}</MenuItem>
                                                                ))
                                                                }
                                                            </Field>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6 col-6">
                                                        <FormGroup>
                                                            <Field
                                                                label={<><span>New Class</span><span className='formmandatorycolor'> *</span></>}
                                                                name="new_class_id"
                                                                select
                                                                component={TextField}
                                                                disabled={false}
                                                                onChange={(e: any, value: any) => {
                                                                    setFieldValue(
                                                                        "new_class_id",
                                                                        e.target.value,
                                                                    )
                                                                }}
                                                            >
                                                                {nextClass.map((item: any) => (
                                                                    <MenuItem value={item.id}>{item.grade_standard}</MenuItem>
                                                                ))
                                                                }
                                                            </Field>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <FieldArray
                                                            // key={index}
                                                            name={`StudentPromoteList`}
                                                            render={({ insert, remove, push }) => (
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                    { values.StudentPromoteList.length > 0 ? <div className="card card-topline-red mt-4">
                                                                             <table className="table table-striped custom-table table-hover">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>
                                                                                        <Button onClick={() => this.showFilterData({ sort_by: sort_by, order_by: 'card_id' },values.previous_class_id,setFieldValue)}
                                                                                                title={sort_title_name} className="headerBold">Card Id&nbsp;
                                                                                                <i className={sort_class_name} aria-hidden="true"></i></Button>
                                                                                        </th>
                                                                                        <th>
                                                                                        <Button onClick={() => this.showFilterData({ sort_by: sort_by, order_by: 'student_name' },values.previous_class_id,setFieldValue)}
                                                                                                title={sort_title_name} className="headerBold">Name&nbsp;
                                                                                                <i className={sort_class_name} aria-hidden="true"></i></Button>
                                                                                        </th>
                                                                                        <th>
                                                                                            <div className="form-check mt-4">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    className="form-check-input"
                                                                                                    checked={this.state.selectAll}
                                                                                                    onChange={(e) =>this.handleSelectAllChange(e, values, setFieldValue)}
                                                                                                // id={`StudentList[${index}].is_passed`}
                                                                                                // checked={item.is_passed}
                                                                                                // onChange={(e: any) => {
                                                                                                //     setFieldValue(`StudentPromoteList[${index}].is_passed`, e.target.checked)
                                                                                                // }}
                                                                                                />
                                                                                                Promotion
                                                                                            </div>
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {values.StudentPromoteList && values.StudentPromoteList.map((item: any, index: number) => {
                                                                                    return(
                                                                                        <tr>
                                                                                            <td><label>{item.card_id}</label></td>
                                                                                            <td><label>{item.name}</label></td>
                                                                                            <td>
                                                                                                <div className="form-check mt-4">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        className="form-check-input"
                                                                                                        name={`StudentPromoteList[${index}].is_passed`}
                                                                                                        id={`StudentList[${index}].is_passed`}
                                                                                                        checked={item.is_passed}
                                                                                                        onChange={(e: any) => {
                                                                                                            setFieldValue(`StudentPromoteList[${index}].is_passed`, e.target.checked)
                                                                                                            this.handleSelectChange(e, values, item.card_id)
                                                                                                        }}
                                                                                                    />
                                                                                                    <label className="form-check-label" htmlFor={`StudentList[${index}].is_passed`}>Promotion</label>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )})}
                                                                                </tbody>
                                                                            </table>
                                                                            </div> : <div>No student Found</div> } 
                                                                    </div>
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="text-right mb-3 mr-2 mt-4">
                                                    <Button
                                                        className="btn btn-pink mr-1 ml-1"
                                                        type="submit"
                                                        disabled={
                                                            (this.state.studentList && this.state.studentList.length <= 0)
                                                        || !values.StudentPromoteList.some((item:any) => item.is_passed === true)
                                                        }
                                                    >
                                                        Submit
                                                    </Button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                                : <div><SpinnerLoader /></div>
                            }
                        </div>
                    </div>
                    <div style={loadingData}><SpinnerLoader /></div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        loading: state.profile.loading,
        DairyLoading: state.diary.loading,
        studentLoading: state.student.loading,
        getYear: state.profile.getYear,
        stopYearDate: state.profile.yearStatusFails,
        getAllClassList: state.diary.gradelist,
        AllStudentList: state.student.studentList,
        getStudentNextClass: state.student.studentNextClassDetail
    }
}

const mapDispatchToProps = {
    fetchGetAllClassList,
    fetchGetStudentByAcademicYear,
    fetchGetStudentNextClass,
    studentPromotion,
    stopYearData
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentPromotionGrid)