import React from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import CommonLoader from '../../components/CommonLoader';
import BreadCrumb from '../../components/BreadCrumb';
import { Link } from 'react-router-dom';
import { formValidationPatten } from "../../services/Constants";
import { CommonModel } from "../../components/CommonModel";
import { fetchControlPanelGet, deleteControlPanelById } from '../../store/HomeStudy/Actions';
import { HomeStudy } from '../../router/Roles'; 

export type Props = {
    loading?: boolean
    records?: any
    page: number
    per_page: number
    total: number
    totalPage: number
    fetchControlPanelGet: (data: any) => any;
    deleteControlPanelById: (id: number) => any;
}

interface StateTypes {
    records: any;
    search: string,
    hasMore: boolean,
    page: any,
    page_no: any,
    SortOrderData: string,
    OrderNameData: string,
    showDelete: boolean,
    deleteId: any,
}

class ControlPanelGrid extends React.Component<Props, StateTypes>{
    constructor(props:any) {
        super(props);
        this.state = {
            records: [],
            search: '',
            hasMore: true,
            page: 1,
            page_no: 1,
            SortOrderData: '',
            OrderNameData: '',
            showDelete: false,
            deleteId:'',
        }
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.getControlPanelDetails()
    }

    getControlPanelDetails() {
        const params = {
          page_no: 1,
          search: this.state.search,
          order_by: this.state.OrderNameData,
          sort_by: this.state.SortOrderData,
        }
        this.props.fetchControlPanelGet(params).then((res: any)=>{
            this.setState({ hasMore: true, page:1 })
        })
    }

    public fetchMorePanelData = () => {
        const { page } = this.state;
        if (this.state.hasMore === true) {
            if (Math.ceil(this.props.total / this.props.per_page) > this.props.page) {
                const params = {
                    page_no: page+ 1,
                    search: this.state.search,
                    order_by: this.state.OrderNameData,
                    sort_by: this.state.SortOrderData,
                }
                this.props.fetchControlPanelGet(params).then((res: any)=>{
                    this.setState({ page: page + 1 })
                })
            }  
            if (Math.ceil(this.props.total / this.props.per_page) === this.props.page) {
                this.setState({
                  hasMore: false,
                })
            }
        }
    }

    private renderControlPanel(records:any){
        const { loading } = this.props;
        const  loadingQuestionFull = { display: loading ? "block" : "none" };

        return(
            <div>
                <InfiniteScroll
                    dataLength={records.length}   
                    next={this.fetchMorePanelData}                 
                    hasMore={this.state.hasMore}
                    loader={<h4 style={loadingQuestionFull}>Loading...</h4>}
                >
                   <table className="table table-striped custom-table table-hover">
                    <thead>
                        <tr>
                            <th>Grade</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {records.length > 0 ?
                            records.map((items: any) => (
                                <tr>
                                    <td >{items['grade_name'] || '-'}</td>                                    
                                    <td>
                                            {items.uid  ?
                                                <Link to={`/control-panel/edit/${items.uid}`}>                                                
                                                    <button className="btn btn-primary btn-xs"
                                                        title="Edit"><i className="fa fa-pencil" aria-hidden="true"></i></button></Link> :
                                                <button className="btn btn-disable btn-xs"
                                                    title="Edit"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                            }
                                        </td>                                        
                                        <td>
                                        {items.uid ?
                                            <button className="btn btn-primary btn-xs"
                                                title="Delete" 
                                                onClick={() => this.showDeletePopup(items.uid)}><i className="fa fa-trash" 
                                                aria-hidden="true"
                                            ></i></button> :
                                            <button className="btn btn-disable btn-xs"
                                                title="Delete"><i className="fa fa-trash" aria-hidden="true"
                                            ></i></button>
                                            }
                                    </td>
                                </tr>                                
                            ))
                            : <CommonLoader />}
                    </tbody>
                   </table>
                </InfiniteScroll>
            </div>
        )
    }

    showDeletePopup (getData: any) {
        if(getData){
            this.setState({ showDelete: true, deleteId: getData});
        }
    }

    public hideControlPanelDelete = () => {
        this.setState({ showDelete: false });
    }

    public handleDelete = () => {
        this.setState({ showDelete: false, page: 1, hasMore: true });
        this.props.deleteControlPanelById(this.state.deleteId).then((res:any)=>{
            this.getControlPanelDetails()
        })
    }

    handleInputChange = (e: any) => {
        const { value } = e.target;
        const getSearchValue = value;
        var intRegex = formValidationPatten.alphanumericTest;
        if(intRegex.test(getSearchValue) || getSearchValue === ''){
           if (getSearchValue) {
          const postSearchValue:any = {
            page_no: 1,
            search: getSearchValue,
          }
          this.props.fetchControlPanelGet(postSearchValue);
          this.setState({
            hasMore: true,
            page: 1,
            search: getSearchValue
          })
        } else {
          const postSearchValue:any = {
            page_no: 1,
            search: '',
          }      
          this.props.fetchControlPanelGet(postSearchValue);
          this.setState({
            hasMore: true,
            page: 1,
            search: ''
          })
        }
        }
      }

      refreshControlPanel = () =>{
        const postSearchValue:any = {
            page_no: 1,
            search: '',
        }
        this.setState({
          search: '',
          SortOrderData:'',
          OrderNameData:'',
        }, () => {
            this.props.fetchControlPanelGet(postSearchValue)
        });  
    }

    render() {
        const { loading, records } = this.props;
        const loadingQuestionFull = { display: loading ? "block" : "none" };
        const { showDelete } = this.state;
        const postValue:any = {
            title:'Please Confirm',
            action_status:'delete',
            action_name: 'record'
        }
        return(
            <div>
                <div className='page-wrapper'>
                <CommonModel
                    onState={showDelete}
                    currentState={postValue}
                    onConfirm={this.handleDelete}
                    onCancel={this.hideControlPanelDelete}
                />
                    <div className='page-content-wrapper'>
                        <div className='page-content pt-3'>
                            <BreadCrumb titleName={['Control Panel']} homeName={['Home']} url={['dashboard']} mainPageTitle={['Control Panel']} />
                                <div>                                    
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='card card-topline-red'>
                                                <div className='card-head'>
                                                    <header>
                                                        <Link to={HomeStudy.AddControlPanel} className="ml-2 mt-1">
                                                            <button className='btn btn-pink'>Add Control Panel</button>
                                                        </Link>
                                                    </header>
                                                    <div className='tools'>
                                                    <div className='d-flex'>
                                                        <div>
                                                            <input 
                                                            placeholder='Search' 
                                                            name='search' 
                                                            className='form-control'
                                                            value={this.state.search}
                                                            onChange={this.handleInputChange}/>
                                                        </div>
                                                        <div className="ml-2 mt-2">
                                                            <span className="fa fa-repeat btn-color box-refresh pointer"></span>
                                                            <span className="t-collapse btn-color fa fa-chevron-down pointer" ></span>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className='card-body no-padding height-9'>
                                                    <div className='row'>
                                                        <div className='table-responsive'>
                                                            {this.renderControlPanel(records)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div style={loadingQuestionFull}><SpinnerLoader /></div>
            </div>
        );
    }
}


const mapStateToProps = (state:any) => {
    return{
        loading: state.HomeStudy.loading,
        records: state.HomeStudy.records,
        page: state.HomeStudy.page,
        per_page: state.HomeStudy.per_page,
        total: state.HomeStudy.total,
        totalPage: state.HomeStudy.totalPage
    }
}
export default connect (mapStateToProps, {fetchControlPanelGet, deleteControlPanelById}) (ControlPanelGrid)