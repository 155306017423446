import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchStudentProfileSubject } from '../../../store/student/Actions';
import SpinnerLoader from '../../../components/spinner/SpinnerLoader';
import CommonSubjectList from '../../../components/CommonSubjectList';
import { stopYearData, stopSubComponentYear } from '../../../store/profile/Actions'

interface propsTypes {
    getChangeYearData?:any;
	stopYearDate?:any;
    subComponentStatus?:any
    stopSubComponentYear:(postValue?: any)=>any;
    getStudentInfoDetails?: any;
    fetchStudentProfileSubject: (postValue: any) => any;
    stopYearData:()=>any;
    loading?: any;
    loginProfile?: any;
    studentProfileSubject: any;
}

export class StudentProfileSubject extends Component<propsTypes> {
    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.UpdateYearDate()
    }
 
  componentWillUpdate(nextProps:any, nextState:any) {
        const {stopYearDate, subComponentStatus} = this.props;
        if(subComponentStatus === true){
			this.props.stopSubComponentYear()
            this.UpdateYearDate(this.props.getChangeYearData)
		}else if(stopYearDate === true){
            this.UpdateYearDate(this.props.getChangeYearData)
        }
    }

    componentDidUpdate() {
        const {stopYearDate, subComponentStatus} = this.props;
        if(subComponentStatus === true){
			this.props.stopSubComponentYear()
		}else if(stopYearDate === true){
            this.props.stopYearData()
        }
    }

    UpdateYearDate(getYear?:any){
        if(getYear && getYear !== undefined && getYear !== null){
            this.getStudentFullDetails(getYear)
        }else {
            this.getStudentFullDetails(this.props.getChangeYearData)
        }
    }

    getStudentFullDetails(getYear?:any) {
        let getStudentId: any = this.props.getStudentInfoDetails;
        const { loginProfile } = this.props;
        if (loginProfile) {
            if (loginProfile.usertype === 1) {
                if (getStudentId) {
                    let postValue: any = {
                        academic_year:getYear,
                        class_id: getStudentId.class_id,
                        card_id: getStudentId.card_id,
                        school_id: getStudentId.school_id
                    }
                    this.props.fetchStudentProfileSubject(postValue);
                }
            } else if (loginProfile.usertype === 2 || loginProfile.usertype === 3) {
                let postValue: any = {
                    academic_year:getYear,
                    class_id: getStudentId.class_id,
                    card_id: getStudentId.card_id
                }
                this.props.fetchStudentProfileSubject(postValue);
            }
        }

    }

    render() {
        const { studentProfileSubject, loading } = this.props;
        const loadingTextCSS = { display: loading ? "block" : "none" };
        return (
            <div>
                {/* <!-- start bar chart --> */}
                <div className="row">
                    <div className="col-md-12">
                        <h4>Subject Performance</h4>
                        {studentProfileSubject && studentProfileSubject !== undefined?
                        <CommonSubjectList
                        getData={studentProfileSubject}
                        onInfo={this.props.getStudentInfoDetails}
                        />
                        :null}
                        
                    </div>
                </div>
                <div style={loadingTextCSS}><SpinnerLoader /></div>
            </div>
        )
    }
}
const mapStateToProps = (state: any) => {
    return {
        loading: state.questionset.loading,
        getChangeYearData:state.profile.getYear,
        stopYearDate:state.profile.yearStatusFails,
		subComponentStatus:state.profile.subComponentStart,
        getStudentInfoDetails: state.profile.AttendanceStudentView,
        studentProfileSubject: state.student.studentProfileSubject,
        loginProfile: state.profile.profileData
    }
}

export default connect(mapStateToProps, { fetchStudentProfileSubject, stopYearData, 
    stopSubComponentYear })(StudentProfileSubject)