export interface PropsInstantFeed {
    slectSchoolId: any;
    getCorrectSchoolId: any;
    getCurrectYear?:any;
    hasMore: boolean;
    prev: any;
    next: any;
    acsOrder: any;
    descOrder: any;
    page: any;
    per_page: any;
    records: any;
    total: any;
    totalPage: any;
    search: any;
    SortOrderData: any;
    OrderNameData: any;
    sort_by?:string;
    sort_class_name?:string;
    sort_title_name?:string;
    academic_year?:any
  }

  // This is view intantfeedback status types
  export interface PropsInstantFeedbackTypes {
    optionA:any,
    optionB:any,
    optionC:any,
    optionD:any,
    optionNotAnswed:any,
    chooseOptionA:any,
    chooseOptionB:any,
    chooseOptionC:any,
    chooseOptionD:any,
    chooseNotAnswed:any,
    clearOption:any,
    academicYear?:any
    
    }

  export const option = {
      legend: {
          display: false
        },
      title: {
          display: true,
          text: 'Response'
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItem:any, data:any) {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            var currentValue = dataset.data[tooltipItem.index];
            return `Count ${currentValue}`;
          },
          title: function(tooltipItem:any, data:any) {
            return `Option ${data.labels[tooltipItem[0].index]}`;
          }
        }
      },
      scales: {
          yAxes: [{
              ticks: {
                  beginAtZero: true,
                  steps: 10,
                  stepValue: 5,
                  userCallback: function(label:any, index:any, labels:any) {
                      if (Math.floor(label) === label) {
                          return label;
                      }
                  }
              },
              gridLines : {
                color: 'rgba(170,170,170,1)',
                        lineWidth: 0.2
              }
              }],
          xAxes: [{
              barPercentage: 0.5,
              gridLines: {
                  display: false
              }
          }]
      }
    }
    