import React, { Component } from "react";
import { connect } from "react-redux";
import { Bar } from "react-chartjs-2";
import { fetchGetAllClassList } from "../../store/diary/Actions";
import { fetchTopicAnalysis } from "../../store/dashboard/Actions";
import { GetCorrectSubject } from "../../store/subject/Actions";
import CommonLoader from "../../components/CommonLoader";
import { DurationListGet } from "../../store/profile/Actions";
import { option, propsTeacherType } from "./Types";
import { stopYearData } from '../../store/profile/Actions'

export type PostsTeacherProps = {
  getChangeYearData?:any;
	stopYearDate?:any;
  stopYearData:()=>any;
  fetchGetAllClassList: (postPage: any) => any;
  fetchTopicAnalysis: (postValue: any) => any;
  GetCorrectSubject: (postValue: any) => any;
  DurationListGet: () => any;
  getAllClassList: any;
  getProfile: any;
  getTopicAnalys: any;
  getCorrectClassList: any;
  getDuration: any;
  getSubjectsList: any;
  loading: boolean;
  isSubjectLoader:boolean;
  subjectPerformanceLoader:boolean;
  isTopicAnalysisLoader:boolean;
};

export class TopicAnalysisViews extends Component<
  PostsTeacherProps,
  propsTeacherType
> {
  constructor(props: any) {
    super(props);
    this.state = {
      schoolId: null,
      academicYear:null,
      classId: null,
      attendFilter: "This Year",
      subjectId: "",
      subjectFilterYear: "This Year",
      subject_name: [],
      performance: [],
      color_code: [],
      subject_name_Perform: [],
      performance_Perform: [],
      color_code_Perform: [],
      labelNameList: [],
      labelNameListTop: [],
      TopicAnalysis: null,
      SubjectPerformance: [],
      addClassName: "height300",
      addSAClassName: "height300",
    };
  }
  componentDidMount(): void {
    this.UpdateYearDate()
  }
 
  componentWillUpdate() {
    const {stopYearDate} = this.props;
    if(stopYearDate === true){
        this.UpdateYearDate(this.props.getChangeYearData)
    }
}

componentDidUpdate() {
    const {stopYearDate} = this.props;
    if(stopYearDate === true){
        this.props.stopYearData()
    }
}

UpdateYearDate(getYear?:any){
    if(getYear && getYear !== undefined && getYear !== null){
        this.getTopicAnalysis(getYear)
        this.setState({academicYear:getYear})
    }else {
        this.getTopicAnalysis(this.props.getChangeYearData)
        this.setState({academicYear:this.props.getChangeYearData})
    }
}

getTopicAnalysis(getYear?:any){
  window.scrollTo(0, 0);
    // this.props.DurationListGet();
    this.getTeacherDetails(getYear);
}
  getTeacherDetails(getYear?:any) {
    const { getProfile } = this.props;
    const loadMoreType = {
      academic_year:getYear,
    };
    this.props.fetchGetAllClassList(loadMoreType).then((res: any) => {
      const { getAllClassList } = this.props;
      if (
        getAllClassList &&
        getAllClassList !== undefined &&
        getAllClassList !== null
      ) {
        if (getProfile.school_id && getAllClassList.length > 0) {
          const postValue = {
            academic_year:getYear,
            school_id: getProfile.school_id,
            class_id: getAllClassList[0].id,
          };

          this.props.GetCorrectSubject(postValue).then((res: any) => {
            const { getAllClassList, getSubjectsList } = this.props;
            let subjectId: any = getSubjectsList;
            if (
              getAllClassList &&
              getAllClassList !== undefined &&
              getAllClassList !== null
            ) {
              if (getAllClassList.length > 0 && subjectId.length) {
                const getPerformanace: any = {
                  academic_year:getYear,
                  class_id: getAllClassList[0].id,
                  subject_id: subjectId[0].id,
                  filter: "This Year",
                };
                this.setState({ classId: getAllClassList[0].id, subjectId: subjectId[0].id, attendFilter:'This Year'});
                this.props
                  .fetchTopicAnalysis(getPerformanace)
                  .then((res: any) => {
                    const { getTopicAnalys } = this.props;
                    if (
                      getTopicAnalys &&
                      getTopicAnalys !== undefined &&
                      getTopicAnalys !== null &&
                      getTopicAnalys.length > 0
                    ) {
                      this.barChartTopicAnalysis(getTopicAnalys);
                    } else {
                      this.setState({ TopicAnalysis: null });
                    }
                  });
              }else {
                this.setState({ TopicAnalysis: null });
              }
            }
          });
        }else{
          this.setState({ TopicAnalysis: null });
        }
      }else {
        this.setState({ TopicAnalysis: null });
      }
    });
  }

  getTeacherFilterClass = (e: any) => {
    const { schoolId, academicYear } = this.state;
    const { value } = e.target;
    const postValue = {
      academic_year:academicYear,
      school_id: schoolId,
      class_id: value,
    };
    this.setState({ classId: value });
    
    this.props.GetCorrectSubject(postValue).then((res: any) => {
      const { getSubjectsList } = this.props;
      if (
        getSubjectsList && getSubjectsList.length !==0 && 
        getSubjectsList !== undefined &&
        getSubjectsList !== null
      ) {
        const { attendFilter } = this.state;
        const getPerformanace: any = {
          academic_year:academicYear,
          class_id: value,
          subject_id: getSubjectsList[0].id,
          filter: 'This Year',
        };
        this.setState({
          classId: value,
          subjectId: getSubjectsList[0].id,
        });
        this.setState({ subjectId: getSubjectsList[0].id, attendFilter:'This Year'});
        this.props.fetchTopicAnalysis(getPerformanace).then((res: any) => {
          const { getTopicAnalys } = this.props;
          if (
            getTopicAnalys &&
            getTopicAnalys !== undefined &&
            getTopicAnalys !== null &&
            getTopicAnalys.length > 0
          ) {
            this.barChartTopicAnalysis(getTopicAnalys);
          } else {
            this.setState({ TopicAnalysis: null });
          }
        });
      } else {
        this.setState({ TopicAnalysis: null, subjectId: '' });
      }
    });
  };
  getTeacherFilterSubject = (e: any) => {
    const { classId, attendFilter, academicYear } = this.state;
    const { value } = e.target;
    const getPerformanace: any = {
      academic_year:academicYear,
      class_id: classId,
      subject_id: value,
      filter: attendFilter,
    };
    this.setState({ subjectId: value });
    this.props.fetchTopicAnalysis(getPerformanace).then((res: any) => {
      const { getTopicAnalys } = this.props;
      if (
        getTopicAnalys &&
        getTopicAnalys !== undefined &&
        getTopicAnalys !== null &&
        getTopicAnalys.length > 0
      ) {
        this.barChartTopicAnalysis(getTopicAnalys);
      } else {
        this.setState({ TopicAnalysis: null });
      }
    });
  };
  // this is function used to topic analysischnage year filter
  getYearTopicAnalysisChange = (e: any) => {
    const { classId, subjectId, academicYear } = this.state;
    const { value } = e.target;
    const getPerformanace: any = {
      academic_year:academicYear,
      class_id: classId,
      subject_id: subjectId,
      filter: value,
    };
    this.setState({ attendFilter: value });
    this.props.fetchTopicAnalysis(getPerformanace).then((res: any) => {
      const { getTopicAnalys } = this.props;
      if (
        getTopicAnalys &&
        getTopicAnalys !== undefined &&
        getTopicAnalys !== null &&
        getTopicAnalys.length > 0
      ) {
        this.barChartTopicAnalysis(getTopicAnalys);
      } else {
        this.setState({ TopicAnalysis: null });
      }
    });
  };

  refreshTopicAnalysis = () => {
    const {academicYear} = this.state;
    this.getTeacherDetails(academicYear);
  };
  cardTopicAnalysisDownUp = () => {
    const { addClassName } = this.state;
    if (addClassName === "height300") {
      this.setState({ addClassName: "" });
    } else {
      this.setState({ addClassName: "height300" });
    }
  };
  barChartTopicAnalysis(TopicAnalysis: any) {
    let subject_name: any = [];
    let performance: any = [];
    let color_code: any = [];
    let labelNameListTop: any = [];
    if (TopicAnalysis) {
      subject_name = [];
      performance = [];
      color_code = [];
      labelNameListTop = [];
      TopicAnalysis.forEach((items: any) => {
        performance.push(items.performance);
        color_code.push(items.color_code);
        subject_name.push(items.subject_name);
        labelNameListTop.push(items.class_name);
      });
      let postValue: any = {
        performance: performance,
        color_code: color_code,
        subject_name: subject_name,
        labelNameListTop: labelNameListTop,
      };
      if (postValue) {
        this.setState({ TopicAnalysis: postValue });
      }
    }
  }
  render() {
    const { isSubjectLoader,subjectPerformanceLoader, getDuration, getCorrectClassList, getSubjectsList ,isTopicAnalysisLoader} =
      this.props;
    const { TopicAnalysis, addClassName } = this.state;
    const isTopicAnalysisLoaderVisible = { display: isSubjectLoader ? "block" : subjectPerformanceLoader ? "block" :isTopicAnalysisLoader ? 'block':'none' };
    let getSubject: any = getSubjectsList;
    return (
      <div>
        <div className={`card card-topline-red ${addClassName}`}>
        <div className="overlay" style={isTopicAnalysisLoaderVisible}><div className='custom-spinner-individual'></div></div>
          <div className="card-head">
            <header>Topic Analysis</header>
            <div className="tools">
              <div className="d-flex">
                <div className="ml-2 mt-1">
                  <span
                    className="fa fa-repeat btn-color box-refresh pointer"
                    onClick={this.refreshTopicAnalysis}
                  ></span>
                  <span
                    className="t-collapse btn-color fa fa-chevron-up pointer"
                    onClick={this.cardTopicAnalysisDownUp}
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div className="row p-3 pb-0">
            <div className="col-md-4">
              {getCorrectClassList ? (
                <select
                  name="classlist"
                  id=""
                  className="form-control"
                  value={this.state.classId}
                  onChange={this.getTeacherFilterClass}
                >
                  {getCorrectClassList.map((items: any) => (
                    <option value={items.id}> {items.grade_standard} </option>
                  ))}
                </select>
              ) : null}
            </div>
            <div className="col-md-4">
              {getSubjectsList && getSubjectsList !== undefined && getSubjectsList !== null ? (
                <select
                  name="subjectList"
                  id=""
                  className="form-control"
                  value={this.state.subjectId}
                  onChange={this.getTeacherFilterSubject}
                >
                  {getSubject.map((items: any) => (
                    <option value={items.id}> {items.name} </option>
                  ))}
                </select>
              ) : null}
            </div>
            <div className="col-md-4">
              {getDuration ? (
                <select
                  name="duration"
                  id=""
                  className="form-control"
                  value={this.state.attendFilter}
                  onChange={this.getYearTopicAnalysisChange}
                >
                  {getDuration.map((items: any) => (
                    <option value={items.value}> {items.value} </option>
                  ))}
                </select>
              ) : null}
            </div>
          </div>
          <div className="card-body no-padding height-9">
            <div className="row">
              {TopicAnalysis &&
              TopicAnalysis !== null &&
              TopicAnalysis !== undefined ? (
                <Bar
                  data={{
                    labels: TopicAnalysis.subject_name,
                    datasets: [
                      {
                        backgroundColor: TopicAnalysis.color_code,
                        borderColor: TopicAnalysis.color_code,
                        borderWidth: 2,
                        data: TopicAnalysis.performance,
                        label: TopicAnalysis.labelNameListTop,
                      },
                    ],
                  }}
                  height={115}
                  options={option}
                />
              ) : (
                <CommonLoader />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    getAllClassList: state.diary.gradelist,
    getChangeYearData:state.profile.getYear,
    stopYearDate:state.profile.yearStatusFails,
    getProfile: state.profile.profileData,
    getTopicAnalys: state.dashboard.getTopicAnalysis,
    getCorrectClassList: state.diary.gradelist,
    getDuration: state.profile.getDuration,
    getSubjectsList: state.subjects.GetCorrectSubject,
    loading: state.dashboard.loading,
    isSubjectLoader:state.diary.isSubjectLoader,
    subjectPerformanceLoader:state.subjects.subjectPerformanceLoader,
    isTopicAnalysisLoader:state.dashboard.isTopicAnalysisLoader,
  };
};

export default connect(mapStateToProps, {
  fetchGetAllClassList,
  fetchTopicAnalysis,
  GetCorrectSubject,
  DurationListGet,
  stopYearData
})(TopicAnalysisViews);
