import React, { Component } from "react";
import BreadCrumb from "../../components/BreadCrumb";
import { Field, FieldArray, Form, Formik } from "formik";
import { Button, FormGroup, MenuItem, Checkbox } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { generateQuestionsSchema } from "./QuestionBankValidation";
import { fetchGenerateQuestions, fetchBluePrintDropdownGet, fetchQuestionPaperDropdownGet } from "../../store/QuestionBank/Actions";
import SpinnerLoader from "../../components/spinner/SpinnerLoader";
import { DateRangePicker } from 'react-date-range';
import history from '../../History';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export type OwnExamTypeProps = {
    getYear?: any;
    getChangeYearData?: any;
    loading?: boolean;
    bluePrintDropdown?: any;
    questionPaperDropdown?: any;
    fetchGenerateQuestions: (postData: any) => any;
    fetchBluePrintDropdownGet: (params: any) => any;
    fetchQuestionPaperDropdownGet: (params: any) => any;
}

interface StateTypes {
    academicYear?: any;
    showPicker?: any;
    selectedRange?: any;
    blueprint_dropdown?: any;
    searchAutoQuestionPaper?: any;
    blueprintId?: any;
    questionPaperData?: any;
}

const initialValues = {
    blueprint_dropdown: '',
    search_question_paper: [],
};


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export class GenerateQuestionsForm extends Component<OwnExamTypeProps, StateTypes> {
    formik: any;
    wrapperRef: any;
    constructor(props: any) {
        super(props)
        this.state = {
            academicYear: '',
            blueprint_dropdown: [],
            searchAutoQuestionPaper: [],
            showPicker: false,
            blueprintId: '',
            questionPaperData: [],
            selectedRange:
            {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection'
            }

        }
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.togglePicker = this.togglePicker.bind(this);
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.getUpdateYear();
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
      }

      handleClickOutside(event:any) {
          if (
            this.wrapperRef &&
            this.wrapperRef.current &&
            !this.wrapperRef.current.contains(event.target) &&
            this.state.showPicker &&
            event.target !== this.wrapperRef.current
          ) {
            this.setState({ showPicker: false });
          }
      }
    
      
    getUpdateYear(getYear?: any) {
        if (getYear && getYear !== undefined && getYear !== null) {
            this.getGenerateQuestionDetails(getYear);
            this.setState({ academicYear: getYear });
        } else {
            this.getGenerateQuestionDetails(this.props.getChangeYearData);
            this.setState({ academicYear: this.props.getChangeYearData });
        }
    }

    getGenerateQuestionDetails(getYear?: any) {
        const getData = {
            academic_year: getYear,
        }
        this.props.fetchBluePrintDropdownGet(getData).then((res: any) => {
            const { bluePrintDropdown } = this.props;
            this.setState({ blueprint_dropdown: bluePrintDropdown });
        });
    }
    handleSelect = (ranges: any) => {
        const { startDate, endDate } = ranges.selection;
        const { blueprintId } = this.state;
        if (this.state.showPicker) {
            this.setState({
                selectedRange: { startDate, endDate, key: 'selection' },
                showPicker: false
            });
        } else {
            this.setState({
                selectedRange: { ...this.state.selectedRange, endDate },
                showPicker: false
            });
        }

        if (startDate && endDate && blueprintId) {
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            const formattedStartDate = startDate.toLocaleDateString('en-GB', options).split('/').reverse().join('-');
            const formattedEndDate = endDate.toLocaleDateString('en-GB', options).split('/').reverse().join('-');

            const postValue: any = {
                blueprint_id: blueprintId,
                from_date: formattedStartDate,
                to_date: formattedEndDate,
            }

            this.props.fetchQuestionPaperDropdownGet(postValue).then((res: any) => {
                const { questionPaperDropdown } = this.props;
                const uids = questionPaperDropdown.map((item: any) => item.uid);
                console.log(uids)
                this.setState({
                    questionPaperData: questionPaperDropdown,
                    searchAutoQuestionPaper: questionPaperDropdown,
                });
            })
        }
    };

    togglePicker = () => {
        this.setState(prevState => ({
            showPicker: !prevState.showPicker
        }));
    };


    render() {
        const { academicYear, showPicker, selectedRange, blueprint_dropdown, questionPaperData, searchAutoQuestionPaper } = this.state;
        const { loading } = this.props;
        const loadingQuestionFull = { display: loading ? "block" : "none" };

        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedStartDate = selectedRange.startDate.toLocaleDateString('en-GB', options).split('/').reverse().join('-');
        const formattedEndDate = selectedRange.endDate.toLocaleDateString('en-GB', options).split('/').reverse().join('-');
        const formattedDate = `${formattedStartDate} - ${formattedEndDate}`;

        return (
            <div className="page-wrapper">
                <div className="page-content-wrapper">
                    <div className="page-content pt-3">
                        <BreadCrumb
                            titleName={['Generate Questions']}
                            homeName={['Home']}
                            url={['dashboard']}
                            mainPageTitle={['Generate Questions']}
                        />
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card-box">
                                    <div className="card-head">
                                        <header>Generate Questions</header>
                                    </div>
                                    <div className="card-body">
                                        <Formik
                                            ref={node => this.formik = node}
                                            initialValues={initialValues}
                                            validationSchema={generateQuestionsSchema}
                                            onSubmit={(values: any, { setSubmitting }) => {

                                                let uids: any = []
                                                if (searchAutoQuestionPaper && searchAutoQuestionPaper.length > 0) {
                                                    uids = searchAutoQuestionPaper.map((item: any) => item.uid);
                                                }
                                                const params = {
                                                    blueprint_id: values.blueprint_dropdown,
                                                    question_paper_id_list: uids
                                                }

                                                this.props.fetchGenerateQuestions(params).then((res: any) => {
                                                })
                                            }}
                                        >
                                            {({
                                                values,
                                                errors,
                                                isSubmitting,
                                                isValidating,
                                                dirty,
                                                touched,
                                                handleReset,
                                                handleSubmit,
                                                handleChange,
                                                setFieldValue,
                                            }) => {
                                                return (
                                                    <Form>
                                                        <div>
                                                            <div className="">
                                                                <div className="row">
                                                                    <div className="col-md-6 p-t-20">
                                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                            <FormGroup>

                                                                                <Field
                                                                                    label={<><span>Blueprint Dropdown</span><span className='formmandatorycolor'> *</span></>}
                                                                                    name="blueprint_dropdown"
                                                                                    select
                                                                                    component={TextField}
                                                                                    onChange={(e: any, value: any) => {

                                                                                        setFieldValue(
                                                                                            "blueprint_dropdown",
                                                                                            e.target.value
                                                                                        );
                                                                                        this.setState({ 'blueprintId': e.target.value })
                                                                                    }}
                                                                                >
                                                                                    {blueprint_dropdown && blueprint_dropdown.length > 0 && blueprint_dropdown.map((item: any) => (
                                                                                        <MenuItem value={item.uid} >
                                                                                            {item.blueprint_name}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Field>

                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 p-t-20">
                                                                        <div>
                                                                            <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                <div>

                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            label={<><span>From Date - To Date</span></>}
                                                                                            type="text"
                                                                                            name="fromDate"
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            fullwidth="true"
                                                                                            disabled={false}
                                                                                            value={formattedDate}
                                                                                            onClick={this.togglePicker}
                                                                                            readOnly
                                                                                        />
                                                                                    </FormGroup>


                                                                                    {showPicker && (

                                                                                        <div style={{ position: 'absolute', zIndex: '9999' }} ref={this.wrapperRef}>
                                                                                            <DateRangePicker
                                                                                                ranges={[selectedRange]}
                                                                                                onChange={this.handleSelect}
                                                                                                moveRangeOnFirstSelection={false}
                                                                                                dateDisplayFormat={'yyyy-MM-dd'}
                                                                                            />
                                                                                        </div>
                                                                                    )}

                                                                                    {questionPaperData ? <div>
                                                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0 pb-0">
                                                                                            <FormGroup>
                                                                                                <Autocomplete
                                                                                                    fullWidth
                                                                                                    multiple
                                                                                                    id="checkboxes-tags-demo"
                                                                                                    options={questionPaperData}
                                                                                                    disableCloseOnSelect
                                                                                                    noOptionsText={'No matching records found.'}
                                                                                                    getOptionLabel={(option: any) => option.question_paper_name}
                                                                                                    value={this.state.searchAutoQuestionPaper}
                                                                                                    onChange={(e, value, resonse) => {
                                                                                                        setFieldValue('search_question_paper', value);
                                                                                                        if (value) {
                                                                                                            this.setState({ searchAutoQuestionPaper: value })
                                                                                                        }
                                                                                                    }}
                                                                                                    renderOption={(option, { selected }) => {
                                                                                                        return (
                                                                                                            <React.Fragment>
                                                                                                                <Checkbox
                                                                                                                    icon={icon}
                                                                                                                    checkedIcon={checkedIcon}
                                                                                                                    style={{ marginRight: 8 }}
                                                                                                                    checked={selected}
                                                                                                                />
                                                                                                                {option.question_paper_name}
                                                                                                            </React.Fragment>
                                                                                                        )
                                                                                                    }}
                                                                                                    className="mb-0 mt-1"
                                                                                                    renderInput={(params) => (
                                                                                                        <Field
                                                                                                            component={TextField}
                                                                                                            name="search_question_paper"

                                                                                                            {...params} label={<><span>Search Question Paper</span></>}
                                                                                                        />
                                                                                                    )}
                                                                                                />

                                                                                            </FormGroup>
                                                                                        </div>
                                                                                    </div>
                                                                                        : null}


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="text-right mb-3 mr-2 mt-4">
                                                                    <Button
                                                                        className="btn btn-pink mr-1 ml-1"
                                                                        type="submit">Generate Questionpaper</Button>
                                                                    <Link to="/questionpaper"
                                                                        style={{ display: "inline-block" }}>
                                                                        <Button className="btn btn-default mr-1 ml-1">Cancel</Button>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                )
                                            }}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={loadingQuestionFull}><SpinnerLoader /></div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        getYear: state.profile.getYear,
        getChangeYearData: state.profile.getYear,
        loading: state.QuestionBank.loading,
        bluePrintDropdown: state.QuestionBank.bluePrintDropdown,
        questionPaperDropdown: state.QuestionBank.questionPaperDropdown,
    }
}

const mapDispatchToProps = {
    fetchGenerateQuestions,
    fetchBluePrintDropdownGet,
    fetchQuestionPaperDropdownGet
}

export default connect(mapStateToProps, mapDispatchToProps)(GenerateQuestionsForm)