import React, { Component } from 'react';
import { connect } from 'react-redux';
import {getOverallPerformance} from '../../store/question/Actions';
import { Link } from 'react-router-dom';
import history from '../../History';
import Cookies from 'universal-cookie';
import { getFeedBackReport, stopYearData } from '../../store/profile/Actions';

interface PropsFeedbackType {
    stopYearDate?:any
	subComponentStatus?:any
	getChangeYearData?:any;
    getOverallPerformance:(postValue:any) => any;
    getFeedBackReport:(postValue:any)=>any;
    stopYearData:()=>any;
    overallPerformance?:any;
    getTeacherDetails?:any
}
interface StatuTypes {
    schoolId:any;
    academicYear:any;
}
class Feedback extends Component<PropsFeedbackType, StatuTypes> {
    cookies:any = new Cookies();
    constructor(props: any) {
        super(props);
        this.state = {
            schoolId: this.cookies.get('ACADEMICSCHOOLID') || null,
			academicYear:null
        }
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);
       this.getCurrectYear()
    }
    componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYearDate, subComponentStatus} = this.props;
		if(stopYearDate === true){
			this.getCurrectYear(this.props.getChangeYearData)
		}else if(subComponentStatus === true){
			this.getCurrectYear(this.props.getChangeYearData)
		}
	}
	componentDidUpdate() {
		const { stopYearDate } = this.props;
        if(stopYearDate === true){
			this.props.stopYearData()
		}
	}
	getCurrectYear(getYear?:any){
		if(getYear && getYear !== undefined && getYear !== null){
			this.getFeedBack(getYear)
			this.setState({academicYear:getYear})
		}else {
			this.getFeedBack(this.props.getChangeYearData)
			this.setState({academicYear:this.props.getChangeYearData})
		}
	}
    getFeedBack(getYear?:any){
        const { getTeacherDetails } = this.props;
        const postValue:any = {
            academic_year: getYear,
            teacher_id: getTeacherDetails
        }
        this.props.getOverallPerformance(postValue)
    }
    getTopicDetails(getValue:any, classList:any){
        const { getTeacherDetails } = this.props;
        const {schoolId} = this.state;
        const postValue:any = {
            feedback:getValue.topics,
            teacher_id:getTeacherDetails,
            pageName:'Teacher',
            subject_id:classList.subject_id,
            school_id:schoolId
        }
        this.props.getFeedBackReport(postValue)
history.push({
    pathname: `/topics/${getValue.topics}`
  });
    }
    render() {
        const { overallPerformance } = this.props; 
        return (
            <div>
                <div className="row">
                    {overallPerformance?
                    overallPerformance.map((items:any)=>(
                    <div className="col-md-6">
                        <div className="card card-topline-red">
                            <div className="card-body no-padding height-9">
                                <div className="row">
                                    <div className="col-md-12 center">
                                        <h4>Topics ({items.subject_name})</h4>
                                    </div>
                                </div>
                                {items.class_list?
                                items.class_list.map((classList:any)=>(
                                <>
                                <div className="row">
                                    <div className="col-md-12">
                                            <h5 className="ml-2 mt-2">{classList.class_name}</h5>
                                    </div>
                                </div>
                                <div className="row">
                                <div className="d-flex flex-wrap ml-3">
                                    {classList.graph_data?
                                    classList.graph_data.map((graghData:any)=>(
                                    <div className="mt-sm-3 ml-1">
                                        <Link to={'#'}  onClick={(e: any) => this.getTopicDetails(graghData, classList)}>
                                        <div
                                            className="teacher-status-color-good" style={{background:graghData.color_code}}>
                                            <p>{graghData.topics}</p>
                                        </div>
                                        </Link>
                                        
                                    </div>
                                    ))
                                    :null}
                                    </div>
                                
                                </div>
                                </>
                                ))
                                :null}
                                
                            </div>
                        </div>
                    </div>
                    ))
                    :null}
                    </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return{
        loading:state.teacher.loading,
		getChangeYearData:state.profile.getYear,
		stopYearDate:state.profile.yearStatusFails,
        overallPerformance:state.questionset.teacherOverallPerformance
    }
}
export default connect(mapStateToProps, {getOverallPerformance, getFeedBackReport, stopYearData})(Feedback);
