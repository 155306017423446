import React, { Component } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import { Link } from 'react-router-dom';
import { QuestionBank } from '../../router/Roles';
import { Field, Formik, Form, FieldArray } from "formik";
import { Button, MenuItem, FormGroup } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { get } from "lodash";
import { fetchBluePrintByIdGet } from "../../store/QuestionBank/Actions";
import { RouteComponentProps } from 'react-router';

interface OwnPropsParams {
    id: string;
}

export interface Props extends RouteComponentProps<OwnPropsParams> {
    loading: boolean;
    bluePrintById?: any;
    fetchBluePrintByIdGet: (data: any) => any;
}

interface StateTypes {
    records?: any;
    bluePrintValues?: any;
}


export class EditBluePrint extends Component<Props, StateTypes> {
    constructor(props: any) {
        super(props);
        this.state = {
            records: [],
        }
    }
    componentDidMount(): void {
       
        const getBluePrintData = {
            uid: this.props.match.params.id
        }

        this.props.fetchBluePrintByIdGet(getBluePrintData).then((res: any) => {
            const { bluePrintById } = this.props;
            this.setState({bluePrintValues:bluePrintById, records: bluePrintById.blueprint_details });
        })

         window.scrollTo(0, 0);
    }


    private renderBluePrint(records: any) {

        const loadingQuestionFull = { display: "none" };
        return (
            <div>

                <table className="table table-striped custom-table table-hover">
                    <thead>
                        <tr>
                            <th>Question Type</th>
                            <th>Mark</th>
                            <th>Lesson</th>
                            <th>Difficulty Level</th>
                            <th>No of Question</th>
                            <th>Total Marks</th>
                        </tr>
                    </thead>
                    <tbody>
                        {records.length > 0 ?
                            records.map((items: any) => (
                                <tr>
                                    <td >{items['question_type_name'] || '-'}</td>
                                    <td >{items['question_type_marks'] || '-'}</td>
                                    <td >{items['wizdomwaves_lesson_name'] || '-'}</td>
                                    <td >{items['difficulty_level_name'] || '-'}</td>
                                    <td >{items['no_of_question'] || '-'}</td>
                                    <td >{items['aggregate_mark'] || '-'}</td>

                                </tr>
                            ))
                            : null}
                        {<>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </>
                        }
                    </tbody>
                </table>
            </div>
        )
    }

    render() {
        const { records,bluePrintValues } = this.state;
        return (
            <div className="page-wrapper">
                <div className="page-content-wrapper">
                    <div className="page-content pt-3">
                        <BreadCrumb
                            titleName={['View BluePrint']}
                            homeName={['Home']}
                            url={['dashboard']}
                            mainPageTitle={['View BluePrint']} />

                        <div className="row">
                            <div className="col-md-12">
                             { bluePrintValues ?
                             <>
                                <div className="card card-topline-red pl-3 pr-3 pb-3">
                                    <>
                                        <h3 className="color-pink">{bluePrintValues.blueprint_name}</h3>
                                        <h5>Subject <span className="color-pink">
                                            {bluePrintValues.internal_subject_name}</span> for <strong className="color-pink">{bluePrintValues.internal_grade_name}</strong></h5>
                                    </>
                                </div>
                                <div className="card card-topline-red height-9 pl-3 pr-3 pb-3">
                                    <div className="row">
                                        <div className="table-responsive">
                                            {this.renderBluePrint(records)}
                                        </div>
                                    </div>
                                </div>
                                </>
                               : null }

                            </div>
                        </div>

                        <div className="text-right mb-3 mr-2 mt-4">
                            <Link to={"/blueprint"}>
                                <Button className="btn btn-pink mr-1 ml-1">
                                    Back
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}



const mapStateToProps = (state: any) => {
    return {
        loading: state.QuestionBank.loading,
        bluePrintById: state.QuestionBank.bluePrintById,
    }
}

export default connect(mapStateToProps, {
    fetchBluePrintByIdGet
})(EditBluePrint)