import { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from 'react-router';
import BreadCrumb from "../../components/BreadCrumb";
import { Field, Form, Formik } from "formik";
import { Button, FormGroup } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { Link } from "react-router-dom";
import { fetchExamTypeGetById, editExamType } from "../../store/QuestionBank/Actions";
import SpinnerLoader from "../../components/spinner/SpinnerLoader";
import { examTypeEditSchema } from "./QuestionBankValidation";
import MinutesToHours from "../../components/MinutesToHours";

interface OwnPropsParams {
    id : string;
}

export interface OwnExamTypeProps extends RouteComponentProps <OwnPropsParams> {
    loading?: boolean
    getYear?: any;
    getExamTypeEdit: any,
    fetchExamTypeGetById: (id: any) => any
    editExamType: (postData: any) => any
}

interface stateTypes {
    academicYear?: Number;
    name?: string
    description?: string
    total_marks?: number
    allowed_time?: number
}

export class EditExamType extends Component<OwnExamTypeProps, stateTypes>{
    formik:any;
    constructor(props: any) {
        super(props)
        this.state= {
            academicYear: 0,
            name : '',
            description: '',
            total_marks: 0,
            allowed_time: 0
        }
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.getUpdateYear()
        this.props.fetchExamTypeGetById(Number(this.props.match.params.id)).then((res: any)=>{
            const {getExamTypeEdit} = this.props
            if(getExamTypeEdit && getExamTypeEdit !== null && getExamTypeEdit !== undefined){
                this.setState({ 
                    name: getExamTypeEdit.name,
                    description: getExamTypeEdit.description,
                    total_marks: getExamTypeEdit.total_marks,
                    allowed_time: getExamTypeEdit.allowed_time,
                })
            }
        })
    }

    getUpdateYear(getYear?: any) {
        if (getYear && getYear !== undefined && getYear !== null) {
            this.setState({ academicYear: getYear })
        } else {
            this.setState({ academicYear: this.props.getYear })
        }
    }

    render() {
        const {name, description, total_marks, allowed_time, academicYear} = this.state
        const {loading } = this.props
        const loadingFull = { display: loading ? "block" : "none" };
        const initialValues: any = {
            name: name,
            description: description,
            total_marks: total_marks,
            allowed_time: allowed_time
        }
        return(
            <div>
                <div className="page-wrapper">
                    <div className="page-content-wrapper">
                        <div className="page-content pt-3">
                            <BreadCrumb
                                titleName={['Exam Type']}
                                homeName={['Home']}
                                url={['dashboard']}
                                baseName={['Exam Type']}
                                mainPageTitle={['Edit Exam Type']} 
                            />
                            { name && description && total_marks ?
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card-box">
                                            <div className="card-head">
                                                <header>Edit Exam Type</header>
                                            </div>
                                            <div className="card-body">
                                                <Formik
                                                    ref={node=>this.formik = node}
                                                    initialValues={initialValues}
                                                    validationSchema={examTypeEditSchema}
                                                    onSubmit={(values, { setSubmitting }) => {
                                                        console.log(values)

                                                        const getExamTypeValue: any = {
                                                            id: this.props.match.params.id,
                                                            name: values.name,
                                                            description: values.description,
                                                            total_marks: parseInt(values.total_marks),
                                                            allowed_time: parseInt(values.allowed_time),
                                                            academic_year: academicYear,                                                
                                                        };
        
                                                        this.props.editExamType(getExamTypeValue).then((res: any) => {
        
                                                        })
                                                    }}
                                                >
                                                    {({
                                                        values,
                                                        errors,
                                                        isSubmitting,
                                                        isValidating,
                                                        dirty,
                                                        touched,
                                                        handleReset,
                                                        handleSubmit,
                                                        handleChange,
                                                        setFieldValue,
                                                    }) => {
                                                        return(
                                                            <Form>
                                                                <div>
                                                                    <div className="">
                                                                        <div className="row">
                                                                            <div className="col-md-6 p-t-20">
                                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            label={<><span>Name</span><span className='formmandatorycolor'> *</span></>}
                                                                                            type="text"
                                                                                            name="name"
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            disabled={false}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6 p-t-20">
                                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            label={<><span>Description</span><span className='formmandatorycolor'> *</span></>}
                                                                                            type="text"
                                                                                            name="description"
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            fullwidth="true"
                                                                                            disabled={false}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-6 p-t-20">
                                                                                <div>
                                                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                        <FormGroup>
                                                                                            <Field
                                                                                                label={<><span>Total Marks</span><span className='formmandatorycolor'> *</span></>}
                                                                                                type="text"
                                                                                                name="total_marks"
                                                                                                component={TextField}
                                                                                                className="textfield__input"
                                                                                                fullwidth="true"
                                                                                                disabled={false}
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6 p-t-20">
                                                                                <div>
                                                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                        <FormGroup>
                                                                                            <Field
                                                                                                label={<><span>Allowed Time (In Minutes)</span><span className='formmandatorycolor'> *</span></>}
                                                                                                type="text"
                                                                                                name="allowed_time"
                                                                                                component={TextField}
                                                                                                className="textfield__input"
                                                                                                fullwidth="true"
                                                                                                onChange={(e: any) => {
                                                                                                    handleChange(e);
                                                                                                }}
                                                                                                disabled={false}
                                                                                            />
                                                                                              <div>
                                                                                                <MinutesToHours minutes={parseInt(values.allowed_time)} />
                                                                                            </div>  
                                                                                        </FormGroup>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="text-right mb-3 mr-2 mt-4">
                                                                            <Button className="btn btn-pink mr-1 ml-1" type="submit">Submit</Button>
                                                                            <Link to="/examType" style={{ display: "inline-block" }}>
                                                                                <Button className="btn btn-default mr-1 ml-1">Cancel</Button>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Form>
                                                        )
                                                    }}
                                                </Formik>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            : <div><SpinnerLoader /></div>}
                        </div>
                    </div>
                    <div style={loadingFull}><SpinnerLoader /></div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any ) => {
    return{
        loading: state.QuestionBank.loading,
        getExamTypeEdit: state.QuestionBank.getExamTypeEdit,
        getYear: state.profile.getYear,
    }
}

const mapDispatchToProps ={
    fetchExamTypeGetById,
    editExamType
}

export default connect (mapStateToProps, mapDispatchToProps)(EditExamType)