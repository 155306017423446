import { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from 'react-router';
import BreadCrumb from "../../components/BreadCrumb";
import { Field, Form, Formik } from "formik";
import { Button, FormGroup, MenuItem } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { Link } from "react-router-dom";
import SpinnerLoader from "../../components/spinner/SpinnerLoader";
import { subjectMappingEditSchema } from "./QuestionBankValidation";
import { fetchSubjectMappingByIdGet,internalGradleGet, internalSubjectGet, externalSubjectGet, editSubjectMapping, languageDropdownGet, } from "../../store/QuestionBank/Actions";
import Autocomplete from '@material-ui/lab/Autocomplete';
interface OwnPropsParams {
    id : string;
}

export interface OwnSubjectMappingProps extends RouteComponentProps <OwnPropsParams> {
    loading?: boolean
    getYear?: any;
    getMappingEdit?: any;
    getInternalGradle?: any;
    getInternalSubject?: any;
    getExternalSubject?: any;
    getLanguage: any;
    fetchSubjectMappingByIdGet: (id: any) => any;
    internalGradleGet: () => any;
    internalSubjectGet:(data: any) => any;
    externalSubjectGet: (id: number) => any;
    languageDropdownGet: () => any;
    editSubjectMapping: (data: any) => any;
}

interface stateTypes {
    academicYear?: Number;
    internalGradle?: any;
    subjectInternal?: any;
    subjectExternal?: any;
    language?: any;
    internalGradleSelectedId?: number;
    subjectInternalSelectedId?: number;
    subjectExternalSelectedId?: number;
    languageSelectedId?: number;
    autocompleteValues?: any;
}

export class EditSubjectMapping extends Component<OwnSubjectMappingProps, stateTypes>{
    formik:any;
    constructor(props: any) {
        super(props)
        this.state= {
            academicYear: 0,
            internalGradle: [],
            subjectInternal: [],
            subjectExternal: [],
            language: [],
            internalGradleSelectedId: 0,
            subjectInternalSelectedId: 0,
            subjectExternalSelectedId: 0,
            languageSelectedId: 0,
            autocompleteValues: {
                gradle_internal: null,
                subject_internal: null,
              }
        }
    }

    componentDidMount(): void {
        const { academicYear } = this.state;
        const { getInternalGradle } = this.props;
        window.scrollTo(0, 0);
        this.getUpdateYear()
        this.fetchEditData()
    }

    getUpdateYear(getYear?: any) {
        if (getYear && getYear !== undefined && getYear !== null) {
            this.setState({ academicYear: getYear })
        } else {
            this.setState({ academicYear: this.props.getYear })
        }
    }
    
    fetchEditData() {
        this.props.fetchSubjectMappingByIdGet(Number(this.props.match.params.id)).then((res: any)=>{
            const {getMappingEdit} = this.props
            if(getMappingEdit && getMappingEdit !== null && getMappingEdit !== undefined){
                this.setState({ 
                    internalGradleSelectedId: getMappingEdit.internal_grade_id,
                    subjectInternalSelectedId: getMappingEdit.internal_subject_id,
                    subjectExternalSelectedId: getMappingEdit.wizdomwaves_subject_id,
                    languageSelectedId: getMappingEdit.language_id,
                })
                this.getInternalGradleList(getMappingEdit.internal_grade_id)
                this.getInternalSubjectList(getMappingEdit.internal_grade_id, getMappingEdit.internal_subject_id)
                this.getExternalSubjectList(getMappingEdit.internal_grade_id)
                this.getLanguageList()
            }
        })
    }

    getInternalGradleList(internal_grade_id:any) {
        this.props.internalGradleGet().then((res: any)=>{
            const {getInternalGradle} = this.props
            const gradle_internal = getInternalGradle.find((grade: any) => grade.id === internal_grade_id)
            this.setState((prevState) => ({
                internalGradle: getInternalGradle,
                autocompleteValues: {
                  ...prevState.autocompleteValues,
                  gradle_internal:  gradle_internal,
                },
            }));
        })
    }

    getExternalSubjectList(id: number) {
        this.props.externalSubjectGet(id).then((res: any)=>{
            const {getExternalSubject} = this.props
            if(
                getExternalSubject &&
                getExternalSubject.length > 0 &&
                getExternalSubject !== undefined
            ) {
                this.setState({
                    subjectExternal: getExternalSubject,
                })
            }else{
                this.setState({ subjectExternal: []});
                this.formik.setFieldError(
                    "wizdomwaves_subject",
                    "No matching records found"
                );
                this.formik.setFieldValue(
                    "wizdomwaves_subject",
                    ''
                );
            }
        })
    }

    getInternalSubjectList(getValue?: any, subjectId?:any) {
        const{academicYear} = this.state
        const data = {
            gradle_id: getValue,
            academicYear: academicYear,
        }
        if(getValue && academicYear){
            this.props.internalSubjectGet(data).then((res: any)=>{
                const {getInternalSubject} = this.props
                if(
                    getInternalSubject &&
                    getInternalSubject.length > 0 &&
                    getInternalSubject !== undefined
                ) {
                    const subject_internal = getInternalSubject.find((grade: any) => grade.id === subjectId)
                    this.setState((prevState) => ({
                        subjectInternal: getInternalSubject,
                        autocompleteValues: {
                          ...prevState.autocompleteValues,
                          subject_internal:  subject_internal,
                        },
                    }));                    
                }else{
                    this.setState({ subjectInternal: []});
                    this.formik.setFieldError(
                      "subject_internal",
                      "No matching records found"
                    );
                    this.formik.setFieldValue(
                        "subject_internal",
                        ''
                    );
                    this.setState((prevState) => ({
                        autocompleteValues: {
                        ...prevState.autocompleteValues,
                        ['subject_internal']:''
                      }
                    }));
                }
            })
        }else{
            this.formik.setFieldValue(
                "subject_internal",
                ''
            );
            this.setState((prevState) => ({
                autocompleteValues: {
                ...prevState.autocompleteValues,
                ['subject_internal']:''
              }
            }));
        }
    }

    getLanguageList() {
        this.props.languageDropdownGet().then((res: any)=>{
            const {getLanguage} = this.props
            this.setState({
                language: getLanguage
            })
        })
    }

    handleAutocompleteChange (key:any, newValue:any) {
        this.setState((prevState) => ({
            autocompleteValues: {
            ...prevState.autocompleteValues,
            [key]: newValue ? newValue : ''
          }
        }));
      };

    render() {
        const {internalGradleSelectedId, subjectInternalSelectedId, subjectExternalSelectedId, languageSelectedId,
            internalGradle, subjectInternal, subjectExternal, language, academicYear, autocompleteValues} = this.state
        const {loading } = this.props
        const loadingFull = { display: loading ? "block" : "none" };
        const initialValues: any = {
            gradle_internal: internalGradleSelectedId,
            subject_internal: subjectInternalSelectedId,
            wizdomwaves_subject: subjectExternalSelectedId,
            language: languageSelectedId
        }
        return(
            <div>
                <div className="page-wrapper">
                    <div className="page-content-wrapper">
                        <div className="page-content pt-3">
                            <BreadCrumb
                                titleName={['Subject Mapping']}
                                homeName={['Home']}
                                url={['dashboard']}
                                baseName={['Subject Mapping']}
                                baseURL={['subjectmapping']}
                                mainPageTitle={['Edit Subject Mapping']} 
                            />
                            {internalGradle && subjectInternal && subjectExternal && language &&
                             internalGradleSelectedId && subjectInternalSelectedId && subjectExternalSelectedId && languageSelectedId ?
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card-box">
                                            <div className="card-head">
                                                <header>Edit Subject Mapping</header>
                                            </div>
                                            <div className="card-body">
                                                <Formik
                                                    ref={node=>this.formik = node}
                                                    initialValues={initialValues}
                                                    validationSchema={subjectMappingEditSchema}
                                                    onSubmit={(values, { setSubmitting }) => {
                                                        const getEditValue: any = {
                                                            id: this.props.match.params.id,
                                                            internal_grade_id: values.gradle_internal,
                                                            internal_subject_id: values.subject_internal,
                                                            wizdomwaves_subject_id: values.wizdomwaves_subject,
                                                            language_id: values.language,
                                                            academic_year: academicYear,                                                
                                                        };
        
                                                        this.props.editSubjectMapping(getEditValue).then((res: any) => {
        
                                                        })
                                                    }}
                                                >
                                                    {({
                                                        values,
                                                        errors,
                                                        isSubmitting,
                                                        isValidating,
                                                        dirty,
                                                        touched,
                                                        handleReset,
                                                        handleSubmit,
                                                        handleChange,
                                                        setFieldValue,
                                                    }) => {
                                                        return(
                                                            <Form>
                                                                <div>
                                                                    <div className="">
                                                                        <div className="row">
                                                                            <div className="col-md-6 p-t-20">
                                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Autocomplete
                                                                                        disabled={true}
                                                                                        fullWidth
                                                                                        id="checkboxes-tags-demo"
                                                                                        options={internalGradle}
                                                                                        noOptionsText={'No matching records found.'}
                                                                                        value={autocompleteValues.gradle_internal}
                                                                                        getOptionLabel={(option) => option ? option.grade : ''}
                                                                                        onChange={(e, value:any, resonse) => {
                                                                                        this.handleAutocompleteChange('gradle_internal',value)
                                                                                            this.getInternalSubjectList(value ? value.id : null);
                                                                                            this.getExternalSubjectList(value.id)
                                                                                        setFieldValue('gradle_internal', value ? value.id : '');
                                                                                        setFieldValue('wizdomwaves_subject','')
                                                                                        
                                                                                        
                                                                                        }}
                                                                                        onInputChange={(event, newInputValue) => {
                                                                                        if (!newInputValue) {
                                                                                            this.handleAutocompleteChange('gradle_internal','')
                                                                                            this.handleAutocompleteChange('subject_internal','')
                                                                                            this.getInternalSubjectList('');
                                                                                            setFieldValue('subject_internal','');
                                                                                        }
                                                                                        }}  
                                                                                        className="mb-0 mt-1"
                                                                                        renderInput={(params) => (
                                                                                        <Field
                                                                                            component={TextField}
                                                                                            name="gradle_internal"
                                                                                            {...params} label={<><span>Internal Grade</span><span className='formmandatorycolor'> *</span></>}
                                                                                            
                                                                                        />
                                                                                        )}
                                                                                    />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6 p-t-20">
                                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Autocomplete
                                                                                            disabled={true}
                                                                                            fullWidth
                                                                                            id="checkboxes-tags-demo"
                                                                                            options={subjectInternal}
                                                                                            noOptionsText={'No matching records found.'}
                                                                                            getOptionLabel={(option: any) => option.name}
                                                                                            value={autocompleteValues.subject_internal}
                                                                                            onChange={(e, value:any, resonse) => {
                                                                                            setFieldValue('subject_internal', value ? value.id : null);
                                                                                            this.handleAutocompleteChange('subject_internal',value)
                                                                                            }}
                                                                                            onInputChange={(event, newInputValue) => {
                                                                                                if (!newInputValue) {
                                                                                                this.handleAutocompleteChange('subject_internal','')
                                                                                                setFieldValue('subject_internal','');
                                                                                                }
                                                                                            }}
                                                                                            className="mb-0 mt-1"
                                                                                            renderInput={(params) => (
                                                                                            <Field
                                                                                                component={TextField}
                                                                                                name="subject_internal"
                                                                                                {...params} label={<><span>Internal Subject</span><span className='formmandatorycolor'> *</span></>}
                                                                                
                                                                                            />
                                                                                            )}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-6 p-t-20">
                                                                                <div>
                                                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                        <FormGroup>
                                                                                            <Field
                                                                                                label={<><span>Wizdomwaves Subject</span><span className='formmandatorycolor'> *</span></>}
                                                                                                select
                                                                                                name="wizdomwaves_subject"
                                                                                                component={TextField}
                                                                                                disabled={true}
                                                                                            >
                                                                                                {subjectExternal && subjectExternal.length > 0 && subjectExternal.map((item: any) => (
                                                                                                <MenuItem value={item.uid}>
                                                                                                    {`${item.name} - ${item.code}`}
                                                                                                </MenuItem>
                                                                                            ))}
                                                                                            </Field>
                                                                                        </FormGroup>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            label={<><span>Language</span><span className='formmandatorycolor'> *</span></>}
                                                                                            select
                                                                                            name="language"
                                                                                            component={TextField}
                                                                                            disabled={true}
                                                                                        >
                                                                                            {language && language.length > 0 && language.map((item: any) => (
                                                                                                <MenuItem value={item.uid}>
                                                                                                    {item.name}
                                                                                                </MenuItem>
                                                                                            ))}
                                                                                        </Field>
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                        <div className="text-right mb-3 mr-2 mt-4">
                                                                            <Link to="/subjectmapping" style={{ display: "inline-block" }}>
                                                                                <Button className="btn btn-default mr-1 ml-1">Back</Button>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Form>
                                                        )
                                                    }}
                                                </Formik>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            : <div><SpinnerLoader /></div>}
                        </div>
                    </div>
                    <div style={loadingFull}><SpinnerLoader /></div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any ) => {
    return{
        loading: state.QuestionBank.loading,
        getYear: state.profile.getYear,
        getMappingEdit: state.QuestionBank.getMappingEdit,
        getInternalGradle: state.QuestionBank.getInternalGradle,
        getInternalSubject: state.QuestionBank.getInternalSubject,
        getExternalSubject: state.QuestionBank.getExternalSubject,
        getLanguage: state.QuestionBank.getLanguage,
    }
}

const mapDispatchToProps ={
    fetchSubjectMappingByIdGet,
    internalGradleGet,
    internalSubjectGet,
    externalSubjectGet,
    languageDropdownGet,
    editSubjectMapping
}

export default connect (mapStateToProps, mapDispatchToProps)(EditSubjectMapping)