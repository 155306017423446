import { Component } from "react";
import BreadCrumb from "../../components/BreadCrumb";
import { connect } from "react-redux";
import { internalGradleGet, internalSubjectGet, externalSubjectGet, addSubjectMapping, languageDropdownGet } from "../../store/QuestionBank/Actions";
import { Field, Form, Formik } from "formik";
import { Button, FormGroup, MenuItem } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { Link } from "react-router-dom";
import SpinnerLoader from "../../components/spinner/SpinnerLoader";
import { subjectMappingAddSchema } from "./QuestionBankValidation";
import Autocomplete from '@material-ui/lab/Autocomplete';


export type OwnExamTypeProps = {
    loading?: any;
    getYear?: any;
    getInternalGradle?: any;
    getInternalSubject?: any;
    getExternalSubject?: any;
    getLanguage: any;
    internalGradleGet: () => any;
    internalSubjectGet:(data: any) => any;
    externalSubjectGet: (id: number) => any;
    languageDropdownGet: () => any;
    addSubjectMapping: (id: number) => any;
}

interface StateTypes{
    academicYear?: any;
    internalGradle?: any;
    subjectInternal?: any;
    subjectExternal?: any;
    language?: any;
    listInternalStatus?: boolean;
    listExternalStatus?: boolean;
    autocompleteValues?: any;
}

const initialValues = {
    gradle_internal: '',
    subject_internal: '',
    wizdomwaves_subject: '',
    language: ''
}

export class AddSubjectMapping extends Component <OwnExamTypeProps, StateTypes> {
    formik: any;
    constructor(props: any) {
        super(props)
        this.state = {
            academicYear: '',
            internalGradle: [],
            subjectInternal: [],
            subjectExternal: [],
            language: [],
            listInternalStatus: true,
            listExternalStatus: true,
            autocompleteValues: {
                gradle_internal: null,
                subject_internal: null,
              }
        }
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.getUpdateYear()
        this.getInternalGradleList()
        this.getLanguageList()
    }

    getInternalGradleList() {
        this.props.internalGradleGet().then((res: any)=>{
            const {getInternalGradle} = this.props
            this.setState({
                internalGradle: getInternalGradle
            })
        })
    }

    getLanguageList() {
        this.props.languageDropdownGet().then((res: any)=>{
            const {getLanguage} = this.props
            this.setState({
                language: getLanguage
            })
        })
    }

    getUpdateYear(getYear?: any) {
        if (getYear && getYear !== undefined && getYear !== null) {
            this.setState({ academicYear: getYear })
        } else {
            this.setState({ academicYear: this.props.getYear })
        }
    }

    getExternalSubjectList(id: number) {
        this.props.externalSubjectGet(id).then((res: any)=>{
            const {getExternalSubject} = this.props
            if(
                getExternalSubject &&
                getExternalSubject.length > 0 &&
                getExternalSubject !== undefined
            ) {
                this.setState({
                    subjectExternal: getExternalSubject,
                    listExternalStatus: false
                })
                this.formik.setFieldValue("wizdomwaves_subject", "");
            }else{
                this.setState({ subjectExternal: [], listExternalStatus: true });
                this.formik.setFieldError(
                    "wizdomwaves_subject",
                    "No matching records found"
                );
                this.formik.setFieldValue(
                    "wizdomwaves_subject",
                    ''
                );
            }
        })
    }

    getInternalSubjectList(e: any, getValue?: any) {
        const{academicYear} = this.state
        const data = {
            gradle_id: getValue,
            academicYear: academicYear,
        }
        if(getValue && academicYear){
            this.props.internalSubjectGet(data).then((res: any)=>{
                const {getInternalSubject} = this.props
                if(
                    getInternalSubject &&
                    getInternalSubject.length > 0 &&
                    getInternalSubject !== undefined
                ) {
                    this.setState({
                        subjectInternal: getInternalSubject,
                        listInternalStatus: false
                    })
                    this.formik.setFieldValue("subject_internal", "");
                    this.setState((prevState) => ({
                        autocompleteValues: {
                        ...prevState.autocompleteValues,
                        ['subject_internal']:''
                      }
                    }));
                }else{
                    this.setState({ subjectInternal: [], listInternalStatus: true });
                    this.formik.setFieldError(
                      "subject_internal",
                      "No matching records found"
                    );
                    this.formik.setFieldValue(
                        "subject_internal",
                        ''
                    );
                    this.setState((prevState) => ({
                        autocompleteValues: {
                        ...prevState.autocompleteValues,
                        ['subject_internal']:''
                      }
                    }));
                }
            })
        }else{
            this.formik.setFieldValue(
                "subject_internal",
                ''
            );
            this.setState((prevState) => ({
                autocompleteValues: {
                ...prevState.autocompleteValues,
                ['subject_internal']:''
              }
            }));
        }
    }

    handleAutocompleteChange (key:any, newValue:any) {
        this.setState((prevState) => ({
            autocompleteValues: {
            ...prevState.autocompleteValues,
            [key]: newValue ? newValue : ''
          }
        }));
      };

    render(){
        const {academicYear, internalGradle, subjectInternal, subjectExternal, language, listInternalStatus, listExternalStatus, autocompleteValues} = this.state;
        const {loading } = this.props
        const loadingFull = { display: loading ? "block" : "none" };
        return(
            <div className="page-wrapper">
                <div className="page-content-wrapper">
                    <div className="page-content pt-3">
                        <BreadCrumb
                            titleName={['Subject Mapping']}
                            homeName={['Home']}
                            url={['dashboard']}
                            mainPageTitle={['Create Subject Mapping']}
                        />
                        {internalGradle && subjectInternal && subjectExternal && language ?
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card-box">
                                        <div className="card-head">
                                            <header>Add Subject Mapping</header>
                                        </div>
                                        <div className="card-body">
                                            <Formik
                                                ref={node => this.formik = node}
                                                initialValues={initialValues}
                                                validationSchema={subjectMappingAddSchema}
                                                onSubmit={(values: any, { setSubmitting }) => {
                                                    const getMappingValue: any = {
                                                        internal_grade_id: values.gradle_internal,
                                                        internal_subject_id: values.subject_internal,
                                                        wizdomwaves_subject_id: values.wizdomwaves_subject,
                                                        language_id: values.language,
                                                        academic_year: academicYear,                                                
                                                    };

                                                    this.props.addSubjectMapping(getMappingValue).then((res: any) => {

                                                    })
                                                }}
                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    isSubmitting,
                                                    isValidating,
                                                    dirty,
                                                    touched,
                                                    handleReset,
                                                    handleSubmit,
                                                    handleChange,
                                                    setFieldValue,
                                                }) => {
                                                    return(
                                                        <Form>
                                                            <div>
                                                                <div className="">
                                                                    <div className="row">
                                                                        <div className="col-md-6 p-t-20">
                                                                            <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                <FormGroup>
                                                                                    <Autocomplete
                                                                                        fullWidth
                                                                                        id="checkboxes-tags-demo"
                                                                                        options={internalGradle}
                                                                                        noOptionsText={'No matching records found.'}
                                                                                        value={autocompleteValues.gradle_internal}
                                                                                        getOptionLabel={(option) => option ? option.grade : ''}
                                                                                        onChange={(e, value:any, resonse) => {
                                                                                        this.handleAutocompleteChange('gradle_internal',value)
                                                                                            this.getInternalSubjectList(e, value ? value.id : null);
                                                                                            this.getExternalSubjectList(value.id)
                                                                                        setFieldValue('gradle_internal', value ? value.id : '');
                                                                                        setFieldValue('wizdomwaves_subject','');
                                                                                        }}
                                                                                        onInputChange={(event, newInputValue) => {
                                                                                        if (!newInputValue) {
                                                                                            this.handleAutocompleteChange('gradle_internal','')
                                                                                            this.handleAutocompleteChange('subject_internal','')
                                                                                            this.getInternalSubjectList(event, '');
                                                                                            setFieldValue('subject_internal','');
                                                                                        }
                                                                                        }}
                                                                                        disableClearable
                                                                                        className="mb-0 mt-1"
                                                                                        renderInput={(params) => (
                                                                                        <Field
                                                                                            component={TextField}
                                                                                            name="gradle_internal"
                                                                                            {...params} label={<><span>Internal Grade</span><span className='formmandatorycolor'> *</span></>}
                                                                                        />
                                                                                        )}
                                                                                    />
                                                                                </FormGroup>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Autocomplete
                                                                                            fullWidth
                                                                                            id="checkboxes-tags-demo"
                                                                                            options={subjectInternal}
                                                                                            noOptionsText={'No matching records found.'}
                                                                                            getOptionLabel={(option: any) => option.name}
                                                                                            value={autocompleteValues.subject_internal}
                                                                                            onChange={(e, value:any, resonse) => {
                                                                                            setFieldValue('subject_internal', value ? value.id : null);
                                                                                            this.handleAutocompleteChange('subject_internal',value)
                                                                                            }}
                                                                                            onInputChange={(event, newInputValue) => {
                                                                                                if (!newInputValue) {
                                                                                                this.handleAutocompleteChange('subject_internal','')
                                                                                                setFieldValue('subject_internal','');
                                                                                                }
                                                                                            }}
                                                                                            disableClearable
                                                                                            className="mb-0 mt-1"
                                                                                            renderInput={(params) => (
                                                                                            <Field
                                                                                                component={TextField}
                                                                                                name="subject_internal"
                                                                                                {...params} label={<><span>Internal Subject</span><span className='formmandatorycolor'> *</span></>}
                                                                                            />
                                                                                            )}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-6 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            label={<><span>Wizdomwaves Subject</span><span className='formmandatorycolor'> *</span></>}
                                                                                            select
                                                                                            name="wizdomwaves_subject"
                                                                                            component={TextField}
                                                                                            disabled={listExternalStatus}
                                                                                        >
                                                                                            {subjectExternal && subjectExternal.length > 0 && subjectExternal.map((item: any) => (
                                                                                                <MenuItem value={item.uid}>
                                                                                                    {`${item.name} - ${item.code}`}
                                                                                                </MenuItem>
                                                                                            ))}
                                                                                        </Field>
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            label={<><span>Language</span><span className='formmandatorycolor'> *</span></>}
                                                                                            select
                                                                                            name="language"
                                                                                            component={TextField}
                                                                                            disabled={false}
                                                                                        >
                                                                                            {language && language.length > 0 && language.map((item: any) => (
                                                                                                <MenuItem value={item.uid}>
                                                                                                    {item.name}
                                                                                                </MenuItem>
                                                                                            ))}
                                                                                        </Field>
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="text-right mb-3 mr-2 mt-4">
                                                                        <Button
                                                                        className="btn btn-pink mr-1 ml-1" 
                                                                        type="submit">Submit</Button>
                                                                        <Link to="/subjectmapping" 
                                                                            style={{ display: "inline-block" }}>
                                                                        <Button className="btn btn-default mr-1 ml-1">Cancel</Button></Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    )
                                                }}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : <div><SpinnerLoader /></div>}
                    </div>
                </div>
                <div style={loadingFull}><SpinnerLoader /></div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return{
        loading: state.QuestionBank.loading,
        getYear: state.profile.getYear,
        getInternalGradle: state.QuestionBank.getInternalGradle,
        getInternalSubject: state.QuestionBank.getInternalSubject,
        getExternalSubject: state.QuestionBank.getExternalSubject,
        getLanguage: state.QuestionBank.getLanguage,
    }
}

const mapDispatchToProps = {
    internalGradleGet,
    internalSubjectGet,
    externalSubjectGet,
    languageDropdownGet,
    addSubjectMapping,
  }

export default connect( mapStateToProps, mapDispatchToProps )(AddSubjectMapping)