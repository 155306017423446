import React, { Component } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import { Link } from 'react-router-dom';
import { QuestionBank } from '../../router/Roles';
import { Field, Formik, Form, FieldArray, ErrorMessage } from "formik";
import "./QuestionBankStyle.scss";
import { Button, MenuItem, FormGroup } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { fetchGenerateQuestions, fetchReloadQuestions, createQuestionPaper } from "../../store/QuestionBank/Actions";
import SpinnerLoader from "../../components/spinner/SpinnerLoader";
import ImageComponent from "../../components/ImageComponent";
import { ModalReloadAlert } from './ModalReloadAlert';

const initialValues = {
    question_name: "",
    question_image: "",
    questionlist: [{ value: '', required: true }],
    options: [{ option: '', content: '' }],
    question_correct_answer: "",
    empty_questions:"",
    questionsoptions: [
        { value: '', answer_image: '' },
        { value: '', answer_image: '' },
        { value: '', answer_image: '' },
        { value: '', answer_image: '' }],
    answer: "",
    answer_image: "",
    sub_question_list: [
        {
            question: '',
            question_image: '',
            marks: 1
        }
    ],
};


export type Props = {
    loading?: any;
    generateQuestions?: any;
    reloadQuestions?: any;
    errors?:any;
    fetchGenerateQuestions: (data: any) => any;
    fetchReloadQuestions: (data: any) => any;
    createQuestionPaper: (data: any) => any;
}

interface StateTypes {
    academicYear?: any;
    questions?: any;
    editableId?: any;
    questionImage?: any;
    options?: any;
    modalAlertShow?: boolean,
    reloadId?: any,
    reloadErrMsg?: any,
    answerImage?: any,
    subQuestionImage?: any;
}
export class GenerateQuestions extends Component<Props, StateTypes> {
    formik: any;
    constructor(props: any) {
        super(props);
        this.state = {
            academicYear: '',
            questions: {},
            editableId: '',
            questionImage: '',
            options: {},
            modalAlertShow:false,
            reloadId: '',
            reloadErrMsg: '',
            answerImage:'',
            subQuestionImage: []
        }
    }
    componentDidMount(): void {
        const { generateQuestions } = this.props;
        console.log(generateQuestions)
        const reorder = this.reOrderQuestions(generateQuestions)
        if(reorder){
            generateQuestions.question_records = reorder
        }
        this.setState({ questions: generateQuestions })
        window.scrollTo(0, 0);
    }

    reOrderQuestions = (generateQuestions: any) => {
        if(generateQuestions?.question_records){
            const questionRecords = generateQuestions.question_records;
            const order: Record<string, number> = {
                "MCQ": 1,
                "FIB": 2,
                "Regular-2 Marks": 3,
                "Regular-5 Marks": 4,
                "Regular-10 Marks": 5
            };
             const sortedQuestions = questionRecords.sort((a:any, b:any) => {
                return order[a.db_question_type] - order[b.db_question_type];
            });
            return sortedQuestions
        }
        return '';
    }
    handleQuestionImageChange = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = (e: any) => {
                this.setState(prevState => ({
                    questionImage: [reader.result]
                }));

            };
            reader.readAsDataURL(file);
        }
    };

    handleAnswerImageChange = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = (e: any) => {
                this.setState(prevState => ({
                    answerImage: [reader.result]
                }));

            };
            reader.readAsDataURL(file);
        }
    };

    handleImageChange = (event: any, option: any) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = (e: any) => {
                this.setState(prevState => ({
                    options: {
                        ...prevState.options,
                        [option]: { ...prevState.options[option], image: reader.result }
                    }
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    handleReadPassageSubImageChange = (event: any, index: any) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = (e: any) => {
                this.setState(prevState => ({
                    subQuestionImage: {
                        ...prevState.subQuestionImage,
                        [index]: { ...prevState.subQuestionImage[index], image: reader.result }
                    }
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    toRoman(num:any) {
        const roman = [
          { value: 1000, symbol: "M" },
          { value: 900, symbol: "CM" },
          { value: 500, symbol: "D" },
          { value: 400, symbol: "CD" },
          { value: 100, symbol: "C" },
          { value: 90, symbol: "XC" },
          { value: 50, symbol: "L" },
          { value: 40, symbol: "XL" },
          { value: 10, symbol: "X" },
          { value: 9, symbol: "IX" },
          { value: 5, symbol: "V" },
          { value: 4, symbol: "IV" },
          { value: 1, symbol: "I" },
        ];
      
        let result = "";
        for (let i = 0; i < roman.length; i++) {
          while (num >= roman[i].value) {
            result += roman[i].symbol;
            num -= roman[i].value;
          }
        }
        return result.toLowerCase(); // Convert to lowercase for i, ii, iii...
      }

    private renderQuestionSectionView(records: any, values: any, setTouched: any, setValues: any, setFieldTouched: any, errors: any, touched: any, setFieldValue: any) {
        console.log(values )
        const { editableId, options, subQuestionImage } = this.state;
        return (
            <div>
                {records?.question_records && records.question_records.length > 0 ?
                    records.question_records.map((items: any) => (
                        <div className="question_section">
                            <div className="question_section_title">
                                <div>
                                    {items.question_type}
                                </div>
                                <div>
                                    {items.marks}
                                </div>
                            </div>
                            {items.question.length > 0 && items.question !== undefined ?
                                items.question.map((ques: any, index: any) => (
                                    <>
                                        {editableId === ques.uid ? (
                                            <>
                                                <div className="questions_block">
                                                <div className="question-lesson-difficulty-level mb-3 mr-2 mt-4 color-pink">
                                                    Lesson Name : {ques.lesson_name}  (Difficulty level : {ques.difficulty_level_name})  
                                                </div>
                                                    {index + 1}.
                                                    <FormGroup>
                                                        <Field
                                                            label={<><span>Question Name</span><span className='formmandatorycolor'> *</span></>}
                                                            type="text"
                                                            name="question_name"
                                                            component={TextField}
                                                            className="textfield__input"
                                                            disabled={false}
                                                        />
                                                    </FormGroup>

                                                    <div className="mt-4">
                                                        <input type="file" accept=".jpg,.jpeg,.png" onChange={(event: any) => {
                                                            this.handleQuestionImageChange(event)
                                                            const file = event.target.files[0];
                                                            if (file) {
                                                                const reader = new FileReader();
                                                                reader.onload = (e: any) => {
                                                                    const base64Image = e.target.result;
                                                                    setFieldValue('question_image', base64Image);
                                                                };
                                                                reader.readAsDataURL(file);
                                                            }
                                                        }} />
                                                        {this.state.questionImage && <img src={this.state.questionImage} alt="Selected" style={{ maxWidth: '100px', maxHeight: '100px' }} />}
                                                    </div>
                                                    <div>
                                                        <ErrorMessage name="question_image" component="div" className="errorcolor" />
                                                    </div>
                                                    {items.db_question_type === "MCQ" && (
                                                        <FieldArray name={`questionsoptions`}>
                                                            {({ push: pushOption, remove }) => (
                                                                <div>
                                                                    {ques.result.map((option: any, optionIndex: any) => (
                                                                       
                                                                        <div key={optionIndex}>
                                                                            <div className="d-flex mt-3">
                                                                            <div className="mt-4 mr-3 ml-2">
                                                                                            <Field
                                                                                                type="radio"
                                                                                                name={`question_correct_answer`}
                                                                                                checked={option.option == values.question_correct_answer}
                                                                                                value={option.option}
                                                                                            />
                                                                                        </div>
                                                                                <div className="mr-3 mt-4">
                                                                                    {option.option}.
                                                                                </div>
                                                                                <FormGroup className="w-50">

                                                                                    <Field
                                                                                        label={<><span>Option</span><span className='formmandatorycolor'> *</span></>}
                                                                                        type="text"
                                                                                        name={`questionsoptions.${optionIndex}.value`}
                                                                                        component={TextField}
                                                                                        className="textfield__input"
                                                                                        disabled={false}
                                                                                    // validate={(value: any) => {

                                                                                    //     let error;
                                                                                    //     if (!value) {
                                                                                    //         error = 'Option content is required';
                                                                                    //     }
                                                                                    //     return error;
                                                                                    // }}
                                                                                    />

                                                                                    {/* {errors[`questionsoptions.${optionIndex}.value`] && touched[`questionsoptions.${optionIndex}.value`] && (
                                                                                        <div className="error"></div>
                                                                                    )} */}
                                                                                    <ErrorMessage name={`questionsoptions.${optionIndex}`} component="div" className="errorcolor" />
                                                                                </FormGroup>
                                                                                <div className="mt-4 ml-4">
                                                                                    <div className="mt-4">
                                                                                        <input type="file" accept=".jpg,.jpeg,.png" onChange={(event: any) => {
                                                                                            this.handleImageChange(event, optionIndex)
                                                                                            const file = event.target.files[0];
                                                                                            if (file) {
                                                                                                const reader = new FileReader();
                                                                                                reader.onload = (e: any) => {
                                                                                                    const base64Image = e.target.result;
                                                                                                    setFieldValue(`questionsoptions.${optionIndex}.answer_image`, base64Image);
                                                                                                };
                                                                                                reader.readAsDataURL(file);
                                                                                            }
                                                                                        }
                                                                                        }
                                                                                        />
                                                                                        {options?.[optionIndex]?.image && <img src={options?.[optionIndex]?.image} alt="Selected" style={{ maxWidth: '100px', maxHeight: '100px' }} />}
                                                                                    </div>

                                                                                </div>

                                                                            </div>
                                                                           
                                                                        </div>
                                                                    ))}
                                                                     <div className="text-center mb-3 mr-2 mt-4">
                                                                        <ErrorMessage name="question_correct_answer" component="div" className="errorcolor" />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </FieldArray>
                                                    )}
                                                    {items.en_question_type === "MatchPairQ" && (
                                                    <>
                                                    <FormGroup>
                                                        <Field
                                                            label={<><span>Answer</span><span className='formmandatorycolor'> *</span></>}
                                                            type="text"
                                                            name="answer"
                                                            component={TextField}
                                                            className="textfield__input"
                                                            disabled={false}
                                                        />
                                                    </FormGroup>
                                                    <div className="mt-4">
                                                        <input type="file" accept=".jpg,.jpeg,.png" onChange={(event: any) => {
                                                            this.handleAnswerImageChange(event)
                                                            const file = event.target.files[0];
                                                            if (file) {
                                                                const reader = new FileReader();
                                                                reader.onload = (e: any) => {
                                                                    const base64Image = e.target.result;
                                                                    setFieldValue('answer_image', base64Image);
                                                                };
                                                                reader.readAsDataURL(file);
                                                            }
                                                        }} />
                                                        {this.state.answerImage && <img src={this.state.answerImage} alt="Selected" style={{ maxWidth: '100px', maxHeight: '100px' }} />}
                                                    </div>
                                                    <div>
                                                        <ErrorMessage name="answer_image" component="div" className="errorcolor" />
                                                    </div>
                                                    </>
                                                    )}
                                                     {items.en_question_type === "ReadPassageQ" && (
                                                     <FieldArray name="sub_question_list">
                                                     {({ push, remove }) => {
                                                     if (values.sub_question_list.length !==  Number(items.en_question_mark)) {
                                                         values.sub_question_list.length = 0;
                                                         for (let i = 0; i <  Number(items.en_question_mark); i++) {
                                                             push({
                                                                 question: "",
                                                                 question_image: '',
                                                                 marks: 1
                                                             })
                                                         }
                                                     }
                                                     return (
                                                         <>
                                                         {values.sub_question_list.map((item:any, index:any) => (
                                                             <div className='row' key={index}>
                                                             <div className="col-md-12">                                                                                                            
                                                                         <div className="d-flex mt-1 w-100">
                                                                             <div className="mr-3 mt-4">
                                                                                 {this.toRoman(index + 1)}.
                                                                             </div>
                                                                             <FormGroup className="w-100">
                                                                                 <Field
                                                                                     label={<><span>Write Sub Question </span><span className='formmandatorycolor'> *</span></>}
                                                                                     type="text"
                                                                                     name={`sub_question_list[${index}].question`}
                                                                                     component={
                                                                                         TextField
                                                                                     }
                                                                                     className="textfield__input"
                                                                                     disabled={false}
                                                                                 />
                                                                                  <ErrorMessage name={`sub_question_list.${index}`} component="div" className="errorcolor" />
                                                                             </FormGroup>
                                                                             <div className="mt-4 ml-4">
                                                                                    <div className="mt-4">
                                                                                        <input type="file" accept=".jpg,.jpeg,.png" onChange={(event: any) => {
                                                                                            this.handleReadPassageSubImageChange(event, index)
                                                                                            const file = event.target.files[0];
                                                                                            if (file) {
                                                                                                const reader = new FileReader();
                                                                                                reader.onload = (e: any) => {
                                                                                                    const base64Image = e.target.result;
                                                                                                    setFieldValue(`sub_question_list.${index}.question_image`, base64Image);
                                                                                                };
                                                                                                reader.readAsDataURL(file);
                                                                                            }
                                                                                        }
                                                                                        }
                                                                                        />
                                                                                        {subQuestionImage?.[index]?.image && <img src={subQuestionImage?.[index]?.image} alt="Selected" style={{ maxWidth: '100px', maxHeight: '100px' }} />}
                                                                                    </div>
                                                                                </div>
                                                                         </div> 

                                                                         </div>
                                                                     </div>
                                                         ))}
                                                         </>
                                                     );
                                                     }}
                                                 </FieldArray>
                                                    )}
                                                    <div className="text-right mb-3 mr-2 mt-4" >
                                                        <Button className="btn red mr-1 ml-1" name="submitButton1" onClick={() => this.handleQuestionSubmit(ques.uid, values, setTouched, setFieldTouched, items.db_question_type)}>
                                                            Submit
                                                        </Button>
                                                        <Button className="btn red mr-1 ml-1" onClick={() => this.handleCancel(ques.uid)}>
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="questions_block">
                                                 <div className="question-lesson-difficulty-level mb-3 mr-2 mt-4 color-pink">
                                                    Lesson Name : {ques.lesson_name}  (Difficulty level : {ques.difficulty_level_name})  
                                                </div>
                                                <div className="question_name">{index + 1}.    {ques.question}</div >
                                                <div className="question_image">
                                                    {ques.question_image ?
                                                        <img src={ques.question_image} width={200} height={200}></img>
                                                        : null}
                                                </div >
                                                <div className="answer">
                                                    {ques.answer ?
                                                        ques.answer
                                                        : null}
                                                </div>
                                                <div className="answer">
                                                    {ques.answer_image ?
                                                         <img src={ques.answer_image} width={200} height={200}></img>
                                                        : null}
                                                </div>
                                                {
                                                    ques.result ? (
                                                        <>
                                                            {ques.result.length > 0 ? (
                                                                ques.result.map((option: any, index: any) => (
                                                                    <>
                                                                        <div className="mt-4">{option.option ? option.option : index + 1}.    {option.content}</div>
                                                                        {option.answer_image ? <div className="question_image">
                                                                            <img src={option.answer_image} width={200} height={200}></img>
                                                                        </div > : null}
                                                                    </>
                                                                ))
                                                            ) : null}
                                                        </>) : null

                                                }
                                                {
                                                    ques.sub_question_list ? (
                                                        <>
                                                            {ques.sub_question_list.length > 0 ? (
                                                                ques.sub_question_list.map((option: any, index: any) => (
                                                                    <>
                                                                        <div className="mt-4">{this.toRoman(index + 1)}.    {option.question}</div>
                                                                        {option.question_image ? <div className="question_image">
                                                                            <img src={option.question_image} width={200} height={200}></img>
                                                                        </div > : null}
                                                                    </>
                                                                ))
                                                            ) : null}
                                                        </>) : null

                                                }
                                                < div className="text-right mb-3 mr-2 mt-4">
                                                {ques.add_new_question ? <>                                                     
                                                    <Button className="btn btn-pink mr-1 ml-1" onClick={() => this.handleEdit(ques.uid)}>
                                                        <i className="fa fa-edit" aria-hidden="true"></i> &nbsp; Add
                                                    </Button> </> : 
                                                    <> 
                                                    {typeof ques?.reload === 'undefined' || ques?.reload !== false ?
                                                    <Button className="btn btn-pink mr-1 ml-1" onClick={() => this.handleReload(items, ques)}>
                                                        <i className="fa fa-refresh" aria-hidden="true"></i> &nbsp; Reload
                                                    </Button> : null}
                                                    <Button className="btn btn-pink mr-1 ml-1" onClick={() => this.handleEdit(ques.uid)}>
                                                        <i className="fa fa-edit" aria-hidden="true"></i> &nbsp; Edit
                                                    </Button>
                                                    </>}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ))
                                : null}
                        </div >

                    ))
                    : null}
            </div >)
    }

    handleRefresh = () => {
        const { generateQuestions } = this.props;
        const params = {
            blueprint_id: generateQuestions.uid,
            question_paper_id_list: generateQuestions.question_paper_id_list,
        }
        this.props.fetchGenerateQuestions(params).then((res: any) => {
            const { generateQuestions } = this.props;
            const reorder = this.reOrderQuestions(generateQuestions)
            if(reorder){
                generateQuestions.question_records = reorder
            }
            this.setState({ questions: generateQuestions })
        })

    };
    handleEdit = (id: any) => {
        const { questions } = this.state;
        const foundQuestion = questions.question_records.reduce((found: any, record: any) => {
            if (found) return found;
            const question = record.question.find((question: any) => question.uid === id);
            return question ? question : null;
        }, null);

        if (foundQuestion) {
            this.formik.setFieldValue(
                "question_name",
                foundQuestion?.is_edit ? foundQuestion.question : ''
            );
            this.setState({questionImage:foundQuestion?.is_edit ? foundQuestion.question_image : ''})  
            this.formik.setFieldValue(
                "question_image",
                foundQuestion?.is_edit ? foundQuestion.question_image : ''
            );

            this.formik.setFieldValue(
                "answer",
                foundQuestion?.is_edit ? foundQuestion.answer : ''
            );
            this.setState({answerImage:foundQuestion?.is_edit ? foundQuestion.answer_image : ''})  
            this.formik.setFieldValue(
                "answer_image",
                foundQuestion?.is_edit ? foundQuestion.answer_image : ''
            );

            {
                (foundQuestion.result && foundQuestion.result.length > 0) && (
                    foundQuestion.result.map((option: any, index: any) => {
                        this.formik.setFieldValue(
                            `questionsoptions.${index}.value`,
                            foundQuestion?.is_edit ? option.content : ''
                        )
                        this.formik.setFieldValue(
                            `questionsoptions.${index}.answer_image`,
                            foundQuestion?.is_edit ? option.answer_image : ''
                        )
                        if(foundQuestion?.is_edit){
                            const correctOption = option.is_correct_answer == true ? option.option : '';
                            if(correctOption){
                                this.formik.setFieldValue(
                                    `question_correct_answer`,
                                    foundQuestion?.is_edit ? correctOption : ''
                                )
                            }
                        }else{
                            this.formik.setFieldValue(
                                `question_correct_answer`,
                               ''
                            )
                        }
                        
                        this.setState(prevState => ({
                            options: {
                                ...prevState.options,
                                [index]: { ...prevState.options[index], image: foundQuestion?.is_edit ? option.answer_image : '' }
                            }
                        }));

                    })
                )
            }

            {
                (foundQuestion.sub_question_list && foundQuestion.sub_question_list.length > 0) && (
                    foundQuestion.sub_question_list.map((subQuestion: any, index: any) => {
                        this.formik.setFieldValue(
                            `sub_question_list.${index}.question`,
                            foundQuestion?.is_edit ? subQuestion.question : ''
                        )
                        this.formik.setFieldValue(
                            `sub_question_list.${index}.question_image`,
                            foundQuestion?.is_edit ? subQuestion.question_image : ''
                        )
                        this.setState(prevState => ({
                            subQuestionImage: {
                                ...prevState.subQuestionImage,
                                [index]: { ...prevState.subQuestionImage[index], image: foundQuestion?.is_edit ? subQuestion.question_image : '' }
                            }
                        }));

                    })
                )
            }
        }
        this.setState({ editableId: id })
    };

    handleCancel = (id: any) => {
        this.setState({ editableId: '' })
    };
    handleQuestionSubmit = (id: any, values: any, setTouched: any, setFieldTouched: any, db_question_type: any) => {
        console.log(values)
        const { questions } = this.state;
        this.formik.validateForm().then((errors: any) => {
            setFieldTouched('question_name', true);
            setFieldTouched('question_image', true);
            setFieldTouched('question_correct_answer', true);
            setFieldTouched('answer', true);
            // const touched = Object.keys(values).reduce((acc:any, key:any) => {
            //     acc[key] = true;
            //     return acc;
            //   }, {});
            //   setTouched(touched, false);


            //           const userFi = {questionsoptions:[

            //   {
            //       "value": true,
            //   },
            //   {
            //     "value": true,
            // },
            // {
            //     "value": true,
            // }
            // ,
            // {
            //     "value": true,
            // },
            // ]}

            //      this.formik.setTouched(userFi, false);

            values.questionsoptions.forEach((user: any, index: any) => {
                setFieldTouched(`questionsoptions.${index}.value`, true);
            });

            values.sub_question_list.forEach((user: any, index: any) => {
                setFieldTouched(`sub_question_list.${index}.question`, true);
            });




            if (Object.keys(errors).length === 0) {
                // No validation errors, proceed with further actions
                const question_records = questions.question_records.map((obj: any) => {
                    const question = obj.question.map((ques: any) => {
                        if (ques.uid === id) {
                            ques.question = values.question_name
                            ques.question_image = values.question_image
                            ques.is_edit = true
                            ques.answer = values.answer
                            ques.answer_image = values.answer_image
                            if(ques.add_new_question){
                                ques.add_new_question = false
                                ques.reload = false
                            } 
                            if (ques?.result) {
                                ques.result = ques.result.map((item: any, index: any) => {
                                    return {
                                        ...item,
                                        content: values.questionsoptions[index].value,
                                        answer_image: values.questionsoptions[index].answer_image ? values.questionsoptions[index].answer_image : "",
                                        is_correct_answer: item.option == values.question_correct_answer ? true : false,
                                    };
                                });
                            }
                            if (ques?.sub_question_list) {
                                ques.sub_question_list = ques.sub_question_list.map((item: any, index: any) => {
                                    return {
                                        ...item,
                                        question: values.sub_question_list[index].question,
                                        question_image: values.sub_question_list[index].question_image,
                                        marks:1
                                    };
                                });
                            }


                            return ques;
                        }
                        return ques;
                    });

                    return { ...obj, question };
                })

                questions.question_records = question_records;
                this.setState({ questions: questions, editableId: '' })
                this.formik.setFieldValue(
                    "question_name",
                    ''
                );
            } else {
                // Validation errors present
                console.log('Validation errors:', errors);
            }
        });
    };
    getQuestionType = () => {
        const { questions, editableId } = this.state;
        let dbQuestionType: any;
        for (const record of questions.question_records) {
            const question = record.question.find((question: any) => question.uid === editableId);
            if (question) {
                dbQuestionType = record.en_question_type;
                break;
            }
        }
        return dbQuestionType;
    }
    isQuestionUnique = (questionText: any) => {
        const { questions, editableId } = this.state;
        let dbQuestionType: any;
        for (const record of questions.question_records) {
            const question = record.question.find((question: any) => question.uid === editableId);
            if (question) {
                dbQuestionType = record.db_question_type;
                break;
            }
        }
        const questionsOfType = questions.question_records
            .filter((record: any) => record.db_question_type === dbQuestionType)
            .flatMap((record: any) => record.question)
            .filter((question: any) => question.uid !== editableId); // Exclude the current question
        const questionTextToCheck = questionsOfType.find((question: any) => question.question === questionText);
        return !questionTextToCheck;
    }

    checkAddQuestionExit = () => {
        const { questions } = this.state;

        const questionsFil =  questions.question_records.map((record: any) => ({
        ...record,
        question: record.question.filter((question: any) => question.add_new_question === true)
        })).filter((record: any) => record.question.length > 0);

        let count = 0;
        questions.question_records.forEach((record:any) => {
          record.question.forEach((question:any) => {
            if (question.add_new_question) {
              count += 1;
            }
          });
        });
        return count;

    //     const questionsOfType = questions.question_records
    //     .filter((record: any) => record.db_question_type === '')
    //     .flatMap((record: any) => record.question)
    //     .filter((question: any) => question.uid !== editableId); // Exclude the current question
    // const questionTextToCheck = questionsOfType.find((question: any) => question.question === questionText);


    }
    handleReload = (items: any, ques: any) => {

        const { questions } = this.state;
        const { generateQuestions } = this.props;
        const uids = items.question.map((item: any) => item.uid);

        const params = {
            question_paper_id_list: [],
            language_code: questions.language_code,
            uid_list: uids,
            grade_id: ques.grade_id,
            subject_id: ques.subject_id,
            lesson_id: ques.lesson_id,
            question_type_id: ques.question_type_id,
            marks: ques.marks,
            difficulty_level_id: ques.difficulty_level_id,
        }

        this.props.fetchReloadQuestions(params).then((res: any) => {
            const { reloadQuestions, errors } = this.props;
            if(Object.keys(reloadQuestions).length != 0){
            const question_records = questions.question_records.map((obj: any) => {
                const question = obj.question.map((q: any) => {
                    if (q.uid === ques.uid) {
                        return reloadQuestions.question[0];
                    }
                    return q;
                });

                return { ...obj, question };
            })
            questions.question_records = question_records;
            this.setState({ questions: questions })
        }else{
            let message = ''
                if(errors?.message){
                    message = errors.message + '\n Do you want Add new Question?'
                }else{
                    message = 'Do you want Add new Question?' 
                }
            this.setState({ modalAlertShow: true, reloadId: ques.uid,reloadErrMsg:message})
        }
        })

    };
    onCancel = () => {
        this.setState({ modalAlertShow: false});
     }
    onAdd = () => {
        this.setState({ modalAlertShow: false});
        this.handleEdit(this.state.reloadId)
     }
    render() {
        const { questions, editableId, modalAlertShow, reloadErrMsg} = this.state;
        const { loading } = this.props;
        const loadingQuestionFull = { display: loading ? "block" : "none" };
        return (
            <div className="page-wrapper">
            <ModalReloadAlert
            onState={modalAlertShow}
            onCancel={this.onCancel}
            onAdd={this.onAdd}
            message={reloadErrMsg}
            />
                <div className="page-content-wrapper">
                    <div className="page-content pt-3">
                        <BreadCrumb
                            titleName={['Generate Questions']}
                            homeName={['Home']}
                            url={['dashboard']}
                            mainPageTitle={['Generate Questions']} />

                        <div className="row">
                            <div className="col-md-12">
                                <Formik
                                    ref={node => this.formik = node}
                                    initialValues={initialValues}
                                    validate={(values) => {
                                        const errors: { [key: string]: any } = {};
                                        const uniqueValues = new Set<string>();
                                        const uniqueImages = new Set<string>();
                                        const imageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
                                        if (editableId) {
                                            if (!values.question_name) {
                                                errors.question_name = 'Question is required';
                                            } else {
                                                const isunique = this.isQuestionUnique(values.question_name.trim())
                                                if (!isunique) {
                                                    errors.question_name = 'Question must be unique required';
                                                }
                                            }
                                            
                                            if (values.question_image) {
                                                const fileSizeInBytesquestion_image = (values.question_image.length * 3) / 4 - 2;
                                                if(fileSizeInBytesquestion_image >= 2 * 1024 * 1024) {
                                                    errors.question_image = 'Image size should be less than 2MB'
                                                }
                                            }
                                            const questionType = this.getQuestionType();
                                            if (questionType == 'MCQ') {
                                                const { questionsoptions,  question_correct_answer } = values;
                                                questionsoptions.forEach((option: any, index: any) => {
                                                    const fileType = option.answer_image.substring(5, option.answer_image.indexOf(';'));
                                                    const fileSizeInBytes = (option.answer_image.length * 3) / 4 - 2;
                                                    if (!option.value.trim() && !option.answer_image) {
                                                        errors.questionsoptions = errors.questionsoptions || [];
                                                        errors.questionsoptions[index] = 'Please add text or upload an image';
                                                    } else if (option.value && uniqueValues.has(option.value.trim())) {
                                                        errors.questionsoptions = errors.questionsoptions || [];
                                                        errors.questionsoptions[index] = 'Option text must be unique';
                                                    } else if (option.answer_image && !imageTypes.includes(fileType)) {
                                                        errors.questionsoptions = errors.questionsoptions || [];
                                                        errors.questionsoptions[index] = 'Invalid image type. Supported types are JPEG, JPG, PNG';
                                                    } else if (option.answer_image && fileSizeInBytes >= 2 * 1024 * 1024) {
                                                        errors.questionsoptions = errors.questionsoptions || [];
                                                        errors.questionsoptions[index] = 'Image size should be less than 2MB';
                                                    } else if (option.answer_image && uniqueImages.has(option.answer_image.trim())) {
                                                        errors.questionsoptions = errors.questionsoptions || [];
                                                        errors.questionsoptions[index] = 'Option image must be unique';
                                                    }
                                                    uniqueValues.add(option.value.trim());
                                                    uniqueImages.add(option.answer_image.trim());
                                                });

                                                if (!question_correct_answer) {
                                                    errors.question_correct_answer = 'Question Correct Answer is required';
                                                }
                                            }
                                            if (questionType == 'MatchPairQ') {
                                                if (!values.answer) {
                                                    errors.answer = 'Answer is required';
                                                }
                                                if (values.answer_image) {
                                                    const fileSizeInBytesquestion_image = (values.answer_image.length * 3) / 4 - 2;
                                                    if(fileSizeInBytesquestion_image >= 2 * 1024 * 1024) {
                                                        errors.answer_image = 'Image size should be less than 2MB'
                                                    }
                                                }
                                            }
                                            if (questionType == 'ReadPassageQ') {
                                                 const { sub_question_list } = values;
                                                 sub_question_list.forEach((question: any, index: any) => {
                                                    const fileSizeInBytes = (question.question_image.length * 3) / 4 - 2;
                                                    if (!question.question.trim()) {
                                                        errors.sub_question_list = errors.sub_question_list || [];
                                                        errors.sub_question_list[index] = 'Question is required';
                                                    }else if (question.question_image && fileSizeInBytes >= 2 * 1024 * 1024) {
                                                        errors.questionsoptions = errors.sub_question_list || [];
                                                        errors.sub_question_list[index] = 'Image size should be less than 2MB';
                                                    }
                                                });
                                            }
                                        }else{
                                            const questionType = this.checkAddQuestionExit();
                                            if (questionType) {
                                                errors.empty_questions = 'Some Questions Are empty. Please Add then Sumbit';
                                            }
                                        }

                                        //   const touched: { [key: string]: any } = {};
                                        //   values.questionsoptions.forEach((user:any, index:any) => {
                                        //     touched[`questionsoptions.${index}.value`] = true;
                                        //   });
                                        //   return { ...errors, touched };

                                        //   values.questionlist.forEach((item:any, index:any) => {
                                        //     if (item.required && !item.value.trim()) {
                                        //       errors[`questions[${index}].value`] = 'Item is required';
                                        //     }
                                        //   });

                                        //   if (!values.questionlist || !values.questionlist.length) {
                                        //     errors.items = 'At least one item is required';
                                        //   } else {
                                        //     values.questionlist.forEach((item:any, index:any) => {
                                        //         console.log(item)
                                        //       if (!item) {
                                        //         errors[`questionlist[${index}]`] = 'Item is required';
                                        //       }
                                        //     });
                                        //   }
                                        return errors;

                                    }}

                                    onSubmit={(values, { setSubmitting }) => {
                                        setSubmitting(false);
                                        this.props.createQuestionPaper(questions).then((res: any) => {

                                        })

                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        isSubmitting,
                                        isValidating,
                                        dirty,
                                        touched,
                                        setTouched,
                                        setFieldTouched,
                                        handleReset,
                                        handleSubmit,
                                        setFieldValue,
                                        setValues
                                    }) => {

                                        return (
                                            <Form>

                                                <div className="row question_paper_row">

                                                    <div className="col-md-8 question_paper">
                                                        <div className="text-right mb-3 mr-2 mt-4" >
                                                            <Button className="btn red mr-1 ml-1" onClick={() => this.handleRefresh()}>
                                                                <i className="fa fa-refresh" aria-hidden="true"></i> &nbsp; Refresh
                                                            </Button>
                                                        </div>
                                                        <div className="question_header">
                                                            <div className="question_header_name">
                                                                <h3>{questions.school_name}</h3>
                                                            </div>
                                                            <div className="question_header_details">
                                                                <div>
                                                                    <h5>{questions.internal_grade_name} std</h5>
                                                                </div>
                                                                <div>
                                                                    <h5>{questions.internal_subject_name}</h5>
                                                                </div>
                                                                <div>
                                                                    <h5>{questions.exam_total_marks} marks</h5>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            {this.renderQuestionSectionView(questions, values, setTouched, setValues, setFieldTouched, errors, touched, setFieldValue)}
                                                        </div>
                                                        <div className="text-center mb-3 mr-2 mt-4">
                                                            <ErrorMessage name="empty_questions" component="div" className="errorcolor" />
                                                        </div>
                                                        <div className="text-right mb-3 mr-2 mt-4" >
                                                            <Button className="btn red mr-1 ml-1" type="submit" name="submitButton2">
                                                                Submit
                                                            </Button>
                                                            <Link to="/generatequestions"
                                                                style={{ display: "inline-block" }}>
                                                                <Button className="btn red mr-1 ml-1">
                                                                    Cancel
                                                                </Button>
                                                            </Link>

                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={loadingQuestionFull}><SpinnerLoader /></div>
            </div>
        )
    }
}



const mapStateToProps = (state: any) => {
    return {
        loading: state.QuestionBank.loading,
        generateQuestions: state.QuestionBank.generateQuestions,
        reloadQuestions: state.QuestionBank.reloadQuestions,
        errors: state.QuestionBank.errors,
    }
}

const mapDispatchToProps = {
    fetchGenerateQuestions,
    fetchReloadQuestions,
    createQuestionPaper
}

export default connect(mapStateToProps, mapDispatchToProps)(GenerateQuestions)