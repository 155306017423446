export const option:any = {
	legend: {
		display: false
	},
	tooltips: {
		callbacks: {
			label: function (tooltipItem: any, data: any) {
				var dataset = data.datasets[tooltipItem.datasetIndex];
				var currentValue = dataset.data[tooltipItem.index];
				return '(' + currentValue + '%)';
			},
			title: function (tooltipItem: any, data: any) {
				return data.datasets[0].label[tooltipItem[0].index];
			}
		}
	},
	title: {
		display: true,
		text: ''
	},
	scales: {
		yAxes: [{
			ticks: {
			  beginAtZero: true,
			  steps: 10,
			  stepValue: 5
			},
			gridLines : {
				color: 'rgba(170,170,170,1)',
				  lineWidth: 0.2
			}
		  }],
		xAxes: [{
			barPercentage: 0.1,
			gridLines: {
				display: false
			}
		}]
	}
}

export interface propsTeacherType {
	schoolId: any;
	academicYear: any;
	classId: any;
	attendFilter?: any;
	subjectId?: any;
	subjectperId?: any;
	subjectFilterYear?: any;
	subject_name?: any;
	performance?: any;
	color_code?: any;
	subject_name_Perform?: any;
	performance_Perform?: any;
	color_code_Perform?: any;
	labelNameList?: any;
	SubjectPerformance?: any;
	correctSubjectList?: any;
	addSAClassName?: any;
	SubjectPerformanceRecord?: any;
  }
// this is state key used teacher subject
  export interface stateTypes {
	attendFilter: any;
	classid: any;
	page?: any;
	search?: string;
	SortOrderData?: string;
	OrderNameData?: string;
	hasMore?: any;
	getLeastAttendeesRecord?: any;
	recordPointValue?: any;
	academicYear?:any
  }
// this is the state type used Topic analysis view
  export interface propsTeacherType {
	schoolId: any;
	academicYear: any;
	classId: any;
	attendFilter?: any;
	subjectId?: any;
	subjectFilterYear?: any;
	subject_name?: any;
	performance?: any;
	color_code?: any;
	subject_name_Perform?: any;
	performance_Perform?: any;
	color_code_Perform?: any;
	labelNameList?: any;
	labelNameListTop?: any;
	TopicAnalysis?: any;
	SubjectPerformance?: any;
	correctSubjectList?: any;
	addClassName?: any;
	addSAClassName?: any;
  }