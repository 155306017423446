import React, { Component } from "react";
import { connect } from "react-redux";
import CommonLoader from '../../components/CommonLoader';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import { VictoryLine, createContainer, VictoryChart, VictoryTooltip, VictoryScatter } from "victory";
const VictoryZoomVoronoiContainer: any = createContainer("zoom", "voronoi")


export type OwnReportProps = {
    teacherOverallReportData?: any;
};

interface StateTypes {
    legendNameList: any,
    teacherOverallDataset: any,
}
export class TeacherOverallViewReport extends Component<OwnReportProps, StateTypes>{
    formik: any
    constructor(props: any) {
        super(props);
        this.state = {
            legendNameList: [],
            teacherOverallDataset: [],
        }
    }
    componentDidMount(): void {
        const { teacherOverallReportData } = this.props
        console.log(teacherOverallReportData)
        this.getTeacherOverallDataset()
    }

    componentDidUpdate(prevProps: any): void {
        const { teacherOverallReportData } = this.props
        if (prevProps.teacherOverallReportData != teacherOverallReportData) {
            this.getTeacherOverallDataset()
        }
    }

    getTeacherOverallDataset() {
        let legendNameList: any = [];

        const { teacherOverallReportData } = this.props
        legendNameList = [];
        teacherOverallReportData && teacherOverallReportData?.performance && Object.keys(teacherOverallReportData?.performance).forEach((subjectKey: any) => {

            let nameList: any = {
                name: teacherOverallReportData?.performance[subjectKey].label,
                color_code: teacherOverallReportData?.performance[subjectKey].color_code
            }
            legendNameList.push(nameList)

        });

        teacherOverallReportData && teacherOverallReportData?.performance && Object.keys(teacherOverallReportData?.performance).forEach(subjectKey => {
            let lineChartSubject: any = [];
            teacherOverallReportData?.performance[subjectKey].values.forEach((getDate: any) => {
                let subjecteObj: any = {}
                subjecteObj['x'] = getDate.month
                subjecteObj['y'] = getDate.value
                subjecteObj['labelName'] = `${teacherOverallReportData?.performance[subjectKey].label} - ${getDate.value}`
                // subjectDataset.push(subjecteObj)
                lineChartSubject.push(subjecteObj);
                teacherOverallReportData.performance[subjectKey].subject_list = lineChartSubject;
                teacherOverallReportData.performance[subjectKey].is_visible = true
            })


        });
        this.setState({ legendNameList: legendNameList, teacherOverallDataset: teacherOverallReportData })
    }

    getHiddenLine = (getValue: any, index: any) => {
        const { legendNameList } = this.state;
        legendNameList.forEach((items: any, ind: any) => {
            if (items.name === getValue) {
                let lineActive: any = document.getElementById(index);
                if (lineActive.className === 'mr-2 pointer lineChartLabel') {
                    lineActive.classList.remove('lineChartLabel');
                } else {
                    lineActive.classList.add('lineChartLabel');
                }
            }
        })


        const { teacherOverallDataset } = this.state;
        if (teacherOverallDataset !== undefined && teacherOverallDataset !== null) {
            Object.keys(teacherOverallDataset?.performance).forEach(subjectKey => {
                let getSubjectList: any = teacherOverallDataset.performance[subjectKey]
                if (getSubjectList && getSubjectList !== undefined) {
                    if (getSubjectList.label === getValue) {
                        if (getSubjectList.is_visible === true && getSubjectList.label === getValue) {
                            teacherOverallDataset.performance[subjectKey].is_visible = false

                        } else if (getSubjectList.is_visible === false && getSubjectList.label === getValue) {

                            teacherOverallDataset.performance[subjectKey].is_visible = true
                        }
                    }
                }
            })
            this.setState({ teacherOverallDataset: teacherOverallDataset })
        }

    }

    render() {
        const { teacherOverallReportData } = this.props
        const { legendNameList, teacherOverallDataset } = this.state

        return (
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-5">
                <div className={`card card-topline-red`}>
                    <div className="card-head">
                        <header>Overall Teacher Performance</header>
                    </div>
                    <div className="card-body no-padding height-9" >
                        {teacherOverallDataset && teacherOverallDataset !== undefined && teacherOverallDataset !== null ?
                            <div className="row justify-content-center">
                                <div className="mt-5">

                                    {legendNameList ?
                                        legendNameList.map((items: any, index: any) => (
                                            <>
                                                <span className="mr-2 pointer" style={{ color: items.color_code }} id={index + items.name} onClick={() => this.getHiddenLine(items.name, index + items.name)}><i className="fa fa-circle" aria-hidden="true"></i> {items.name}</span>
                                            </>
                                        )) : null}
                                </div>
                                <VictoryChart
                                    width={1200}
                                    height={300}
                                    scale={{ x: "time" }}
                                    padding={{ top: 20, left: 50, right: 50, bottom: 40 }}
                                    domain={{ y: [0, 100] }}
                                    domainPadding={{ x: 10, y: 30 }}
                                    containerComponent={
                                        <VictoryZoomVoronoiContainer
                                            voronoiBlacklist={["LineLinear"]}
                                            zoomDimension="x"
                                            labels={({ datum }: any) => `${datum.labelName}`}
                                            labelComponent={<VictoryTooltip cornerRadius={0} flyoutStyle={{ fill: "white" }} />}
                                        />
                                    }
                                >

                                    {teacherOverallDataset && teacherOverallDataset?.performance ?

                                        Object.entries(teacherOverallDataset.performance).map(([subjectKey, subjectList]: any) => {
                                            if (subjectList.is_visible === true) {
                                                return ([
                                                    <VictoryLine
                                                        name="LineLinear"
                                                        interpolation="linear"
                                                        style={{
                                                            data: { stroke: `${subjectList.color_code}` },
                                                            parent: { border: `1px solid ${subjectList.color_code}` }
                                                        }}
                                                        data={subjectList.subject_list}
                                                    />,
                                                    <VictoryScatter
                                                        size={4}
                                                        style={{
                                                            data: { fill: `${subjectList.color_code}` }
                                                        }}
                                                        data={subjectList.subject_list}
                                                    />
                                                ]

                                                )
                                            }

                                        })

                                        : null}
                                </VictoryChart>
                            </div>
                            : <CommonLoader />}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

export default connect(mapStateToProps, {

})(TeacherOverallViewReport)