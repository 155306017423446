import { ThunkAction } from 'redux-thunk';
import { Dispatch } from 'redux';
import { RootState, RootActions } from '../Index';
import { AxiosResponse } from 'axios';
import history from '../../History';
import { baseAPI } from '../../Service';
import { SCHOOLMANAGE, SchoolCalendar } from '../../services/Config'
import { SchoolsActionTypes } from './Types'
import toaster from "toasted-notes";
import { notificationMsg } from '../../services/Constants'
import { LoadMoreType } from '../../components/type';
import { UserRoles } from '../../services/Constants';

//Implement Thunk middle ware
export type ThunkResult<R> = ThunkAction<R, RootState, undefined, RootActions>;

interface FetchSchools {
    type: SchoolsActionTypes.FETCH_SCHOOLS;
}

interface FetchSchoolsSuccess {
    type: SchoolsActionTypes.FETCH_SCHOOLS_SUCCESS;
    payload: any;
}

interface FetchSchoolsFail {
    type: SchoolsActionTypes.FETCH_SCHOOLS_FAIL;
}

export const fetchSchoolsPost = (loadMoreType: LoadMoreType): ThunkResult<void> => async dispatch => {
    handleFetchSchools(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.get(SCHOOLMANAGE.SCHOOLMANAGEVIEW, {
            params: loadMoreType,
            headers: {
                "Authorization": localStorage.getItem('token')
            }
        });
        handleFetchSchoolsSuccess(dispatch, response.data);
    } catch (e) {
        handleFetchSchoolsFail(dispatch);
    }
};

export const handleFetchSchools = (dispatch: Dispatch<FetchSchools>) => {
    dispatch({ type: SchoolsActionTypes.FETCH_SCHOOLS });
};

export const handleFetchSchoolsSuccess = (
    dispatch: Dispatch<FetchSchoolsSuccess>,
    response: any
) => {
    dispatch({
        type: SchoolsActionTypes.FETCH_SCHOOLS_SUCCESS,
        payload: response,
        records: response.data.records,
        per_page: response.data.per_page,
        page: response.data.page,
        total: response.data.total
    });

};

export const handleFetchSchoolsFail = (dispatch: Dispatch<FetchSchoolsFail>) => {
    dispatch({
        type: SchoolsActionTypes.FETCH_SCHOOLS_FAIL
    });
};


interface FetchSchoolsView {
    type: SchoolsActionTypes.SCHOOL_VIEW;
}

interface FetchSchoolsViewSuccess {
    type: SchoolsActionTypes.SCHOOL_VIEW_SUCCESS;
    payload: any;
}

interface FetchSchoolsViewFail {
    type: SchoolsActionTypes.SCHOOL_VIEW_FAIL;
}

export const fetchSchoolsViewGet = (loadMoreType: LoadMoreType): ThunkResult<void> => async dispatch => {
    handleFetchSchoolsView(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.get(SCHOOLMANAGE.SCHOOLMANAGEVIEW, {
            params: loadMoreType,
            headers: {
                "Authorization": localStorage.getItem('token')
            }
        });
        handleFetchSchoolsViewSuccess(dispatch, response.data);
    } catch (e) {
        handleFetchSchoolsViewFail(dispatch);
    }
};

export const handleFetchSchoolsView = (dispatch: Dispatch<FetchSchoolsView>) => {
    dispatch({ type: SchoolsActionTypes.SCHOOL_VIEW });
};

export const handleFetchSchoolsViewSuccess = (
    dispatch: Dispatch<FetchSchoolsViewSuccess>,
    response: any
) => {
    dispatch({
        type: SchoolsActionTypes.SCHOOL_VIEW_SUCCESS,
        payload: response,
        records: response.data.records,
        per_page: response.data.per_page,
        page: response.data.page,
        total: response.data.total
    });

};

export const handleFetchSchoolsViewFail = (dispatch: Dispatch<FetchSchoolsViewFail>) => {
    dispatch({
        type: SchoolsActionTypes.SCHOOL_VIEW_FAIL
    });
};

// FETCH SCHOOL edit details

interface FetchSchool {
    type: SchoolsActionTypes.FETCH_SCHOOL_ID;
}

interface FetchSchoolSuccess {
    type: SchoolsActionTypes.FETCH_SCHOOL_SUCCESS_ID;
    payload: any;
}

interface FetchSchoolFail {
    type: SchoolsActionTypes.FETCH_SCHOOL_FAIL_ID;
}

export const fetchSchool = (id: number): ThunkResult<void> => async dispatch => {
    handleFetchSchool(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.get(`/api/school/${id}/`, 
        { headers: { "Authorization": localStorage.getItem('token') } });
        handleFetchSchoolSuccess(dispatch, response.data);
    } catch (e) {
        handleFetchSchoolFail(dispatch);
    }
};

export const handleFetchSchool = (dispatch: Dispatch<FetchSchool>) => {
    dispatch({ type: SchoolsActionTypes.FETCH_SCHOOL_ID });
};

const handleFetchSchoolSuccess = (
    dispatch: Dispatch<FetchSchoolSuccess>,
    response: any
) => {
    dispatch({
        type: SchoolsActionTypes.FETCH_SCHOOL_SUCCESS_ID,
        payload: response,
        getSchoolEdit:response.data
    });
};

const handleFetchSchoolFail = (dispatch: Dispatch<FetchSchoolFail>) => {
    dispatch({
        type: SchoolsActionTypes.FETCH_SCHOOL_FAIL_ID
    });
};


// FETCH SCHOOL edit details

interface commonGetServices {
    type: SchoolsActionTypes.COMMON_GET;
}

interface commonGetServicesSuccess {
    type: SchoolsActionTypes.COMMON_GET_SUCCESS;
    payload: any;
}

interface commonGetServicesFail {
    type: SchoolsActionTypes.COMMON_GET_FAIL;
}

export const commonGet = (URL?: number, body?:any): ThunkResult<void> => async dispatch => {
    handleCommonGetServices(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.get(`${URL}${body}`, 
        { headers: { "Authorization": localStorage.getItem('token') } });
        handleCommonGetServicesSuccess(dispatch, response.data);
    } catch (e) {
        handleCommonGetServicesFail(dispatch);
    }
};

export const handleCommonGetServices = (dispatch: Dispatch<commonGetServices>) => {
    dispatch({ type: SchoolsActionTypes.COMMON_GET });
};

const handleCommonGetServicesSuccess = (
    dispatch: Dispatch<commonGetServicesSuccess>,
    response: any
) => {
    dispatch({
        type: SchoolsActionTypes.COMMON_GET_SUCCESS,
        payload: response,
        schoolCategoryList:response.data
    });
};

const handleCommonGetServicesFail = (dispatch: Dispatch<commonGetServicesFail>) => {
    dispatch({
        type: SchoolsActionTypes.COMMON_GET_FAIL
    });
};

// ADD SCHOOL

interface AddSchool {
    type: SchoolsActionTypes.ADD_SCHOOL;
}

interface AddSchoolSuccess {
    type: SchoolsActionTypes.ADD_SCHOOL_SUCCESS;
    payload: any;
}

interface AddSchoolFail {
    type: SchoolsActionTypes.ADD_SCHOOL_FAIL;
    payload: any;
}

export const addSchool = (school: any): ThunkResult<void> => async dispatch => {
    handleAddSchool(dispatch);

    try {
        const response: AxiosResponse<any> = await baseAPI.post(SCHOOLMANAGE.SCHOOLMANAGEVIEW, school,
            { headers: { "Authorization": localStorage.getItem('token') } });
        if (response.data.status === true) {
            toaster.notify(response.data.message, {
                position: 'top',
                duration: notificationMsg.duration
            });
            handleAddSchoolSuccess(dispatch, response.data);
        } else {
            if (response.data) {
                const mapError = response.data.data.latitude + "Latitude and Longitude";
                if (response.data.data.latitude) {
                    toaster.notify(mapError, {
                        position: 'top',
                        duration: notificationMsg.errorNotificationDuration
                    });
                }
            }
            handleAddSchoolFail(dispatch, response.data);
        }
    } catch (e) {
        handleAddSchoolFail(dispatch, e);
    }
};

const handleAddSchool = (dispatch: Dispatch<AddSchool>) => {
    dispatch({ type: SchoolsActionTypes.ADD_SCHOOL });
};

const handleAddSchoolSuccess = (
    dispatch: Dispatch<AddSchoolSuccess>,
    response: any
) => {
    dispatch({ type: SchoolsActionTypes.ADD_SCHOOL_SUCCESS, payload: response });
    history.push('/school');
};

const handleAddSchoolFail = (dispatch: Dispatch<AddSchoolFail>, response: any) => {
        dispatch({ type: SchoolsActionTypes.ADD_SCHOOL_FAIL, payload: response.data });
};

// EDIT SCHOOL

interface EditSchool {
    type: SchoolsActionTypes.EDIT_SCHOOL;
}

interface EditSchoolSuccess {
    type: SchoolsActionTypes.EDIT_SCHOOL_SUCCESS;
    payload: any;
}

interface EditSchoolFail {
    type: SchoolsActionTypes.EDIT_SCHOOL_FAIL;
    payload: any;
}

export const editSchool = (editedSchool: any): ThunkResult<void> => async dispatch => {
    handleEditSchool(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.put(`/api/school/${editedSchool.id}/`, editedSchool, 
        { headers: { "Authorization": localStorage.getItem('token') } });
        let getUserType = localStorage.getItem('usertype');
        if (response.data.status === true) {
            toaster.notify(response.data.message, {
                position: 'top',
                duration: notificationMsg.duration
            });
            handleEditSchoolSuccess(dispatch, response.data);
            if (getUserType === UserRoles.acadamicAdmin) {
                history.push('/school');
            } else {
                history.push('/view_school');
            }
        } else {
            toaster.notify(response.data.message, {
                position: 'top',
                duration: notificationMsg.duration
            });
            handleEditSchoolFail(dispatch, response.data);
            if (getUserType === UserRoles.acadamicAdmin) {
                history.push('/school');
            } else {
                history.push('/view_school');
            }
        }
    }
    catch (e) {
        handleEditSchoolFail(dispatch, e);
    }
};
const handleEditSchool = (dispatch: Dispatch<EditSchool>): void => {
    dispatch({ type: SchoolsActionTypes.EDIT_SCHOOL });
};
const handleEditSchoolSuccess = (dispatch: Dispatch<EditSchoolSuccess>, editedSchool: any) => {
    dispatch({ type: SchoolsActionTypes.EDIT_SCHOOL_SUCCESS, payload: editedSchool });

};

const handleEditSchoolFail = (dispatch: Dispatch<EditSchoolFail>, response: any) => {
        dispatch({ type: SchoolsActionTypes.EDIT_SCHOOL_FAIL, payload: response.data });
};

// DELETE SCHOOL

interface DeleteSchool {
    type: SchoolsActionTypes.DELETE_SCHOOL;
}

interface DeleteSchoolSuccess {
    type: SchoolsActionTypes.DELETE_SCHOOL_SUCCESS;
    payload: any;
}

interface DeleteSchoolFail {
    type: SchoolsActionTypes.DELETE_SCHOOL_FAIL;
}

export const deletePost = (deletedId: any): ThunkResult<void> => async dispatch => {
    const getvalue = {
        id: deletedId.id,
        is_active: deletedId.isActive
    }
    handleDeleteSchool(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.post(`/api/school/${deletedId.id}/`, getvalue, 
        { headers: { "Authorization": localStorage.getItem('token') } });
        if (response.data.status === true) {
            toaster.notify(response.data.message, {
                position: 'top',
                duration: notificationMsg.duration
            });
            handleDeleteSchoolSuccess(dispatch, response.data);
        } else {
            toaster.notify(response.data.message, {
                position: 'top',
                duration: notificationMsg.errorNotificationDuration
            });
            handleDeleteSchoolFail(dispatch);
        }

    } catch (e) {
        handleDeleteSchoolFail(dispatch);
    }
};

const handleDeleteSchool = (dispatch: Dispatch<DeleteSchool>) => {
    dispatch({ type: SchoolsActionTypes.DELETE_SCHOOL });
};

const handleDeleteSchoolSuccess = (
    dispatch: Dispatch<DeleteSchoolSuccess>,
    response: any
) => {
    dispatch({ type: SchoolsActionTypes.DELETE_SCHOOL_SUCCESS, payload: response });
    history.push('/school');
};

const handleDeleteSchoolFail = (dispatch: Dispatch<DeleteSchoolFail>) => {
    dispatch({ type: SchoolsActionTypes.DELETE_SCHOOL_FAIL });
};
// Clear All Data
interface FetchResetSchool {
    type: SchoolsActionTypes.Reset_School_Details
}

export const ResetSchool = (): ThunkResult<void> => async dispatch => {
    handleResetSchool(dispatch);
}

export const handleResetSchool = (dispatch: Dispatch<FetchResetSchool>) => {
    dispatch({ type: SchoolsActionTypes.Reset_School_Details });
};
// Create School Calendar Default Entry
interface createSchoolCalendarDefaultEntry{
    type:SchoolsActionTypes.CREATE_SCHOOL_CALENDAR_DEFAULT_DIARY
};
interface createSchoolCalendarDefaultEntrySuccess{
    type:SchoolsActionTypes.CREATE_SCHOOL_CALENDAR_DEFAULT_DIARY_SUCCESS,
    payload:any
};
interface createSchoolCalendarDefaultEntryFail{
    type:SchoolsActionTypes.CREATE_SCHOOL_CALENDAR_DEFAULT_DIARY_FAIL
};

export const CreateSchoolCalendarDefaultEntry = (data: any): ThunkResult<void> => async dispatch => {
    handleCreateSchoolCalendarDefaultEntry(dispatch);

    try {
        const response: AxiosResponse<any> = await baseAPI.post(SchoolCalendar.createSchoolCalendarDefaultEntry,
            { headers: { "Authorization": localStorage.getItem('token') } });
        if (response.data.status === true) {
            toaster.notify(response.data.message, {
                position: 'top',
                duration: notificationMsg.duration
            });
            handleCreateSchoolCalendarDefaultEntrySuccess(dispatch, response.data);
        } else {
            toaster.notify(response.data.message, {
                position: 'top',
                duration: notificationMsg.errorNotificationDuration
            });
            handleCreateSchoolCalendarDefaultEntryFail(dispatch, response.data);
        }
    } catch (e) {
        handleCreateSchoolCalendarDefaultEntryFail(dispatch, e);
    }
};

const handleCreateSchoolCalendarDefaultEntry = (dispatch: Dispatch<createSchoolCalendarDefaultEntry>) => {
    dispatch({ type: SchoolsActionTypes.CREATE_SCHOOL_CALENDAR_DEFAULT_DIARY });
};

const handleCreateSchoolCalendarDefaultEntrySuccess = (
    dispatch: Dispatch<createSchoolCalendarDefaultEntrySuccess>,
    response: any
) => {
    dispatch({ type: SchoolsActionTypes.CREATE_SCHOOL_CALENDAR_DEFAULT_DIARY_SUCCESS, payload: response });
    history.push('/dashboard');
};

const handleCreateSchoolCalendarDefaultEntryFail = (dispatch: Dispatch<createSchoolCalendarDefaultEntryFail>, response: any) => {
        dispatch({ type: SchoolsActionTypes.CREATE_SCHOOL_CALENDAR_DEFAULT_DIARY_FAIL, payload: response.data });
};
//Create School Calendar
interface createSchoolCalendar{
    type:SchoolsActionTypes.CREATE_SCHOOL_CALENDAR
};
interface createSchoolCalendarSuccess{
    type:SchoolsActionTypes.CREATE_SCHOOL_CALENDAR_SUCCESS,
    payload:any
};
interface createSchoolCalendarFail{
    type:SchoolsActionTypes.CREATE_SCHOOL_CALENDAR_FAIL
};
export const CreateSchoolCalendar = (data: any): ThunkResult<void> => async dispatch => {
    handleCreateSchoolCalendar(dispatch);

    try {
        const response: AxiosResponse<any> = await baseAPI.post(SchoolCalendar.createSchoolCalendar,data,
            { headers: { "Authorization": localStorage.getItem('token') } });
        if (response.data.status === true) {
            toaster.notify(response.data.message, {
                position: 'top',
                duration: notificationMsg.duration
            });
            handleCreateSchoolCalendarSuccess(dispatch, response.data);
        } else {
            toaster.notify(response.data.message, {
                position: 'top',
                duration: notificationMsg.errorNotificationDuration
            });
            handleCreateSchoolCalendarFail(dispatch, response.data);
        }
    } catch (e) {
        handleCreateSchoolCalendarFail(dispatch, e);
    }
};

const handleCreateSchoolCalendar = (dispatch: Dispatch<createSchoolCalendar>) => {
    dispatch({ type: SchoolsActionTypes.CREATE_SCHOOL_CALENDAR });
};

const handleCreateSchoolCalendarSuccess = (
    dispatch: Dispatch<createSchoolCalendarSuccess>,
    response: any
) => {
    dispatch({ type: SchoolsActionTypes.CREATE_SCHOOL_CALENDAR_SUCCESS, payload: response });
    history.push('/school-calendar');
};

const handleCreateSchoolCalendarFail = (dispatch: Dispatch<createSchoolCalendarFail>, response: any) => {
        dispatch({ type: SchoolsActionTypes.CREATE_SCHOOL_CALENDAR_FAIL, payload: response.data });
};
//Get All School Calendar 
interface getAllSchoolCalendar{
    type:SchoolsActionTypes.GET_ALL_SCHOOL_CALENDAR
};
interface getAllSchoolCalendarSuccess{
    type:SchoolsActionTypes.GET_ALL_SCHOOL_CALENDAR_SUCCESS,
    payload:any
};
interface getAllSchoolCalendarFail{
    type: SchoolsActionTypes.GET_ALL_SCHOOL_CALENDAR_FAIL
};
export const GetAllSchoolCalendar = (data: any): ThunkResult<void> => async dispatch => {
    handleGetAllSchoolCalendar(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.patch(SchoolCalendar.GetAllSchoolCalendar, null,
            {
                params: data,
                headers: {"Authorization" : localStorage.getItem('token')}
            });
        handleGetAllSchoolCalendarSuccess(dispatch, response.data);
    } catch (e) {
        handleGetAllSchoolCalendarFail(dispatch);
    }
};

export const handleGetAllSchoolCalendar = (dispatch: Dispatch<getAllSchoolCalendar>) => {
    dispatch({ type: SchoolsActionTypes.GET_ALL_SCHOOL_CALENDAR });
};

export const handleGetAllSchoolCalendarSuccess = (
    dispatch: Dispatch<getAllSchoolCalendarSuccess>,
    response: any
) => {
    dispatch({
        type: SchoolsActionTypes.GET_ALL_SCHOOL_CALENDAR_SUCCESS,
        payload: response,
    });

};

export const handleGetAllSchoolCalendarFail = (dispatch: Dispatch<getAllSchoolCalendarFail>) => {
    dispatch({
        type: SchoolsActionTypes.GET_ALL_SCHOOL_CALENDAR_FAIL
    });
};
// Get School Calendar By calendar Id
interface getSchoolCalendarByCalendarId{
    type:SchoolsActionTypes.GET_SCHOOL_CALENDAR_BY_CALENDAR_ID
};
interface getSchoolCalendarByCalendarIdSuccess{
    type:SchoolsActionTypes.GET_SCHOOL_CALENDAR_BY_CALENDAR_ID_SUCCESS,
    payload:any
};
interface getSchoolCalendarByCalendarIdFail{
    type:SchoolsActionTypes.GET_SCHOOL_CALENDAR_BY_CALENDAR_ID_FAIL
};
export const GetSchoolCalendarByCalendarId = (params: any): ThunkResult<void> => async dispatch => {
    handleGetSchoolCalendarByCalendarId(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.put( SchoolCalendar.GetSchoolCalendarByCalendarId,params, 
            {
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            if (response.data.status === true) {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.duration
                });
                handleGetSchoolCalendarByCalendarIdSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleGetSchoolCalendarByCalendarIdFail(dispatch);
            }
    } catch (e) {
        handleGetSchoolCalendarByCalendarIdFail(dispatch);
    }
};

export const handleGetSchoolCalendarByCalendarId = (dispatch: Dispatch<getSchoolCalendarByCalendarId>) => {
    dispatch({ type: SchoolsActionTypes.GET_SCHOOL_CALENDAR_BY_CALENDAR_ID});
};

export const handleGetSchoolCalendarByCalendarIdSuccess = (
    dispatch: Dispatch<getSchoolCalendarByCalendarIdSuccess>, 
                response: any
) => {
    dispatch({ type: SchoolsActionTypes.GET_SCHOOL_CALENDAR_BY_CALENDAR_ID_SUCCESS,
                payload: response});
};

export const handleGetSchoolCalendarByCalendarIdFail = (dispatch: Dispatch<getSchoolCalendarByCalendarIdFail>) => {
    dispatch({ type: SchoolsActionTypes.GET_SCHOOL_CALENDAR_BY_CALENDAR_ID_FAIL});
};
// Edit School Calendar
interface editSchoolCalendar{
    type: SchoolsActionTypes.EDIT_SCHOOL_CALENDAR
};
interface editSchoolCalendarSuccess{
    type:SchoolsActionTypes.EDIT_SCHOOL_CALENDAR_SUCCESS,
    payload: any
};
interface editSchoolCalendarFail{
    type:SchoolsActionTypes.EDIT_SCHOOL_CALENDAR_FAIL
};
export const EditSchoolCalendar = (data:any): ThunkResult<void> => async dispatch => {
    handleEditSchoolCalendar(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.put(SchoolCalendar.editSchoolCalendar,data, 
        { headers: {"Authorization" : localStorage.getItem('token')}}); 
        if(response.data.status === true) {
            handleEditSchoolCalendarSuccess(dispatch, response.data);
            toaster.notify(response.data.message, {
                position: 'top', 
                duration: notificationMsg.duration
              });
        } else {
            handleEditSchoolCalendarFail(dispatch);
        }
    } catch (e) {
        handleEditSchoolCalendarFail(dispatch);
    }
};

export const handleEditSchoolCalendar = (dispatch: Dispatch<editSchoolCalendar>) => {
    dispatch({ type: SchoolsActionTypes.EDIT_SCHOOL_CALENDAR });
};

export const handleEditSchoolCalendarSuccess = (
    dispatch: Dispatch<editSchoolCalendarSuccess>,
    response: any
) => {
    dispatch({
        type: SchoolsActionTypes.EDIT_SCHOOL_CALENDAR_SUCCESS,
        payload: response
    });

};

export const handleEditSchoolCalendarFail = (dispatch: Dispatch<editSchoolCalendarFail>) => {
    dispatch({
        type: SchoolsActionTypes.EDIT_SCHOOL_CALENDAR_FAIL
    });
};

interface DeleteSchoolCalendar {
    type: SchoolsActionTypes.DELETE_SCHOOL_CALENDAR;
}

interface DeleteSchoolCalendarSuccess {
    type: SchoolsActionTypes.DELETE_SCHOOL_CALENDAR_SUCCESS;
    payload: any;
}

interface DeleteSchoolCalendarFail {
    type: SchoolsActionTypes.DELETE_SCHOOL_CALENDAR_FAIL;
}

export const deleteSchoolCalendar = (data: any): ThunkResult<void> => async dispatch => {
    handleDeleteSchoolCalendar(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.delete(SchoolCalendar.createSchoolCalendar+`?calendar_id=${data.calendar_id}&academic_year=${data.academic_year}`,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            if (response.data.status === true) {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.duration
                });
                history.push("/school-calendar");
                handleDeleteSchoolCalendarSuccess(dispatch, response.data);
            } else {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleDeleteSchoolCalendarFail(dispatch);
            }
    } catch (e) {
        handleDeleteSchoolCalendarFail(dispatch);
    }
};

export const handleDeleteSchoolCalendar = (dispatch: Dispatch<DeleteSchoolCalendar>) => {
    dispatch({ type: SchoolsActionTypes.DELETE_SCHOOL_CALENDAR });
};

export const handleDeleteSchoolCalendarSuccess = (
    dispatch: Dispatch<DeleteSchoolCalendarSuccess>,
    response: any
) => {
    dispatch({
        type: SchoolsActionTypes.DELETE_SCHOOL_CALENDAR_SUCCESS,
        payload: response,
    });
};

export const handleDeleteSchoolCalendarFail = (dispatch: Dispatch<DeleteSchoolCalendarFail>) => {
    dispatch({
        type: SchoolsActionTypes.DELETE_SCHOOL_CALENDAR_FAIL
    });
};


interface schoolCalendarDownloadExcel {
    type: SchoolsActionTypes.SCHOOL_CALENDAR_DOWNLOAD_EXCEL;
}

interface schoolCalendarDownloadExcelSuccess {
    type: SchoolsActionTypes.SCHOOL_CALENDAR_DOWNLOAD_EXCEL_SUCCESS;
    payload: any;
}

interface schoolCalendarDownloadExcelFail {
    type: SchoolsActionTypes.SCHOOL_CALENDAR_DOWNLOAD_EXCEL_FAIL;
}

export const schoolCalendarDownloadExcelGet = (): ThunkResult<void> => async dispatch => {
    handleSchoolCalendarDownloadExcel(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(SchoolCalendar.excelUploadSchoolCalendar,
            {
                headers: {"Authorization" : localStorage.getItem('token')},
                responseType: 'blob',
            });

         const dispositionHeader = response.headers['content-disposition'];
         const matches = /filename="([^"]+)"/.exec(dispositionHeader);
         const filename = matches && matches[1] ? matches[1] : 'downloaded_school_calendar.excel';

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(a);

        handleSchoolCalendarDownloadExcelSuccess(dispatch, response.data);
    } catch (e) {
        handleSchoolCalendarDownloadExcelFail(dispatch);
    }
};

export const handleSchoolCalendarDownloadExcel = (dispatch: Dispatch<schoolCalendarDownloadExcel>) => {
    dispatch({ type: SchoolsActionTypes.SCHOOL_CALENDAR_DOWNLOAD_EXCEL });
};

export const handleSchoolCalendarDownloadExcelSuccess = (
    dispatch: Dispatch<schoolCalendarDownloadExcelSuccess>,
    response: any
) => {
    dispatch({
        type: SchoolsActionTypes.SCHOOL_CALENDAR_DOWNLOAD_EXCEL_SUCCESS,
        payload: response,
    });

};

export const handleSchoolCalendarDownloadExcelFail = (dispatch: Dispatch<schoolCalendarDownloadExcelFail>) => {
    dispatch({
        type: SchoolsActionTypes.SCHOOL_CALENDAR_DOWNLOAD_EXCEL_FAIL
    });
};

interface schoolCalendarUploadExcel {
    type: SchoolsActionTypes.SCHOOL_CALENDAR_UPLOAD_EXCEL;
}

interface schoolCalendarUploadExcelSuccess {
    type: SchoolsActionTypes.SCHOOL_CALENDAR_UPLOAD_EXCEL_SUCCESS;
    payload: any;
}

interface schoolCalendarUploadExcelFail {
    type: SchoolsActionTypes.SCHOOL_CALENDAR_UPLOAD_EXCEL_FAIL
    payload:any
}

export const SchoolCalendarUploadExcel = (postData?:any): ThunkResult<void> => async dispatch => {
    handleSchoolCalendarUploadExcel(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.post(SchoolCalendar.excelUploadSchoolCalendar, postData, 
            { headers: { "Authorization": localStorage.getItem('token') } });
        if (response.data.status === true) {
            toaster.notify(response.data.message, {
                position: 'top',
                duration: notificationMsg.duration
            });
            handleSchoolCalendarUploadExcelSuccess(dispatch, response.data);
            history.push('/school-calendar');
        } else {
            toaster.notify(response.data.message, {
                position: 'top',
                duration: notificationMsg.errorNotificationDuration
            });
            handleSchoolCalendarUploadExcelFail(dispatch, response.data);
        }
    } catch (e) {
        handleSchoolCalendarUploadExcelFail(dispatch, e);
    }
};


export const handleSchoolCalendarUploadExcel = (dispatch: Dispatch<schoolCalendarUploadExcel>) => {
    dispatch({ type: SchoolsActionTypes.SCHOOL_CALENDAR_UPLOAD_EXCEL });
};

export const handleSchoolCalendarUploadExcelSuccess = (
    dispatch: Dispatch<schoolCalendarUploadExcelSuccess>,
    response: any
) => {
    dispatch({
        type: SchoolsActionTypes.SCHOOL_CALENDAR_UPLOAD_EXCEL_SUCCESS,
        payload: response,
    });
    
};

const handleSchoolCalendarUploadExcelFail = (dispatch: Dispatch<schoolCalendarUploadExcelFail>, response: any) => {
    dispatch({ type: SchoolsActionTypes.SCHOOL_CALENDAR_UPLOAD_EXCEL_FAIL, 
        payload: response,
        errors: response.data
    });
};

export type SchoolsAction =
    | FetchSchoolsSuccess
    | FetchSchoolsFail
    | FetchSchoolSuccess
    | FetchSchoolFail
    | AddSchoolSuccess
    | AddSchoolFail
    | EditSchoolSuccess
    | EditSchoolFail
    | DeleteSchoolSuccess
    | DeleteSchoolFail;