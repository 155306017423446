import React from 'react'
import Modal from 'react-bootstrap/Modal'

export const MapSubjectModalView = (props:any) => {
    return (
        <div>
        <Modal show={props.onState}>
          <Modal.Header className="pb-0 pt-0">
            <Modal.Title>{props.currentState.header}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p className="mb-2">{props.currentState.message}</p>
          </Modal.Body>

          <Modal.Footer className="modelboxbutton">
            <button className="btn btn-danger mr-1 ml-1 w-15"   onClick={props.onConfirm}>Confirm</button>
            <button className="btn btn-default mr-1 ml-1 w-15" onClick={props.onCancel}>Cancel</button>
          </Modal.Footer>
        </Modal>
        </div>
    )
}
