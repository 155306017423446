import { Reducer } from "redux";
import { HomeStudyState, HomeStudyTypes } from "./Types";


export const initialState: HomeStudyState = {
    loading: false,
    errors: [],
    records: [],
    items: [],
    page: 1,
    per_page: 10,
    total: 0,
    isAuthenticated: null,
    addControlType: [],
    getInternalGradle:[],
    getSubjectList:[],
    report_records: [],
    report_items: [],
    report_page: 1,
    report_per_page: 10,
    report_total: 0,
    report_errors: [],
    searchValue: []
}

const reducer: Reducer<HomeStudyState> = (state = initialState, action) => {
    switch(action.type) {
        case HomeStudyTypes.CONTROL_PANEL:
        case HomeStudyTypes.ADD_CONTROL_PANEL:
        case HomeStudyTypes.FETCH_CONTROL_PANEL_BY_ID:
        case HomeStudyTypes.EDIT_CONTROL_PANEL:
        case HomeStudyTypes.HOME_STUDY_VIEW:
            return{
                ...state,
                loading: true,
                isAuthenticated: false,
                errors: [],
            }
        case HomeStudyTypes.CONTROL_PANEL_SUCCESS:
            const { payload, records, per_page, page, total } = action;
            if(page===1) {
                state.records = []
            }
            return{
                ...state,
                loading: false,
                isAuthenticated: false,
                items: payload,
                records: [...state.records, ...records],
                page: page,
                per_page: per_page,
                total: total,
                totalPage: Math.ceil(total / per_page),
            }
        case HomeStudyTypes.EDIT_CONTROL_PANEL_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                editControlPanel: action.payload.data,
            }
        case HomeStudyTypes.CONTROL_PANEL_FAIL:    
        case HomeStudyTypes.HOME_STUDY_VIEW_FAIL:         
            const { errors } = action;
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                errors: errors,
                records: [],
                total: 0
            } 
        case HomeStudyTypes.FETCH_CONTROL_PANEL_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                controlPanelById: action.payload.data,
            } 
        case HomeStudyTypes.FETCH_CONTROL_PANEL_BY_ID_FAIL:
        case HomeStudyTypes.EDIT_CONTROL_PANEL_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                errors: action.payload,
            }        
        case HomeStudyTypes.SUBJECT_LIST:
            return{
                ...state,
                loading: true,
                getSubjectList: [],
            }
        case HomeStudyTypes.SUBJECT_LIST_SUCCESS:
            const { getSubjectList } = action;
            return{
                ...state,
                loading: false,
                items: action.payload,
                isAuthenticated: false,
                getSubjectList: getSubjectList,
            };
        case HomeStudyTypes.SUBJECT_LIST_FAIL:
            return{
                ...state,
                loading:false,
                isAuthenticated: true,
                getSubjectList: [],
            };
        case HomeStudyTypes.SEARCH_VALUE:
            return{
                ...state,
                loading: true,
                errors: [],
            };
        case HomeStudyTypes.SEARCH_VALUE_SUCCESS:
            return{
                ...state,
                loading: false,
                searchValue: action.payload,
                errors: null,
            };
        case HomeStudyTypes.SEARCH_VALUE_FAIL:
            return{
                ...state,
                loading: false,
                errors: [],
            };
        case HomeStudyTypes.HOME_STUDY_VIEW_SUCCESS:
            const { report_records, report_page, report_per_page, report_total} = action;
                if(page===1) {
                    state.report_records = []
                }
                return{
                    ...state,
                    loading: false,
                    isAuthenticated: false,
                    report_items: payload,
                    report_records: [...state.report_records, ...report_records],
                    report_page: report_page,
                    report_per_page: report_per_page,
                    report_total: report_total,
                    totalPage: Math.ceil(report_total / report_per_page),
                }
            case HomeStudyTypes.RESET_HOME_STUDY:
                return {
                    ...initialState
                }
        default:
            return state;
    }
}

export { reducer as HomeStudyReducer }