import React, { Component } from 'react'
import { connect } from 'react-redux'
import BreadCrumb from '../../../components/BreadCrumb';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import WeakGoodColor from '../../../components/dashboard/WeakGoodColor';
import SpinnerLoader from '../../../components/spinner/SpinnerLoader';
import { getstudentRecentQuiz } from '../../../store/question/Actions';
import history from '../../../History';
import { getAttendanceStudentView, fetchProfilePost, getFeedBackReport } from '../../../store/profile/Actions';
import StudentLineChart from './StudentLineChart';
import StudentProfileSubject from './StudentProfileSubject';
import StudentProfileInfo from './StudentProfileInfo';
import StudentProfileTopic from './StudentProfileTopic';
import { stopYearData, stopSubComponentYear } from '../../../store/profile/Actions'
import CommonLoader from '../../../components/CommonLoader';
interface propsStudentType extends RouteComponentProps<OwnPropsParams> {
    getStudentInfoDetails?: any;
    getChangeYearData?:any;
	stopYearDate?:any;
    getstudentRecentQuiz: (postValue: any) => any;
    getAttendanceStudentView: (postValue: any) => any;
    getFeedBackReport: (postValue: any) => any;
    fetchProfilePost: () => any;
    stopYearData:()=>any;
	stopSubComponentYear:(postValue?:any)=>any
    loading?: any
    studentProfileTopic: any;
    studentRecentQuizList?: any;
    loginProfile?: any;
}
export class ViewStudentFull extends Component<propsStudentType> {
    getStateValue: any;
    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.UpdateYearDate()
    }
    componentWillUpdate() {
        const {stopYearDate} = this.props;
        if(stopYearDate === true){
            this.UpdateYearDate(this.props.getChangeYearData)
            let postValue:any = {
				status:true
			}
			this.props.stopSubComponentYear(postValue)
        }
    }

    componentDidUpdate() {
        const {stopYearDate} = this.props;
        if(stopYearDate === true){
            this.props.stopYearData()
        }
    }

    UpdateYearDate(getYear?:any){
        if(getYear && getYear !== undefined && getYear !== null){
            this.getStudentFullDetails(getYear)
        }else {
            this.getStudentFullDetails(this.props.getChangeYearData)
        }
    }
    getStudentFullDetails(getYear?:any) {
        let getStudentId: any = this.props.getStudentInfoDetails;
        this.props.fetchProfilePost().then((res: any) => {
            const { loginProfile } = this.props;
            if (loginProfile) {
                if (loginProfile.usertype === 1) {
                    if (getStudentId) {
                        let postValue: any = {
                            academic_year:getYear,
                            class_id: getStudentId.class_id,
                            card_id: getStudentId.card_id,
                            school_id: getStudentId.school_id
                        }
                        this.props.getstudentRecentQuiz(postValue);
                    }
                } else if (loginProfile.usertype === 2 || loginProfile.usertype === 3) {
                    let postValue: any = {
                        academic_year:getYear,
                        class_id: getStudentId.class_id,
                        card_id: getStudentId.card_id
                    }
                    this.props.getstudentRecentQuiz(postValue);
                }
            }
        })

    }
    getTopicDetails(getValue?: any, getAllValue?: any) {
        let studentDetails: any = this.props.getStudentInfoDetails;
        history.push({
            pathname: `/topics/${getValue}`
        });
        let postData: any = {
            feedback: getValue,
            subject_id: getAllValue.subject_id,
            school_id: studentDetails.school_id,
            pageName: 'View Student',
            card_id: studentDetails.card_id,
            class_id: studentDetails.class_id
        }
        this.props.getFeedBackReport(postData)
    }
    getQuizzesReport = (getValue: any) => {
        if (getValue) {
            history.push({
                pathname: `/quizzes_report/${getValue.quiz_id}`,
            });
        }
    }
    render() {
        const { loading, studentRecentQuizList, getStudentInfoDetails } = this.props;
        const loadingTextCSS = { display: loading ? "block" : "none" };
        return (
            <div>
                <div className="page-wrapper">
                    <div className="page-content-wrapper">
                        <div className="page-content pt-3">
                        <BreadCrumb
                            titleName={['View Student']}
                            homeName={['Home']}
                            url={['dashboard']}
                            mainPageTitle={['View Student']}
                            baseName={[`${getStudentInfoDetails.titleName}`]}
                            baseURL={[`${getStudentInfoDetails.postURL}`]} />
                            {/* This part student profile details */}
                            <StudentProfileInfo />
                            {/* Here import student line chart */}
                            <StudentLineChart />
                            {/* This is weak to good color code */}
                            <WeakGoodColor />
                            {/* This is the part of show topics details list */}
                            <StudentProfileTopic/>
                            {/* Here import the student profile subject details */}
                            <StudentProfileSubject />

                            {/* Recent Quiz */}
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-topline-red">
                                        <div className="card-head">
                                            <header>Recent Quizzes</header>
                                            <div className="tools">

                                            </div>
                                        </div>

                                        <div className="card-body no-padding height-9">
                                            <div className="row">
                                                <div className="table-responsive">
                                                    <table className="table table-striped
                                                custom-table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Performance</th>
                                                                <th>Time</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {studentRecentQuizList && studentRecentQuizList !== null && studentRecentQuizList.length !==0 ?

                                                                studentRecentQuizList.map((items: any) => (
                                                                    <tr>
                                                                        <td><Link to={`#`} onClick={() => this.getQuizzesReport(items)}>{items.name}</Link></td>
                                                                        <td><Link to={`#`} onClick={() => this.getQuizzesReport(items)}>{items.performance}</Link></td>
                                                                        <td><Link to={`#`} onClick={() => this.getQuizzesReport(items)}>{items.date}</Link></td>
                                                                    </tr>
                                                                ))

                                                                : <tr > <th colSpan={3}><CommonLoader/></th></tr>}

                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="col-md-12 text-right">
                                                    <Link  to={{
                                                        pathname: "/quizzes",
                                                        state: { isFromFeedback: true }
                                                    }} className="btn btn-pink">...more</Link>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={loadingTextCSS}><SpinnerLoader /></div>
            </div>
        )
    }
}
interface OwnPropsParams {
    id: string;
}
const mapStateToProps = (state: any, ownProps: RouteComponentProps<OwnPropsParams>) => {
    return {
        loading: state.questionset.loading,
        getChangeYearData:state.profile.getYear,
        stopYearDate:state.profile.yearStatusFails,
        getStudentInfoDetails: state.profile.AttendanceStudentView,
        loginProfile: state.profile.profileData,
        studentRecentQuizList: state.questionset.studentRecentQuiz
    }
}

export default connect(mapStateToProps, { getstudentRecentQuiz, getAttendanceStudentView, getFeedBackReport,
    fetchProfilePost, stopYearData, stopSubComponentYear })(ViewStudentFull)
