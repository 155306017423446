export interface ProfilelDetailsType extends ApiResponse {
    id?:number,
    email: string,
    firstname: string,
    usertype: number,
    lastname: string,
    school_id: string,
    role: string,
    timezone: string,
    school_name: string
}
export type ApiResponse = Record<string, any>

export interface LicenseState {
    loading: boolean;
    license: any;
    errors: any;
    menuList: any;
    isAuthenticated: boolean | null;

}

//Schools fetch details
export enum LicenseActionTypes {
    FETCH_LICENSE_VALIDATE = 'FETCH_LICENSE_VALIDATE',
    FETCH_LICENSE_VALIDATE_SUCCESS = 'FETCH_LICENSE_VALIDATE_SUCCESS',
    FETCH_LICENSE_VALIDATE_FAIL = 'FETCH_LICENSE_VALIDATE_FAIL',
    FETCH_MENU_LIST = 'FETCH_MENU_LIST',
    FETCH_MENU_LIST_SUCCESS = 'FETCH_MENU_LIST_SUCCESS',
    FETCH_MENU_LIST_FAIL = 'FETCH_MENU_LIST_FAIL',
    FETCH_LICENSE_SCHOOL_LIST = 'FETCH_LICENSE_SCHOOL_LIST',
    FETCH_LICENSE_SCHOOL_LIST_SUCCESS = 'FETCH_LICENSE_SCHOOL_LIST_SUCCESS',
    FETCH_LICENSE_SCHOOL_LIST_FAIL = 'FETCH_LICENSE_SCHOOL_LIST_FAIL',
    FETCH_LICENSE_BY_SCHOOL = 'FETCH_LICENSE_BY_SCHOOL',
    FETCH_LICENSE_BY_SCHOOL_SUCCESS = 'FETCH_LICENSE_BY_SCHOOL_SUCCESS',
    FETCH_LICENSE_BY_SCHOOL_FAIL = 'FETCH_LICENSE_BY_SCHOOL_FAIL',
    FETCH_SUPERUSER_LICENSE_DETAILS = 'FETCH_SUPERUSER_LICENSE_DETAILS',
    FETCH_SUPERUSER_LICENSE_DETAILS_SUCCESS = 'FETCH_SUPERUSER_LICENSE_DETAILS_SUCCESS',
    FETCH_SUPERUSER_LICENSE_DETAILS_FAIL = 'FETCH_SUPERUSER_LICENSE_DETAILS_FAIL',
    FETCH_ALL_LICENSE_PARTITION = 'FETCH_ALL_LICENSE_PARTITION',
    FETCH_ALL_LICENSE_PARTITION_SUCCESS = 'FETCH_ALL_LICENSE_PARTITION_SUCCESS',
    FETCH_ALL_LICENSE_PARTITION_FAIL = 'FETCH_ALL_LICENSE_PARTITION_FAIL',
    FETCH_LICENSE_PARTITION = 'FETCH_LICENSE_PARTITION',
    FETCH_LICENSE_PARTITION_SUCCESS = 'FETCH_LICENSE_PARTITION_SUCCESS',
    FETCH_LICENSE_PARTITION_FAIL = 'FETCH_LICENSE_PARTITION_FAIL',
    ADD_LICENSE_PARTITION = 'ADD_LICENSE_PARTITION',
    ADD_LICENSE_PARTITION_SUCCESS = 'ADD_LICENSE_PARTITION_SUCCESS',
    ADD_LICENSE_PARTITION_FAIL = 'ADD_LICENSE_PARTITION_FAIL',
    EDIT_LICENSE_PARTITION = 'EDIT_LICENSE_PARTITION',
    EDIT_LICENSE_PARTITION_SUCCESS = 'EDIT_LICENSE_PARTITION_SUCCESS',
    EDIT_LICENSE_PARTITION_FAIL = 'EDIT_LICENSE_PARTITION_FAIL',
    DELETE_LICENSE_PARTITION = 'DELETE_LICENSE_PARTITION',
    DELETE_LICENSE_PARTITION_SUCCESS = 'DELETE_LICENSE_PARTITION_SUCCESS',
    DELETE_LICENSE_PARTITION_FAIL = 'DELETE_LICENSE_PARTITION_FAIL',
}