import React, { Component } from 'react';

export default class SpinnerLoader extends Component {

    render() {
        
        return (
            <div className="loading">Loading&#8230;</div>
        );
        
    }
}
