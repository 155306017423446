import React, { Component } from 'react';
import { connect } from 'react-redux';
import './dashboardStyle.scss';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import TeacherEngagementView from '../admindashboard/schooladmin/TeacherEngagementView';
import TeacherPerformanceView from '../admindashboard/schooladmin/TeacherPerformanceView';
import { stopYearData, stopSubComponentYear } from '../../store/profile/Actions'

export type PostsTeacherProps = {
	getChangeYearData?:any;
	stopYearData:()=>any;
	stopSubComponentYear:(postValue?:any)=>any
	loading?:any;
	loadingDashboard?:any;
	getProfile?:any;
	getSchoolId?:any;
	stopYearDate?:any
}

interface StateTypes  {
	academicYear?:any
}
class Teacherupdatedetails extends Component<PostsTeacherProps, StateTypes> {
	constructor(props:any){
		super(props);
		this.state = {
			academicYear:null
		}
	}

	componentDidMount(): void {
		window.scrollTo(0, 0);
		this.getCurrectYear()
	}

	componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYearDate} = this.props;
		if(stopYearDate === true){
			this.getCurrectYear(this.props.getChangeYearData)
			const postValue:any = {
				status:true
			}
			this.props.stopSubComponentYear(postValue)
		}
	}
	componentDidUpdate() {
		const {stopYearDate} = this.props;
		if(stopYearDate === true){
			this.props.stopYearData()
		}
	}
	getCurrectYear(getYear?:any){
		if(getYear && getYear !== undefined && getYear !== null){
			this.setState({academicYear:getYear})
		}else {
			this.setState({academicYear:this.props.getChangeYearData})
		}
	}
	render() {
		const { loading, stopYearDate } = this.props;
		const loadingTeacher = { display: loading || stopYearDate ? "block" : "none" };
		return (
			<div>
				<div className="row">
					<div className="col-lg-6 col-md-12 col-sm-12 col-12">
						<TeacherEngagementView
						getSchoolId={this.props.getSchoolId}
						/>
					</div>
					<div className="col-lg-6 col-md-12 col-sm-12 col-12">
					<TeacherPerformanceView
					getSchoolId={this.props.getSchoolId}
					/>
					</div>
				</div>
				{/* <div style={loadingTeacher}><SpinnerLoader /></div> */}
			</div>
		);
	}
}

const mapStateToProps = (state:any) => {
	return {
		loading:state.profile.loading,
		loadingDashboard:state.dashboard.loading,
		getChangeYearData:state.profile.getYear,
		stopYearDate:state.profile.yearStatusFails,
		getProfile:state.profile.profileData
	}
}


export default  connect(mapStateToProps, {stopYearData, stopSubComponentYear})(Teacherupdatedetails);
