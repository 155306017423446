import { Component } from "react";
import BreadCrumb from "../../components/BreadCrumb";
import { Field, FieldArray, Form, Formik } from "formik";
import { Button, FormGroup, MenuItem } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { examTypeAddSchema } from "./QuestionBankValidation";
import { addExamType } from "../../store/QuestionBank/Actions";
import SpinnerLoader from "../../components/spinner/SpinnerLoader";
import MinutesToHours from "../../components/MinutesToHours";

export type OwnExamTypeProps = {
    getYear?: any;
    loading?: boolean
    addExamType: (postData: any) => any;
}

interface StateTypes{
    academicYear?: any;
}

const initialValues = {
    name: '',
    description: '',
    total_marks: '',
    allowed_time: '',
};

export class AddExamType extends Component <OwnExamTypeProps, StateTypes> {
    formik: any;
    constructor(props: any) {
        super(props)
        this.state = {
            academicYear: '',
        }
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.getUpdateYear()
    }

    getUpdateYear(getYear?: any) {
        if (getYear && getYear !== undefined && getYear !== null) {
            this.setState({ academicYear: getYear })
        } else {
            this.setState({ academicYear: this.props.getYear })
        }
    }


    render(){
        const {academicYear} = this.state;
        const { loading } = this.props;
        const loadingQuestionFull = { display: loading ? "block" : "none" };
        return(
            <div className="page-wrapper">
                <div className="page-content-wrapper">
                    <div className="page-content pt-3">
                        <BreadCrumb
                            titleName={['Exam Type']}
                            homeName={['Home']}
                            url={['dashboard']}
                            mainPageTitle={['Create Exam Type']}
                        />
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card-box">
                                    <div className="card-head">
                                        <header>Add Exam Type</header>
                                    </div>
                                    <div className="card-body">
                                        <Formik
                                            ref={node => this.formik = node}
                                            initialValues={initialValues}
                                            validationSchema={examTypeAddSchema}
                                            onSubmit={(values: any, { setSubmitting }) => {
                                                const getExamTypeValue: any = {
                                                    name: values.name,
                                                    description: values.description,
                                                    total_marks: parseInt(values.total_marks),
                                                    allowed_time: parseInt(values.allowed_time),
                                                    academic_year: academicYear,                                                
                                                };

                                                this.props.addExamType(getExamTypeValue).then((res: any) => {

                                                })
                                            }}
                                        >
                                            {({
                                                values,
                                                errors,
                                                isSubmitting,
                                                isValidating,
                                                dirty,
                                                touched,
                                                handleReset,
                                                handleSubmit,
                                                handleChange,
                                                setFieldValue,
                                            }) => {
                                                return(
                                                    <Form>
                                                        <div>
                                                            <div className="">
                                                                <div className="row">
                                                                    <div className="col-md-6 p-t-20">
                                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                            <FormGroup>
                                                                                <Field
                                                                                    label={<><span>Name</span><span className='formmandatorycolor'> *</span></>}
                                                                                    type="text"
                                                                                    name="name"
                                                                                    component={TextField}
                                                                                    className="textfield__input"
                                                                                    fullwidth="true"
                                                                                    disabled={false}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 p-t-20">
                                                                        <div>
                                                                            <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                <FormGroup>
                                                                                    <Field
                                                                                        label={<><span>Description</span><span className='formmandatorycolor'> *</span></>}
                                                                                        type="text"
                                                                                        name="description"
                                                                                        component={TextField}
                                                                                        className="textfield__input"
                                                                                        fullwidth="true"
                                                                                        disabled={false}
                                                                                    />
                                                                                </FormGroup>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6 p-t-20">
                                                                        <div>
                                                                            <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                <FormGroup>
                                                                                    <Field
                                                                                        label={<><span>Total Marks</span><span className='formmandatorycolor'> *</span></>}
                                                                                        type="text"
                                                                                        name="total_marks"
                                                                                        component={TextField}
                                                                                        className="textfield__input"
                                                                                        fullwidth="true"
                                                                                        disabled={false}
                                                                                    />
                                                                                </FormGroup>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 p-t-20">
                                                                        <div>
                                                                            <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                <FormGroup>
                                                                                    <Field
                                                                                        label={<><span>Allowed Time (In Minutes)</span><span className='formmandatorycolor'> *</span></>}
                                                                                        type="text"
                                                                                        name="allowed_time"
                                                                                        component={TextField}
                                                                                        className="textfield__input"
                                                                                        fullwidth="true"
                                                                                        onChange={(e: any) => {
                                                                                            handleChange(e);
                                                                                        }}
                                                                                        disabled={false}
                                                                                    />
                                                                                    <div>
                                                                                        <MinutesToHours minutes={parseInt(values.allowed_time)} />
                                                                                    </div>  
                                                                                </FormGroup>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="text-right mb-3 mr-2 mt-4">
                                                                    <Button
                                                                    className="btn btn-pink mr-1 ml-1" 
                                                                    type="submit">Submit</Button>
                                                                    <Link to="/examType" 
                                                                        style={{ display: "inline-block" }}>
                                                                    <Button className="btn btn-default mr-1 ml-1">Cancel</Button></Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                )
                                            }}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={loadingQuestionFull}><SpinnerLoader /></div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return{
        getYear: state.profile.getYear,
        loading: state.QuestionBank.loading,
    }
}

const mapDispatchToProps = {
    addExamType
  }

export default connect( mapStateToProps, mapDispatchToProps )(AddExamType)