import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Bar } from 'react-chartjs-2';
import { fetchFeedbackQuestionResponses } from '../../store/dashboard/Actions';
import { stopYearData, stopSubComponentYear } from '../../store/profile/Actions'
import CommonLoader from '../../components/CommonLoader';

export type PostsFetchQuizBarProps = {
	getChangeYearData?:any;
	stopYearDate?:any;
	stopYearData:()=>any;
	stopSubComponentYear:(postValue:any) => any;
	fetchFeedbackQuestionResponses:(postValue:any) => any;
	getfetchFeedbackDetails:any;
	getDurationList:any;
	isAdminFeedbackLoader?:boolean;
}
interface StateTypes {
	feedbackFilter:any,
	feedbackData?:any,
	academicYear?:any
}

export class FeedbackQuestionResponses extends Component<PostsFetchQuizBarProps, StateTypes> {
	constructor(props: any) {
        super(props);
        this.state = {
            feedbackFilter:'This Month',
			feedbackData:[],
			academicYear:null
        }
    }
	componentDidMount(): void {
		window.scrollTo(0, 0);
		this.getCurrectYear();
	}
	componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYearDate} = this.props;
		if(stopYearDate === true){
			this.getCurrectYear(this.props.getChangeYearData)
			const postValue:any = {
				status:true
			}
			this.props.stopSubComponentYear(postValue)
		}
	}
	componentDidUpdate() {
		const {stopYearDate} = this.props;
		if(stopYearDate === true){
			this.props.stopYearData()
		}
	}
	getCurrectYear(getYear?:any){
		if(getYear && getYear !== undefined && getYear !== null){
			this.setState({academicYear:getYear})
			this.getFetchQuizChartBar(getYear)
		}else {
			this.setState({academicYear:this.props.getChangeYearData})
			this.getFetchQuizChartBar(this.props.getChangeYearData)
		}
	}
	getFetchQuizChartBar(getYear?:any){
		const loadMoreType = {
			filter: 'This Month',
			academic_year: getYear
		  }
		  this.props.fetchFeedbackQuestionResponses(loadMoreType).then((res:any)=>{
			  const {getfetchFeedbackDetails} = this.props;
			  if(getfetchFeedbackDetails && getfetchFeedbackDetails !== undefined && getfetchFeedbackDetails !== null && getfetchFeedbackDetails.length > 0){
				this.getFeedbackRecord(getfetchFeedbackDetails)
				this.setState({feedbackFilter:'This Month'})
			  }else {
				this.setState({feedbackData:[]})
				this.setState({feedbackFilter:'This Month'})
			  }
			  
		  });
	}
	FilterFeedback = (e:any)=> {
        const { value } = e.target;
		const {academicYear} = this.state;
		if(value){
			const loadMoreType = {
				filter: value,
				academic_year: academicYear
			}
			this.props.fetchFeedbackQuestionResponses(loadMoreType).then((res:any)=>{
				const {getfetchFeedbackDetails} = this.props;
				if(getfetchFeedbackDetails && getfetchFeedbackDetails !== undefined && getfetchFeedbackDetails !== null && getfetchFeedbackDetails.length > 0){
					this.getFeedbackRecord(getfetchFeedbackDetails)
				  }else {
					this.setState({feedbackData:[]})
				  }
			});
			this.setState({feedbackFilter:value})
		}
	}
	getFeedbackRecord(getfetchFeedbackDetails:any){
		let getSchoolName:any = [];
			  let getColorCode:any = [];
			  let getFeedbackResponse:any = [];
			  if(getfetchFeedbackDetails){
				  getSchoolName = [];
				  getColorCode = [];
				  getFeedbackResponse = [];
				  getfetchFeedbackDetails.forEach((feedback:any, index:any)=>{
					  getSchoolName.push(feedback.school_name);
					  getColorCode.push(feedback.color_code);
					  getFeedbackResponse.push(feedback.feedback_response);
				  })
				  const postValue:any = {
					getSchoolName:getSchoolName,
					getColorCode:getColorCode,
					getFeedbackResponse:getFeedbackResponse
				  }
				  if(postValue){
					  this.setState({feedbackData:postValue})
				  }
			  }
	}
	refreshFeedbackTable = () => {
		const {academicYear} = this.state;
		this.getFetchQuizChartBar(academicYear);
	}
    render() {
		const { getDurationList, isAdminFeedbackLoader } = this.props;
		const { feedbackData } = this.state;
		const loadingDashboard = { display: isAdminFeedbackLoader ? "block" : "none" };
        return (
            <div>
                 <div className="row">
					<div className="col-lg-12 col-md-12 col-sm-12 col-12">
						<div className="card card-topline-red">
						<div className="overlay" style={loadingDashboard}><div className='custom-spinner-individual'></div></div>
							<div className="card-head">
								<header>Feedback Question Responses</header>
								{getDurationList ?
								<div className="tools">
								<div className="d-flex">
										<div>
										{getDurationList?                                      
									<select name="classlist" value={this.state.feedbackFilter} className="form-control" onChange={this.FilterFeedback}>
										{getDurationList.map((items: any) => (
											<option value={items.value}> {items.value} </option>
										))}
									</select>
									:null}
										</div>
										<div className="ml-2 mt-2">
											<span className="fa fa-repeat btn-color box-refresh pointer" onClick={this.refreshFeedbackTable}></span>
											<span className="t-collapse btn-color fa fa-chevron-up pointer" ></span>
										</div>
									</div>                              
								</div>
								:null}
								
							</div>
							<div className="card-body no-padding height-9">
								<div className="row">
									{feedbackData && feedbackData.length !== 0 && feedbackData !== null?
									<Bar
										data={{
											labels: feedbackData.getSchoolName,
											datasets: [
												{
													backgroundColor: feedbackData.getColorCode,
													borderColor: feedbackData.getColorCode,
													borderWidth: 2,
													data: feedbackData.getFeedbackResponse
												}
											]
										}}
										height={90}
										options={{
											legend:{
												display:false
										 },
											title: {
												display: true,
												text: ''
											},
											scales : {
												yAxes: [{
													ticks: {
													  beginAtZero: true,
													  steps: 10,
													  stepValue: 5,
													  userCallback: function(label:any, index:any, labels:any) {
														  if (Math.floor(label) === label) {
															  return label;
														  }
													  }
													},
													gridLines : {
														color: 'rgba(170,170,170,1)',
              											lineWidth: 0.2
													}
												  }],
												xAxes : [ {													
            										barPercentage: 0.1,
													gridLines : {
														display : false
													}
												} ]
											}
										  }}
									/>
									:<CommonLoader/>
								}
									
								</div>
							</div>
						</div>
					</div>
                    </div> 
				{/* <div style={loadingDashboard}><SpinnerLoader /></div> */}
            </div>
        )
    }
}

const mapStateToProps = (state:any) => {
	return {
		getChangeYearData:state.profile.getYear,
		stopYearDate:state.profile.yearStatusFails,
		getfetchFeedbackDetails:state.dashboard.feedbackQuestionResponses,
		getDurationList:state.profile.getDuration,
		isAdminFeedbackLoader: state.dashboard.isAdminFeedbackLoader
	}
}


export default connect(mapStateToProps, {fetchFeedbackQuestionResponses, stopYearData, stopSubComponentYear})(FeedbackQuestionResponses)
