import React, { Component } from 'react';

class NotFoundPage extends Component {
    render() {
        return (
            <div className="page404">
                404
            </div>
        );
    }
}

export default NotFoundPage;
