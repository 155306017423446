import { Reducer } from 'redux'
import { LicenseActionTypes, LicenseState } from './Types'

export const initialState: LicenseState = {
    loading: false,
    isAuthenticated: null,
    errors: [],
    license: [],
    menuList: [],
}


const reducer: Reducer<LicenseState> = (state = initialState, action) => {
    switch (action.type) {
        case LicenseActionTypes.FETCH_LICENSE_VALIDATE:
        case LicenseActionTypes.FETCH_MENU_LIST:
        case LicenseActionTypes.FETCH_SUPERUSER_LICENSE_DETAILS:
        case LicenseActionTypes.FETCH_ALL_LICENSE_PARTITION:
        case LicenseActionTypes.ADD_LICENSE_PARTITION:
        case LicenseActionTypes.EDIT_LICENSE_PARTITION:
        case LicenseActionTypes.FETCH_LICENSE_PARTITION:
        case LicenseActionTypes.FETCH_LICENSE_SCHOOL_LIST:
        case LicenseActionTypes.FETCH_LICENSE_BY_SCHOOL:
        case LicenseActionTypes.DELETE_LICENSE_PARTITION:
            return {
                ...state,
                loading: true,
                isAuthenticated: false,
                errors: [],
            };
        case LicenseActionTypes.FETCH_LICENSE_VALIDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                license: action.payload,
                isAuthenticated: false,
            };
        case LicenseActionTypes.FETCH_MENU_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                menuList: action.payload,
                isAuthenticated: false,
            };
        case LicenseActionTypes.FETCH_SUPERUSER_LICENSE_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                licenseModuleDetails: action.payload,
                isAuthenticated: false,
            };
        case LicenseActionTypes.FETCH_ALL_LICENSE_PARTITION_SUCCESS:
            return {
                ...state,
                loading: false,
                licensePartition: action.payload,
                isAuthenticated: false,
            };

        case LicenseActionTypes.ADD_LICENSE_PARTITION_SUCCESS:
        case LicenseActionTypes.ADD_LICENSE_PARTITION_FAIL:
        case LicenseActionTypes.EDIT_LICENSE_PARTITION_SUCCESS:
        case LicenseActionTypes.EDIT_LICENSE_PARTITION_FAIL:
            return {
                ...state,
                loading: false,
                addlicensePartition: action.payload,
            };
        case LicenseActionTypes.FETCH_LICENSE_PARTITION_SUCCESS:
            return {
                ...state,
                loading: false,
                licensePartitionValue: action.payload.data,
            };
        case LicenseActionTypes.FETCH_LICENSE_SCHOOL_LIST_SUCCESS:
            console.log(action.payload.data)
            return {
                ...state,
                loading: false,
                licenseSchoolList: action.payload.data,
            };
        case LicenseActionTypes.FETCH_LICENSE_BY_SCHOOL_SUCCESS:
            return {
                ...state,
                loading: false,
                licenseBySchool: action.payload,
            };
        case LicenseActionTypes.DELETE_LICENSE_PARTITION_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
}

export { reducer as LicenseReducer }