import React from 'react'

export const PsychometricStudentList = (props:any) => {
  const { studentlist, handleCheckStudent} = props;
  return (
    <>
    {studentlist?
      <>
      {studentlist.map((item: any, index: any) => (
        <li className="list-group-item" key={index} >
          <div className="form-check">
            <input type="checkbox" className="form-check-input" id={`student_${item.id}`} data-id={item.id} data-disabled={!item.overall_status} onClick={handleCheckStudent} disabled={!item.overall_status}/>
            <label className="form-check-label" htmlFor={`student_${item.id}`}>
            <>
              {item.student_name}
                {
              item?.overall_status_details?.notstarted > 0 ? <span>  (Already Assinged ) </span> 
                : item?.overall_status_details?.inprogress > 0 ? <span>  ( Inprogess ) </span> 
                : item?.overall_status_details?.completed > 0 ? <span>  (Completed - {item.overall_status_details.completed}) </span> : ''
              }
               </>
              </label>
          </div>
        </li>
      ))}
      </>
      : 'No Records'} 
      </>
  )
}
export default PsychometricStudentList
