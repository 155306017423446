import React from 'react'
import { Field, Formik, Form } from "formik";
import { Button, MenuItem, FormGroup } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { TextField } from "formik-material-ui";
import { DropzoneArea } from "material-ui-dropzone";
import { getTeacherSubjectList, postUploadCSVFormat, getAdminSubjectList } from '../../store/question/Actions';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {SchoolCalendarUploadExcel } from "../../store/school/Actions";

const useStyles = makeStyles(theme => createStyles({
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
}));
export interface stateTypes {
  question_name: any,
  select_class: any,
  select_subject: any,
}
const UploadSchoolCalendarCsvFile = (props?: any) => {
  const classes = useStyles();
  const { TeacherSubjectList, AdminSubjectList } = props;
  const [uploadFile, setUploadFile] = React.useState<any>([])
  const [statuuploadFile, setStatuUploadFile] = React.useState<any>(false)
  const [SubjectList, setSubjectList] = React.useState([])
  const [classListStatus, setClassListStatus] = React.useState(false)
  const initialValues: stateTypes = {
    question_name: "",
    select_class: "",
    select_subject: "",
  }
  React.useEffect(()=>{
    setClassListStatus(true)
  },[])
  
  const handleChange = (files?: any) => {
    if (files.length) {
      const file = files[0]
      setUploadFile(file)
      setStatuUploadFile(true)
    }else{
      setUploadFile([])
      setStatuUploadFile(false)
    }

  }
  React.useEffect(()=>{
    if (
      TeacherSubjectList &&
      TeacherSubjectList.length > 0 &&
      TeacherSubjectList !== undefined
    ) {
      setSubjectList(TeacherSubjectList)
      setClassListStatus(false)
    } else {
      setSubjectList([])
      setClassListStatus(true)
    }
  },[TeacherSubjectList])

  React.useEffect(()=>{
    if (
      AdminSubjectList &&
      AdminSubjectList.length > 0 &&
      AdminSubjectList !== undefined
    ) {
      setSubjectList(AdminSubjectList)
      setClassListStatus(false)
    } else {
      setSubjectList([])
      setClassListStatus(true)
    }
  },[AdminSubjectList])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        let fileInfo: any = uploadFile;
        const formData = new FormData();
        formData.append("academic_year", props.academicYear);
        formData.append("calendar_file", fileInfo);
        props.SchoolCalendarUploadExcel(formData)
      }}
    >
      {({
        values,
      }) => {
        return (
          <Form>
            <div className="row">
              <div className="col-md-12 mt-3">
                <DropzoneArea onChange={(event) => handleChange(event)} 
                 acceptedFiles={[".xlsx, application/vnd.ms-excel"]}
                  maxFileSize={5000000}
                  filesLimit={1}
                  showPreviews={true}
                  showPreviewsInDropzone={false}
                  useChipsForPreview
                  previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                  previewChipProps={{ classes: { root: classes.previewChip } }}
                  previewText="Selected files"
                />
              </div>
            </div>
            <div className="text-right mb-3 mr-2 mt-4">
              <Button
                className="btn btn-pink mr-1 ml-1"
                type="submit"
                disabled={
                  !statuuploadFile
                }
              >
                Submit
              </Button>
              <Link to={"/school-calendar"}>
                <Button className="btn btn-default mr-1 ml-1 ">
                  Cancel
                </Button>
              </Link>
            </div>
          </Form>
        );
      }}
    </Formik>
  )
}


const mapStateToProps = (state: any) => {
  return {
    TeacherSubjectList: state.questionset.getTeacherSubjectList,
    AdminSubjectList: state.questionset.getAdminSubjectList,
    getChangeYearData: state.profile.getYear,
  }
}

const mapDispatchToProps = (dispatch?: any) => {
  return {
    getTeacherSubjectList: (postUserInfo?: any) => dispatch(getTeacherSubjectList(postUserInfo)),
    getAdminSubjectList: (postUserInfo?: any) => dispatch(getAdminSubjectList(postUserInfo)),
    SchoolCalendarUploadExcel: (postQuestionData?: any) => dispatch(SchoolCalendarUploadExcel(postQuestionData))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadSchoolCalendarCsvFile);
// export default UploadSchoolCalendarCsvFile;
