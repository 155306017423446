import { Reducer } from 'redux'
import { ProfileActionTypes, ProfileState } from './Types'

export const initialState: ProfileState = {
    items: {},
    loading: false,
    isAuthenticated: null,
    schoolId:'',
    profileData:[],
    YearList:[],
    getDuration:[],
    getSubjects:[],
    getCurrectYear:'',
    getStudentClasses:null,
    getYear:null,
    getYearFail:null,
    diaryNotificationMessage:[],
    noticeBoardNotificationMessage:[],
    failStudentClasses:[],
    AttendanceStudentView:[],
    FeedBackReport:[],
    QuizzesReport:[],
    CommonDataStorage:[],
    yearStatusFails:false,
    subComponentStart:false,
    profileServiceLoader:false,
    dashBoardCountYearChange:false,
    dashBoardSchoolChartYearChange:false
}

const reducer: Reducer<ProfileState> = (state = initialState, action) => {
    switch (action.type) {
        
        case ProfileActionTypes.TOKEN_VALIDATION_REQUEST:
        case ProfileActionTypes.YEAR_LIST_REQUEST:
            return { ...state, loading: true,  };
            case ProfileActionTypes.DURATION_LIST_REQUEST:
                return { ...state,  getDuration:[] };
        case ProfileActionTypes.FETCH_PROFILE:
            return { ...state, loading: true,  getDuration:[],profileServiceLoader:false,profileData:[] };
        
        case ProfileActionTypes.SUBJECT_LIST_REQUEST:
            return { ...state, loading: false,  getSubjects:[] };

        case ProfileActionTypes.DIARY_NOTIFICATION_MESSAGE:
            return { ...state, loading: true,  diaryNotificationMessage:[] };

        case ProfileActionTypes.DIARY_NOTIFICATION_MESSAGE_FAIL:
                return { ...state, loading: false }; 

        case ProfileActionTypes.DIARY_NOTIFICATION_MESSAGE_SUCCESS:
            const { diaryNotificationMessage } = action;
                return {
                    ...state, 
                    loading: false, 
                    items: action.payload,
                    isAuthenticated: false,
                    diaryNotificationMessage: diaryNotificationMessage
                };

        case ProfileActionTypes.NOTICEBOARD_NOTIFICATION_MESSAGE:
            return { ...state, loading: true,  noticeBoardNotificationMessage:[] };

        case ProfileActionTypes.NOTICEBOARD_NOTIFICATION_MESSAGE_SUCCESS:
            const { noticeBoardNotificationMessage } = action;
                return {
                    ...state, 
                    loading: false, 
                    items: action.payload,
                    isAuthenticated: false,
                    noticeBoardNotificationMessage: noticeBoardNotificationMessage
                };

        case ProfileActionTypes.NOTICEBOARD_NOTIFICATION_MESSAGE_FAIL:
                return { ...state, loading: false }; 


        case ProfileActionTypes.Storage_Class_Request:
            return { ...state, loading: true };
        
        case ProfileActionTypes.Storage_Class_Request_Success:
            const { getStudentClasses } = action;
                return {
                    ...state, 
                    loading: false, 
                    getStudentClasses: getStudentClasses
                };
        // This is function used to store feedback report view
        case ProfileActionTypes.Feedback_Report_view:
            return { ...state, loading: true };
        
        case ProfileActionTypes.Feedback_Report_view_Success:
            const { FeedBackReport } = action;
                return {
                    ...state, 
                    loading: false, 
                    FeedBackReport: FeedBackReport
                };
        // This is function used to store feedback report view
        case ProfileActionTypes.QUIZZES_REPORT:
            return { ...state, loading: true };
        
        case ProfileActionTypes.QUIZZES_REPORT_SUCCESS:
            const { QuizzesReport } = action;
                return {
                    ...state, 
                    loading: false, 
                    QuizzesReport: QuizzesReport
                };
        // This is function used to store feedback report view
        case ProfileActionTypes.Common_Data_Storage:
            return { ...state, loading: true };
        
        case ProfileActionTypes.Common_Data_Storage_Success:
            const { CommonDataStorage } = action;
                return {
                    ...state, 
                    loading: false, 
                    CommonDataStorage: CommonDataStorage
                };
        case ProfileActionTypes.Storage_Class_Request_Fail:
            const { failStudentClasses } = action;
                return { 
                    ...state, 
                    loading: false,
                    getStudentClasses: failStudentClasses
                  }; 
    
            case ProfileActionTypes.View_Student_Details:
            return { 
                ...state, 
                loading: true, 
                AttendanceStudentView: [] };
        
        case ProfileActionTypes.View_Student_Details_Success:
            const { AttendanceStudentView } = action;
                return {
                    ...state, 
                    loading: false, 
                    AttendanceStudentView: AttendanceStudentView
                };
            
        case ProfileActionTypes.YEAR_STORAGE: 
        return { ...state, loading: true,dashBoardCountYearChange:false,dashBoardSchoolChartYearChange:false };

        case ProfileActionTypes.YEAR_STORAGE_FAIL:
            const { getYearFail } = action;
            return { ...state, loading: false, 
                getYear:getYearFail,
                yearStatusFails:false,
                dashBoardCountYearChange:false ,
                dashBoardSchoolChartYearChange:false };
            
        case ProfileActionTypes.YEAR_STORAGE_SUCCESS:
            const { getYear } = action;
            return { ...state, 
                loading: false, 
                getYear:getYear,
                yearStatusFails:true,
                dashBoardCountYearChange:true,
                dashBoardSchoolChartYearChange:true };

        case ProfileActionTypes.Stop_Year_Data:{
            return { 
                ...state,
                yearStatusFails:false
            }
        }

        case ProfileActionTypes.Stop_Year_Data_DashBoardCount:{
            return { 
                ...state,
                dashBoardCountYearChange:false
            }
        }

        case ProfileActionTypes.Stop_Year_Data_DashBoardSchoolChart:{
            return { 
                ...state,
                dashBoardSchoolChartYearChange:false
            }
        }

        case ProfileActionTypes.Stop_Sub_Year_Data:{
            return { 
                ...state,
                subComponentStart:true
            }
        }

        case ProfileActionTypes.Stop_Sub_Year_Data_Fail:{
            return { 
                ...state,
                subComponentStart:false
            }
        }

        
        case ProfileActionTypes.TOKEN_VALIDATION_ERROR:
        case ProfileActionTypes.YEAR_LIST_ERROR:
        case ProfileActionTypes.DURATION_LIST_ERROR:
                return { ...state, loading: false,
                    isAuthenticated: true }; 
        case ProfileActionTypes.FETCH_PROFILE_FAIL:
            return { ...state, loading: false,profileData:[],
                isAuthenticated: true,profileServiceLoader:false }; 
        
        case ProfileActionTypes.SUBJECT_LIST_ERROR:
                return { ...state, loading: false,
                    isAuthenticated: true };           
        
        case ProfileActionTypes.FETCH_PROFILE_SUCCESS:
            const { schoolId, profileData } = action
                return {
                    ...state, 
                    loading: false, 
                    items: action.payload,
                    isAuthenticated: false,
                    schoolId:schoolId,
                    profileData: profileData,
                    profileServiceLoader:true
                };
        case ProfileActionTypes.Stop_Profile_Year_Data:
            return {
                ...state,
                profileServiceLoader:false
            }
            case ProfileActionTypes.YEAR_LIST_SUCCESS:
            const { YearList } = action;
                return {
                    ...state, 
                    loading: false, 
                    items: action.payload,
                    isAuthenticated: false,
                    YearList: YearList
                };
            case ProfileActionTypes.DURATION_LIST_SUCCESS:
            const { getDuration } = action;
                return {
                    ...state, 
                    loading: false, 
                    items: action.payload,
                    isAuthenticated: false,
                    getDuration: getDuration
                };

            case ProfileActionTypes.SUBJECT_LIST_SUCCESS:
            const { getSubjects } = action;
                return {
                    ...state, 
                    loading: false, 
                    items: action.payload,
                    isAuthenticated: false,
                    getSubjects: getSubjects
                };
            case ProfileActionTypes.Reset_Profile_Details:
                return initialState;
            default:
                return state;
    }
}

export { reducer as ProfileReducer }