import { ThunkAction } from 'redux-thunk';
import { Dispatch } from 'redux';
import { RootState, RootActions } from '../Index';
import { AxiosResponse } from 'axios';
import { baseAPIAuth, baseAPI } from '../../Service';
import { License } from '../../services/Config'
import { AUTHAPI, CATEGORYMANAGE, DashboardAPI } from '../../services/Config'
import { LicenseActionTypes } from './Types'
import history from '../../History';
import toaster from "toasted-notes";
import { notificationMsg } from '../../services/Constants';

//Implement Thunk middle ware
export type ThunkResult<R> = ThunkAction<R, RootState, undefined, RootActions>;
interface FetchLicenseValidate {
    type: LicenseActionTypes.FETCH_LICENSE_VALIDATE;
}

interface FetchLicenseValidateSuccess {
    type: LicenseActionTypes.FETCH_LICENSE_VALIDATE_SUCCESS;
    payload: any;
}

interface FetchLicenseValidateFail {
    type: LicenseActionTypes.FETCH_LICENSE_VALIDATE_FAIL;
}

export const fetchLicenseValidateGet = (getParams?: any): ThunkResult<void> => async dispatch => {
    handleFetchLicenseValidate(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.put(License.GetLicenseValidate,getParams, {
            headers: {
                "Authorization": localStorage.getItem('token')
            }
        });
        console.log(response.data)
        handleFetchLicenseValidateSuccess(dispatch, response.data);
    } catch (e) {
        handleFetchLicenseValidateFail(dispatch);
    }
};

export const handleFetchLicenseValidate = (dispatch: Dispatch<FetchLicenseValidate>) => {
    dispatch({ type: LicenseActionTypes.FETCH_LICENSE_VALIDATE });
};

export const handleFetchLicenseValidateSuccess = (
    dispatch: Dispatch<FetchLicenseValidateSuccess>,
    response: any
) => {
    dispatch({
        type: LicenseActionTypes.FETCH_LICENSE_VALIDATE_SUCCESS,
        payload: response,
    });

};

export const handleFetchLicenseValidateFail = (dispatch: Dispatch<FetchLicenseValidateFail>) => {
    dispatch({
        type: LicenseActionTypes.FETCH_LICENSE_VALIDATE_FAIL
    });
};


interface FetchLicenseBySchool {
    type: LicenseActionTypes.FETCH_LICENSE_BY_SCHOOL;
}

interface FetchLicenseBySchoolSuccess {
    type: LicenseActionTypes.FETCH_LICENSE_BY_SCHOOL_SUCCESS;
    payload: any;
}

interface FetchLicenseBySchoolFail {
    type: LicenseActionTypes.FETCH_LICENSE_BY_SCHOOL_FAIL;
}

export const fetchLicenseBySchoolGet = (getParams: any): ThunkResult<void> => async dispatch => {
    handleFetchLicenseBySchool(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.get(License.GetLicenseBySchool, {
            params: getParams,
            headers: {
                "Authorization": localStorage.getItem('token')
            }
        });
        console.log(response.data)
        handleFetchLicenseBySchoolSuccess(dispatch, response.data);
    } catch (e) {
        handleFetchLicenseBySchoolFail(dispatch);
    }
};

export const handleFetchLicenseBySchool = (dispatch: Dispatch<FetchLicenseBySchool>) => {
    dispatch({ type: LicenseActionTypes.FETCH_LICENSE_BY_SCHOOL });
};

export const handleFetchLicenseBySchoolSuccess = (
    dispatch: Dispatch<FetchLicenseBySchoolSuccess>,
    response: any
) => {
    dispatch({
        type: LicenseActionTypes.FETCH_LICENSE_BY_SCHOOL_SUCCESS,
        payload: response,
    });

};

export const handleFetchLicenseBySchoolFail = (dispatch: Dispatch<FetchLicenseBySchoolFail>) => {
    dispatch({
        type: LicenseActionTypes.FETCH_LICENSE_BY_SCHOOL_FAIL
    });
};

interface FetchMenuList {
    type: LicenseActionTypes.FETCH_MENU_LIST;
}

interface FetchMenuListSuccess {
    type: LicenseActionTypes.FETCH_MENU_LIST_SUCCESS;
    payload: any;
}

interface FetchMenuListFail {
    type: LicenseActionTypes.FETCH_MENU_LIST_FAIL;
}

export const fetchMenuListGet = (getParams: any): ThunkResult<void> => async dispatch => {
    handleFetchMenuList(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.get(License.GetMenuList, {
            params: getParams,
            headers: {
                "Authorization": localStorage.getItem('token')
            }
        });
        console.log(response.data)
        handleFetchMenuListSuccess(dispatch, response.data);
    } catch (e) {
        handleFetchMenuListFail(dispatch);
    }
};

export const handleFetchMenuList = (dispatch: Dispatch<FetchMenuList>) => {
    dispatch({ type: LicenseActionTypes.FETCH_MENU_LIST });
};

export const handleFetchMenuListSuccess = (
    dispatch: Dispatch<FetchMenuListSuccess>,
    response: any
) => {
    dispatch({
        type: LicenseActionTypes.FETCH_MENU_LIST_SUCCESS,
        payload: response,
    });

};

export const handleFetchMenuListFail = (dispatch: Dispatch<FetchMenuListFail>) => {
    dispatch({
        type: LicenseActionTypes.FETCH_MENU_LIST_FAIL
    });
};

interface FetchLicenseSchoolList {
    type: LicenseActionTypes.FETCH_LICENSE_SCHOOL_LIST;
}

interface FetchLicenseSchoolListSuccess {
    type: LicenseActionTypes.FETCH_LICENSE_SCHOOL_LIST_SUCCESS;
    payload: any;
}

interface FetchLicenseSchoolListFail {
    type: LicenseActionTypes.FETCH_LICENSE_SCHOOL_LIST_FAIL;
}

export const fetchLicenseSchoolListGet = (getParams: any): ThunkResult<void> => async dispatch => {
    handleFetchLicenseSchoolList(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.get(License.GetLicenseSchoolList, {
            params: getParams,
            headers: {
                "Authorization": localStorage.getItem('token')
            }
        });
        console.log(response.data)
        handleFetchLicenseSchoolListSuccess(dispatch, response.data);
    } catch (e) {
        handleLicenseSchoolListFail(dispatch);
    }
};

export const handleFetchLicenseSchoolList = (dispatch: Dispatch<FetchLicenseSchoolList>) => {
    dispatch({ type: LicenseActionTypes.FETCH_LICENSE_SCHOOL_LIST });
};

export const handleFetchLicenseSchoolListSuccess = (
    dispatch: Dispatch<FetchLicenseSchoolListSuccess>,
    response: any
) => {
    dispatch({
        type: LicenseActionTypes.FETCH_LICENSE_SCHOOL_LIST_SUCCESS,
        payload: response,
    });

};

export const handleLicenseSchoolListFail = (dispatch: Dispatch<FetchLicenseSchoolListFail>) => {
    dispatch({
        type: LicenseActionTypes.FETCH_LICENSE_SCHOOL_LIST_FAIL
    });
};


interface FetchSuperuserLicenseDetails {
    type: LicenseActionTypes.FETCH_SUPERUSER_LICENSE_DETAILS;
}

interface FetchSuperuserLicenseDetailsSuccess {
    type: LicenseActionTypes.FETCH_SUPERUSER_LICENSE_DETAILS_SUCCESS;
    payload: any;
}

interface FetchSuperuserLicenseDetailsFail {
    type: LicenseActionTypes.FETCH_SUPERUSER_LICENSE_DETAILS_FAIL;
}

export const fetchSuperuserLicenseDetailsGet = (param:any): ThunkResult<void> => async dispatch => {
    handleSuperuserLicenseDetails(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.get(License.GetSuperuserLicenseDetails, {
            params: param,
            headers: {
                "Authorization": localStorage.getItem('token')
            }
        });
        console.log(response.data)
        handleSuperuserLicenseDetailsSuccess(dispatch, response.data);
    } catch (e) {
        handleSuperuserLicenseDetailsFail(dispatch);
    }
};

export const handleSuperuserLicenseDetails = (dispatch: Dispatch<FetchSuperuserLicenseDetails>) => {
    dispatch({ type: LicenseActionTypes.FETCH_SUPERUSER_LICENSE_DETAILS });
};

export const handleSuperuserLicenseDetailsSuccess = (
    dispatch: Dispatch<FetchSuperuserLicenseDetailsSuccess>,
    response: any
) => {
    dispatch({
        type: LicenseActionTypes.FETCH_SUPERUSER_LICENSE_DETAILS_SUCCESS,
        payload: response,
    });

};

export const handleSuperuserLicenseDetailsFail = (dispatch: Dispatch<FetchSuperuserLicenseDetailsFail>) => {
    dispatch({
        type: LicenseActionTypes.FETCH_SUPERUSER_LICENSE_DETAILS_FAIL
    });
};


interface FetchAllLicensePartition {
    type: LicenseActionTypes.FETCH_ALL_LICENSE_PARTITION;
}

interface FetchAllLicensePartitionSuccess {
    type: LicenseActionTypes.FETCH_ALL_LICENSE_PARTITION_SUCCESS;
    payload: any;
}

interface FetchAllLicensePartitionFail {
    type: LicenseActionTypes.FETCH_ALL_LICENSE_PARTITION_FAIL;
}

export const fetchAllLicensePartitionGet = (params?:any): ThunkResult<void> => async dispatch => {
    handleAllLicensePartition(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.get(License.LicensePartition, {
            headers: {
                "Authorization": localStorage.getItem('token')
            }
        });
        handleAllLicensePartitionSuccess(dispatch, response.data);
    } catch (e) {
        handleAllLicensePartitionFail(dispatch);
    }
};

export const handleAllLicensePartition = (dispatch: Dispatch<FetchAllLicensePartition>) => {
    dispatch({ type: LicenseActionTypes.FETCH_ALL_LICENSE_PARTITION });
};

export const handleAllLicensePartitionSuccess = (
    dispatch: Dispatch<FetchAllLicensePartitionSuccess>,
    response: any
) => {
    dispatch({
        type: LicenseActionTypes.FETCH_ALL_LICENSE_PARTITION_SUCCESS,
        payload: response,
    });

};

export const handleAllLicensePartitionFail = (dispatch: Dispatch<FetchAllLicensePartitionFail>) => {
    dispatch({
        type: LicenseActionTypes.FETCH_ALL_LICENSE_PARTITION_FAIL
    });
};

interface AddLicensePartition {
    type: LicenseActionTypes.ADD_LICENSE_PARTITION;
}

interface AddLicensePartitionSuccess {
    type: LicenseActionTypes.ADD_LICENSE_PARTITION_SUCCESS;
    payload: any;
}

interface AddLicensePartitionFail {
    type: LicenseActionTypes.ADD_LICENSE_PARTITION_FAIL;
    payload: any;
}

export const CreateLicensePartition = (data: any): ThunkResult<void> => async dispatch => {
    handleAddLicensePartition(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.post(License.LicensePartition, data,
            {
                headers: { "Authorization": localStorage.getItem('token') } });
                console.log(response)
        if (response.data.status === true) {

                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.duration
                });
                history.push("/licensepartition");
            handleAddLicensePartitionSuccess(dispatch, response.data);
        } else {
            toaster.notify(response.data.message, {
                position: 'top',
                duration: notificationMsg.errorNotificationDuration
            });
            handleAddLicensePartitionFail(dispatch, response.data);
        }
    } catch (e) {
        handleAddLicensePartitionFail(dispatch, e);
    }
};

const handleAddLicensePartition = (dispatch: Dispatch<AddLicensePartition>) => {
    dispatch({ type: LicenseActionTypes.ADD_LICENSE_PARTITION });
};

const handleAddLicensePartitionSuccess = (
    dispatch: Dispatch<AddLicensePartitionSuccess>,
    response: any
) => {
    dispatch({ type: LicenseActionTypes.ADD_LICENSE_PARTITION_SUCCESS, payload: response });
};

const handleAddLicensePartitionFail = (dispatch: Dispatch<AddLicensePartitionFail>, response: any) => {
    dispatch({
        type: LicenseActionTypes.ADD_LICENSE_PARTITION_FAIL,
        payload: response,
        errors: response.data
    });
};


interface EditLicensePartition {
    type: LicenseActionTypes.EDIT_LICENSE_PARTITION;
}

interface EditLicensePartitionSuccess {
    type: LicenseActionTypes.EDIT_LICENSE_PARTITION_SUCCESS;
    payload: any;
}

interface EditLicensePartitionFail {
    type: LicenseActionTypes.EDIT_LICENSE_PARTITION_FAIL;
    payload: any;
}

export const editLicensePartition = (data: any): ThunkResult<void> => async dispatch => {
    handleEditLicensePartition(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.put(License.LicensePartition+`?uid=${data.uid}`, data,
            {
                headers: { "Authorization": localStorage.getItem('token') } });
        if (response.data.status === true) {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.duration
                });
                history.push("/licensepartition");
            handleEditLicensePartitionSuccess(dispatch, response.data);
        } else {
            toaster.notify(response.data.message, {
                position: 'top',
                duration: notificationMsg.errorNotificationDuration
            });
            handleEditLicensePartitionFail(dispatch, response.data);
        }
    } catch (e) {
        handleEditLicensePartitionFail(dispatch, e);
    }
};

const handleEditLicensePartition = (dispatch: Dispatch<EditLicensePartition>) => {
    dispatch({ type: LicenseActionTypes.EDIT_LICENSE_PARTITION });
};

const handleEditLicensePartitionSuccess = (
    dispatch: Dispatch<EditLicensePartitionSuccess>,
    response: any
) => {
    dispatch({ type: LicenseActionTypes.EDIT_LICENSE_PARTITION_SUCCESS, payload: response });
};

const handleEditLicensePartitionFail = (dispatch: Dispatch<EditLicensePartitionFail>, response: any) => {
    dispatch({
        type: LicenseActionTypes.EDIT_LICENSE_PARTITION_FAIL,
        payload: response,
        errors: response.data
    });
};

interface FetchLicensePartition {
    type: LicenseActionTypes.FETCH_LICENSE_PARTITION;
}

interface FetchLicensePartitionSuccess {
    type: LicenseActionTypes.FETCH_LICENSE_PARTITION_SUCCESS;
    payload: any;
}

interface FetchLicensePartitionFail {
    type: LicenseActionTypes.FETCH_LICENSE_PARTITION_FAIL;
}

export const fetchLicensePartitionGet = (params:any): ThunkResult<void> => async dispatch => {
    handleLicensePartition(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.get(License.LicensePartition+`?uid=${params.uid}&academic_year=${params.academic_year}`,{
            headers: {
                "Authorization": localStorage.getItem('token')
            }
        });
        handleLicensePartitionSuccess(dispatch, response.data);
    } catch (e) {
        handleLicensePartitionFail(dispatch);
    }
};

export const handleLicensePartition = (dispatch: Dispatch<FetchLicensePartition>) => {
    dispatch({ type: LicenseActionTypes.FETCH_LICENSE_PARTITION });
};

export const handleLicensePartitionSuccess = (
    dispatch: Dispatch<FetchLicensePartitionSuccess>,
    response: any
) => {
    dispatch({
        type: LicenseActionTypes.FETCH_LICENSE_PARTITION_SUCCESS,
        payload: response,
    });

};

export const handleLicensePartitionFail = (dispatch: Dispatch<FetchLicensePartitionFail>) => {
    dispatch({
        type: LicenseActionTypes.FETCH_LICENSE_PARTITION_FAIL
    });
};

interface DeleteLicensePartition {
    type: LicenseActionTypes.DELETE_LICENSE_PARTITION;
}

interface DeleteLicensePartitionSuccess {
    type: LicenseActionTypes.DELETE_LICENSE_PARTITION_SUCCESS;
    payload: any;
}

interface DeleteLicensePartitionFail {
    type: LicenseActionTypes.DELETE_LICENSE_PARTITION_FAIL;
}

export const deleteLicensePartition = (deletedId: any): ThunkResult<void> => async dispatch => {
    handleDeleteLicensePartition(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.delete(License.LicensePartition+`?uid=${deletedId}`,
            { headers: { "Authorization": localStorage.getItem('token') } });
        const getResponse = JSON.parse(JSON.stringify(response.data));
        if (getResponse.status === true) {
            toaster.notify(getResponse.message, {
                position: 'top',
                duration: notificationMsg.duration
            });
            handleDeleteLicensePartitionSuccess(dispatch, response.data);
        } else {
            toaster.notify(getResponse.message, {
                position: 'top',
                duration: notificationMsg.errorNotificationDuration
            });
            handleDeleteLicensePartitionFail(dispatch);
        }

    } catch (e) {
        handleDeleteLicensePartitionFail(dispatch);
    }
};

const handleDeleteLicensePartition = (dispatch: Dispatch<DeleteLicensePartition>) => {
    dispatch({ type: LicenseActionTypes.DELETE_LICENSE_PARTITION });
};

const handleDeleteLicensePartitionSuccess = (
    dispatch: Dispatch<DeleteLicensePartitionSuccess>,
    response: any
) => {
    dispatch({ type: LicenseActionTypes.DELETE_LICENSE_PARTITION_SUCCESS, payload: response });
};
const handleDeleteLicensePartitionFail = (dispatch: Dispatch<DeleteLicensePartitionFail>) => {
    dispatch({ type: LicenseActionTypes.DELETE_LICENSE_PARTITION_FAIL });
};




export type LicenseAction =
    | FetchLicenseValidateSuccess
    | FetchMenuListSuccess;