export interface PropsTypes {
    getCorrectSchoolId:any,
    hasMore:boolean,
    prev: any,
    next: any,
    acsOrder: any,
    descOrder: any,
    page:any,
    per_page: any,
    records:any,
    total:any,
    totalPage:any,
    search: any,
    SortOrderData:any,
    OrderNameData:any,
    sort_by?:string,
    sort_class_name?:string,
    sort_title_name?:string,
    getCurrectYear?:any
}

export const optionView = {
    legend: {
        display: true
      },
    tooltips: {
      callbacks: {
        label: function(tooltipItem:any, data:any) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var meta = dataset._meta[Object.keys(dataset._meta)[0]];
          var total = meta.total;
          var currentValue = dataset.data[tooltipItem.index];
          var percentage = parseFloat((currentValue/total*100).toFixed(1));
          return `${currentValue} ${percentage }%`;
        },
        title: function(tooltipItem:any, data:any) {
          return data.labels[tooltipItem[0].index];
        }
      }
    }
  }

  export const option = {
    legend: {
        display: false
      },
    tooltips: {
      callbacks: {
        label: function(tooltipItem:any, data:any) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var meta = dataset._meta[Object.keys(dataset._meta)[0]];
          var total = meta.total;
          var currentValue = dataset.data[tooltipItem.index];
          var percentage = parseFloat((currentValue/total*100).toFixed(1));
          return `${currentValue} ${percentage }%`;
        },
        title: function(tooltipItem:any, data:any) {
          return data.labels[tooltipItem[0].index];
        }
      }
    }
  }