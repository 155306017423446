import * as yup from 'yup';
import { QuestionBankValidation } from '../../services/Constants';

export const schema = yup.object().shape({
    blueprint_name: yup.string()
        .required('Please enter the Blue Print Name')
        .max(100, QuestionBankValidation.bluprintMaxMsg)
        .min(5, QuestionBankValidation.bluprintMinMsg),
    blueprint_desc: yup.string()
        .required('Please enter the Blue Print Desc')
        .max(2000, QuestionBankValidation.bluprintdescMaxMsg)
        .min(5, QuestionBankValidation.bluprintdescMinMsg),
    select_grade: yup.string()
        .required('Please select the Grade'),
    select_exam_type: yup.string()
        .required('Please select the Exam Type'),
    select_subject: yup.string()
        .required('Please select the Subject'),
    blueprintlist: yup.array().of(
        yup.object().shape({
            question_type: yup.string()
                .required('Please Select the Question Type'),
            question_type_marks: yup.string()
                .required('Please Select the Mark'),
            lessons: yup.string()
                .required('Please Select the lesson'),
            difficulty_level: yup.string()
                .required('Please Select the Difficult Level'),
            no_of_question: yup.string()
                .required('Please enter the No of Question')
                .typeError('Please enter a valid number')
                .matches(/^[1-9][0-9]*$/,'Value must be an integer')
                //.integer('Value must be an integer'),

        })
    )
});

export const subjectMappingAddSchema = yup.object({
    gradle_internal: yup.string()
        .required('Value is required'),
    subject_internal: yup.string()
        .required('Value is required'),
    wizdomwaves_subject: yup.string()
        .required('Value is required'),
    language: yup.string()
        .required('Value is required'),
})

export const subjectMappingEditSchema = yup.object({
    gradle_internal: yup.string()
        .required('Value is required'),
    subject_internal: yup.string()
        .required('Value is required'),
    wizdomwaves_subject: yup.string()
        .required('Value is required'),
    language: yup.string()
        .required('Value is required'),
})

export const examTypeAddSchema = yup.object({
    name: yup.string()
        .required('Value is required')
        .max(150, 'Should not be more than 150 characters.')
        .min(5, 'Should have atleast 5 characters'),
    description: yup.string()
        .required('Value is required')
        .max(2000, 'Should not be more than 2000 characters.')
        .min(5, 'Should have atleast 5 characters'),
    total_marks: yup.number()
        .required('Value is required')
        .typeError('Please enter a valid number')
        .integer('Value must be an integer')
        .max(100, 'Should not be more than 100 marks.')
        .min(10, 'Should have atleast 10 marks'),
    allowed_time: yup.number()
        .required('Value is required')
        .typeError('Please enter a valid number')
        .integer('Value must be an integer')
        .max(300, 'Should not be more than 300 minutes.')
        .min(10, 'Should have atleast 10 minutes'),
})

export const examTypeEditSchema = yup.object({
    name: yup.string()
        .required('Value is required')
        .max(150, 'Should not be more than 150 characters.')
        .min(5, 'Should have atleast 5 characters'),
    description: yup.string()
        .required('Value is required')
        .max(2000, 'Should not be more than 2000 characters.')
        .min(5, 'Should have atleast 5 characters'),
    total_marks: yup.number()
        .required('Value is required')
        .typeError('Please enter a valid number')
        .integer('Value must be an integer')
        .max(100, 'Should not be more than 100 marks.')
        .min(10, 'Should have atleast 10 marks'),
    allowed_time: yup.number()
        .required('Value is required')
        .typeError('Please enter a valid number')
        .integer('Value must be an integer')
        .max(300, 'Should not be more than 300 minutes.')
        .min(10, 'Should have atleast 10 minutes'),
})

export const generateQuestionsSchema = yup.object({
    blueprint_dropdown: yup.string()
        .required('Value is required'),
})

export const feedBackAddschema = yup.object().shape({
    select_grade: yup.string()
        .required('Please select the Grade'),
    select_subject: yup.string()
        .required('Please select the Subject'),
    question_type: yup.string()
        .required('Please Select the Question Type'),
    question_type_marks: yup.string()
        .required('Please Select the Mark'),
    lessons: yup.string()
        .required('Please Select the lesson'),
    difficulty_level: yup.string()
        .required('Please Select the Difficult Level'),
    question: yup.string()
        .required('Please enter the question'),
    question_image: yup.mixed()
        .test('fileType', 'Supported image format jpeg, jpg, png', (value) => {
            // Only validate file type if a file is selected
            if (value) {
                // value.type
                const fileType = value.substring(5, value.indexOf(';'));
                return ['image/jpeg', 'image/jpg', 'image/png'].includes(fileType);
            }
            return true; // Return true if no file is selected
        })
        .test('fileSize', 'File size is too large (max 2MB)', (value) => {
            // Check file size (max 2MB)
            if (value) {
                //value.size
                const fileSizeInBytes = (value.length * 3) / 4 - 2; // Approximate size of base64 string in bytes
                return fileSizeInBytes <= 2 * 1024 * 1024; // 2MB in bytes
            }
            return true; // Return true if no file is selected
        }),
    answer: yup.string().when('question_type_name', {
        is: (value) => value == "MatchPairQ" ? true : false,
        then: yup.string()
            .required('Please enter the answer'),
    }),
    answer_image: yup.mixed()
        .test('fileType', 'Supported image format jpeg, jpg, png', (value) => {
            // Only validate file type if a file is selected
            if (value) {
                // value.type
                const fileType = value.substring(5, value.indexOf(';'));
                return ['image/jpeg', 'image/jpg', 'image/png'].includes(fileType);
            }
            return true; // Return true if no file is selected
        })
        .test('fileSize', 'File size is too large (max 2MB)', (value) => {
            // Check file size (max 2MB)
            if (value) {
                //value.size
                const fileSizeInBytes = (value.length * 3) / 4 - 2; // Approximate size of base64 string in bytes
                return fileSizeInBytes <= 2 * 1024 * 1024; // 2MB in bytes
            }
            return true; // Return true if no file is selected
    }),    
    optionA: yup.object().when('question_type_name', {
        is: (value) => value == "MCQ" ? true : false,
        then: yup.object().test(
            'imageOrTextSelected',
            'Please add text or upload an image for option A',
            function (value: any) {
                const { text, image } = value;
                return !!text || !!image;
            }
        ).test('fileType', 'Supported image format jpeg, jpg, png', (value: any) => {
            // Only validate file type if a file is selected
            const { text, image } = value;
            if (image) {
                // value.type
                const fileType = image.substring(5, image.indexOf(';'));
                return ['image/jpeg', 'image/jpg', 'image/png'].includes(fileType);
            }
            return true; // Return true if no file is selected
        }).test('fileSize', 'File size is too large (max 2MB)', (value: any) => {
                // Check file size (max 2MB)
                const { text, image } = value;
                if (image) {
                    //value.size
                    const fileSizeInBytes = (image.length * 3) / 4 - 2; // Approximate size of base64 string in bytes
                    return fileSizeInBytes <= 2 * 1024 * 1024; // 2MB in bytes
                }
                return true; // Return true if no file is selected
            }).test(
                'is-unique-text',
                'Option A text must be unique',
                function (value: any) {
                    const { text, image } = value;
                    if (text) {
                        return text &&  text !== this.parent.optionB.text &&
                  text !== this.parent.optionC.text &&
                  text !== this.parent.optionD.text;
                    }
                    return true;
                 
                }
              ).test(
                'is-unique-image',
                'Option A image must be unique',
                function (value: any) {
                    const { text, image } = value;
                    if (image) {
                  return image && image !== this.parent.optionB.image &&
                  image !== this.parent.optionC.image &&
                  image !== this.parent.optionD.image;
                    }
                    return true
                }
              ),
    }),
    optionB: yup.object().when('question_type_name', {
        is: (value) => value == "MCQ" ? true : false,
        then: yup.object().test(
            'imageOrTextSelected',
            'Please add text or upload an image for option B',
            function (value: any) {
                const { text, image } = value;
                return !!text || !!image;
            }
        ).test('fileType', 'Supported image format jpeg, jpg, png', (value: any) => {
            // Only validate file type if a file is selected
            const { text, image } = value;
            if (image) {
                // value.type
                const fileType = image.substring(5, image.indexOf(';'));
                return ['image/jpeg', 'image/jpg', 'image/png'].includes(fileType);
            }
            return true; // Return true if no file is selected
        })
            .test('fileSize', 'File size is too large (max 2MB)', (value: any) => {
                // Check file size (max 2MB)
                const { text, image } = value;
                if (image) {
                    //value.size
                    const fileSizeInBytes = (image.length * 3) / 4 - 2; // Approximate size of base64 string in bytes
                    return fileSizeInBytes <= 2 * 1024 * 1024; // 2MB in bytes
                }
                return true; // Return true if no file is selected
            }).test(
                'is-unique-text',
                'Option B text must be unique',
                function (value: any) {
                    const { text, image } = value;
                    if (text) {
                  return text && text !== this.parent.optionA.text &&
                  text !== this.parent.optionC.text &&
                  text !== this.parent.optionD.text;
                    }
                    return true;
                }
              ).test(
                'is-unique-image',
                'Option B image must be unique',
                function (value: any) {
                    const { text, image } = value;
                    if (image) {
                  return image && image !== this.parent.optionA.image &&
                  image !== this.parent.optionC.image &&
                  image !== this.parent.optionD.image;
                    }
                    return true;
                }
              ),
    }),
    optionC: yup.object().when('question_type_name', {
        is: (value) => value == "MCQ" ? true : false,
        then: yup.object().test(
            'imageOrTextSelected',
            'Please add text or upload an image for option C',
            function (value: any) {
                const { text, image } = value;
                return !!text || !!image;
            }
        ).test('fileType', 'Supported image format jpeg, jpg, png', (value: any) => {
            // Only validate file type if a file is selected
            const { text, image } = value;
            if (image) {
                // value.type
                const fileType = image.substring(5, image.indexOf(';'));
                return ['image/jpeg', 'image/jpg', 'image/png'].includes(fileType);
            }
            return true; // Return true if no file is selected
        })
            .test('fileSize', 'File size is too large (max 2MB)', (value: any) => {
                // Check file size (max 2MB)
                const { text, image } = value;
                if (image) {
                    //value.size
                    const fileSizeInBytes = (image.length * 3) / 4 - 2; // Approximate size of base64 string in bytes
                    return fileSizeInBytes <= 2 * 1024 * 1024; // 2MB in bytes
                }
                return true; 
            }).test(
                'is-unique-text',
                'Option C text must be unique',
                function (value: any) {
                    const { text, image } = value;
                    if (text) {
                  return text && text !== this.parent.optionA.text &&
                  text !== this.parent.optionB.text &&
                  text !== this.parent.optionD.text;
                    }
                    return true; 
                }
              ).test(
                'is-unique-image',
                'Option C image must be unique',
                function (value: any) {
                    const { text, image } = value;
                    if (image) {
                  return image && image !== this.parent.optionA.image &&
                  image !== this.parent.optionB.image &&
                  image !== this.parent.optionD.image;
                }
                return true; 
                }
              ),
    }),
    optionD: yup.object().when('question_type_name', {
        is: (value) => value == "MCQ" ? true : false,
        then: yup.object().test(
            'imageOrTextSelected',
            'Please add text or upload an image for option D',
            function (value: any) {
                const { text, image } = value;
                return !!text || !!image;
            }
        ).test('fileType', 'Supported image format jpeg, jpg, png', (value: any) => {
            // Only validate file type if a file is selected
            const { text, image } = value;
            if (image) {
                // value.type
                const fileType = image.substring(5, image.indexOf(';'));
                return ['image/jpeg', 'image/jpg', 'image/png'].includes(fileType);
            }
            return true; // Return true if no file is selected
        })
            .test('fileSize', 'File size is too large (max 2MB)', (value: any) => {
                // Check file size (max 2MB)
                const { text, image } = value;
                if (image) {
                    //value.size
                    const fileSizeInBytes = (image.length * 3) / 4 - 2; // Approximate size of base64 string in bytes
                    return fileSizeInBytes <= 2 * 1024 * 1024; // 2MB in bytes
                }
                return true; // Return true if no file is selected
            }).test(
                'is-unique-text',
                'Option D text must be unique',
                function (value: any) {
                    const { text, image } = value;
                    if (text) {
                  return text && text !== this.parent.optionA.text &&
                  text !== this.parent.optionB.text &&
                  text !== this.parent.optionC.text;
                }
                return true;
                }
              ).test(
                'is-unique-image',
                'Option D image must be unique',
                function (value: any) {
                    const { text, image } = value;
                    if (image) {
                  return image && image !== this.parent.optionA.image &&
                  image !== this.parent.optionB.image &&
                  image !== this.parent.optionC.image;
                }
                return true;
                }
              ),
    }),
    question_correct_answer: yup.string().when('question_type_name', {
        is: (value) => value == "MCQ" ? true : false,
        then: yup.string()
            .required('Please select correct option'),
    }),
    sub_question_list:  yup.mixed()
    .when('question_type_name', {
        is: (value) => value == "ReadPassageQ" ? true : false,
        then: yup.array().of(
        yup.object().shape({
                question: yup.string()
                .required('Please enter the Question'),
            })
        )})
});

