import { ThunkAction } from "redux-thunk";
import { RootActions, RootState } from "../Index";
import { Dispatch } from "redux";
import { AxiosResponse } from "axios";
import { HomeStudy } from "../../services/Config";
import { baseAPI } from "../../Service";
import toaster from "toasted-notes";
import { notificationMsg } from "../../services/Constants";
import history from "../../History";
import { HomeStudyTypes } from "./Types";
 


//Implement Thunk Middleware
export type ThunkResult<R> = ThunkAction<R, RootState, undefined, RootActions>;

interface FetchControlPanel {
    type: HomeStudyTypes.CONTROL_PANEL;
}

interface FetchControlPanelSuccess {
    type: HomeStudyTypes.CONTROL_PANEL_SUCCESS;
    payload: any;
}

interface FetchControlPanelFail {
    type: HomeStudyTypes.CONTROL_PANEL_FAIL;
}

export const fetchControlPanelGet = (params: any) : ThunkResult<void> => async dispatch => {
    handleFetchControlPanel(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.patch(HomeStudy.ControlPanelGrid, null, {
                params: params,
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            if (response.data.status === true) {
                handleFetchControlPanelSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleFetchControlPanelFail(dispatch);
            }
    } catch (e) {
        handleFetchControlPanelFail(dispatch);
    }
};

export const handleFetchControlPanel = (dispatch: Dispatch<FetchControlPanel>) => {
    dispatch({ type: HomeStudyTypes.CONTROL_PANEL });
};

export const handleFetchControlPanelSuccess = (
    dispatch: Dispatch<FetchControlPanelSuccess>,
    response: any
) => {
    dispatch({
        type: HomeStudyTypes.CONTROL_PANEL_SUCCESS,
        payload: response,
        records: response.data.records,
        per_page: response.data.per_page,
        page: response.data.page,
        total: response.data.total
    });
};

export const handleFetchControlPanelFail = (dispatch: Dispatch<FetchControlPanelFail>) => {
    dispatch({
        type: HomeStudyTypes.CONTROL_PANEL_FAIL
    });
};

interface DeleteControlPanelById{
    type: HomeStudyTypes.DELETE_CONTROL_PANEL_BY_ID;
}

interface DeleteControlPanelByIdSuccess {
    type: HomeStudyTypes.DELETE_CONTROL_PANEL_BY_ID_SUCCESS;
    payload: any;
}

interface DeleteControlPanelByIdFail {
    type: HomeStudyTypes.DELETE_CONTROL_PANEL_BY_ID_FAIL;
}

export const deleteControlPanelById = (deletedId: any): ThunkResult<void> => async dispatch => {
    handleDeleteControlPanelById(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.delete(HomeStudy.ControlPanelGrid+`?uid=${deletedId}`,
            { headers: { "Authorization": localStorage.getItem('token') } });
        const getResponse = JSON.parse(JSON.stringify(response.data));
        if (getResponse.status === true) {
            toaster.notify(getResponse.message, {
                position: 'top',
                duration: notificationMsg.duration
            });
            handleDeleteControlPanelByIdSuccess(dispatch, response.data);
        } else {
            toaster.notify(getResponse.message, {
                position: 'top',
                duration: notificationMsg.errorNotificationDuration
            });
            handleDeleteControlPanelByIdFail(dispatch);
        }

    } catch (e) {
        handleDeleteControlPanelByIdFail(dispatch);
    }
};

const handleDeleteControlPanelById = (dispatch: Dispatch<DeleteControlPanelById>) => {
    dispatch({ type: HomeStudyTypes.DELETE_CONTROL_PANEL_BY_ID });
};

const handleDeleteControlPanelByIdSuccess = (
    dispatch: Dispatch<DeleteControlPanelByIdSuccess>,
    response: any
) => {
    dispatch({ type: HomeStudyTypes.DELETE_CONTROL_PANEL_BY_ID_SUCCESS, payload: response });
};

const handleDeleteControlPanelByIdFail = (dispatch: Dispatch<DeleteControlPanelByIdFail>) => {
    dispatch({ type: HomeStudyTypes.DELETE_CONTROL_PANEL_BY_ID_FAIL });
};

interface addControlPanel {
    type: HomeStudyTypes.ADD_CONTROL_PANEL;
}

interface addControlPanelSuccess {
    type: HomeStudyTypes.ADD_CONTROL_PANEL_SUCCESS;
    payload: any;
}

interface addControlPanelFail {
    type: HomeStudyTypes.ADD_CONTROL_PANEL_FAIL;
}

export const addControlPanelget = (params: any): ThunkResult<void> => async dispatch => {
    handleAddControlPanel(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.post(HomeStudy.ControlPanelGrid, params, {
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            if (response.data.status === true) {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.duration
                });
                history.push("/control-panel");
                handleAddControlPanelSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                history.push("/control-panel");
                handleAddControlPanelFail(dispatch);
            }
    } catch (e) {
        handleAddControlPanelFail(dispatch);
    }
};

export const handleAddControlPanel = (dispatch: Dispatch<addControlPanel>) => {
    dispatch({ type: HomeStudyTypes.ADD_CONTROL_PANEL });
};

export const handleAddControlPanelSuccess = (
    dispatch: Dispatch<addControlPanelSuccess>,
    response: any
) => {
    dispatch({
        type: HomeStudyTypes.ADD_CONTROL_PANEL_SUCCESS,
        payload: response,
    });
};

export const handleAddControlPanelFail = (dispatch: Dispatch<addControlPanelFail>) => {
    dispatch({
        type: HomeStudyTypes.ADD_CONTROL_PANEL_FAIL
    });
};

interface editControlPanel {
    type: HomeStudyTypes.EDIT_CONTROL_PANEL;
};

interface editControlPanelSuccess{
    type: HomeStudyTypes.EDIT_CONTROL_PANEL_SUCCESS;
    payload: any;
};

interface editControlPanelFail {
    type: HomeStudyTypes.EDIT_CONTROL_PANEL_FAIL;
};

export const editControlPanel = (params: any): ThunkResult<void> => async dispatch => {
    handleEditControlPanel(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.put(HomeStudy.ControlPanelGrid+`?uid=${params.uid}`, params, 
            {
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            if (response.data.status === true) {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.duration
                });
                history.push("/control-panel");
                handleEditControlPanelSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleEditControlPanelFail(dispatch);
            }
    } catch (e) {
        handleEditControlPanelFail(dispatch);
    }
};

export const handleEditControlPanel = (dispatch: Dispatch<editControlPanel>) => {
    dispatch({ type: HomeStudyTypes.EDIT_CONTROL_PANEL});
};

export const handleEditControlPanelSuccess = (
    dispatch: Dispatch<editControlPanelSuccess>, 
                response: any
) => {
    dispatch({ type: HomeStudyTypes.EDIT_CONTROL_PANEL_SUCCESS,
                payload: response});
};

export const handleEditControlPanelFail = (dispatch: Dispatch<editControlPanelFail>) => {
    dispatch({ type: HomeStudyTypes.EDIT_CONTROL_PANEL_FAIL});
};

interface fetchControlPanelById {
    type: HomeStudyTypes.FETCH_CONTROL_PANEL_BY_ID;
};

interface fetchControlPanelByIdSuccess{
    type: HomeStudyTypes.FETCH_CONTROL_PANEL_BY_ID_SUCCESS;
    payload: any;
};

interface fetchControlPanelByIdFail{
    type: HomeStudyTypes.FETCH_CONTROL_PANEL_BY_ID_FAIL;
};

export const fetchControlPanelByIdGet = (data: any): ThunkResult<void> => async dispatch => {
    handleFetchControlPanelById(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(HomeStudy.ControlPanelGrid+`?uid=${data.uid}`, {
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            if (response.data.status === true) {
                handleFetchControlPanelByIdSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleFetchControlPanelByIdFail(dispatch);
            }
    } catch (e) {
        handleFetchControlPanelByIdFail(dispatch);
    }
};

export const handleFetchControlPanelById = (dispatch: Dispatch<fetchControlPanelById>) => {
    dispatch({ type: HomeStudyTypes.FETCH_CONTROL_PANEL_BY_ID });
};

export const handleFetchControlPanelByIdSuccess = (
    dispatch: Dispatch<fetchControlPanelByIdSuccess>,
    response: any
) => {
    dispatch({
        type: HomeStudyTypes.FETCH_CONTROL_PANEL_BY_ID_SUCCESS,
        payload: response,
    });
};

export const handleFetchControlPanelByIdFail = (dispatch: Dispatch<fetchControlPanelByIdFail>) => {
    dispatch({
        type: HomeStudyTypes.FETCH_CONTROL_PANEL_BY_ID_FAIL
    });
};

interface fetchhomeStudyView{
    type: HomeStudyTypes.HOME_STUDY_VIEW;
};

interface fetchhomeStudyViewSuccess {
    type: HomeStudyTypes.HOME_STUDY_VIEW_SUCCESS;
    payload: any;
};

interface fetchhomeStudyViewFail {
    type: HomeStudyTypes.HOME_STUDY_VIEW_FAIL;
};

export const fetchHomeStudyView = (params: any) : ThunkResult<void> => async dispatch => {
    handleHomeStudyView(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.get(HomeStudy.HomeStudyView,
        {   params:params,
            headers: { "Authorization": localStorage.getItem('token') } }); 
            if (response.data.status === true) {
                handleHomeStudyViewSuccess(dispatch, response.data);
            } else {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleHomeStudyViewFail(dispatch);
            }
        
    } catch (e) {
        handleHomeStudyViewFail(dispatch);
    }
};

export const handleHomeStudyView = (dispatch: Dispatch<fetchhomeStudyView>) => {
    dispatch({ type: HomeStudyTypes.HOME_STUDY_VIEW });
};

export const handleHomeStudyViewSuccess = (dispatch: Dispatch<fetchhomeStudyViewSuccess>, response: any) => {
    dispatch({type: HomeStudyTypes.HOME_STUDY_VIEW_SUCCESS,
            payload: response});
};

export const handleHomeStudyViewFail = (dispatch: Dispatch<fetchhomeStudyViewFail>) => {
    dispatch({ type: HomeStudyTypes.HOME_STUDY_VIEW_FAIL});
}

// Home study view subject list
interface subjectList{
    type: HomeStudyTypes.SUBJECT_LIST;
}

interface subjectListSuccess{
    type: HomeStudyTypes.SUBJECT_LIST_SUCCESS;
    payload: any;
}

interface subjectListFail{
    type: HomeStudyTypes.SUBJECT_LIST_FAIL;
}

export const fetchSubjectList = (postValue: any): ThunkResult<void> => async dispatch => {
    handleSubjectList(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.get(HomeStudy.getSubjectList,
        {   params:postValue,
            headers: { "Authorization": localStorage.getItem('token') } }); 
            if (response.data.status === true) {
                handleSubjectListSuccess(dispatch, response.data);
            } else {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleSubjectListFail(dispatch);
            }
        
    } catch (e) {
        handleSubjectListFail(dispatch);
    }
};

export const handleSubjectList = (dispatch: Dispatch<subjectList>) => {
    dispatch({ type: HomeStudyTypes.SUBJECT_LIST });
};

const handleSubjectListSuccess = (
    dispatch: Dispatch<subjectListSuccess>,
    response: any
) => {
    dispatch({
        type: HomeStudyTypes.SUBJECT_LIST_SUCCESS,
        payload: response,
        getSubjectList: response.data
    });
};

const handleSubjectListFail = (dispatch: Dispatch<subjectListFail>) => {
    dispatch({
        type: HomeStudyTypes.SUBJECT_LIST_FAIL
    });
};

interface searchList{
    type: HomeStudyTypes.SEARCH_VALUE;
}
interface searchListSuccess {
    type: HomeStudyTypes.SEARCH_VALUE_SUCCESS;
    payload: any;
}
interface searchListFail {
    type: HomeStudyTypes.SEARCH_VALUE_FAIL;
}

export const getSearchValues = (searchValue: any) : ThunkResult<void> => async dispatch => {
    handleSearchList(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(HomeStudy.HomeStudySearch, {
                params: searchValue,
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            if (response.data.status === true) {
                handleSearchListSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleSearchListFail(dispatch);
            }
    } catch (e) {
        handleSearchListFail(dispatch);
    }
};

export const handleSearchList = (dispatch: Dispatch<searchList>) => {
    dispatch({ type: HomeStudyTypes.SEARCH_VALUE });
};

const handleSearchListSuccess = (
    dispatch: Dispatch<searchListSuccess>,
    response: any

) => {
    dispatch({
        type: HomeStudyTypes.SEARCH_VALUE_SUCCESS,
        payload: response,        
    });
};

const handleSearchListFail = (dispatch: Dispatch<searchListFail>) => {
    dispatch({
        type: HomeStudyTypes.SEARCH_VALUE_FAIL
    });
};
//clear all data
interface fetchResetHomestudy {
    type: HomeStudyTypes.RESET_HOME_STUDY
}

export const ResetHomeStudyGrid = (): ThunkResult<void> => async dispatch => {
    handleResetHomeStudyGrid(dispatch);
}

export const handleResetHomeStudyGrid = (dispatch: Dispatch<fetchResetHomestudy>) => {
    dispatch({ type: HomeStudyTypes.RESET_HOME_STUDY });
};

export type HomeStudyAction = | FetchControlPanelSuccess