export interface DashboardState {
    loading: boolean;
    items: any;
    isAuthenticated: boolean | null;
    errors?:any;
    page: number,
    per_page: number,
    records: any,
    total: number,
    DashboardCount:any,
    page_Teacher_Engagement: number,
    per_page_Teacher_Engagement: number,
    records_Teacher_Engagement: any,
    total_Teacher_Engagement: number,
    page_Teacher_Performance: number,
    per_page_Teacher_Performance: number,
    records_Teacher_Performance: any,
    total_Teacher_Performance: number,
    SubjectAnalysis:any,
    StandardAnalysis:any,
    records_InstantFeedBack:any,
    per_page_InstantFeedBack:any,
    page_InstantFeedBack:any,
    total_InstantFeedBack:any,
    totalPageTeacherPerformance?:any,
    totalPageInstantFeedBack?:any,
    totalPageTeacherEngagement?:any,
    feedbackQuestionResponses:any,
    quizQuestionResponses:any,
    attendanceUsage:any,
    getTopicAnalysis:any,
    getSubjectPerformance:any,
    getConcernPoint:any,
    getAttendanceReport:any,
    getQuizReport:any,
    recordsAttendanceReport:any,
    pageAttendanceReport:any,
    perPageAttendanceReport:any,
    totalAttendanceReport:any,
    totalPageAttendanceReport:any,
    recordsQuizReport:any,
    pageQuizReport:any,
    perPageQuizReport:any,
    totalQuizReport:any,
    totalPageQuizReport:any,
    recordsClassesReport:any,
    pageClassesReport:any,
    perPageClassesReport:any,
    totalClassesReport:any,
    totalPageClassesReport:any,
    pagePostTeacherEngagement: number,
    perPagePostTeacherEngagement: number,
    recordsPostTeacherEngagement: any,
    totalPostTeacherEngagement: number,
    getGradeComparisonReport?:any,
    getGradeComparisonHeader?:any,
    pageGradeComparison?:any,
    perPageGradeComparison?:any,
    totalGradeComparison?:any,
    totalPageGradeComparison?:any,
    recordsTodayAttendanceReport?:any,
    pageTodayAttendance?:any,
    perPageTodayAttendance?:any,
    totalTodayAttendance?:any,
    totalPageTodayAttendance?:any,
    gradeSujectList?:any,
    dashboardCountLoader?:any,
    subjectAnalysisLoader?:any,
    standardAnalysisLoader?:any,
    teacherEngagementLoader?:any,
    isTeacherPerformanceLoader?:boolean,
    isTopicAnalysisLoader?:boolean,
    isteacherSubjectPerdormanceLoader?:boolean,
    isPointofConcernLoader?:boolean,
    isAdminAttendandeUsage?:boolean,
    isAdminQuizReportLoader?:boolean,
    isAdminFeedbackLoader?:boolean,
    isAdminTodayAttendeanceLoader?:boolean,
    isAdminGradeComparsionLoader?:boolean
}

export enum DashboardType {
    FETCH_DASHBOARD = 'FATCH_DASHBOARD',
    FETCH_DASHBOARD_SUCCESS = 'FATCH_DASHBOARD_SUCCESS',
    FETCH_DASHBOARD_FAIL = 'FATCH_DASHBOARD_FAIL',
    FETCH_TEACHER_ENGAGEMENT = 'FATCH_TEACHER_ENGAGEMENT',
    FETCH_TEACHER_ENGAGEMENT_SUCCESS = 'FATCH_TEACHER_ENGAGEMENT_SUCCESS',
    FETCH_TEACHER_ENGAGEMENT_FAIL = 'FATCH_TEACHER_ENGAGEMENT_FAIL',
    FETCH_TEACHER_PERFORMANCE = 'FATCH_TEACHER_PERFORMANCE',
    FETCH_TEACHER_PERFORMANCE_SUCCESS = 'FATCH_TEACHER_PERFORMANCE_SUCCESS',
    FETCH_TEACHER_PERFORMANCE_FAIL = 'FATCH_TEACHER_PERFORMANCE_FAIL',
    FETCH_STANDARD_ANALYSIS = 'FATCH_STANDARD_ANALYSIS',
    FETCH_STANDARD_ANALYSIS_SUCCESS = 'FATCH_STANDARD_ANALYSIS_SUCCESS',
    FETCH_STANDARD_ANALYSIS_FAIL = 'FATCH_STANDARD_ANALYSIS_FAIL',
    FETCH_SUBJECT_ANALYSIS = 'FATCH_SUBJECT_ANALYSIS',
    FETCH_SUBJECT_ANALYSIS_SUCCESS = 'FATCH_SUBJECT_ANALYSIS_SUCCESS',
    FETCH_SUBJECT_ANALYSIS_FAIL = 'FATCH_SUBJECT_ANALYSIS_FAIL',
    FETCH_INSTANTFEEDBACK = 'FATCH_INSTANTFEEDBACK',
    FETCH_INSTANTFEEDBACK_SUCCESS = 'FATCH_INSTANTFEEDBACK_SUCCESS',
    FETCH_INSTANTFEEDBACK_FAIL = 'FATCH_INSTANTFEEDBACK_FAIL',
    Chart_Attendance_Usage = 'Chart_Attendance_Usage',
    Chart_Attendance_Usage_Success = 'Chart_Attendance_Usage_Success',
    Chart_Attendance_Usage_Fail = 'Chart_Attendance_Usage_Fail',
    Chart_Feedback_Question_Responses = 'Chart_Feedback_Question_Responses',
    Chart_Feedback_Question_Responses_Success = 'Chart_Feedback_Question_Responses_Success',
    Chart_Feedback_Question_Responses_Fail = 'Chart_Feedback_Question_Responses_Fail',
    Chart_Quiz_Question_Responses = 'Chart_Quiz_Question_Responses',
    Chart_Quiz_Question_Responses_SUCCESS = 'Chart_Quiz_Question_Responses_Success',
    Chart_Quiz_Question_Responses_FAIL = 'Chart_Quiz_Question_Responses_Fail',
    Chart_Topic_Analysis = 'Chart_Topic_Analysis',
    Chart_Topic_Analysis_Success = 'Chart_Topic_Analysis_Success',
    Chart_Topic_Analysis_Fail = 'Chart_Topic_Analysis_Fail',
    Chart_Subject_Performance = 'Chart_Subject_Performance',
    Chart_Subject_Performance_Success = 'Chart_Subject_Performance_Success',
    Chart_Subject_Performance_Fail = 'Chart_Subject_Performance_Fail',
    Chart_Concern_Point = 'Chart_Concern_Point',
    Chart_Concern_Point_Success = 'Chart_Concern_Point_Success',
    Chart_Concern_Point_Fail = 'Chart_Concern_Point_Fail',
    Quiz_Report = 'Quiz_Report',
    Quiz_Report_Success = 'Quiz_Report_Success',
    Quiz_Report_Fail = 'Quiz_Report_Fail',
    Classes_Report = 'Classes_Report',
    Classes_Report_Success = 'Classes_Report_Success',
    Classes_Report_Fail = 'Classes_Report_Fail',
    Attendance_Report = 'Attendance_Report',
    Attendance_Report_Success = 'Attendance_Report_Success',
    Attendance_Report_Fail = 'Attendance_Report_Fail',
    POST_TEACHER_ENGAGEMENT = 'POST_TEACHER_ENGAGEMENT',
    POST_TEACHER_ENGAGEMENT_SUCCESS = 'POST_TEACHER_ENGAGEMENT_SUCCESS',
    POST_TEACHER_ENGAGEMENT_FAIL = 'POST_TEACHER_ENGAGEMENT_FAIL',
    Grade_Comparison_Report = 'Grade_Comparison_Report',
    Grade_Comparison_Report_Success = 'Grade_Comparison_Report_Success',
    Grade_Comparison_Report_Fail = 'Grade_Comparison_Report_Fail',
    Today_Attendance_Report = 'Today_Attendance_Report',
    Today_Attendance_Report_Success = 'Today_Attendance_Report_Success',
    Today_Attendance_Report_Fail = 'Today_Attendance_Report_Fail',
    Grade_Subject_List = 'Grade_Subject_List',
    Grade_Subject_List_Success = 'Grade_Subject_List_Success',
    Grade_Subject_List_Fail = 'Grade_Subject_List_Fail',
    Reset_Data = 'Reset_Data',
    Reset_Data_Success = 'Reset_Data_Success'
    
}