export interface HomeStudyState{
        loading?: boolean;
        errors?: any;
        records?: any;
        items?: any,
        page?: number,
        per_page?: number,
        total?: number,
        isAuthenticated?: boolean | null;
        addControlType?: any;
        getInternalGradle?: any;
        getSubjectList?: any;
        getStudentList?: any;
        report_records?: any;
        report_items?:any;
        report_page?: any;
        report_per_page?: any;
        report_total?: any;
        report_errors?: any;
        searchValue?: any;
}


export enum HomeStudyTypes {
    CONTROL_PANEL = "CONTROL_PANEL",
    CONTROL_PANEL_SUCCESS = "CONTROL_PANEL_SUCCESS",
    CONTROL_PANEL_FAIL = "CONTROL_PANEL_FAIL",
    DELETE_CONTROL_PANEL_BY_ID = " DELETE_CONTROL_PANEL_BY_ID",
    DELETE_CONTROL_PANEL_BY_ID_SUCCESS = " DELETE_CONTROL_PANEL_BY_ID_SUCCESS",
    DELETE_CONTROL_PANEL_BY_ID_FAIL = " DELETE_CONTROL_PANEL_BY_ID_FAIL",
    ADD_CONTROL_PANEL = 'ADD_CONTROL_PANEL',
    ADD_CONTROL_PANEL_SUCCESS = 'ADD_CONTROL_PANEL_SUCCESS',
    ADD_CONTROL_PANEL_FAIL = 'ADD_CONTROL_PANEL_FAIL',
    EDIT_CONTROL_PANEL = 'EDIT_CONTROL_PANEL',
    EDIT_CONTROL_PANEL_SUCCESS = 'EDIT_CONTROL_PANEL_SUCCESS',
    EDIT_CONTROL_PANEL_FAIL = 'EDIT_CONTROL_PANEL_FAIL',
    FETCH_CONTROL_PANEL_BY_ID = 'FETCH_CONTROL_PANEL_BY_ID',
    FETCH_CONTROL_PANEL_BY_ID_SUCCESS = 'FETCH_CONTROL_PANEL_BY_ID_SUCCESS',
    FETCH_CONTROL_PANEL_BY_ID_FAIL = 'FETCH_CONTROL_PANEL_BY_ID_FAIL',
    HOME_STUDY_VIEW = 'HOME_STUDY_VIEW',
    HOME_STUDY_VIEW_SUCCESS = 'HOME_STUDY_VIEW_SUCCESS',
    HOME_STUDY_VIEW_FAIL = 'HOME_STUDY_VIEW_FAIL',
    SUBJECT_LIST = 'SUBJECT_LIST',
    SUBJECT_LIST_SUCCESS = 'SUBJECT_LIST_SUCCESS',
    SUBJECT_LIST_FAIL = 'SUBJECT_LIST_FAIL',
    SEARCH_VALUE = 'SEARCH_VALUE',
    SEARCH_VALUE_SUCCESS = 'SEARCH_VALUE_SUCCESS',
    SEARCH_VALUE_FAIL = 'SEARCH_VALUE_FAIL',
    RESET_HOME_STUDY = 'RESET_HOME_STUDY'
}