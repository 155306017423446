import React, { Component } from 'react'
import { connect } from 'react-redux'
import BreadCrumb from '../../components/BreadCrumb';
import { RouteComponentProps } from 'react-router';
import FeedBackView from './FeedBackView';
import { getTopicAnalysisReport } from '../../store/question/Actions';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import { stopYearData } from '../../store/profile/Actions';

interface PropsTypes  extends RouteComponentProps<OwnPropsParams> {
    getChangeYearData?:any;
	stopYearDate?:any;
    FeedbackDetails?:any;
	loading?: boolean;
    getTopicAnalysisReport:(postValue:any) => any;
    stopYearData:()=>any;
    getTopicsData?:any
}

export class FeedBack extends Component<PropsTypes> {
    componentDidMount(): void {
        this.getCurrectYear();
    }
      // This is life cycle is initial load  component Will Update
      componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYearDate} = this.props;
		if(stopYearDate === true){
			this.getCurrectYear(this.props.getChangeYearData)
		}
	}
    // This is life cycle is initial load  component Did Update
	componentDidUpdate() {
		const { stopYearDate } = this.props;
        if(stopYearDate === true){
			this.props.stopYearData()
		}
	}
    // This is function is used get correct year
	getCurrectYear(getYear?:any){
		if(getYear && getYear !== undefined && getYear !== null){
			this.getTopicDetails(getYear)
		}else {
			this.getTopicDetails(this.props.getChangeYearData)
		}
	}
    getTopicDetails(getYear?:any){
        const { FeedbackDetails } = this.props;
        const postValue:any = {
            academic_year: getYear,
            subject_id:FeedbackDetails.subject_id,
            topic: FeedbackDetails.feedback,
            school_id:FeedbackDetails.school_id
        }
        this.props.getTopicAnalysisReport(postValue)
    }
    render() {
        const {getTopicsData, FeedbackDetails, loading} = this.props;
        const loadingTextCSS = { display: loading ? "block" : "none" };
        return (
            <div>
                 <BreadCrumb 
                 titleName={['Topic']} 
                 homeName={['Home']} 
                 url={['dashboard']}
                 mainPageTitle={['Topic']}/>
                 <div className="row">
                                <div className="col-md-12">
                                    <div className="card-box pl-3 pr-3 pb-3">
                                    {getTopicsData ?
                                    <>
                                    <h3 className="color-pink titleCapitalize">{FeedbackDetails.feedback}</h3>
                                    <h5 className="titleCapitalize">{getTopicsData.subject_name}</h5>
                                    </>
                                    :null}
                                    </div>
                                </div>
                            </div>
                 <FeedBackView getTops={this.props.FeedbackDetails}/>
            <div style={loadingTextCSS}><SpinnerLoader /></div>
            </div>
        )
    }
}
interface OwnPropsParams {
    id: string;
}

const mapStateToProps = (state:any) => {
    return{
        loading: state.questionset.loading,
		getChangeYearData:state.profile.getYear,
		stopYearDate:state.profile.yearStatusFails,
        getTopicsData: state.questionset.topicAnalysisReport,
        FeedbackDetails:state.profile.FeedBackReport
    }
}


export default connect(mapStateToProps, {getTopicAnalysisReport, stopYearData})(FeedBack)
