import { ThunkAction } from "redux-thunk";
import { RootActions, RootState } from "../Index";
import { Dispatch } from "redux";
import { AxiosResponse } from "axios";
import { baseAPI } from "../../Service";
import toaster from "toasted-notes";
import { notificationMsg } from "../../services/Constants";
import { Assessment } from "../../services/Config";
import { AssessmentTypes } from "./Types";
import history from "../../History";

export type ThunkResult<R> = ThunkAction<R, RootState, undefined, RootActions>;

interface fetchAssessment {
    type: AssessmentTypes.FETCH_ASSESSMENT;
}

interface fetchAssesmentSuccess {
    type: AssessmentTypes.FETCH_ASSESSMENT_SUCCESS;
    payload: any;
}

interface fetchAssesmentFail {
    type: AssessmentTypes.FETCH_ASSESSMENT_FAIL;
}

export const fetchAssesment = (params: any): ThunkResult<void> => async dispatch => {
    handleFetchAssesment(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.patch(Assessment.AssesmentView, null, {
                params: params,
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            if (response.data.status === true) {
                handleFetchAssesmentSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleFetchAssesmentFail(dispatch);
            }
    } catch (e) {
        handleFetchAssesmentFail(dispatch);
    }
};

export const handleFetchAssesment = (dispatch: Dispatch<fetchAssessment>) => {
    dispatch({ type: AssessmentTypes.FETCH_ASSESSMENT });
};

export const handleFetchAssesmentSuccess = (
    dispatch: Dispatch<fetchAssesmentSuccess>,
    response: any
) => {
    dispatch({
        type: AssessmentTypes.FETCH_ASSESSMENT_SUCCESS,
        payload: response,
        records: response.data.records,
        per_page: response.data.per_page,
        page: response.data.page,
        total: response.data.total
    });
};

export const handleFetchAssesmentFail = (dispatch: Dispatch<fetchAssesmentFail>) => {
    dispatch({
        type: AssessmentTypes.FETCH_ASSESSMENT_FAIL
    });
};

interface createAssessment {
    type: AssessmentTypes.CREATE_ASSESSMENT;
}

interface createAssessmentSuccess {
    type: AssessmentTypes.CREATE_ASSESSMENT_SUCCESS;
    payload: any;
}

interface createAssessmentFail {
    type: AssessmentTypes.CREATE_ASSESSMENT_FAIL;
}

export const createAssessmentpost = (params: any): ThunkResult<void> => async dispatch => {
    handleCreateAssessment(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.post(Assessment.AssessmentCreate, params, {
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            if (response.data.status === true) {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.duration
                });
                history.push("/assessment");
                handleCreateAssessmentSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleCreateAssessmentFail(dispatch);
            }
    } catch (e) {
        handleCreateAssessmentFail(dispatch);
    }
};

export const handleCreateAssessment = (dispatch: Dispatch<createAssessment>) => {
    dispatch({ type: AssessmentTypes.CREATE_ASSESSMENT });
};

export const handleCreateAssessmentSuccess = (
    dispatch: Dispatch<createAssessmentSuccess>,
    response: any
) => {
    dispatch({
        type: AssessmentTypes.CREATE_ASSESSMENT_SUCCESS,
        payload: response,
    });
};

export const handleCreateAssessmentFail = (dispatch: Dispatch<createAssessmentFail>) => {
    dispatch({
        type: AssessmentTypes.CREATE_ASSESSMENT_FAIL
    });
};

interface editAssessment {
    type: AssessmentTypes.EDIT_ASSESSMENT;
}

interface editAssessmentSuccess {
    type: AssessmentTypes.EDIT_ASSESSMENT_SUCCESS;
    payload: any;
}

interface editAssessmentFail {
    type: AssessmentTypes.EDIT_ASSESSMENT_FAIL;
}

export const editAssessmentDetails = (params: any): ThunkResult<void> => async dispatch => {
    handleEditAssessment(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.put(Assessment.AssessmentEdit+`?uid=${params.uid}`, params, 
            {
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            if (response.data.status === true) {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.duration
                });
                history.push("/assessment");
                handleEditAssessmentSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleEditAssessmentFail(dispatch);
            }
    } catch (e) {
        handleEditAssessmentFail(dispatch);
    }
};

export const handleEditAssessment = (dispatch: Dispatch<editAssessment>) => {
    dispatch({ type: AssessmentTypes.EDIT_ASSESSMENT});
};

export const handleEditAssessmentSuccess = (
    dispatch: Dispatch<editAssessmentSuccess>, 
                response: any
) => {
    dispatch({ type: AssessmentTypes.EDIT_ASSESSMENT_SUCCESS,
                payload: response});
};

export const handleEditAssessmentFail = (dispatch: Dispatch<editAssessmentFail>) => {
    dispatch({ type: AssessmentTypes.EDIT_ASSESSMENT_FAIL});
};

interface deleteAssessment {
    type:AssessmentTypes.DELETE_ASSESSMENT_BY_ID;
}

interface deleteAssessmentSuccess { 
    type:AssessmentTypes.DELETE_ASSESSMENT_SUCCESS_BY_ID;
}

interface deleteAssessmentFail {
    type:AssessmentTypes.DELETE_ASSESSMENT_FAIL_BY_ID;
}

export const deleteAssessmentById = (id: any): ThunkResult<void> => async dispatch => {
    handleDeleteAssessmentById(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.delete(Assessment.AssessmentDelete+`?uid=${id}`,
            { headers: { "Authorization": localStorage.getItem('token') } });
        const getResponse = JSON.parse(JSON.stringify(response.data));
        if (getResponse.status === true) {
            toaster.notify(getResponse.message, {
                position: 'top',
                duration: notificationMsg.duration
            });
            handleDeleteAssessmentByIdSuccess(dispatch, response.data);
        } else {
            toaster.notify(getResponse.message, {
                position: 'top',
                duration: notificationMsg.errorNotificationDuration
            });
            handleDeleteAssessmentByIdFail(dispatch);
        }

    } catch (e) {
        handleDeleteAssessmentByIdFail(dispatch);
    }
};

const handleDeleteAssessmentById = (dispatch: Dispatch<deleteAssessment>) => {
    dispatch({ type: AssessmentTypes.DELETE_ASSESSMENT_BY_ID });
};

const handleDeleteAssessmentByIdSuccess = (
    dispatch: Dispatch<deleteAssessmentSuccess>,
    response: any
) => {
    dispatch({ type: AssessmentTypes.DELETE_ASSESSMENT_SUCCESS_BY_ID, payload: response });
};

const handleDeleteAssessmentByIdFail = (dispatch: Dispatch<deleteAssessmentFail>) => {
    dispatch({ type: AssessmentTypes.DELETE_ASSESSMENT_FAIL_BY_ID });
};

interface fetchAssesmentQuestion {
    type:AssessmentTypes.FETCH_QUESTION_ASSESSMENT_BY_ID;
}

interface fetchAssesmentQuestionSuccess {
    type: AssessmentTypes.FETCH_QUESTION_ASSESSMENT_BY_ID_SUCCESS;
    payload: any;
}

interface fetchAssesmentQuestionFail {
    type: AssessmentTypes.FETCH_QUESTION_ASSESSMENT_BY_ID_FAIL;
}

export const fetchAssessmentQuestionById = (question_paper_id: number): ThunkResult<void> => async dispatch => {
    handleFetchAssessmentQuestionById(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(Assessment.AssessmentQuestionFetch+`?question_paper_id=${question_paper_id}`, {
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            if (response.data.status === true) {
                handleFetchAssessmentQuestionByIdSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleFetchAssessmentQuestionByIdFail(dispatch);
            }
    } catch (e) {
        handleFetchAssessmentQuestionByIdFail(dispatch);
    }
};

export const handleFetchAssessmentQuestionById = (dispatch: Dispatch<fetchAssesmentQuestion>) => {
    dispatch({ type: AssessmentTypes.FETCH_QUESTION_ASSESSMENT_BY_ID });
};

export const handleFetchAssessmentQuestionByIdSuccess = (
    dispatch: Dispatch<fetchAssesmentQuestionSuccess>,
    response: any
) => {
    dispatch({
        type: AssessmentTypes.FETCH_QUESTION_ASSESSMENT_BY_ID_SUCCESS,
        payload: response,
        // getExamTypeEdit: response.data
    });

};

export const handleFetchAssessmentQuestionByIdFail = (dispatch: Dispatch<fetchAssesmentQuestionFail>) => {
    dispatch({
        type: AssessmentTypes.FETCH_QUESTION_ASSESSMENT_BY_ID_FAIL
    });
};

interface fetchAssesmentStudentList{
    type:AssessmentTypes.FETCH_ASSESSMENT_STUDENTLIST;
}

interface fetchAssesmentStudentListSuccess{
    type:AssessmentTypes.FETCH_ASSESSMENT_STUDENTLIST_SUCCESS;
    payload: any;
}

interface fetchAssesmentStudentListFail{
    type:AssessmentTypes.FETCH_ASSESSMENT_STUDENTLIST_FAIL;
}

export const fetchAssessmentStudentListGet = (question_paper_id: number, class_id: number): ThunkResult<void> => async dispatch => {
    handleAssessmentStudentList(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(Assessment.GetAssessmentStudentList+`?question_paper_id=${question_paper_id}&class_id=${class_id}`,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            handleAssessmentStudentListSuccess(dispatch, response.data);
    } catch (e) {
        handleAssessmentStudentListFail(dispatch);
    }
};

export const handleAssessmentStudentList = (dispatch: Dispatch<fetchAssesmentStudentList>) => {
    dispatch({ type: AssessmentTypes.FETCH_ASSESSMENT_STUDENTLIST });
};

export const handleAssessmentStudentListSuccess = (
    dispatch: Dispatch<fetchAssesmentStudentListSuccess>,
    response: any
) => {
    dispatch({
        type: AssessmentTypes.FETCH_ASSESSMENT_STUDENTLIST_SUCCESS,
        payload: response,
        getAssessmentStudents: response.data
    });

};

export const handleAssessmentStudentListFail = (dispatch: Dispatch<fetchAssesmentStudentListFail>) => {
    dispatch({
        type: AssessmentTypes.FETCH_ASSESSMENT_STUDENTLIST_FAIL
    });
};

interface getAssessment{
    type: AssessmentTypes.GET_ASSESSMENT;
}
interface getAssessmentSuccess{
    type: AssessmentTypes.GET_ASSESSMENT_SUCCESS;
    payload: any;
}
interface getAssessmentFail{
    type:AssessmentTypes.GET_ASSESSMENT_FAIL;
}

export const getAssessmentByID = (uid: number): ThunkResult<void> => async dispatch => {
    handleGetAssessmentById(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(Assessment.GetAssessment+`?uid=${uid}`, {
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            if (response.data.status === true) {
                handleGetAssessmentByIdSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleGetAssessmentByIdFail(dispatch);
                history.push('/assessment')
            }
    } catch (e) {
        handleGetAssessmentByIdFail(dispatch);
        history.push('/assessment')
    }
};

export const handleGetAssessmentById = (dispatch: Dispatch<getAssessment>) => {
    dispatch({ type: AssessmentTypes.GET_ASSESSMENT });
};

export const handleGetAssessmentByIdSuccess = (
    dispatch: Dispatch<getAssessmentSuccess>,
    response: any
) => {
    dispatch({
        type: AssessmentTypes.GET_ASSESSMENT_SUCCESS,
        payload: response,
        getAssessmentDetails: response.data
    });

};

export const handleGetAssessmentByIdFail = (dispatch: Dispatch<getAssessmentFail>) => {
    dispatch({
        type: AssessmentTypes.GET_ASSESSMENT_FAIL
    });
};
export type AssessmentAction = | fetchAssesmentSuccess