import * as yup from 'yup';
import { questionSetValidation } from '../../services/Constants';
export const QuestionValidation = yup.object({
    question_name: yup.string()
    .required('Please enter the Question Set Name'),
    select_class: yup.string()
    .required('Please select the Grade'),
    select_subject: yup.string()
    .required('Please select the Subject'),
    question: yup.string()
    .required('Please enter the Question'),
    add_topics: yup.string()
    .required('Please enter the Topics'),
    question_name_A: yup.string()
    .required('Please enter the  Option A'),
    question_name_B: yup.string()
    .required('Please enter the  Option B'),
    question_name_C: yup.string()
    .required('Please enter the  Option C'),
    question_name_D: yup.string()
    .required('Please enter the Option D'),
    answer: yup.string()
    .required('Please select the Correct Answer')
})


export const  validationSchemaTest = yup.object().shape({
    question_name: yup.string()
    .required('Please enter the Question Set Name')
    .max(50, questionSetValidation.questionMsg)
    .min(3, questionSetValidation.questionMinMsg),
    select_class: yup.string()
    .required('Please select the Grade'),
    select_subject: yup.string()
    .required('Please select the Subject'),
  questionlist: yup.array().of(
    yup.object().shape({
      question: yup.string()
        .required('Please enter the Question')
        .max(1000, questionSetValidation.questionMaxMsg),
      add_topics: yup.string()
        .max(1000, "Add Topics should not be more than 1000 characters")
        .required('Please Add Topics'),
      question_name_A: yup.string()
        .required('Please enter Option A')
        .max(1000, questionSetValidation.optionMsg)
        .test('is-unique', 'Option A must be unique', function (value) {
          return (
            value !== this.parent.question_name_B &&
            value !== this.parent.question_name_C &&
            value !== this.parent.question_name_D
          );
        }),
      question_name_B: yup.string()
        .required('Please enter Option B')
        .max(1000, questionSetValidation.optionMsg)
        .test('is-unique', 'Option B must be unique', function (value) {
          return (
            value !== this.parent.question_name_A &&
            value !== this.parent.question_name_C &&
            value !== this.parent.question_name_D
          );
        }),
      question_name_C: yup.string()
        .required('Please enter Option C')
        .max(1000, questionSetValidation.optionMsg)
        .test('is-unique', 'Option C must be unique', function (value) {
          return (
            value !== this.parent.question_name_A &&
            value !== this.parent.question_name_B &&
            value !== this.parent.question_name_D
          );
        }),
      question_name_D: yup.string()
        .required('Please enter Option D')
        .max(1000, questionSetValidation.optionMsg)
        .test('is-unique', 'Option D must be unique', function (value) {
          return (
            value !== this.parent.question_name_A &&
            value !== this.parent.question_name_B &&
            value !== this.parent.question_name_C
          );
        }),
      answer: yup.string()
        .required('Please choose the Correct Answer'),
    })
  )
});
    

    export const UpdateQuestionValidation = yup.object({
        question_name: yup.string()
        .required('Please enter the Question Set Name')
        .max(50, questionSetValidation.questionMsg)
        .min(3, questionSetValidation.questionMinMsg),
        select_class: yup.string()
        .required('Please select the Grade'),
        select_subject: yup.string()
        .required('Please select the Subject'),
    })