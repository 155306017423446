import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Bar } from 'react-chartjs-2';
import { fetchGetAllClassList } from '../../store/diary/Actions';
import { getTopicAnalysisReport } from '../../store/question/Actions';
import { getTeacherNameList } from '../../store/teacher/Actions';
import { GetStudentNameList } from '../../store/student/Actions';
import history from '../../History';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import { fetchProfilePost, stopYearData } from '../../store/profile/Actions';
import {GetAllTopicClassList} from '../../store/classes/Actions';
import { optionFeedback } from '../../components/chartoption/ChartCommonFun';
import CommonLoader from '../../components/CommonLoader';

interface PropsTypes { 
    getChangeYearData?:any;
	stopYearDate?:any;
    getTops?:any;
    FeedbackDetails?:any;
    fetchGetAllClassList: (postPage: any) => any;
    getTopicAnalysisReport:(postValue:any) => any;
    GetStudentNameList:(postValue?:any) => any;
    getTeacherNameList:() => any;
    fetchProfilePost:() => any;
    GetAllTopicClassList:(postValue?:any) =>any;
    stopYearData:()=>any;
    getStudentDetails:any;
	getAllClassList?:any;
	loading?: boolean;
    loginProfile?: any;
    getTopicsData?:any;
    teacherNameList?:any;
}
interface StateTypes {
    class_id?:any,
    teacher_name?:any,
    student_name?:any,
    viewFeedBack?:any,
    teacherRole?:any;
    studentCardId?:any,
    teacherId?:any,
    subjectLabel?:any,
    academicYear?:any
    }
export class FeedBackView extends Component<PropsTypes, StateTypes> {
    constructor(props: any) {
        super(props);
        this.state = {
            class_id: this.props.getTops.card_id || null,
            teacher_name:'',
            student_name:'',
            teacherRole:false,
            viewFeedBack: this.props.getTops || null,
            studentCardId:'',
            teacherId:this.props.getTops.teacher_id || null,
            subjectLabel:[],
            academicYear:null
        }
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.getCurrectYear()        
    }
     // This is life cycle is initial load  component Will Update
     componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYearDate} = this.props;
		if(stopYearDate === true){
			this.getCurrectYear(this.props.getChangeYearData)
		}
	}
    // This is life cycle is initial load  component Did Update
	componentDidUpdate() {
		const { stopYearDate } = this.props;
        if(stopYearDate === true){
			this.props.stopYearData()
		}
	}
    // This is function is used get correct year
	getCurrectYear(getYear?:any){
		if(getYear && getYear !== undefined && getYear !== null){
			this.getFeedback(getYear)
			this.setState({academicYear:getYear})
		}else {
			this.getFeedback(this.props.getChangeYearData)
			this.setState({academicYear:this.props.getChangeYearData})
		}
	}
getFeedback(getYear?:any){
    this.props.fetchProfilePost().then((res:any)=>{
        const { loginProfile } = this.props;
        if(loginProfile){
            if(loginProfile.usertype === 1){
                this.props.getTeacherNameList();
                this.setState({teacherRole:false})
                this.getAdacemicStudent(getYear)
                this.getAcademicFeedBackDetails(getYear)
            }else if(loginProfile.usertype === 2){
                this.setState({teacherRole:true})
                this.getClassList(getYear);
                this.getSchoolFeedBackDetails()
            }else if(loginProfile.usertype === 3){
                this.setState({teacherRole:false})
                this.getClassList(getYear)
                this.getStudentNameList();
                this.getTeacherFeedBackDetails(getYear)
            }
        }
    })
}
    getAdacemicStudent(getYear?:any){
        const {viewFeedBack} = this.state;
        const loadMoreType:any = {
			academic_year:getYear,
            school_id:viewFeedBack.school_id || undefined,
            subject_id:viewFeedBack.subject_id,
            topic:viewFeedBack.feedback
		  }
        this.props.GetAllTopicClassList(loadMoreType).then((res:any)=>{
            const { getTopicsData } = this.props;
            let subjectLabel:any = [];
            if(getTopicsData &&  getTopicsData !== null && getTopicsData !== undefined){
                subjectLabel = [];
                this.setState({subjectLabel:subjectLabel})
                if(getTopicsData.instant_Feedback){
                    getTopicsData.instant_Feedback.forEach((item:any)=>{
                        subjectLabel.push(item.label)
                    })
                }
            }else {
                this.setState({subjectLabel:[]})
            }
        });
        if(viewFeedBack.class_id){
            const postValue:any = {
                academic_year:getYear,
                class_id:viewFeedBack.class_id,
                school_id:viewFeedBack.school_id
            }
            this.props.GetStudentNameList(postValue)
        }
    }
    // this is function called academic admin role
    getAcademicFeedBackDetails(getYear?:any){
        const {viewFeedBack} = this.state;
        let postValue:any;
        if(viewFeedBack !== undefined || viewFeedBack !== null){
            if(viewFeedBack){
                postValue = null;
                if(viewFeedBack.pageName === "View Instant Feedback" || viewFeedBack.pageName === "View Quizzes") {
                    postValue = {
                        academic_year:getYear,
                        subject_id:viewFeedBack.subject_id,
                        topic: viewFeedBack.feedback,
                        school_id:viewFeedBack.school_id
                    }
                    this.setState({class_id:'', studentCardId:'',
                    teacherId:''})
                }else if(viewFeedBack.pageName === "View Student") {
                    postValue = {
                        academic_year:getYear,
                        subject_id:viewFeedBack.subject_id,
                        topic:viewFeedBack.feedback,
                        class_id:viewFeedBack.class_id,
                        card_id:viewFeedBack.card_id,
                        school_id:viewFeedBack.school_id
                    }
                    this.getStudentNameList(viewFeedBack.class_id)
                    this.setState({class_id:viewFeedBack.class_id, studentCardId:viewFeedBack.card_id,
                        teacherId:''})
                }else if(viewFeedBack.pageName === "Teacher") {
                    postValue = {
                        academic_year:getYear,
                        subject_id:viewFeedBack.subject_id,
                        topic: viewFeedBack.feedback,
                        teacher_id:viewFeedBack.teacher_id,
                        school_id:viewFeedBack.school_id
                    }
                    this.props.getTeacherNameList();
                    this.setState({class_id:viewFeedBack.class_id || undefined, studentCardId:viewFeedBack.card_id || undefined,
                        teacherId:viewFeedBack.teacher_id})
                }
            }
        }
        if(postValue !== undefined || postValue !== null){
            if(postValue){
                this.props.getTopicAnalysisReport(postValue)
            }
        }
    }
    // this is function called School admin role
    getSchoolFeedBackDetails(){
        const {viewFeedBack, academicYear} = this.state;
        let postValue:any;
        if(viewFeedBack !== undefined || viewFeedBack !== null){
            if(viewFeedBack){
                postValue = null;
                if(viewFeedBack.pageName === 'View Instant Feedback' || viewFeedBack.pageName === 'View Quizzes') {
                    postValue = {
                        academic_year: academicYear,
                        subject_id:viewFeedBack.subject_id,
                        topic: viewFeedBack.feedback
                    }
                    this.setState({class_id:'', studentCardId:'',
                    teacherId:''})
                }else if(viewFeedBack.pageName === 'View Student') {
                    postValue = {
                        academic_year: academicYear,
                        subject_id:viewFeedBack.subject_id,
                        topic: viewFeedBack.feedback,
                        class_id:viewFeedBack.class_id,
                        card_id:viewFeedBack.card_id
                    }
                    this.getStudentNameList(viewFeedBack.class_id)
                    this.setState({class_id:viewFeedBack.class_id, studentCardId:viewFeedBack.card_id,
                        teacherId:''})
                }else if(viewFeedBack.pageName === 'Teacher') {
                    postValue = {
                        academic_year: academicYear,
                        subject_id:viewFeedBack.subject_id,
                        topic: viewFeedBack.feedback,
                        teacher_id:viewFeedBack.teacher_id
                    }
                    this.props.getTeacherNameList();
                    this.setState({class_id:viewFeedBack.class_id || undefined, studentCardId:viewFeedBack.card_id || undefined,
                        teacherId:viewFeedBack.teacher_id})
                }
            }
        }
        if(postValue !== undefined || postValue !== null){
            if(postValue){
                this.props.getTopicAnalysisReport(postValue)
            }
        }
    }
        // This is function called Teacher admin role
        getTeacherFeedBackDetails(getYear?:any){
        const {viewFeedBack} = this.state;
        let postValue:any;
        if(viewFeedBack !== undefined || viewFeedBack !== null){
            if(viewFeedBack){
                postValue = null;
                if(viewFeedBack.pageName === 'View Instant Feedback' || viewFeedBack.pageName === 'View Quizzes') {
                    postValue = {
                        academic_year:getYear,
                        subject_id:viewFeedBack.subject_id,
                        topic: viewFeedBack.feedback
                    }
                    this.setState({class_id:'', studentCardId:'',
                    teacherId:''})
                }else if(viewFeedBack.pageName === 'View Student') {
                    postValue = {
                        academic_year:getYear,
                        subject_id:viewFeedBack.subject_id,
                        topic: viewFeedBack.feedback,
                        class_id:viewFeedBack.class_id,
                        card_id:viewFeedBack.card_id
                    }
                    this.getStudentNameList(viewFeedBack.class_id)
                    this.setState({class_id:viewFeedBack.class_id, studentCardId:viewFeedBack.card_id,
                        teacherId:''})
                }else if(viewFeedBack.pageName === 'Teacher') {
                    postValue = {
                        academic_year:getYear,
                        subject_id:viewFeedBack.subject_id,
                        topic: viewFeedBack.feedback,
                        teacher_id:viewFeedBack.teacher_id
                    }
                    this.props.getTeacherNameList();
                    this.setState({class_id:viewFeedBack.class_id || undefined, studentCardId:viewFeedBack.card_id || undefined,
                        teacherId:viewFeedBack.teacher_id})
                }
            }
        }
        if(postValue !== undefined || postValue !== null){
            if(postValue){
                this.props.getTopicAnalysisReport(postValue)
            }
        }
        }

        // This is function use to called class name list
        getClassList(getYear?:any){
            const {viewFeedBack} = this.state;
            const loadMoreType:any = {
                academic_year:getYear,
                school_id:viewFeedBack.school_id || undefined,
                subject_id:viewFeedBack.subject_id,
                topic:viewFeedBack.feedback
              }
            this.props.GetAllTopicClassList(loadMoreType).then((res:any)=>{
                const { getTopicsData } = this.props;
                let subjectLabel:any = [];
                if(getTopicsData &&  getTopicsData !== null && getTopicsData !== undefined){
                    subjectLabel = [];
                    this.setState({subjectLabel:subjectLabel})
                    if(getTopicsData.instant_Feedback){
                        getTopicsData.instant_Feedback.forEach((item:any)=>{
                            subjectLabel.push(item.label)
                        })
                    }
                }else {
                    this.setState({subjectLabel:[]})
                }
            });
        }
        getInstantFeedBackResult = (getValue:any) =>{
            if(getValue.type === 'Instant Feedback'){
                history.push({
                pathname: `/view_instant_feedback/${getValue.id}`,
              });
            } else  if(getValue.type === 'Quiz'){
                history.push({
                    pathname: `/quizzes_report/${getValue.id}`,
                  });
            }
        }
        getfilterClasses = (e:any) =>{
            const{ teacherId, student_name, viewFeedBack, academicYear } = this.state;
        const { value } = e.target;
        let postValue:any = {
            academic_year:academicYear,
            subject_id:viewFeedBack.subject_id,
            topic: viewFeedBack.feedback,
            school_id:viewFeedBack.school_id || undefined,
            class_id:value || undefined,
            teacher_id:teacherId || undefined,
            card_id:student_name || undefined
        }
        this.props.getTeacherNameList();
        this.props.getTopicAnalysisReport(postValue)
            this.getStudentNameList(value)
            this.setState({class_id: value})
        // }
        }
           // This is the function used to called student name list
      getStudentNameList(getClassId?:any){
        const { class_id, academicYear } = this.state;
        if(getClassId){
            const postValue:any = {
                academic_year:academicYear,
                class_id: getClassId
            }
            this.props.GetStudentNameList(postValue)
        } else {
            const postValue:any = {
                academic_year:academicYear,
                class_id:class_id || getClassId
            }
            this.props.GetStudentNameList(postValue)
        }
    }
    // This is function used to filete student topic report
        getfilterStudent = (e:any) =>{
            const{ viewFeedBack, teacherId, class_id, academicYear } = this.state;
        const { value } = e.target;
        const postValue:any = {
            academic_year:academicYear,
            subject_id:viewFeedBack.subject_id || undefined,
            topic: viewFeedBack.feedback || undefined,
            school_id:viewFeedBack.school_id || undefined,
            class_id:class_id || undefined,
            teacher_id:teacherId || undefined,
            card_id:value || undefined,
        }
        this.props.getTopicAnalysisReport(postValue)
        this.setState({studentCardId: value})
        }
        // This is the function used to teacher relative data 
        getfilterTeacher = (e:any) =>{
            const{ viewFeedBack, student_name, class_id, academicYear } = this.state;
        const { value } = e.target;
        const postValue:any = {
            academic_year:academicYear,
            subject_id:viewFeedBack.subject_id || undefined,
            topic: viewFeedBack.feedback || undefined,
            school_id:viewFeedBack.school_id || undefined,
            class_id:class_id || undefined,
            teacher_id:value || undefined,
            card_id:student_name || undefined
        }
        this.props.getTopicAnalysisReport(postValue)
        this.getStudentNameList();
        this.setState({teacherId: value})
        }

        datasetKeyProvider=()=>{ 
            return Math.random()
        } 
   
        
        render() {
            const { getAllClassList, getTopicsData, teacherNameList, getStudentDetails, loading } = this.props;
            const { teacherRole, viewFeedBack } = this.state;
            const loadingTextCSS = { display: loading ? "block" : "none" };
            const teacherDropdown = {display: teacherRole?"block":"none"};
            return (
                <div>
                    <div className="row">
                    <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12 col-12">
                    <div className="card card-topline-red">
                        <div className="row mt-3 ml-2 mr-2">
                        {getAllClassList && getAllClassList !== null && getAllClassList !== undefined && getAllClassList.length > 0?
                            <>
                            <div className="col-md-4">
                            <div className="form-group">
                            
                            <label>Class</label>
                            <select name="className" id="" className="form-control" value={this.state.class_id} onChange={this.getfilterClasses}>
                                <option selected className="selecttag"> Select... </option>
                                {getAllClassList.map((items:any)=>(
                                    <option value={items.id}> {items.grade_standard} </option>
                                ))}
                            </select>
                            </div>
                            </div>
                            </>
                            :null}
                            {this.state.teacherId && teacherNameList !== undefined?
                            <>
                            {teacherNameList && teacherNameList !== null && teacherNameList !== undefined && teacherNameList.length > 0?
                            <div className="col-md-4" style={teacherDropdown}>
                            <div className="form-group">
                            <label>Teacher Name</label>
                            <select name="teacherName" id="" className="form-control" value={this.state.teacherId} onChange={this.getfilterTeacher}>
                            <option selected className="selecttag"> Select... </option>
                            {teacherNameList.map((items:any)=>(
                                <option value={items.ldap_id}> {items.firstname} {items.lastname} </option>
                            ))}
                            </select>
                            </div>
                            </div>
                            :null}
                            </>
                            :null}
                            {this.state.class_id && getStudentDetails !== undefined?
                            <>
                            {getStudentDetails && getStudentDetails !== null && getStudentDetails !== undefined && getStudentDetails.length > 0?
                            <>
                            <div className="col-md-4">
                            <div className="form-group">
                            <label>Student Name</label>
                            <select name="StudentName" id="" className="form-control" value={this.state.studentCardId} onChange={this.getfilterStudent}>
                            <option selected className="selecttag"> Select... </option>
                            {getStudentDetails.map((items:any)=>(
                                <option value={items.card_id}> {items.student_name} </option>
                            ))}
                            </select>
                            </div>
                            </div>
                            </>
                            :null}
                            </>
                            :null}
                        </div>
                        <div className="col-md-12">
                            {getAllClassList && getAllClassList !== null && getAllClassList !== undefined && getAllClassList.length > 0?
                         
                            <div className="feedbackcharbar ml-2">
                             { getAllClassList.map((items:any)=>(
                            <>
                            <span className="mr-2"><i className="fa fa-circle" aria-hidden="true"></i></span><span className="mr-2">{items.grade_standard}</span>
                            </> 
                            ))}
                            </div>
                           
                            :null}
                        </div>
                        {getTopicsData && getTopicsData.instant_Feedback &&  getTopicsData.instant_Feedback.length ?
                                <div className="card-body no-padding height-9">
                                    <div className="row">
                                        <Bar
                                            data={{
                                                labels: [
                                                  "Correct",
                                                  "Incorrect",
                                                  "Missing"
                                                ],
                                                datasets: getTopicsData.instant_Feedback
                                              }}
                                            height={85}
                                            options={optionFeedback}
                                            datasetKeyProvider={this.datasetKeyProvider}
                                        />
                                    </div>
                                </div>
                                :<CommonLoader/>}
                            </div>
                    </div>
                    {getTopicsData?
                    <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12 col-12">
                    <div className="card card-box">
                        <div className="cardBoxSize mt-5 mb-3">
                            <p>Overall Performance</p>
                            <h3>{getTopicsData.over_all_performance}</h3>               
                        </div>
                        <div className="cardBoxSize mt-5 mb-5">
                        <p>No. of times Asked</p>
                        <h3>{getTopicsData.no_of_times_asked}</h3> 
                        </div>
                    </div>
                    </div>
                    :null}
                    </div>
                    {getTopicsData?
                    <div className="row">
                        <div className="col-md-2">
                        </div>
                        <div className="col-md-7 mb-3">
                        {getTopicsData.questions?
                        getTopicsData.questions.map((items:any)=>(
                        <div className="card card-topline-red">
                            <div className="card-body">
                            <div className="row">
                            <div className="col-md-11">
                                {items.type === "Quiz"?
                                <h4 className="mb-1">{items.question_name}</h4>
                                :
                                <h4 className="mb-1">{items.name}</h4>}
                            <h6>({items.type})</h6>
                            <hr className="mt-3 mb-2"/>
                            {items.student_answered && items.student_answered !== undefined && viewFeedBack.pageName === 'View Student'?
                                <div className="mb-3 mt-2">
                                    <h5><strong>Student Answered</strong> <span style={{color:items.student_answered_color}}>{items.student_answered}</span></h5>
                                </div>
                            :null}
                            <h5><strong >Correct answer is</strong> <span style={{color:'#318f94'}}>{items.correct_ans}</span></h5>
                            <hr className="mt-3 mb-2"/>
                            <p>{items.class_name} : Class Performance</p>
                            </div>
                            <div className="col-md-1">
                                <button className="btn btn-circle btn-pink pull-right"  
                                onClick={()=> this.getInstantFeedBackResult(items)}>view</button>
                            </div>
                            </div>
                            <div className="progress progressbar mt-3">
                            <div className="progress-bar pragressBarGood" role="progressbar" 
                            style={{width: items.correct_performance.performance, height:'15px', background: items.correct_performance.color_code}}>
                                {items.correct_performance.performance} ({items.correct_performance.student_count})</div>
                            <div className="progress-bar pragressBarMiddel" role="progressbar" 
                            style={{width: items.incorrect_performance.performance, height:'15px', background: items.incorrect_performance.color_code}}>
                            {items.incorrect_performance.performance} ({items.incorrect_performance.student_count})</div>
                            <div className="progress-bar pragressBarwid" role="progressbar" 
                            style={{width: items.not_answered.performance, height:'15px', background: items.not_answered.color_code}}>
                                {items.not_answered.performance} ({items.not_answered.student_count})</div>
                            </div>
                            </div>
                        </div>
                        ))                    
                        :null}
                        </div>
                        <div className="col-md-3">
    
                        </div>
                    </div>
                    :null}
                    <div style={loadingTextCSS}><SpinnerLoader /></div>
                </div>
            )
        }
    
}

export const option = {
    legend:{
        display:false
 },
    title: {
        display: true,
        text: ''
    },
    scales : {
        yAxes: [{
            ticks: {
              beginAtZero: true,
              steps: 10,
              stepValue: 5,
              userCallback: function(label:any, index:any, labels:any) {
                  if (Math.floor(label) === label) {
                      return label;
                  }
              }
            },
            scaleLabel: {
                display: true,
                labelString: 'Performance (%)'
              }
          }],
        xAxes : [ {
            barPercentage:0.1,
            barThickness: 10,
            gridLines : {
                display : false
            },
            scaleLabel: {
                display: true,
                labelString: 'Classes'
              }
        } ]
    }
  }
const mapStateToProps = (state:any) => {
    return {
        loading: state.questionset.loading,
		getChangeYearData:state.profile.getYear,
		stopYearDate:state.profile.yearStatusFails,
        FeedbackDetails:state.profile.FeedBackReport,
        teacherNameList:state.teacher.GetTeacherName,
        getStudentDetails:state.student.getStudentNameList,
        getAllClassList:state.classes.topicClassList,
        loginProfile:state.profile.profileData,
        getTopicsData: state.questionset.topicAnalysisReport
    }
}


export default connect(mapStateToProps, {fetchGetAllClassList, 
    getTopicAnalysisReport, getTeacherNameList, stopYearData, GetStudentNameList, 
    fetchProfilePost, GetAllTopicClassList})(FeedBackView)
    
