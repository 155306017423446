import React from 'react';
import { connect } from 'react-redux';
import { fetchSchoolsPost, fetchSchool, deletePost, addSchool } from '../../store/school/Actions'
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb'
import history from '../../History';
import { UserRoles } from '../../services/Constants'
import { LoadMoreType } from '../../components/type';
import InfiniteScroll from 'react-infinite-scroll-component';
import SpinnerLoader from '../../components/spinner/SpinnerLoader'
import CommonLoader from '../../components/CommonLoader';
import Switch from '@material-ui/core/Switch';
import { formValidationPatten } from '../../services/Constants';
import {ISearchBarState} from './Types';
import {CommonModel} from '../../components/CommonModel';
import { stopYearData } from '../../store/profile/Actions'

export interface PostsListProps {
  loading: boolean;
  getYear?:any,
  loginProfile:any;
  schoolDetails: any;
  schoolDetailDelete: any;
  deleteDetails: any;
  fetchSchoolsPost: (loadMoreType: LoadMoreType) => any;
  deletePost: (schoolDetailDelete: any) => void;
  addSchool: (school: any) => void;
	stopYearData:() => any;
  page: number;
  per_page: number;
  totalPage: number;
  records: any;
  total: number;
  stopYear?:any;
}

class ViewSchoolDetails extends React.Component<PostsListProps, ISearchBarState> {
  constructor(props: any) {
    super(props);
    this.state = {
      show: false,
      showDelete: false,
      addShow: false,
      acadamicAdmin: false,
      schoolAdmin: false,
      bothData: false,
      teacher: false,
      parent: false,
      deleteSchoolId: [],
      query: '',
      schoolData: [],
      subjectsTotal: 1,
      hasMore: true,
      prev: 0,
      next: 0,
      acsOrder: true,
      descOrder: false,
      page: 1,
      search: '',
      SortOrderData: '',
      OrderNameData: '',
      postData:{},
      sort_by:'desc',
      sort_class_name:'fa fa-sort-amount-asc',
      sort_title_name:'Ascending order',
      academicYear:null
    };

  }
  componentDidMount(): void {
    window.scrollTo(0, 0);  
    this.getUpdateYear(); 
    this.checkUserType();   
  }
  componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYear} = this.props;
		if(stopYear === true){
            this.getUpdateYear(this.props.getYear)
		}
	}
	componentDidUpdate() {
		const {stopYear, deleteDetails} = this.props;
    if(deleteDetails === true) {
      this.checkStatusProcess()
    }
		if(stopYear === true){
			this.props.stopYearData()
		}
	}
  getUpdateYear(getYearData?:any){
    if(getYearData && getYearData !== undefined && getYearData !== null){
      this.setState({academicYear:getYearData})
      this.getDataValue(getYearData)
    }else {
      this.setState({academicYear:this.props.getYear})
      this.getDataValue(this.props.getYear)
    }
  }
  getDataValue(getYearData?:any) {
    const postValue = {
      page_no: 1,
      search: this.state.search,
      sort_by: this.state.SortOrderData,
      order_by: this.state.OrderNameData,
      academic_year:getYearData
    }
    this.props.fetchSchoolsPost(postValue).then((res:any)=>{
      window.scrollTo(0, 0);
      this.setState({hasMore:true, page: 1})
    });
  }
  checkUserType() {
    const {loginProfile} = this.props;
    const getSchoolToken:any = String(loginProfile.usertype);
    if(getSchoolToken){
      if (getSchoolToken === UserRoles.acadamicAdmin) {
        this.setState({ acadamicAdmin: true })
      } else if (getSchoolToken === UserRoles.schoolAdmin) {
        this.setState({ schoolAdmin: true })
      } else if (getSchoolToken === UserRoles.teacher) {
        this.setState({ teacher: true })
      } else if (getSchoolToken === UserRoles.parent) {
        this.setState({ parent: true })
      }
    }
  }

  checkStatusProcess() {
    const selectDataList = this.props.deleteDetails;
    if (selectDataList === true) {
      this.getDataValue()
    }
  }

  public handleDelete = () => {
    this.props.deletePost(this.state.deleteSchoolId);
    this.setState({ showDelete: false, page: 1, hasMore: true });
  }
  public hideSchoolDelete = () => {
    this.setState({ showDelete: false });
  }
  public showSchoolDelete = (getData: any) => {
    let titleClassMessage: any;
    let postValue:any
    if (getData.isActive === true) {
      titleClassMessage = 'Activate';
    } else {
      titleClassMessage = 'Deactivate';
    }
    if(getData && titleClassMessage){
      postValue = {
        title:'Please Confirm',
        action_status:titleClassMessage,
        action_name:'School'
      }
      this.setState({ showDelete: true, deleteSchoolId: getData, postData:postValue });
    }
  }

  public fetchMoreSchoolData = () => {
    const {academicYear} = this.state;
    if (this.state.hasMore === true) {
      if (Math.ceil(this.props.total / this.props.per_page) > this.props.page) {
        const postValue = {
          page_no: this.state.page + 1,
          search: this.state.search,
          sort_by: this.state.SortOrderData,
          order_by: this.state.OrderNameData,
          academic_year:academicYear
        }
        this.props.fetchSchoolsPost(postValue);
        this.setState({
          page: this.state.page + 1
        })
      }

      if (Math.ceil(this.props.total / this.props.per_page) === this.props.page) {
        this.setState({
          hasMore: false,
        })
      }
    }
  }
  public showFilterData = (getdata: any) => {
    const {academicYear} = this.state;
    if (getdata.sort_by === 'desc') {
      this.setState({ acsOrder: false })
      const postValue = {
        page_no: 1,
        search: this.state.search,
        sort_by: getdata.sort_by,
        order_by: getdata.order_by,
        academic_year:academicYear,
      }
      this.props.fetchSchoolsPost(postValue);
      this.setState({
        hasMore: true,
        SortOrderData: getdata.sort_by,
        OrderNameData: getdata.order_by,
        page: 1,
        sort_by:'asc',
        sort_class_name:'fa fa-sort-amount-desc',
        sort_title_name:'Descending order'
      })
    } else {
      this.setState({ acsOrder: true })
      const postValue = {
        page_no: 1,
        search: this.state.search,
        sort_by: getdata.sort_by,
        order_by: getdata.order_by,
        academic_year:academicYear
      }
      this.props.fetchSchoolsPost(postValue);
      this.setState({
        hasMore: true,
        SortOrderData: getdata.sort_by,
        OrderNameData: getdata.order_by,
        page: 1,
        sort_by:'desc',
        sort_class_name:'fa fa-sort-amount-asc',
        sort_title_name:'Ascending order'
      })
    }
  }

  private renderSchoolManageView(getSchoolData: any) {
    const { loading } = this.props;
    const { sort_by, sort_class_name, sort_title_name } = this.state;
    const loadingTextCSS = { display: loading ? "block" : "none" };
    return (
      <div>
        <InfiniteScroll
          dataLength={getSchoolData.length}
          next={this.fetchMoreSchoolData}
          hasMore={this.state.hasMore}
          loader={<h4 style={loadingTextCSS}>Loading...</h4>}
        >
          <table className="table table-striped custom-table table-hover">
            <thead>
              <tr>
                <th>
                <button onClick={() => this.showFilterData({ sort_by: sort_by, order_by: 'school_name' })} 
                title={sort_title_name} className="headerBold">
                School Name  <i className={sort_class_name} aria-hidden="true"></i>
                </button>
                </th>
                <th>
                <button onClick={() => this.showFilterData({ sort_by: sort_by, order_by: 'category' })} 
                title={sort_title_name} className="headerBold">
                Category <i className={sort_class_name} aria-hidden="true"></i>
                </button> 
                </th>
                <th>
                <button onClick={() => this.showFilterData({ sort_by: sort_by, order_by: 'start_time' })} 
                title={sort_title_name} className="headerBold">
                Start Time <i className={sort_class_name} aria-hidden="true"></i>
                </button> 
                </th>
                <th>
                <button onClick={() => this.showFilterData({ sort_by: sort_by, order_by: 'end_time' })} 
                title={sort_title_name} className="headerBold">
                End Time <i className={sort_class_name} aria-hidden="true"></i></button>  
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {getSchoolData.length > 0 ?
                getSchoolData.map((data: any, index: number) => (
                  <tr key={data['id'] || null}>
                    <td >{data['school_name'] || '-'}</td>
                    <td >{data['category'] || '-'}</td>
                    <td >{data['start_time'] || '-'}</td>
                    <td >{data['end_time'] || '-'}</td>
                    <td>                      
                        {data.is_active ?
                        <Link to={`/edit_school/${data.id}`}>
                          <button className="btn btn-primary btn-xs"
                            title="Edit School"><i className="fa fa-pencil" aria-hidden="true" ></i></button></Link>   : 
                            <button className="btn btn-disable btn-xs"
                            title="Edit School"><i className="fa fa-pencil" aria-hidden="true" ></i></button>}
                                          
                      {data.is_active ?
                        <Switch
                        checked={data.is_active}
                        onClick={() => this.showSchoolDelete({ id: String(data['id']), isActive: false })}
                        name="checkedSchool"
                        inputProps={{ 'aria-label': 'success checkbox' }}
                        title='Deactivate School'
                      /> :
                      <Switch
                      checked={data.is_active}
                      onClick={() => this.showSchoolDelete({ id: String(data['id']), isActive: true })}
                      name="checkedSchool"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                      title='Activate School'
                    />}
                    </td>
                  </tr>
                ))
                : <CommonLoader />}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    )
  }

  handleInputSchoolChange = (e: any) => {
    const {academicYear} = this.state;
    const { value } = e.target;
    const getSearchValue = value;
    var intRegex = formValidationPatten.alphanumericTest;
    if(intRegex.test(getSearchValue) || getSearchValue === ''){
      if (getSearchValue) {
        const postSearchValue = {
          page_no: 1,
          search: getSearchValue,
          academic_year: academicYear,
          sort_by: this.state.SortOrderData,
          order_by: this.state.OrderNameData
        }
        this.props.fetchSchoolsPost(postSearchValue);
        this.setState({
          hasMore: true,
          page: 1,
          search: getSearchValue
        })
      } else {
        const postSearchValue = {
          page_no: 1,
          search: '',
          academic_year: academicYear,
          sort_by: this.state.SortOrderData,
          order_by: this.state.OrderNameData
        }
        this.props.fetchSchoolsPost(postSearchValue);
        this.setState({
          hasMore: true,
          page: 1,
          search: ''
        })
      }
    } else {

    }
  
  }
  refreshSchoolTable = () =>{
    this.getDataValue(); 
  }
  render() {
    const { loading } = this.props;
    const {showDelete, postData} = this.state;
    const loadingTextCSS = { display: loading ? "block" : "none" };
    const getSchoolToken = localStorage.getItem('token');
    const schoolTotel = this.props.total;
    if (!getSchoolToken) {
      history.push("/");
    }
      
    return (
      <div>
         <CommonModel
       onState={showDelete}
       currentState={postData}
       onConfirm={this.handleDelete}
       onCancel={this.hideSchoolDelete}
       />
        <div className="page-wrapper">
          <div className="page-content-wrapper">
            <div className="page-content pt-3">
              <BreadCrumb 
              titleName={['Schools']} 
              homeName={['Home']} 
              url={['dashboard']} 
              mainPageTitle={['Schools']} />
              <div>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <div className="mr-4 mt-2">
                    <h4 className="pull-right">Total: {schoolTotel}</h4>
                  </div>
                </div>
              </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-topline-red">
                      <div className="card-head" style={{ display: this.state.acadamicAdmin ? "block" : "none" }}>
                        <header>
                          <Link to="/add_school">
                            <button className="btn btn-pink">Add School</button>
                          </Link>
                        </header>
                        <div className="tools">
                        <div className="d-flex mt-2">
										    <div>
                            <input
                              placeholder="Search"
                              name="search"
                              className="form-control"
                              onChange={this.handleInputSchoolChange}
                            />
										    </div>
										<div className="ml-2 mt-2">
											<span className="fa fa-repeat btn-color box-refresh pointer" onClick={this.refreshSchoolTable}></span>
											<span className="t-collapse btn-color fa fa-chevron-up pointer" ></span>
										</div>
									</div>
                            
                        </div>
                      </div>
                      <div className="card-body no-padding height-9">
                        <div className="row">
                          <div className="table-responsive">
                            {this.renderSchoolManageView(this.props.records)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div style={loadingTextCSS}><SpinnerLoader /></div>
      </div>
    );
  }
}


const mapStateToProps = (state: any) => {
  return {
    getYear:state.profile.getYear,
    stopYear:state.profile.yearStatusFails,
    loginProfile:state.profile.profileData,
    schoolDetails: state.schools.items,
    loading: state.schools.loading,
    total: state.schools.total,
    per_page: state.schools.per_page,
    records: state.schools.records,
    page: state.schools.page,
    totalPage: state.schools.totalPage,
    deleteDetails: state.schools.isAuthenticated
  };
};
export default connect(
  mapStateToProps, { fetchSchoolsPost, fetchSchool, deletePost,  stopYearData, addSchool }
)(ViewSchoolDetails);
