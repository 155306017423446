import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getClassRecentFeedback, fetchClassRecentQuiz } from '../../store/classes/Actions';
import CommonLoader from '../../components/CommonLoader';
import { Link } from "react-router-dom";
import history from '../../History';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import { getAttendanceStudentView, getQuizzesReportData } from '../../store/profile/Actions';
import Cookies from 'universal-cookie';
import { stopYearData, stopSubComponentYear } from '../../store/profile/Actions'

interface propsClassRecent {
    getQuizzes?:any;
    getChangeYearData?:any;
	stopYearDate?:any;
	stopYearData:()=>any;
	stopSubComponentYear:(postValue:any) => any;
    getClassId?:any;
    getClassRecentFeedback:(postValue:any) => any;
    fetchClassRecentQuiz:(postValue:any) => any;
    getAttendanceStudentView:(postValue:any) =>any;
    ClassRecentFeedback?:any;
    ClassRecentQuiz?:any;
    loading?:any;
    getQuizzesReportData:(postValue:any)=>any
}

export class ClassRecentFeedback extends Component<propsClassRecent> {
    cookies:any = new Cookies();
    componentDidMount(): void {
        this.getCurrectYear()
    }
    componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYearDate} = this.props;
		if(stopYearDate === true){
			this.getCurrectYear(this.props.getChangeYearData)
			let postValue:any = {
				status:true
			}
			this.props.stopSubComponentYear(postValue)
		}
	}
	componentDidUpdate() {
		const {stopYearDate} = this.props;
		if(stopYearDate === true){
			this.props.stopYearData()
		}
	}
	getCurrectYear(getYear?:any){
		if(getYear && getYear !== undefined && getYear !== null){
			this.getClassesRecent(getYear)
		}else {
			this.getClassesRecent(this.props.getChangeYearData)
		}
	}
    getClassesRecent(getYear?:any){
        let viewQuizzeData:any = this.props.getQuizzes;
        if(viewQuizzeData){
            if(viewQuizzeData.classResport){
                let postValue:any = {
                    academic_year: getYear,
                    class_id:viewQuizzeData.classResport.id,
                    school_id: viewQuizzeData.school_id
                }
                this.props.getClassRecentFeedback(postValue);
                this.props.fetchClassRecentQuiz(postValue);
            }
        }
       
    }
    getQuizzesReport = (getValue:any) =>{     
        if(getValue) {
            let viewQuizzeData:any = this.props.getQuizzes;
            const postValue:any = {
                school_id:viewQuizzeData.school_id
            }
            this.props.getQuizzesReportData(postValue)
            history.push({
                pathname: `/quizzes_report/${getValue.quiz_id}`,
              });
        } 
    }
    getInstantFeedBackResult = (getValue:any) =>{
        if(getValue){
            let postValue:any ={ 
                class_id:getValue.class_id,
                school_id: getValue.school_id
            }
            history.push({
                pathname: `/view_instant_feedback/${getValue.id}`,
              });
              this.cookies.set('VIEWINTANTFEEDBACK', postValue)
              this.props.getAttendanceStudentView(postValue);
        }
    }
    render() {
        const { ClassRecentQuiz, ClassRecentFeedback, loading } = this.props;
        const loadingTextCSS = { display: loading ? "block" : "none" };
        return (
            <div>
                   <div className="row">
                    <div className="col-md-12">
                    <h4 className="mb-2">Recent Quiz</h4>
                        <div className="card card-topline-red">
                            <div className="card-body no-padding height-9">
                                <div className="table-responsive">
                                    <table className="table table-striped custom-table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Performance (Average)</th>
                                                <th>Last Updated Time</th>
                                            </tr>
                                        </thead>
                                        {ClassRecentQuiz && ClassRecentQuiz.length !==0? 
                                        <tbody>
                                            {
                                                ClassRecentQuiz.map((items: any, index: any) => (
                                                    <tr key={index}>
                                                        <td><Link to={`#`} onClick={()=> this.getQuizzesReport(items)}>{items.name}</Link></td>
                                                        <td><Link to={`#`} onClick={()=> this.getQuizzesReport(items)}>{items.performance}</Link></td>
                                                        <td><Link to={`#`} onClick={()=> this.getQuizzesReport(items)}>{items.date}</Link></td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                        :<CommonLoader/>}
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="pull-right">
                            <Link to={"/quizzes"}>
                            <button className="btn btn-pink">More...</button>
                            </Link>
                        </div>
                        
                    </div>
                </div>

                
               <div className="row">
                    <div className="col-md-12">
                    <h4 className="mb-2">Recent Feedback</h4>
                        <div className="card card-topline-red">
                            <div className="card-body no-padding height-9">
                                <div className="table-responsive">
                                    <table className="table table-striped custom-table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Performance (Average)</th>
                                                <th>Last Updated Time</th>
                                                
                                            </tr>
                                        </thead>
                                        {ClassRecentFeedback && ClassRecentFeedback.length !==0? 
                                        <tbody>
                                            {
                                                ClassRecentFeedback.map((items: any, index: any) => (
                                                    <tr key={index}>
                                                        <td><Link to={`#`} onClick={()=> this.getInstantFeedBackResult(items)}>{items.name}</Link></td>
                                                        <td><Link to={`#`} onClick={()=> this.getInstantFeedBackResult(items)}>{items.performance}</Link></td>
                                                        <td><Link to={`#`} onClick={()=> this.getInstantFeedBackResult(items)}>{items.created_at}</Link></td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                        :<CommonLoader/>}
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="pull-right">
                            <Link to={"/instant_feedback"}>
                            <button className="btn btn-pink">More...</button>
                            </Link>
                        </div>
                        
                    </div>
                </div> 
                <div style={loadingTextCSS}><SpinnerLoader /></div>
            </div>
        )
    }
}

const mapStateToProps = (state:any) => {
    return{
        getChangeYearData:state.profile.getYear,
		stopYearDate:state.profile.yearStatusFails,
        getQuizzes:state.classes.classesReportDir,
        ClassRecentFeedback:state.classes.getClassRecentFeedback,
        ClassRecentQuiz:state.classes.getClassRecentQuiz,
        loading:state.classes.loading,
    }
}

export default connect(mapStateToProps, {getClassRecentFeedback, fetchClassRecentQuiz, getAttendanceStudentView, stopYearData, stopSubComponentYear,getQuizzesReportData})(ClassRecentFeedback)
