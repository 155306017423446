import { ThunkAction } from "redux-thunk";
import { RootActions, RootState } from "../Index";
import { QuestionBankTypes } from "./Types";
import { Dispatch } from "redux";
import { AxiosResponse } from "axios";
import { QuestionBank } from "../../services/Config";
import { baseAPI } from "../../Service";
import toaster from "toasted-notes";
import { notificationMsg } from "../../services/Constants";
import history from "../../History";

//Implement Thunk middle ware
export type ThunkResult<R> = ThunkAction<R, RootState, undefined, RootActions>;

interface FetchExamType {
    type: QuestionBankTypes.EXAM_TYPE;
}

interface FetchExamTypeSuccess {
    type: QuestionBankTypes.EXAM_TYPE_SUCCESS;
    payload: any;
}

interface FetchExamTypeFail {
    type: QuestionBankTypes.EXAM_TYPE_FAIL;
}

export const fetchExamTypeGet = (params: any): ThunkResult<void> => async dispatch => {
    handleFetchExamType(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.patch(QuestionBank.GetExamType, null, {
                params: params,
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            if (response.data.status === true) {
                handleFetchExamTypeSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleFetchExamTypeFail(dispatch);
            }
    } catch (e) {
        handleFetchExamTypeFail(dispatch);
    }
};

export const handleFetchExamType = (dispatch: Dispatch<FetchExamType>) => {
    dispatch({ type: QuestionBankTypes.EXAM_TYPE });
};

export const handleFetchExamTypeSuccess = (
    dispatch: Dispatch<FetchExamTypeSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.EXAM_TYPE_SUCCESS,
        payload: response,
        records: response.data.records,
        per_page: response.data.per_page,
        page: response.data.page,
        total: response.data.total
    });
};

export const handleFetchExamTypeFail = (dispatch: Dispatch<FetchExamTypeFail>) => {
    dispatch({
        type: QuestionBankTypes.EXAM_TYPE_FAIL
    });
};


interface AddExamTypeSet {
    type: QuestionBankTypes.ADD_EXAM_TYPE;
}

interface AddExamTypeSuccess {
    type: QuestionBankTypes.ADD_EXAM_TYPE_SUCCESS;
    payload: any;
}

interface AddExamTypeFail {
    type: QuestionBankTypes.ADD_EXAM_TYPE_FAIL;
}

export const addExamType = (postData: any): ThunkResult<void> => async dispatch => {
    handleAddExamTypeSet(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.post(QuestionBank.GetExamType, postData,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            if (response.data.status === true) {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.duration
                });
                history.push("/examtype");
                handleAddExamTypeSuccess(dispatch, response.data);
            } else {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleAddExamTypeFail(dispatch);
            }
    } catch (e) {
        handleAddExamTypeFail(dispatch);
    }
};

export const handleAddExamTypeSet = (dispatch: Dispatch<AddExamTypeSet>) => {
    dispatch({ type: QuestionBankTypes.ADD_EXAM_TYPE });
};

export const handleAddExamTypeSuccess = (
    dispatch: Dispatch<AddExamTypeSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.ADD_EXAM_TYPE_SUCCESS,
        payload: response,
    });

};

export const handleAddExamTypeFail = (dispatch: Dispatch<AddExamTypeFail>) => {
    dispatch({
        type: QuestionBankTypes.ADD_EXAM_TYPE_FAIL
    });
};




interface fetchExamTypeGetByIdSet {
    type: QuestionBankTypes.FETCH_EXAM_TYPE_BY_ID;
}

interface fetchExamTypeGetByIdSuccess {
    type: QuestionBankTypes.FETCH_EXAM_TYPE_BY_ID_SUCCESS;
    payload: any;
}

interface fetchExamTypeGetByIdFail {
    type: QuestionBankTypes.FETCH_EXAM_TYPE_BY_ID_FAIL;
}

export const fetchExamTypeGetById = (id: number): ThunkResult<void> => async dispatch => {
    handleFetchExamTypeGetByIdSet(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(QuestionBank.GetExamType+`?uid=${id}`,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            handleFetchExamTypeGetByIdSuccess(dispatch, response.data);
    } catch (e) {
        handleFetchExamTypeGetByIdFail(dispatch);
    }
};

export const handleFetchExamTypeGetByIdSet = (dispatch: Dispatch<fetchExamTypeGetByIdSet>) => {
    dispatch({ type: QuestionBankTypes.FETCH_EXAM_TYPE_BY_ID });
};

export const handleFetchExamTypeGetByIdSuccess = (
    dispatch: Dispatch<fetchExamTypeGetByIdSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.FETCH_EXAM_TYPE_BY_ID_SUCCESS,
        payload: response,
        getExamTypeEdit: response.data
    });

};

export const handleFetchExamTypeGetByIdFail = (dispatch: Dispatch<fetchExamTypeGetByIdFail>) => {
    dispatch({
        type: QuestionBankTypes.FETCH_EXAM_TYPE_BY_ID_FAIL
    });
};


interface EditExamTypeSet {
    type: QuestionBankTypes.EDIT_EXAM_TYPE;
}

interface EditExamTypeSuccess {
    type: QuestionBankTypes.EDIT_EXAM_TYPE_SUCCESS;
    payload: any;
}

interface EditExamTypeFail {
    type: QuestionBankTypes.EDIT_EXAM_TYPE_FAIL;
}

export const editExamType = (postData: any): ThunkResult<void> => async dispatch => {
    handleEditExamTypeSet(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.put(QuestionBank.GetExamType+`?uid=${postData.id}`, postData,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            if (response.data.status === true) {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.duration
                });
                history.push("/examtype");
                handleEditExamTypeSuccess(dispatch, response.data);
            } else {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleEditExamTypeFail(dispatch);
            }
    } catch (e) {
        handleEditExamTypeFail(dispatch);
    }
};

export const handleEditExamTypeSet = (dispatch: Dispatch<EditExamTypeSet>) => {
    dispatch({ type: QuestionBankTypes.EDIT_EXAM_TYPE });
};

export const handleEditExamTypeSuccess = (
    dispatch: Dispatch<EditExamTypeSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.EDIT_EXAM_TYPE_SUCCESS,
        payload: response,
        getExamTypeEdit: response.data
    });

};

export const handleEditExamTypeFail = (dispatch: Dispatch<EditExamTypeFail>) => {
    dispatch({
        type: QuestionBankTypes.EDIT_EXAM_TYPE_FAIL
    });
};


interface DeleteExamType {
    type: QuestionBankTypes.DELETE_EXAM_TYPE;
}

interface DeleteExamTypeSuccess {
    type: QuestionBankTypes.DELETE_EXAM_TYPE_SUCCESS;
    payload: any;
}

interface DeleteExamTypeFail {
    type: QuestionBankTypes.DELETE_EXAM_TYPE_FAIL;
}

export const deleteExamType = (id: number): ThunkResult<void> => async dispatch => {
    handleDeleteExamType(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.delete(QuestionBank.GetExamType+`?uid=${id}`,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            if (response.data.status === true) {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.duration
                });
                history.push("/examtype");
                handleDeleteExamTypeSuccess(dispatch, response.data);
            } else {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleDeleteExamTypeFail(dispatch);
            }
    } catch (e) {
        handleDeleteExamTypeFail(dispatch);
    }
};

export const handleDeleteExamType = (dispatch: Dispatch<DeleteExamType>) => {
    dispatch({ type: QuestionBankTypes.DELETE_EXAM_TYPE });
};

export const handleDeleteExamTypeSuccess = (
    dispatch: Dispatch<DeleteExamTypeSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.DELETE_EXAM_TYPE_SUCCESS,
        payload: response,
    });
};

export const handleDeleteExamTypeFail = (dispatch: Dispatch<DeleteExamTypeFail>) => {
    dispatch({
        type: QuestionBankTypes.DELETE_EXAM_TYPE_FAIL
    });
};

interface FetchBluePrint {
    type: QuestionBankTypes.BLUE_PRINT;
}

interface FetchBluePrintSuccess {
    type: QuestionBankTypes.BLUE_PRINT_SUCCESS;
    payload: any;
}

interface FetchBluePrintFail {
    type: QuestionBankTypes.BLUE_PRINT_FAIL;
}

export const fetchBluePrintGet = (params: any): ThunkResult<void> => async dispatch => {
    handleFetchBluePrint(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.patch(QuestionBank.BluePrint, null, {
                params: params,
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            if (response.data.status === true) {
                handleFetchBluePrintSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleFetchBluePrintFail(dispatch);
            }
    } catch (e) {
        handleFetchBluePrintFail(dispatch);
    }
};

export const handleFetchBluePrint = (dispatch: Dispatch<FetchBluePrint>) => {
    dispatch({ type: QuestionBankTypes.BLUE_PRINT });
};

export const handleFetchBluePrintSuccess = (
    dispatch: Dispatch<FetchBluePrintSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.BLUE_PRINT_SUCCESS,
        payload: response,
        records: response.data.records,
        per_page: response.data.per_page,
        page: response.data.page,
        total: response.data.total
    });
};

export const handleFetchBluePrintFail = (dispatch: Dispatch<FetchBluePrintFail>) => {
    dispatch({
        type: QuestionBankTypes.BLUE_PRINT_FAIL
    });
};

interface addBluePrint {
    type: QuestionBankTypes.ADD_BLUE_PRINT;
}

interface addBluePrintSuccess {
    type: QuestionBankTypes.ADD_BLUE_PRINT_SUCCESS;
    payload: any;
}

interface addBluePrintFail {
    type: QuestionBankTypes.ADD_BLUE_PRINT_FAIL;
}

export const addBluePrintGet = (params: any): ThunkResult<void> => async dispatch => {
    handleAddBluePrint(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.post(QuestionBank.BluePrint, params, {
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            if (response.data.status === true) {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.duration
                });
                history.push("/blueprint");
                handleAddBluePrintSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleAddBluePrintFail(dispatch);
            }
    } catch (e) {
        handleAddBluePrintFail(dispatch);
    }
};

export const handleAddBluePrint = (dispatch: Dispatch<addBluePrint>) => {
    dispatch({ type: QuestionBankTypes.ADD_BLUE_PRINT });
};

export const handleAddBluePrintSuccess = (
    dispatch: Dispatch<addBluePrintSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.ADD_BLUE_PRINT_SUCCESS,
        payload: response,
    });
};

export const handleAddBluePrintFail = (dispatch: Dispatch<addBluePrintFail>) => {
    dispatch({
        type: QuestionBankTypes.ADD_BLUE_PRINT_FAIL
    });
};


interface FetchBluePrintById {
    type: QuestionBankTypes.FETCH_BLUE_PRINT_BY_ID;
}

interface FetchBluePrintByIdSuccess {
    type: QuestionBankTypes.FETCH_BLUE_PRINT_BY_ID_SUCCESS;
    payload: any;
}

interface FetchBluePrintByIdFail {
    type: QuestionBankTypes.FETCH_BLUE_PRINT_BY_ID_FAIL;
}

export const fetchBluePrintByIdGet = (data: any): ThunkResult<void> => async dispatch => {
    handleFetchBluePrintById(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(QuestionBank.BluePrint+`?uid=${data.uid}`, {
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            if (response.data.status === true) {
                handleFetchBluePrintByIdSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleFetchBluePrintByIdFail(dispatch);
            }
    } catch (e) {
        handleFetchBluePrintByIdFail(dispatch);
    }
};

export const handleFetchBluePrintById = (dispatch: Dispatch<FetchBluePrintById>) => {
    dispatch({ type: QuestionBankTypes.FETCH_BLUE_PRINT_BY_ID });
};

export const handleFetchBluePrintByIdSuccess = (
    dispatch: Dispatch<FetchBluePrintByIdSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.FETCH_BLUE_PRINT_BY_ID_SUCCESS,
        payload: response,
    });
};

export const handleFetchBluePrintByIdFail = (dispatch: Dispatch<FetchBluePrintByIdFail>) => {
    dispatch({
        type: QuestionBankTypes.FETCH_BLUE_PRINT_BY_ID_FAIL
    });
};

interface editBluePrint {
    type: QuestionBankTypes.EDIT_BLUE_PRINT;
}

interface editBluePrintSuccess {
    type: QuestionBankTypes.EDIT_BLUE_PRINT_SUCCESS;
    payload: any;
}

interface editBluePrintFail {
    type: QuestionBankTypes.EDIT_BLUE_PRINT_FAIL;
}

export const editBluePrint = (params: any): ThunkResult<void> => async dispatch => {
    handleEditBluePrint(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.put(QuestionBank.BluePrint+`?uid=${params.uid}`, params, 
            {
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            if (response.data.status === true) {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.duration
                });
                history.push("/blueprint");
                handleEditBluePrintSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleEditBluePrintFail(dispatch);
            }
    } catch (e) {
        handleEditBluePrintFail(dispatch);
    }
};

export const handleEditBluePrint = (dispatch: Dispatch<editBluePrint>) => {
    dispatch({ type: QuestionBankTypes.EDIT_BLUE_PRINT });
};

export const handleEditBluePrintSuccess = (
    dispatch: Dispatch<editBluePrintSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.EDIT_BLUE_PRINT_SUCCESS,
        payload: response,
    });
};

export const handleEditBluePrintFail = (dispatch: Dispatch<editBluePrintFail>) => {
    dispatch({
        type: QuestionBankTypes.EDIT_BLUE_PRINT_FAIL
    });
};

interface DeleteBluePrintById {
    type: QuestionBankTypes.DELETE_BLUE_PRINT_BY_ID;
}

interface DeleteBluePrintByIdSuccess {
    type: QuestionBankTypes.DELETE_BLUE_PRINT_BY_ID_SUCCESS;
    payload: any;
}

interface DeleteBluePrintByIdFail {
    type: QuestionBankTypes.DELETE_BLUE_PRINT_BY_ID_FAIL;
}

export const deleteBluePrintById = (deletedId: any): ThunkResult<void> => async dispatch => {
    handleDeleteBluePrintById(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.delete(QuestionBank.BluePrint+`?uid=${deletedId}`,
            { headers: { "Authorization": localStorage.getItem('token') } });
        const getResponse = JSON.parse(JSON.stringify(response.data));
        if (getResponse.status === true) {
            toaster.notify(getResponse.message, {
                position: 'top',
                duration: notificationMsg.duration
            });
            handleDeleteBluePrintByIdSuccess(dispatch, response.data);
        } else {
            toaster.notify(getResponse.message, {
                position: 'top',
                duration: notificationMsg.errorNotificationDuration
            });
            handleDeleteBluePrintByIdFail(dispatch);
        }

    } catch (e) {
        handleDeleteBluePrintByIdFail(dispatch);
    }
};

const handleDeleteBluePrintById = (dispatch: Dispatch<DeleteBluePrintById>) => {
    dispatch({ type: QuestionBankTypes.DELETE_BLUE_PRINT_BY_ID });
};

const handleDeleteBluePrintByIdSuccess = (
    dispatch: Dispatch<DeleteBluePrintByIdSuccess>,
    response: any
) => {
    dispatch({ type: QuestionBankTypes.DELETE_BLUE_PRINT_BY_ID_SUCCESS, payload: response });
};
const handleDeleteBluePrintByIdFail = (dispatch: Dispatch<DeleteBluePrintByIdFail>) => {
    dispatch({ type: QuestionBankTypes.DELETE_BLUE_PRINT_BY_ID_FAIL });
};
interface fetchBluePrintGradeDropdown {
    type: QuestionBankTypes.BLUE_PRINT_GRADE_DROPDOWN;
}

interface fetchBluePrintGradeDropdownSuccess {
    type: QuestionBankTypes.BLUE_PRINT_GRADE_DROPDOWN_SUCCESS;
    payload: any;
}

interface fetchBluePrintGradeDropdownFail {
    type: QuestionBankTypes.BLUE_PRINT_GRADE_DROPDOWN_FAIL;
}

export const fetchBluePrintGradeDropdownGet = (data: any): ThunkResult<void> => async dispatch => {
    handleBluePrintGradeDropdown(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(QuestionBank.GetBluePrintGradeDropdown+`?academic_year=${data.academic_year}`,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            handleBluePrintGradeDropdownSuccess(dispatch, response.data);
    } catch (e) {
        handleBluePrintGradeDropdownFail(dispatch);
    }
};

export const handleBluePrintGradeDropdown = (dispatch: Dispatch<fetchBluePrintGradeDropdown>) => {
    dispatch({ type: QuestionBankTypes.BLUE_PRINT_GRADE_DROPDOWN });
};

export const handleBluePrintGradeDropdownSuccess = (
    dispatch: Dispatch<fetchBluePrintGradeDropdownSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.BLUE_PRINT_GRADE_DROPDOWN_SUCCESS,
        payload: response,
    });

};

export const handleBluePrintGradeDropdownFail = (dispatch: Dispatch<fetchBluePrintGradeDropdownFail>) => {
    dispatch({
        type: QuestionBankTypes.BLUE_PRINT_GRADE_DROPDOWN_FAIL
    });
};

interface fetchBluePrintSubjectDropdown {
    type: QuestionBankTypes.BLUE_PRINT_SUBJECT_DROPDOWN;
}

interface fetchBluePrintSubjectDropdownSuccess {
    type: QuestionBankTypes.BLUE_PRINT_SUBJECT_DROPDOWN_SUCCESS;
    payload: any;
}

interface fetchBluePrintSubjectDropdownFail {
    type: QuestionBankTypes.BLUE_PRINT_SUBJECT_DROPDOWN_FAIL;
}

export const fetchBluePrintSubjectDropdownGet = (data: any): ThunkResult<void> => async dispatch => {
    handleBluePrintSubjectDropdown(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(QuestionBank.GetBluePrintSubjectDropdown+`?internal_grade_id=${data.internal_grade_id}&academic_year=${data.academic_year}`,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            handleBluePrintSubjectDropdownSuccess(dispatch, response.data);
    } catch (e) {
        handleBluePrintSubjectDropdownFail(dispatch);
    }
};

export const handleBluePrintSubjectDropdown = (dispatch: Dispatch<fetchBluePrintSubjectDropdown>) => {
    dispatch({ type: QuestionBankTypes.BLUE_PRINT_SUBJECT_DROPDOWN });
};

export const handleBluePrintSubjectDropdownSuccess = (
    dispatch: Dispatch<fetchBluePrintSubjectDropdownSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.BLUE_PRINT_SUBJECT_DROPDOWN_SUCCESS,
        payload: response,
    });

};

export const handleBluePrintSubjectDropdownFail = (dispatch: Dispatch<fetchBluePrintSubjectDropdownFail>) => {
    dispatch({
        type: QuestionBankTypes.BLUE_PRINT_SUBJECT_DROPDOWN_FAIL
    });
};

interface fetchBluePrintExamTypeDropdown {
    type: QuestionBankTypes.BLUE_PRINT_EXAM_TYPE_DROPDOWN;
}

interface fetchBluePrintExamTypeDropdownSuccess {
    type: QuestionBankTypes.BLUE_PRINT_EXAM_TYPE_DROPDOWN_SUCCESS;
    payload: any;
}

interface fetchBluePrintExamTypeDropdownFail {
    type: QuestionBankTypes.BLUE_PRINT_EXAM_TYPE_DROPDOWN_FAIL;
}

export const fetchBluePrintExamTypeDropdownGet = (data: any): ThunkResult<void> => async dispatch => {
    handleBluePrintExamTypeDropdown(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(QuestionBank.GetBluePrintExamTypeDropdown+`?academic_year=${data.academic_year}`,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            handleBluePrintExamTypeDropdownSuccess(dispatch, response.data);
    } catch (e) {
        handleBluePrintExamTypeDropdownFail(dispatch);
    }
};

export const handleBluePrintExamTypeDropdown = (dispatch: Dispatch<fetchBluePrintExamTypeDropdown>) => {
    dispatch({ type: QuestionBankTypes.BLUE_PRINT_EXAM_TYPE_DROPDOWN });
};

export const handleBluePrintExamTypeDropdownSuccess = (
    dispatch: Dispatch<fetchBluePrintExamTypeDropdownSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.BLUE_PRINT_EXAM_TYPE_DROPDOWN_SUCCESS,
        payload: response,
    });

};

export const handleBluePrintExamTypeDropdownFail = (dispatch: Dispatch<fetchBluePrintExamTypeDropdownFail>) => {
    dispatch({
        type: QuestionBankTypes.BLUE_PRINT_EXAM_TYPE_DROPDOWN_FAIL
    });
};

interface fetchBluePrintLessonDropdown {
    type: QuestionBankTypes.BLUE_PRINT_LESSON_DROPDOWN;
}

interface fetchBluePrintLessonDropdownSuccess {
    type: QuestionBankTypes.BLUE_PRINT_LESSON_DROPDOWN_SUCCESS;
    payload: any;
}

interface fetchBluePrintLessonDropdownFail {
    type: QuestionBankTypes.BLUE_PRINT_LESSON_DROPDOWN_FAIL;
}

export const fetchBluePrintLessonDropdownGet = (data: any): ThunkResult<void> => async dispatch => {
    handleBluePrintLessonDropdown(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(QuestionBank.GetBluePrintLessonDropdown+`?academic_year=${data.academic_year}&internal_grade_id=${data.internal_grade_id}&internal_subject_id=${data.internal_subject_id}`,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            handleBluePrintLessonDropdownSuccess(dispatch, response.data);
    } catch (e) {
        handleBluePrintLessonDropdownFail(dispatch);
    }
};

export const handleBluePrintLessonDropdown = (dispatch: Dispatch<fetchBluePrintLessonDropdown>) => {
    dispatch({ type: QuestionBankTypes.BLUE_PRINT_LESSON_DROPDOWN });
};

export const handleBluePrintLessonDropdownSuccess = (
    dispatch: Dispatch<fetchBluePrintLessonDropdownSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.BLUE_PRINT_LESSON_DROPDOWN_SUCCESS,
        payload: response,
    });

};

export const handleBluePrintLessonDropdownFail = (dispatch: Dispatch<fetchBluePrintLessonDropdownFail>) => {
    dispatch({
        type: QuestionBankTypes.BLUE_PRINT_LESSON_DROPDOWN_FAIL
    });
};


interface fetchBluePrintDifficultyLevelDropdown {
    type: QuestionBankTypes.BLUE_PRINT_DIFFICULTY_LEVEL_DROPDOWN;
}

interface fetchBluePrintDifficultyLevelDropdownSuccess {
    type: QuestionBankTypes.BLUE_PRINT_DIFFICULTY_LEVEL_DROPDOWN_SUCCESS;
    payload: any;
}

interface fetchBluePrintDifficultyLevelDropdownFail {
    type: QuestionBankTypes.BLUE_PRINT_DIFFICULTY_LEVEL_DROPDOWN_FAIL;
}

export const fetchBluePrintDifficultyLevelDropdownGet = (): ThunkResult<void> => async dispatch => {
    handleBluePrintDifficultyLevel(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(QuestionBank.GetBluePrintDifficultyLevelDropdown,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            handleBluePrintDifficultyLevelSuccess(dispatch, response.data);
    } catch (e) {
        handleBluePrintDifficultyLevelFail(dispatch);
    }
};

export const handleBluePrintDifficultyLevel = (dispatch: Dispatch<fetchBluePrintDifficultyLevelDropdown>) => {
    dispatch({ type: QuestionBankTypes.BLUE_PRINT_DIFFICULTY_LEVEL_DROPDOWN });
};

export const handleBluePrintDifficultyLevelSuccess = (
    dispatch: Dispatch<fetchBluePrintDifficultyLevelDropdownSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.BLUE_PRINT_DIFFICULTY_LEVEL_DROPDOWN_SUCCESS,
        payload: response,
    });

};

export const handleBluePrintDifficultyLevelFail = (dispatch: Dispatch<fetchBluePrintDifficultyLevelDropdownFail>) => {
    dispatch({
        type: QuestionBankTypes.BLUE_PRINT_DIFFICULTY_LEVEL_DROPDOWN_FAIL
    });
};


interface fetchBluePrintQuestionTypeDropdown {
    type: QuestionBankTypes.BLUE_PRINT_QUESTION_TYPE_DROPDOWN;
}

interface fetchBluePrintQuestionTypeDropdownSuccess {
    type: QuestionBankTypes.BLUE_PRINT_QUESTION_TYPE_DROPDOWN_SUCCESS;
    payload: any;
}

interface fetchBluePrintQuestionTypeDropdownFail {
    type: QuestionBankTypes.BLUE_PRINT_QUESTION_TYPE_DROPDOWN_FAIL;
}

export const fetchBluePrintQuestionTypeDropdownGet = (): ThunkResult<void> => async dispatch => {
    handleBluePrintQuestionType(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(QuestionBank.GetBluePrintQuestionTypeDropdown,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            handleBluePrintQuestionTypeSuccess(dispatch, response.data);
    } catch (e) {
        handleBluePrintQuestionTypeFail(dispatch);
    }
};

export const handleBluePrintQuestionType = (dispatch: Dispatch<fetchBluePrintQuestionTypeDropdown>) => {
    dispatch({ type: QuestionBankTypes.BLUE_PRINT_QUESTION_TYPE_DROPDOWN });
};

export const handleBluePrintQuestionTypeSuccess = (
    dispatch: Dispatch<fetchBluePrintQuestionTypeDropdownSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.BLUE_PRINT_QUESTION_TYPE_DROPDOWN_SUCCESS,
        payload: response,
    });

};

export const handleBluePrintQuestionTypeFail = (dispatch: Dispatch<fetchBluePrintQuestionTypeDropdownFail>) => {
    dispatch({
        type: QuestionBankTypes.BLUE_PRINT_QUESTION_TYPE_DROPDOWN_FAIL
    });
};

//Internal Gradle Dropdown
interface InternalGradleSet {
    type: QuestionBankTypes.INTERNAL_GRADLE;
}

interface InternalGradleSuccess {
    type: QuestionBankTypes.INTERNAL_GRADLE_SUCCESS;
    payload: any;
}

interface InternalGradleFail {
    type: QuestionBankTypes.INTERNAL_GRADLE_FAIL;
}

export const internalGradleGet = (postData: any): ThunkResult<void> => async dispatch => {
    handleInternalGradleSet(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(QuestionBank.InternalGradleDropdown,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            if (response.data.status === true) {
                handleInternalGradleSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleInternalGradleFail(dispatch);
            }
    } catch (e) {
        handleInternalGradleFail(dispatch);
    }
};

export const handleInternalGradleSet = (dispatch: Dispatch<InternalGradleSet>) => {
    dispatch({ type: QuestionBankTypes.INTERNAL_GRADLE });
};

export const handleInternalGradleSuccess = (
    dispatch: Dispatch<InternalGradleSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.INTERNAL_GRADLE_SUCCESS,
        payload: response,
        getInternalGradle: response.data
    });
};

export const handleInternalGradleFail = (dispatch: Dispatch<InternalGradleFail>) => {
    dispatch({
        type: QuestionBankTypes.INTERNAL_GRADLE_FAIL
    });
};

// INTERNAL SUBJECT
interface InternalSubjectSet {
    type: QuestionBankTypes.INTERNAL_SUBJECT;
}

interface InternalSubjectSuccess {
    type: QuestionBankTypes.INTERNAL_SUBJECT_SUCCESS;
    payload: any;
}

interface InternalSubjectFail {
    type: QuestionBankTypes.INTERNAL_SUBJECT_FAIL;
}

export const internalSubjectGet = (data: any): ThunkResult<void> => async dispatch => {
    handleInternalSubjectSet(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(QuestionBank.InternalSubjectDropdown+`?internal_grade_id=${data.gradle_id}&academic_year=${data.academicYear}`,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            if (response.data.status === true) {
                handleInternalSubjectSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleInternalSubjectFail(dispatch);
            }
    } catch (e) {
        handleInternalSubjectFail(dispatch);
    }
};

export const handleInternalSubjectSet = (dispatch: Dispatch<InternalSubjectSet>) => {
    dispatch({ type: QuestionBankTypes.INTERNAL_SUBJECT });
};

export const handleInternalSubjectSuccess = (
    dispatch: Dispatch<InternalSubjectSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.INTERNAL_SUBJECT_SUCCESS,
        payload: response,
        getInternalSubject: response.data
    });
};

export const handleInternalSubjectFail = (dispatch: Dispatch<InternalSubjectFail>) => {
    dispatch({
        type: QuestionBankTypes.INTERNAL_SUBJECT_FAIL
    });
};


//EXTERNAL_SUBJECT
interface ExternalSubjectSet {
    type: QuestionBankTypes.EXTERNAL_SUBJECT;
}

interface ExternalSubjectSuccess {
    type: QuestionBankTypes.EXTERNAL_SUBJECT_SUCCESS;
    payload: any;
}

interface ExternalSubjectFail {
    type: QuestionBankTypes.EXTERNAL_SUBJECT_FAIL;
}

export const externalSubjectGet = (id: number): ThunkResult<void> => async dispatch => {
    handleExternalSubjectSet(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(QuestionBank.ExternalSubjectDropdown+`?internal_grade_id=${id}`,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            if (response.data.status === true) {
                handleExternalSubjectSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleExternalSubjectFail(dispatch);
            }
    } catch (e) {
        handleExternalSubjectFail(dispatch);
    }
};

export const handleExternalSubjectSet = (dispatch: Dispatch<ExternalSubjectSet>) => {
    dispatch({ type: QuestionBankTypes.EXTERNAL_SUBJECT });
};

export const handleExternalSubjectSuccess = (
    dispatch: Dispatch<ExternalSubjectSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.EXTERNAL_SUBJECT_SUCCESS,
        payload: response,
        getExternalSubject: response.data
    });
};

export const handleExternalSubjectFail = (dispatch: Dispatch<ExternalSubjectFail>) => {
    dispatch({
        type: QuestionBankTypes.EXTERNAL_SUBJECT_FAIL
    });
};

//LANGUAGE DROPDOWN
interface LanguageDropdownSet {
    type: QuestionBankTypes.LANGUAGE_DROPDOWN;
}

interface LanguageDropdownSuccess {
    type: QuestionBankTypes.LANGUAGE_DROPDOWN_SUCCESS;
    payload: any;
}

interface LanguageDropdownFail {
    type: QuestionBankTypes.LANGUAGE_DROPDOWN_FAIL;
}

export const languageDropdownGet = (id: number): ThunkResult<void> => async dispatch => {
    handleLanguageDropdownSet(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(QuestionBank.LanguageDropdown,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            if (response.data.status === true) {
                handleLanguageDropdownSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleLanguageDropdownFail(dispatch);
            }
    } catch (e) {
        handleLanguageDropdownFail(dispatch);
    }
};

export const handleLanguageDropdownSet = (dispatch: Dispatch<LanguageDropdownSet>) => {
    dispatch({ type: QuestionBankTypes.LANGUAGE_DROPDOWN });
};

export const handleLanguageDropdownSuccess = (
    dispatch: Dispatch<LanguageDropdownSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.LANGUAGE_DROPDOWN_SUCCESS,
        payload: response,
        getLanguage: response.data
    });
};

export const handleLanguageDropdownFail = (dispatch: Dispatch<LanguageDropdownFail>) => {
    dispatch({
        type: QuestionBankTypes.LANGUAGE_DROPDOWN_FAIL
    });
};

//FETCH_SUBJECT_MAPPING_BY_ID
interface FetchSubjectMappingByIdSet {
    type: QuestionBankTypes.FETCH_SUBJECT_MAPPING_BY_ID;
}

interface FetchSubjectMappingByIdSuccess {
    type: QuestionBankTypes.FETCH_SUBJECT_MAPPING_BY_ID_SUCCESS;
    payload: any;
}

interface FetchSubjectMappingByIdFail {
    type: QuestionBankTypes.FETCH_SUBJECT_MAPPING_BY_ID_FAIL;
}

export const fetchSubjectMappingByIdGet = (id: Number): ThunkResult<void> => async dispatch => {
    handleFetchSubjectMappingByIdSet(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(QuestionBank.SubjectMapping+`?uid=${id}`,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            if (response.data.status === true) {
                handleFetchSubjectMappingByIdSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleFetchSubjectMappingByIdFail(dispatch);
            }
    } catch (e) {
        handleFetchSubjectMappingByIdFail(dispatch);
    }
};

export const handleFetchSubjectMappingByIdSet = (dispatch: Dispatch<FetchSubjectMappingByIdSet>) => {
    dispatch({ type: QuestionBankTypes.FETCH_SUBJECT_MAPPING_BY_ID });
};

export const handleFetchSubjectMappingByIdSuccess = (
    dispatch: Dispatch<FetchSubjectMappingByIdSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.FETCH_SUBJECT_MAPPING_BY_ID_SUCCESS,
        payload: response,
        getMappingEdit: response.data
    });
};

export const handleFetchSubjectMappingByIdFail = (dispatch: Dispatch<FetchSubjectMappingByIdFail>) => {
    dispatch({
        type: QuestionBankTypes.FETCH_SUBJECT_MAPPING_BY_ID_FAIL
    });
};

//ADD_SUBJECT_MAPPING
interface AddSubjectMappingSet {
    type: QuestionBankTypes.ADD_SUBJECT_MAPPING;
}

interface AddSubjectMappingSuccess {
    type: QuestionBankTypes.ADD_SUBJECT_MAPPING_SUCCESS;
    payload: any;
}

interface AddSubjectMappingFail {
    type: QuestionBankTypes.ADD_SUBJECT_MAPPING_FAIL;
}

export const addSubjectMapping = (postData: any): ThunkResult<void> => async dispatch => {
    handleAddSubjectMappingSet(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.post(QuestionBank.SubjectMapping, postData,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            if (response.data.status === true) {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.duration
                });
                history.push("/subjectmapping");
                handleAddSubjectMappingSuccess(dispatch, response.data);
            } else {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleAddSubjectMappingFail(dispatch);
            }
    } catch (e) {
        handleAddSubjectMappingFail(dispatch);
    }
};

export const handleAddSubjectMappingSet = (dispatch: Dispatch<AddSubjectMappingSet>) => {
    dispatch({ type: QuestionBankTypes.ADD_SUBJECT_MAPPING });
};

export const handleAddSubjectMappingSuccess = (
    dispatch: Dispatch<AddSubjectMappingSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.ADD_SUBJECT_MAPPING_SUCCESS,
        payload: response,
    });
};

export const handleAddSubjectMappingFail = (dispatch: Dispatch<AddSubjectMappingFail>) => {
    dispatch({
        type: QuestionBankTypes.ADD_SUBJECT_MAPPING_FAIL
    });
};

//EDIT_SUBJECT_MAPPING
interface EditSubjectMappingSet {
    type: QuestionBankTypes.EDIT_SUBJECT_MAPPING;
}

interface EditSubjectMappingSuccess {
    type: QuestionBankTypes.EDIT_SUBJECT_MAPPING_SUCCESS;
    payload: any;
}

interface EditSubjectMappingFail {
    type: QuestionBankTypes.EDIT_SUBJECT_MAPPING_FAIL;
}

export const editSubjectMapping = (postData: any): ThunkResult<void> => async dispatch => {
    handleEditSubjectMappingSet(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.put(QuestionBank.SubjectMapping+`?uid=${postData.id}`, postData,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            if (response.data.status === true) {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.duration
                });
                history.push("/subjectmapping");
                handleEditSubjectMappingSuccess(dispatch, response.data);
            } else {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleEditSubjectMappingFail(dispatch);
            }
    } catch (e) {
        handleEditSubjectMappingFail(dispatch);
    }
};

export const handleEditSubjectMappingSet = (dispatch: Dispatch<EditSubjectMappingSet>) => {
    dispatch({ type: QuestionBankTypes.EDIT_SUBJECT_MAPPING });
};

export const handleEditSubjectMappingSuccess = (
    dispatch: Dispatch<EditSubjectMappingSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.EDIT_SUBJECT_MAPPING_SUCCESS,
        payload: response,
    });
};

export const handleEditSubjectMappingFail = (dispatch: Dispatch<EditSubjectMappingFail>) => {
    dispatch({
        type: QuestionBankTypes.EDIT_SUBJECT_MAPPING_FAIL
    });
};

//DELETE_SUBJECT_MAPPING
interface DeleteSubjectMappingSet {
    type: QuestionBankTypes.DELETE_SUBJECT_MAPPING;
}

interface DeleteSubjectMappingSuccess {
    type: QuestionBankTypes.DELETE_SUBJECT_MAPPING_SUCCESS;
    payload: any;
}

interface DeleteSubjectMappingFail {
    type: QuestionBankTypes.DELETE_SUBJECT_MAPPING_FAIL;
}

export const deleteSubjectMapping = (id: number): ThunkResult<void> => async dispatch => {
    handleDeleteSubjectMappingSet(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.delete(QuestionBank.SubjectMapping+`?uid=${id}`,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            if (response.data.status === true) {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.duration
                });
                history.push("/subjectmapping");
                handleDeleteSubjectMappingSuccess(dispatch, response.data);
            } else {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleDeleteSubjectMappingFail(dispatch);
            }
    } catch (e) {
        handleDeleteSubjectMappingFail(dispatch);
    }
};

export const handleDeleteSubjectMappingSet = (dispatch: Dispatch<DeleteSubjectMappingSet>) => {
    dispatch({ type: QuestionBankTypes.DELETE_SUBJECT_MAPPING });
};

export const handleDeleteSubjectMappingSuccess = (
    dispatch: Dispatch<DeleteSubjectMappingSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.DELETE_SUBJECT_MAPPING_SUCCESS,
        payload: response,
    });
};

export const handleDeleteSubjectMappingFail = (dispatch: Dispatch<DeleteSubjectMappingFail>) => {
    dispatch({
        type: QuestionBankTypes.DELETE_SUBJECT_MAPPING_FAIL
    });
};

//FETCH_SUBJECT_MAPPING
interface SubjectMappingViewSet {
    type: QuestionBankTypes.FETCH_SUBJECT_MAPPING;
}

interface SubjectMappingViewSuccess {
    type: QuestionBankTypes.FETCH_SUBJECT_MAPPING_SUCCESS;
    payload: any;
}

interface SubjectMappingViewFail {
    type: QuestionBankTypes.FETCH_SUBJECT_MAPPING_FAIL;
}

export const SubjectMappingView = (params: any): ThunkResult<void> => async dispatch => {
    handleSubjectMappingViewSet(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.patch(QuestionBank.SubjectMapping, null,
            {
                params: params,
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            if (response.data.status === true) {
                handleSubjectMappingViewSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleSubjectMappingViewFail(dispatch);
            }
    } catch (e) {
        handleSubjectMappingViewFail(dispatch);
    }
};

export const handleSubjectMappingViewSet = (dispatch: Dispatch<SubjectMappingViewSet>) => {
    dispatch({ type: QuestionBankTypes.FETCH_SUBJECT_MAPPING });
};

export const handleSubjectMappingViewSuccess = (
    dispatch: Dispatch<SubjectMappingViewSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.FETCH_SUBJECT_MAPPING_SUCCESS,
        payload: response,
        records: response.data.records,
        per_page: response.data.per_page,
        page: response.data.page,
        total: response.data.total
    });
};

export const handleSubjectMappingViewFail = (dispatch: Dispatch<SubjectMappingViewFail>) => {
    dispatch({
        type: QuestionBankTypes.FETCH_SUBJECT_MAPPING_FAIL
    });
};


interface FetchGenerateQuestions {
    type: QuestionBankTypes.FETCH_GENERATE_QUESTIONS;
}

interface FetchGenerateQuestionsSuccess {
    type: QuestionBankTypes.FETCH_GENERATE_QUESTIONS_SUCCESS;
    payload: any;
}

interface FetchGenerateQuestionsFail {
    type: QuestionBankTypes.FETCH_GENERATE_QUESTIONS_FAIL;
}

export const fetchGenerateQuestions = (postData: any): ThunkResult<void> => async dispatch => {
    handleFetchGenerateQuestions(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.post(QuestionBank.GenerateQuestions, postData,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            if (response.data.status === true) {
                handleFetchGenerateQuestionsSuccess(dispatch, response.data);
                history.push("/viewgeneratequestions");
            } else {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleFetchGenerateQuestionsFail(dispatch);
                history.push("/generatequestions");
            }
    } catch (e) {
        handleFetchGenerateQuestionsFail(dispatch);
    }
};

export const handleFetchGenerateQuestions = (dispatch: Dispatch<FetchGenerateQuestions>) => {
    dispatch({ type: QuestionBankTypes.FETCH_GENERATE_QUESTIONS });
};

export const handleFetchGenerateQuestionsSuccess = (
    dispatch: Dispatch<FetchGenerateQuestionsSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.FETCH_GENERATE_QUESTIONS_SUCCESS,
        payload: response,
    });

};

export const handleFetchGenerateQuestionsFail = (dispatch: Dispatch<FetchGenerateQuestionsFail>) => {
    dispatch({
        type: QuestionBankTypes.FETCH_GENERATE_QUESTIONS_FAIL
    });
};

interface FetchReloadQuestions {
    type: QuestionBankTypes.FETCH_RELOAD_QUESTIONS;
}

interface FetchReloadQuestionsSuccess {
    type: QuestionBankTypes.FETCH_RELOAD_QUESTIONS_SUCCESS;
    payload: any;
}

interface FetchReloadQuestionsFail {
    type: QuestionBankTypes.FETCH_RELOAD_QUESTIONS_FAIL;
}

export const fetchReloadQuestions = (postData: any): ThunkResult<void> => async dispatch => {
    handleFetchReloadQuestions(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.post(QuestionBank.ReloadQuestions, postData,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            if (response.data.status === true) {
                handleFetchReloadQuestionsSuccess(dispatch, response.data);
            } else {
                // toaster.notify(response.data.message, {
                //     position: 'top',
                //     duration: notificationMsg.errorNotificationDuration
                // });
                handleFetchReloadQuestionsFail(dispatch, response.data);
            }
    } catch (e) {
        handleFetchReloadQuestionsFail(dispatch, '');
    }
};

export const handleFetchReloadQuestions = (dispatch: Dispatch<FetchReloadQuestions>) => {
    dispatch({ type: QuestionBankTypes.FETCH_RELOAD_QUESTIONS });
};

export const handleFetchReloadQuestionsSuccess = (
    dispatch: Dispatch<FetchReloadQuestionsSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.FETCH_RELOAD_QUESTIONS_SUCCESS,
        payload: response,
    });

};

export const handleFetchReloadQuestionsFail = (dispatch: Dispatch<FetchReloadQuestionsFail>, response: any) => {
    dispatch({
        type: QuestionBankTypes.FETCH_RELOAD_QUESTIONS_FAIL,
        payload: response,
    });
};

interface fetchBluePrintDropdown {
    type: QuestionBankTypes.FETCH_BLUE_PRINT_DROPDOWN;
}

interface fetchBluePrintDropdownSuccess {
    type: QuestionBankTypes.FETCH_BLUE_PRINT_DROPDOWN_SUCCESS;
    payload: any;
}

interface fetchBluePrintDropdownFail {
    type: QuestionBankTypes.FETCH_BLUE_PRINT_DROPDOWN_FAIL;
}

export const fetchBluePrintDropdownGet = (data: any): ThunkResult<void> => async dispatch => {
    handleBluePrintDropdown(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(QuestionBank.GetBluePrintDropdown+`?academic_year=${data.academic_year}`,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            handleBluePrintDropdownSuccess(dispatch, response.data);
    } catch (e) {
        handleBluePrintDropdownFail(dispatch);
    }
};

export const handleBluePrintDropdown = (dispatch: Dispatch<fetchBluePrintDropdown>) => {
    dispatch({ type: QuestionBankTypes.FETCH_BLUE_PRINT_DROPDOWN });
};

export const handleBluePrintDropdownSuccess = (
    dispatch: Dispatch<fetchBluePrintDropdownSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.FETCH_BLUE_PRINT_DROPDOWN_SUCCESS,
        payload: response,
    });

};

export const handleBluePrintDropdownFail = (dispatch: Dispatch<fetchBluePrintDropdownFail>) => {
    dispatch({
        type: QuestionBankTypes.FETCH_BLUE_PRINT_DROPDOWN_FAIL
    });
};

interface fetchQuestionPaperDropdown {
    type: QuestionBankTypes.FETCH_QUESTION_PAPER_DROPDOWN;
}

interface fetchQuestionPaperDropdownSuccess {
    type: QuestionBankTypes.FETCH_QUESTION_PAPER_DROPDOWN_SUCCESS;
    payload: any;
}

interface fetchQuestionPaperDropdownFail {
    type: QuestionBankTypes.FETCH_QUESTION_PAPER_DROPDOWN_FAIL;
}

export const fetchQuestionPaperDropdownGet = (data: any): ThunkResult<void> => async dispatch => {
    handleQuestionPaperDropdown(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(QuestionBank.GetQuestionPaperDropdown+`?blueprint_id=${data.blueprint_id}&from_date=${data.from_date}&to_date=${data.to_date}`,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            handleQuestionPaperDropdownSuccess(dispatch, response.data);
    } catch (e) {
        handleQuestionPaperDropdownFail(dispatch);
    }
};

export const handleQuestionPaperDropdown = (dispatch: Dispatch<fetchQuestionPaperDropdown>) => {
    dispatch({ type: QuestionBankTypes.FETCH_QUESTION_PAPER_DROPDOWN });
};

export const handleQuestionPaperDropdownSuccess = (
    dispatch: Dispatch<fetchQuestionPaperDropdownSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.FETCH_QUESTION_PAPER_DROPDOWN_SUCCESS,
        payload: response,
    });

};

export const handleQuestionPaperDropdownFail = (dispatch: Dispatch<fetchQuestionPaperDropdownFail>) => {
    dispatch({
        type: QuestionBankTypes.FETCH_QUESTION_PAPER_DROPDOWN_FAIL
    });
};


interface FetchQuestionPaper {
    type: QuestionBankTypes.FETCH_QUESTION_PAPER;
}

interface FetchQuestionPaperSuccess {
    type: QuestionBankTypes.FETCH_QUESTION_PAPER_SUCCESS;
    payload: any;
}

interface FetchQuestionPaperFail {
    type: QuestionBankTypes.FETCH_QUESTION_PAPER_FAIL;
}

export const fetchQuestionPaperGet = (params: any): ThunkResult<void> => async dispatch => {
    handleFetchQuestionPaper(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.patch(QuestionBank.CreateQuestionpaper, null, {
                params: params,
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            if (response.data.status === true) {
                handleFetchQuestionPaperSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleFetchQuestionPaperFail(dispatch);
            }
    } catch (e) {
        handleFetchQuestionPaperFail(dispatch);
    }
};

export const handleFetchQuestionPaper = (dispatch: Dispatch<FetchQuestionPaper>) => {
    dispatch({ type: QuestionBankTypes.FETCH_QUESTION_PAPER });
};

export const handleFetchQuestionPaperSuccess = (
    dispatch: Dispatch<FetchQuestionPaperSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.FETCH_QUESTION_PAPER_SUCCESS,
        payload: response,
        records: response.data.records,
        per_page: response.data.per_page,
        page: response.data.page,
        total: response.data.total
    });
};

export const handleFetchQuestionPaperFail = (dispatch: Dispatch<FetchQuestionPaperFail>) => {
    dispatch({
        type: QuestionBankTypes.FETCH_QUESTION_PAPER_FAIL
    });
};

interface createQuestionPaper {
    type: QuestionBankTypes.CREATE_QUESTION_PAPER;
}

interface createQuestionPaperSuccess {
    type: QuestionBankTypes.CREATE_QUESTION_PAPER_SUCCESS;
    payload: any;
}

interface createQuestionPaperFail {
    type: QuestionBankTypes.CREATE_QUESTION_PAPER_FAIL;
}

export const createQuestionPaper = (postData: any): ThunkResult<void> => async dispatch => {
    handleCreateQuestionPaperSet(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.post(QuestionBank.CreateQuestionpaper, postData,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            if (response.data.status === true) {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.duration
                });
                history.push("/questionpaper");
                handleCreateQuestionPaperSuccess(dispatch, response.data);
            } else {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleCreateQuestionPaperFail(dispatch);
            }
    } catch (e) {
        handleCreateQuestionPaperFail(dispatch);
    }
};

export const handleCreateQuestionPaperSet = (dispatch: Dispatch<createQuestionPaper>) => {
    dispatch({ type: QuestionBankTypes.CREATE_QUESTION_PAPER });
};

export const handleCreateQuestionPaperSuccess = (
    dispatch: Dispatch<createQuestionPaperSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.CREATE_QUESTION_PAPER_SUCCESS,
        payload: response,
    });
};

export const handleCreateQuestionPaperFail = (dispatch: Dispatch<createQuestionPaperFail>) => {
    dispatch({
        type: QuestionBankTypes.CREATE_QUESTION_PAPER_FAIL
    });
};

interface fetchDownloadQuestionPaper {
    type: QuestionBankTypes.DOWNLOAD_QUESTION_PAPER;
}

interface fetchDownloadQuestionPaperSuccess {
    type: QuestionBankTypes.DOWNLOAD_QUESTION_PAPER_SUCCESS;
    payload: any;
}

interface fetchDownloadQuestionPaperFail {
    type: QuestionBankTypes.DOWNLOAD_QUESTION_PAPER_FAIL;
}

export const fetchDownloadQuestionPaperGet = (data: any): ThunkResult<void> => async dispatch => {
    handleDownloadQuestionPaper(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(QuestionBank.DownloadQuestionpaper+`?question_paper_id=${data.question_paper_id}`,
            {
                headers: {"Authorization" : localStorage.getItem('token')},
                responseType: 'blob',
            });

         console.log(response)

         const dispositionHeader = response.headers['content-disposition'];
         const matches = /filename="([^"]+)"/.exec(dispositionHeader);
         const filename = matches && matches[1] ? matches[1] : 'downloaded_file.pdf';
         
    //    const url = window.URL.createObjectURL(new Blob([response.data]));
    //    const link = document.createElement('a');
    //    link.href = url;
    //    link.setAttribute('download',filename); //or any other extension
    //    document.body.appendChild(link);
    //    link.click();

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(a);

            handleDownloadQuestionPaperSuccess(dispatch, response.data);
    } catch (e) {
        handleDownloadQuestionPaperFail(dispatch);
    }
};

export const handleDownloadQuestionPaper = (dispatch: Dispatch<fetchDownloadQuestionPaper>) => {
    dispatch({ type: QuestionBankTypes.DOWNLOAD_QUESTION_PAPER });
};

export const handleDownloadQuestionPaperSuccess = (
    dispatch: Dispatch<fetchDownloadQuestionPaperSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.DOWNLOAD_QUESTION_PAPER_SUCCESS,
        payload: response,
    });

};

export const handleDownloadQuestionPaperFail = (dispatch: Dispatch<fetchDownloadQuestionPaperFail>) => {
    dispatch({
        type: QuestionBankTypes.DOWNLOAD_QUESTION_PAPER_FAIL
    });
};

interface DeleteQuestionPaper {
    type: QuestionBankTypes.DELETE_QUESTION_PAPER;
}

interface DeleteQuestionPaperSuccess {
    type: QuestionBankTypes.DELETE_QUESTION_PAPER_SUCCESS;
    payload: any;
}

interface DeleteQuestionPaperFail {
    type: QuestionBankTypes.DELETE_QUESTION_PAPER_FAIL;
}

export const deleteQuestionPaper = (id: number): ThunkResult<void> => async dispatch => {
    handleDeleteQuestionPaper(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.delete(QuestionBank.CreateQuestionpaper+`?uid=${id}`,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            if (response.data.status === true) {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.duration
                });
                history.push("/questionpaper");
                handleDeleteQuestionPaperSuccess(dispatch, response.data);
            } else {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleDeleteQuestionPaperFail(dispatch);
            }
    } catch (e) {
        handleDeleteQuestionPaperFail(dispatch);
    }
};

export const handleDeleteQuestionPaper = (dispatch: Dispatch<DeleteQuestionPaper>) => {
    dispatch({ type: QuestionBankTypes.DELETE_QUESTION_PAPER });
};

export const handleDeleteQuestionPaperSuccess = (
    dispatch: Dispatch<DeleteQuestionPaperSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.DELETE_QUESTION_PAPER_SUCCESS,
        payload: response,
    });
};

export const handleDeleteQuestionPaperFail = (dispatch: Dispatch<DeleteQuestionPaperFail>) => {
    dispatch({
        type: QuestionBankTypes.DELETE_QUESTION_PAPER_FAIL
    });
};

interface FetchFeedbackQuestions {
    type: QuestionBankTypes.FETCH_FEEDBACK_QUESTIONS;
}

interface FetchFeedbackQuestionsSuccess {
    type: QuestionBankTypes.FETCH_FEEDBACK_QUESTIONS_SUCCESS;
    payload: any;
}
interface FetchFeedbackQuestionsFail {
    type: QuestionBankTypes.FETCH_FEEDBACK_QUESTIONS_FAIL;
}

export const fetchFeedbackQuestionsGet = (params: any): ThunkResult<void> => async dispatch => {
    handleFetchFeedbackQuestions(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.patch(QuestionBank.FeedbackQuestions, null, {
                params: params,
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            if (response.data.status === true) {
                handleFetchFeedbackQuestionsSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleFetchFeedbackQuestionsFail(dispatch);
            }
    } catch (e) {
        handleFetchFeedbackQuestionsFail(dispatch);
    }
};

export const handleFetchFeedbackQuestions = (dispatch: Dispatch<FetchFeedbackQuestions>) => {
    dispatch({ type: QuestionBankTypes.FETCH_FEEDBACK_QUESTIONS });
};

export const handleFetchFeedbackQuestionsSuccess = (
    dispatch: Dispatch<FetchFeedbackQuestionsSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.FETCH_FEEDBACK_QUESTIONS_SUCCESS,
        payload: response,
        records: response.data.records,
        per_page: response.data.per_page,
        page: response.data.page,
        total: response.data.total
    });
};

export const handleFetchFeedbackQuestionsFail = (dispatch: Dispatch<FetchFeedbackQuestionsFail>) => {
    dispatch({
        type: QuestionBankTypes.FETCH_FEEDBACK_QUESTIONS_FAIL
    });
};

//FETCH_FEEDBACK_QUESTIONS

interface FetchFeedbackQuestionsByIdSet {
    type: QuestionBankTypes.FETCH_FEEDBACK_QUESTIONS_BY_ID;
}

interface FetchFeedbackQuestionsByIdSuccess {
    type: QuestionBankTypes.FETCH_FEEDBACK_QUESTIONS_BY_ID_SUCCESS;
    payload: any;
}

interface FetchFeedbackQuestionsByIdFail {
    type: QuestionBankTypes.FETCH_FEEDBACK_QUESTIONS_BY_ID_FAIL;
}

export const fetchFeedbackQuestionsByIdGet = (id: Number): ThunkResult<void> => async dispatch => {
    handleFetchFeedbackQuestionsByIdSet(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(QuestionBank.FeedbackQuestions+`?uid=${id}`,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            if (response.data.status === true) {
                handleFetchFeedbackQuestionsByIdSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleFetchFeedbackQuestionsByIdFail(dispatch);
            }
    } catch (e) {
        handleFetchFeedbackQuestionsByIdFail(dispatch);
    }
};

export const handleFetchFeedbackQuestionsByIdSet = (dispatch: Dispatch<FetchFeedbackQuestionsByIdSet>) => {
    dispatch({ type: QuestionBankTypes.FETCH_FEEDBACK_QUESTIONS_BY_ID });
};

export const handleFetchFeedbackQuestionsByIdSuccess = (
    dispatch: Dispatch<FetchFeedbackQuestionsByIdSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.FETCH_FEEDBACK_QUESTIONS_BY_ID_SUCCESS,
        payload: response,
        getMappingEdit: response.data
    });
};

export const handleFetchFeedbackQuestionsByIdFail = (dispatch: Dispatch<FetchFeedbackQuestionsByIdFail>) => {
    dispatch({
        type: QuestionBankTypes.FETCH_FEEDBACK_QUESTIONS_BY_ID_FAIL
    });
};

//ADD_FEEDBACK_QUESTIONS
interface AddFeedbackQuestionsSet {
    type: QuestionBankTypes.ADD_FEEDBACK_QUESTIONS;
}

interface AddFeedbackQuestionsSuccess {
    type: QuestionBankTypes.ADD_FEEDBACK_QUESTIONS_SUCCESS;
    payload: any;
}

interface AddFeedbackQuestionsFail {
    type: QuestionBankTypes.ADD_FEEDBACK_QUESTIONS_FAIL;
}

export const addFeedbackQuestions = (postData: any): ThunkResult<void> => async dispatch => {
    handleAddFeedbackQuestionsSet(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.post(QuestionBank.FeedbackQuestions, postData,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            if (response.data.status === true) {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.duration
                });
                history.push("/feedbackquestion");
                handleAddFeedbackQuestionsSuccess(dispatch, response.data);
            } else {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleAddFeedbackQuestionsFail(dispatch);
            }
    } catch (e) {
        handleAddFeedbackQuestionsFail(dispatch);
    }
};

export const handleAddFeedbackQuestionsSet = (dispatch: Dispatch<AddFeedbackQuestionsSet>) => {
    dispatch({ type: QuestionBankTypes.ADD_FEEDBACK_QUESTIONS });
};

export const handleAddFeedbackQuestionsSuccess = (
    dispatch: Dispatch<AddFeedbackQuestionsSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.ADD_FEEDBACK_QUESTIONS_SUCCESS,
        payload: response,
    });
};

export const handleAddFeedbackQuestionsFail = (dispatch: Dispatch<AddFeedbackQuestionsFail>) => {
    dispatch({
        type: QuestionBankTypes.ADD_FEEDBACK_QUESTIONS_FAIL
    });
};

//EDIT_FEEDBACK_QUESTIONS
interface EditFeedbackQuestionsSet {
    type: QuestionBankTypes.EDIT_FEEDBACK_QUESTIONS;
}

interface EditFeedbackQuestionsSuccess {
    type: QuestionBankTypes.EDIT_FEEDBACK_QUESTIONS_SUCCESS;
    payload: any;
}

interface EditFeedbackQuestionsFail {
    type: QuestionBankTypes.EDIT_FEEDBACK_QUESTIONS_FAIL;
}

export const editFeedbackQuestions = (postData: any): ThunkResult<void> => async dispatch => {
    handleEditFeedbackQuestionsSet(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.put(QuestionBank.FeedbackQuestions+`?uid=${postData.uid}`, postData,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            if (response.data.status === true) {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.duration
                });
                history.push("/feedbackquestion");
                handleEditFeedbackQuestionsSuccess(dispatch, response.data);
            } else {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleEditFeedbackQuestionsFail(dispatch);
            }
    } catch (e) {
        handleEditFeedbackQuestionsFail(dispatch);
    }
};

export const handleEditFeedbackQuestionsSet = (dispatch: Dispatch<EditFeedbackQuestionsSet>) => {
    dispatch({ type: QuestionBankTypes.EDIT_FEEDBACK_QUESTIONS });
};

export const handleEditFeedbackQuestionsSuccess = (
    dispatch: Dispatch<EditFeedbackQuestionsSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.EDIT_FEEDBACK_QUESTIONS_SUCCESS,
        payload: response,
    });
};

export const handleEditFeedbackQuestionsFail = (dispatch: Dispatch<EditFeedbackQuestionsFail>) => {
    dispatch({
        type: QuestionBankTypes.EDIT_FEEDBACK_QUESTIONS_FAIL
    });
};

//DELETE_FEEDBACK_QUESTIONS
interface DeleteFeedbackQuestionsSet {
    type: QuestionBankTypes.DELETE_FEEDBACK_QUESTIONS;
}

interface DeleteFeedbackQuestionsSuccess {
    type: QuestionBankTypes.DELETE_FEEDBACK_QUESTIONS_SUCCESS;
    payload: any;
}

interface DeleteFeedbackQuestionsFail {
    type: QuestionBankTypes.DELETE_FEEDBACK_QUESTIONS_FAIL;
}

export const deleteFeedbackQuestions = (id: number): ThunkResult<void> => async dispatch => {
    handleDeleteFeedbackQuestionsSet(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.delete(QuestionBank.FeedbackQuestions+`?uid=${id}`,
            {
                headers: {"Authorization" : localStorage.getItem('token')}
            });
            if (response.data.status === true) {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.duration
                });
                history.push("/feedbackquestion");
                handleDeleteFeedbackQuestionsSuccess(dispatch, response.data);
            } else {
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleDeleteFeedbackQuestionsFail(dispatch);
            }
    } catch (e) {
        handleDeleteFeedbackQuestionsFail(dispatch);
    }
};

export const handleDeleteFeedbackQuestionsSet = (dispatch: Dispatch<DeleteFeedbackQuestionsSet>) => {
    dispatch({ type: QuestionBankTypes.DELETE_FEEDBACK_QUESTIONS });
};

export const handleDeleteFeedbackQuestionsSuccess = (
    dispatch: Dispatch<DeleteFeedbackQuestionsSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.DELETE_FEEDBACK_QUESTIONS_SUCCESS,
        payload: response,
    });
};

export const handleDeleteFeedbackQuestionsFail = (dispatch: Dispatch<DeleteFeedbackQuestionsFail>) => {
    dispatch({
        type: QuestionBankTypes.DELETE_FEEDBACK_QUESTIONS_FAIL
    });
};

interface fetchDownloadOMRSheet {
    type: QuestionBankTypes.DOWNLOAD_OMR_SHEET;
}

interface fetchDownloadOMRSheetSuccess {
    type: QuestionBankTypes.DOWNLOAD_OMR_SHEET_SUCCESS;
    payload: any;
}

interface fetchDownloadOMRSheetFail {
    type: QuestionBankTypes.DOWNLOAD_OMR_SHEET_FAIL;
}

export const fetchDownloadOMRSheetGet = (data: any): ThunkResult<void> => async dispatch => {
    handleDownloadOMRSheet(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(QuestionBank.DownloadOMRSheet+`?question_paper_id=${data.question_paper_id}&grade_id=${data.grade_id}`,
            {
                headers: {"Authorization" : localStorage.getItem('token')},
                responseType: 'blob',
            });

         const dispositionHeader = response.headers['content-disposition'];
         const matches = /filename="([^"]+)"/.exec(dispositionHeader);
         const filename = matches && matches[1] ? matches[1] : 'downloaded_omr_sheet.pdf';

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(a);

        handleDownloadOMRSheetSuccess(dispatch, response.data);
    } catch (e) {
        handleDownloadOMRSheetFail(dispatch);
    }
};

export const handleDownloadOMRSheet = (dispatch: Dispatch<fetchDownloadOMRSheet>) => {
    dispatch({ type: QuestionBankTypes.DOWNLOAD_OMR_SHEET });
};

export const handleDownloadOMRSheetSuccess = (
    dispatch: Dispatch<fetchDownloadOMRSheetSuccess>,
    response: any
) => {
    dispatch({
        type: QuestionBankTypes.DOWNLOAD_OMR_SHEET_SUCCESS,
        payload: response,
    });

};

export const handleDownloadOMRSheetFail = (dispatch: Dispatch<fetchDownloadOMRSheetFail>) => {
    dispatch({
        type: QuestionBankTypes.DOWNLOAD_OMR_SHEET_FAIL
    });
};
export type QuestionBankAction = | FetchExamTypeSuccess