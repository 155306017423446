import React, { Component } from 'react'
import { connect } from 'react-redux'
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import SubjectPerformanceViews from './SubjectPerformanceViews';
import TopicAnalysisViews from './TopicAnalysisViews';

export type PostsTeacherProps = {
	loading: boolean;
}

export class TeacherDashboard extends Component<PostsTeacherProps> {

	componentDidMount(): void {
		window.scrollTo(0, 0);
	}

	render() {	
		const { loading } = this.props;
		return (
			<div>
				<div className="row">
					<div className="col-lg-6 col-md-12 col-sm-12 col-12">
						<TopicAnalysisViews/>
					</div>
					<div className="col-lg-6 col-md-12 col-sm-12 col-12">
						<SubjectPerformanceViews/>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state: any) => {
	return {
		loading:state.dashboard.loading
	}
}

export default connect(mapStateToProps, {})(TeacherDashboard)
