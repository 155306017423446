import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import axios from 'axios';
import { connect } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import { DashboardAPI } from "../../services/Config";
import { DelTeachAtt,DelStudAtt,DelTodayQuiz,DelTodayPsych } from "../../store/psychometric/Actions";

// Import the enum
import { DemoDel } from '../../router/Roles';

interface DemoDeleteProps {
  loading: boolean;
  getDownloadFile: any;
  getProfile: any;
  DelTeachAtt: (postvalue?: any) => any;
  DelStudAtt: (postvalue?: any) => any;
  DelTodayQuiz:(postvalue?: any) => any;
  DelTodayPsych:(postvalue?: any) => any;
  delteacheratt: any;
  delstudatt:any;
  deltodayquiz:any
  deltodaypsych:any
}

interface DemoDeleteState {
  responseData: any;
}

class DemoDelete extends Component<DemoDeleteProps, DemoDeleteState> {
  constructor(props: DemoDeleteProps) {
    super(props);
    this.state = {
      responseData: null
    };
  }

  handleDeleteTeacherAttendanceDemo = async () => {
    try {
      const response = await this.props.DelTeachAtt();
    } catch (error) {
      console.error('Error deleting teacher attendance demo:', error);
    }
  };

  
  handleDeleteStudentAttendanceDemo = async () => {
    try {
      const response = await this.props.DelStudAtt();
    } catch (error) {
      console.error('Error deleting Student attendance demo:', error);
    }
  };

  handleDeleteTodayQuiz = async () => {
    try {
      const response = await this.props.DelTodayQuiz();
    } catch (error) {
      console.error('Error deleting today quiz demo:', error);
    }
  };

  handleDeleteTodayPsych = async () => {
    try {
      const response = await this.props.DelTodayPsych();
    } catch (error) {
      console.error('Error deleting today quiz demo:', error);
    }
  };

  render() {
    const { loading } = this.props;
    const { responseData } = this.state;

    return (

      <div className="page-wrapper">
        <div className="page-content-wrapper">
          <BreadCrumb
            titleName={["Delete"]}
            homeName={["Home"]}
            url={['dashboard']}
            mainPageTitle={["Delete"]}
          />
   
          <div>
          <Button 
            style={{ 
              background: 'radial-gradient(circle, rgba(230,81,222,1) 0%, rgba(34,238,233,1) 100%)',
              color: 'white',
              padding: '25px',
              border: 'none',
              borderRadius: '40px',
              fontSize: '15px',
              cursor: 'pointer'
            }}
            onClick={this.handleDeleteTeacherAttendanceDemo}
            disabled={loading}>
            Teacher Attendance   <i className="fa fa-trash-o ml-3"></i>
          </Button>
          {loading && <SpinnerLoader />}
          </div>
          <br></br>

          <div>
          <Button 
            style={{ 
              background: 'radial-gradient(circle, rgba(230,81,222,1) 0%, rgba(34,238,233,1) 100%)',
              color: 'white',
              padding: '25px',
              border: 'none',
              borderRadius: '40px',
              fontSize: '15px',
              cursor: 'pointer'
            }}
            onClick={this.handleDeleteStudentAttendanceDemo}
            disabled={loading}>
            Student Attendance   <i className="fa fa-trash-o ml-3"></i>
          </Button>
          {loading && <SpinnerLoader />}
          </div>
          

          <br></br>

          <div>
          <Button 
            style={{ 
              background: 'radial-gradient(circle, rgba(230,81,222,1) 0%, rgba(34,238,233,1) 100%)',
              color: 'white',
              padding: '25px',
              border: 'none',
              borderRadius: '40px',
              fontSize: '15px',
              cursor: 'pointer'
            }}
            onClick={this.handleDeleteTodayQuiz}
            disabled={loading}>
            Quiz   <i className="fa fa-trash-o ml-3"></i>
          </Button>
          {loading && <SpinnerLoader />}
          </div>

          <br></br>

          <div>
          <Button 
            style={{ 
              background: 'radial-gradient(circle, rgba(230,81,222,1) 0%, rgba(34,238,233,1) 100%)',
              color: 'white',
              padding: '25px',
              border: 'none',
              borderRadius: '40px',
              fontSize: '15px',
              cursor: 'pointer'
            }}
            onClick={this.handleDeleteTodayPsych}
            disabled={loading}>
            Psychometric Test   <i className="fa fa-trash-o ml-3"></i>
          </Button>
          {loading && <SpinnerLoader />}
          </div>


        
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    loading: state.psychometric.loading,
    delteacheratt: state.psychometric.delteacheratt,
    delstudatt: state.psychometric.delstudatt,
    deltodayquiz: state.psychometric.deltodayquiz,
    deltodaypsych: state.psychometric.deltodaypsych

  };
};

export default connect(mapStateToProps, { DelTeachAtt, DelStudAtt,DelTodayQuiz,DelTodayPsych  })(DemoDelete);