import React from 'react';
import { connect } from 'react-redux';
import { SubjectFieldsType, CategoryFieldsType } from '../../store/subject/Types'
import { fetchCategoryPost, addSubject, getSubjectList, getSubjectListPost } from '../../store/subject/Actions'
import BreadCrumb from '../../components/BreadCrumb'
import history from '../../History';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import { Link } from 'react-router-dom';
import { Button, FormGroup, MenuItem, Checkbox  } from '@material-ui/core';
import { Field, Formik, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import { SubjectValidation } from './SubjectValidation';
import { getAllSchoolNameList, ResetUserManage } from '../../store/usermanage/Actions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {icon, checkedIcon} from '../../components/checkBoxTypes';
import { studentValida } from '../../services/Constants';

const initialSchoolAdd: SubjectFieldsType = {
    category: '',
    short_name: '',
    school_id: '',
    created_by: '',
    name: '',
    subject_name: ''
}
export type OwnSubjectFormProps = {
    categoryDetails: CategoryFieldsType[],
    addSubject: (subject: SubjectFieldsType) => any;
    fetchCategoryPost: () => void;
    getAllSchoolNameList:()=>any;
    ResetUserManage:()=>any;
    getSubjectList:() => any;
    getSubjectListPost:(postValue:any)=>any;
    loading: boolean;
    errorMessage: any;
    schoolNameList:any;
    getSubjectItems:any;
    getUpdateSchoolList:any;
};
class AddNewSubject extends React.Component<OwnSubjectFormProps, SubjectFieldsType> {
    formik:any;
    constructor(props:any){
        super(props);
        this.state = {
            school_name:null,
            get_Subject_id:null,
            getSubjectEditDetail:[],
            checkSchoolList:false,
            get_Subject:null,
            created_by:null
        }
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.props.fetchCategoryPost();
        this.props.getSubjectList();
        this.props.getAllSchoolNameList()
    }
    // get school name list
    getSchoolNameList = (getValue:any, getUpdateValue?:any, response?:any) =>{
        if(getValue && getValue.length > 0 && getValue !== null && getValue !== undefined){
            let getSchoolName:any = [];
            getValue.forEach((items:any)=>{
                getSchoolName.push(items.id)
            })
            this.setState({school_name:getSchoolName})
        }
    }
    getSubjectValue = (e:any) => {
        const {getSubjectItems} = this.props;
        const {value} = e.target;
        if(getSubjectItems && getSubjectItems !== null && getSubjectItems !== undefined){
            getSubjectItems.forEach((SubjectName:any) => {
                if(SubjectName.name === value){
                    this.formik.setFieldValue('short_name', SubjectName.short_name)
                    this.setState({get_Subject_id:SubjectName.id, get_Subject:SubjectName.short_name})
                    if(this.state.created_by && value && this.state.get_Subject){
                        let postValue:any = {
                            subject_id:SubjectName.id,
                            category:this.state.created_by,
                            short_name:SubjectName.short_name
                        }
                        this.checkSchoolData(postValue)
                    }
                }
                
            })
        }
    }

    getSchoolList = (e:any)=>{
        const {value} = e.target;
        if(this.state.get_Subject_id && value && this.state.get_Subject){
            let postValue:any = {
                subject_id:this.state.get_Subject_id,
                category:value,
                short_name:this.state.get_Subject
            }
            this.checkSchoolData(postValue)
            this.setState({created_by:value})
        }
       
    }
checkSchoolData = (postValue?:any) =>{
    this.setState({school_name:null, checkSchoolList:true})
    this.props.getSubjectListPost(postValue).then((res:any)=>{
        let updateSubjectList:any;
        if(this.props.getUpdateSchoolList !== null && this.props.getUpdateSchoolList !== undefined && this.props.getUpdateSchoolList.length > 0){
            this.props.getUpdateSchoolList.forEach((items:any)=>{
                updateSubjectList = []
                items.school_list.forEach((item:any)=>{
                    let getClassId:any = this.props.schoolNameList.find((schoolName:any)=> schoolName.id === parseInt(item.school_id))
                    updateSubjectList.push(getClassId)
                })
            })
            this.setState({school_name:updateSubjectList, checkSchoolList:false})
        }else{
            
    this.setState({school_name:null, checkSchoolList:true})
        }
    })
}
    render() {
        const { loading, schoolNameList, getSubjectItems } = this.props;
        const {school_name} = this.state;
        const loadingNewSubject = { display: loading ? "block" : "none" };
        const getToken = localStorage.getItem('token');
        if (!getToken) {
            history.push("/");
        }
        const getCategoryDetails:any = this.props.categoryDetails;
        const getCategoryList = getCategoryDetails;
        let categorySubjectData:any;
        if(getCategoryList){
            categorySubjectData = getCategoryList.data;
        }       
        return (
            <div>
                <div className="page-wrapper">
                    <div className="page-content-wrapper">
                        <div className="page-content pt-3">
                            <BreadCrumb 
                            titleName={['Subject']} 
                            homeName={['Home']} 
                            url={['dashboard']} 
                            baseName={['Subject']} 
                            baseURL={['subject']} 
                            mainPageTitle={['Map Subject']} />
                            <div className="row">
                                <div className="col-md-12">
                             <div className="card-box">
                                <div className="card-head">
                                    <header>Map Subject</header>
                                </div>
                                <div className="card-body">
                            { categorySubjectData && getSubjectItems ?                                 
                                <Formik
                                ref={node=>this.formik = node}
                                initialValues={initialSchoolAdd}
                                onSubmit={(values: SubjectFieldsType, actions) =>{
                                    const {school_name, get_Subject_id} = this.state;
                                    let postValue:any = {
                                        subject_id:get_Subject_id,
                                        category: values.category,
                                        name: values.name,
                                        school_list: school_name,
                                        short_name: values.short_name
                                    }
                                    this.props.addSubject(postValue)
                                }}
                                validationSchema={SubjectValidation}
                                render={({
                                    values, errors, isSubmitting, isValidating, dirty, touched, handleSubmit, setFieldValue
                                }: FormikProps<SubjectFieldsType>) => {
                                const isEmpty = (!values.name || !values.short_name || !values.category || !this.state.school_name);
                                return(
                                    <form onSubmit={handleSubmit}>
                                    <div>
                                        <div className="row">
                                            <div className="col-md-6 p-t-20">

                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                    <FormGroup>
                                                        <Field
                                                            label={<><span>Subject Name</span><span className='formmandatorycolor'> *</span></>}
                                                            select
                                                            type="text"
                                                            name="name"
                                                            component={TextField}
                                                            className="textfield__input"
                                                            disabled={false}
                                                            onChange={(e:any) => {
                                                                this.formik.setFieldValue('name', e.target.value)
                                                                this.getSubjectValue(e)
                                                              }}  
                                                        >
                                                          {getSubjectItems.map((item: any) => (
                                                                    <MenuItem value={item.name}>{item.name}</MenuItem>
                                                                ))}
                                                        </Field>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="col-md-6 p-t-20">
                                                <div>
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                        <FormGroup>
                                                            <Field
                                                                label={<><span>Short Name</span><span className='formmandatorycolor'> *</span></>}
                                                                type="text"
                                                                name="short_name"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={true}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" row">
                                            <div className="col-md-6">
                                                <div>
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-2">
                                                        <FormGroup>
                                                            <Field
                                                                label={<><span>Category</span><span className='formmandatorycolor'> *</span></>}
                                                                name="category"
                                                                select
                                                                component={TextField}
                                                                className="textfield__input"
                                                                fullwidth="true"
                                                                disabled={false}
                                                                onChange={(e:any) => {
                                                                    this.formik.setFieldValue('category', e.target.value)
                                                                    this.getSchoolList(e)
                                                                  }} 
                                                            >
                                                                {categorySubjectData.map((item: any) => (
                                                                    <MenuItem value={item.value}>{item.value}</MenuItem>
                                                                ))}
                                                            </Field>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                            {schoolNameList && school_name === null?
                                            <div className="col-md-6">
                                                <Autocomplete
                                                fullWidth
                                                multiple
                                                id="school_name"
                                                options={schoolNameList}
                                                disableCloseOnSelect
                                                noOptionsText={studentValida.noRecordData}
                                                getOptionLabel={(option:any) => option.school_name}
                                                onChange={(e:any, getvalue:any, reason) => {
                                                    setFieldValue('school_name', e.target.value)
                                                    this.getSchoolNameList(getvalue, getvalue, reason)
                                                }}
                                                renderOption={(option, { selected }) => (
                                                    <React.Fragment>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.school_name}
                                                    </React.Fragment>
                                                )}
                                                className="mb-0 mt-1"
                                                renderInput={(params) => {
                                                    return(
                                                    <Field component={TextField} {...params} label={<><span>Search School Name</span><span className='formmandatorycolor'> *</span></>} name="school_name"/>
                                                )}}
                                                />
                                                </div>
                                                :null}
                                                {schoolNameList && school_name !== null && school_name !== undefined?
                                            <div className="col-md-6">
                                                <Autocomplete
                                                fullWidth
                                                multiple
                                                id="school_name"
                                                options={schoolNameList.length?schoolNameList:[]}
                                                disableCloseOnSelect
                                                noOptionsText={studentValida.noRecordData}
                                                defaultValue={school_name} 
                                                getOptionLabel={(option:any) => option.school_name}
                                                onChange={(e:any, getvalue:any, reason) => {
                                                    setFieldValue('school_name', e.target.value)
                                                    this.getSchoolNameList(getvalue, getvalue, reason)
                                                }}
                                                renderOption={(option, { selected }) => (
                                                    <React.Fragment>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.school_name}
                                                    </React.Fragment>
                                                )}
                                                className="mb-0 mt-1"
                                                renderInput={(params) => {
                                                    return(
                                                    <Field component={TextField} {...params} label={<><span>Search School Name</span><span className='formmandatorycolor'> *</span></>} name="school_name"/>
                                                )}}
                                                />
                                                </div>
                                                :null}
                                            
                                        </div>
                                        <div className="text-right mb-3 mr-2 mt-4">
                                            <Button 
                                            className="btn btn-pink mr-1 ml-1" 
                                            disabled={isEmpty || isValidating || !dirty ||
                                                !!(errors.category && touched.category)||
                                                !!(errors.short_name && touched.short_name) || 
                                                !!(errors.name && touched.name)} type="submit">Submit</Button>
                                            <Link to="/subject">
                                            <Button 
                                            className="btn btn-default mr-1 ml-1 ">Cancel</Button>
                                            </Link>
                                        </div>
                                    </div>
                                </form>
                                )}
                            }
                                />                                         
                                : <div><SpinnerLoader/></div>}
                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={loadingNewSubject}><SpinnerLoader /></div>
            </div>

        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        schoolNameList: state.userManage.category,
        categoryDetails: state.subjects.category,
        loading: state.subjects.loading,
        errorMessage: state.subjects.errors,
        getSubjectItems: state.subjects.getAllSubjectList,
        getUpdateSchoolList:state.subjects.getSubjectList
    };
};
export default connect(
    mapStateToProps, { fetchCategoryPost, addSubject, getAllSchoolNameList, getSubjectList, ResetUserManage, getSubjectListPost }
)(AddNewSubject);



