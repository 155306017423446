import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb'
import {getAllSchoolNameList} from '../../store/usermanage/Actions';
import { getClassesReport } from '../../store/dashboard/Actions';
import CommonLoader from '../../components/CommonLoader';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import history from '../../History';
import { getClassesReportData } from '../../store/classes/Actions';
import InfiniteScroll from 'react-infinite-scroll-component';
import Cookies from 'universal-cookie';
import { fetchProfilePost, getQuizzesReportData, stopYearData, YearListGet } from '../../store/profile/Actions';
import { PropsTypes } from './Types';

export interface PostsClassesProps extends RouteComponentProps<OwnPropsParams>{
    getYear?:any,
    loginProfile?: any,
    getAllSchoolNameList: () => any;
    getClassesReport: (postValue:any) => any;
    getClassesReportData:(postValue?:any) => any;
    fetchProfilePost:() => any;
	stopYearData:() => any;
    YearListGet: (postValue?: any) => any;
    loading?: boolean,
    stopYear?:any,
    getSchoolList?:any,
    ClassesReport?:any,
    total?:any, 
    perPage?:any,
    pageNo?:any,
    getQuizzesReportData:(postValue:any)=>any
}

export class Classes extends Component<PostsClassesProps, PropsTypes> {
    cookies:any = new Cookies();
    getClassPropsValue:any;
    constructor(props: any) {
        super(props);
        this.state = {
            getCorrectSchoolId: this.props.match.params.id || null,
            getCurrectYear: null,
            hasMore: true,
            academicYear:null,
            prev: 0,
            next: 10,
            acsOrder: true,
            descOrder: false,
            page: 1,
            per_page: 10,
            records: [],
            total: 0,
            totalPage: 0,
            search: '',
            SortOrderData:'',
            OrderNameData:''
        }
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);
        const postViewQuzzis:any = []
        this.props.getClassesReportData(postViewQuzzis);
        this.getClassesGroup()
    }
    componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYear} = this.props;
		if(stopYear === true){
            this.getClassesGroup(this.props.getYear)
		}
	}
	componentDidUpdate() {
		const {stopYear} = this.props;
		if(stopYear === true){
			this.props.stopYearData()
		}
	}
    getClassesGroup(getYear?:any){
    if(getYear && getYear !== undefined && getYear !== null){
        this.setState({getCurrectYear:getYear})
        this.getClasses(getYear)
    }else {
        this.setState({getCurrectYear:this.props.getYear})
        this.getClasses(this.props.getYear)
    }
    }
    getClasses(getYear?:any){
        this.props.fetchProfilePost().then((res:any)=>{
            const { loginProfile } = this.props;
            const getUserType:any = loginProfile.usertype
            if(loginProfile){
                if(loginProfile.usertype){
                    if(getUserType === 1){
                        this.getAcdemicAdminLogin(getYear);
                    } else if(getUserType === 2){
                        this.ClassesReportList(getYear);
                    } else if(getUserType === 3){
                        this.ClassesReportList(getYear);
                    }
                }
            }
        })
    }
    getAcdemicAdminLogin(getYearData?:any){
        this.props.getAllSchoolNameList().then((res:any)=>{
            const {getSchoolList} = this.props;
            const {getCorrectSchoolId} = this.state;
            this.getClassPropsValue =  this.props.match.params.id;  
            if(getCorrectSchoolId === null){
                if(getSchoolList.length){
                    this.setState({getCorrectSchoolId: getSchoolList[0].id})
                    this.props.YearListGet({school_id:getSchoolList[0].id});
                    this.academicAdminLogin(getYearData)
                } 
            } else {
                this.academicAdminLogin(getYearData)
            }
        });
    }
    ClassesReportList(getYearData?:any) {
        if(getYearData){
            const postValue = {
                page_no:1,
                academic_year:getYearData
            }
            this.props.getClassesReport(postValue).then((res:any)=>{
                window.scrollTo(0, 0);
          this.setState({hasMore:true, page:1})
            }); 
        }
           
}
academicAdminLogin(getYearData?:any){
    const { getCorrectSchoolId } = this.state; 
    if(getCorrectSchoolId && getYearData){
        const postValue = {
            page_no:1,
            academic_year: getYearData,
            school_id: getCorrectSchoolId
        }
        this.props.getClassesReport(postValue).then((res:any)=>{
            window.scrollTo(0, 0);
      this.setState({hasMore:true, page:1})
        });
    }
}
    getfilterClasses = (e:any) =>{
        const{ getCurrectYear } = this.state;
    const { value } = e.target;
    if(value && getCurrectYear){
        const postValue = {
            page_no:1,
            academic_year: getCurrectYear,
            school_id:value
        }
        this.props.getClassesReport(postValue);
        this.props.YearListGet({school_id:value});
        this.setState({getCorrectSchoolId: value})
    }
    }
    getClassResport = (getValue:any) =>{
        const { getCorrectSchoolId } = this.state;
        if(getValue){
            const getClassName:any = getValue.class_name
            const postViewQuzzis:any = {
                classResport:getValue,
                school_id: getCorrectSchoolId
            }
            history.push({
                pathname: `/classes_report/${getValue.id}`,
                state:{class_id:getClassName}
              });
              this.cookies.set("CLASSESINFO", postViewQuzzis)
              this.props.getClassesReportData(postViewQuzzis);
        }
    }
    getOpenResport = (postValue:any) =>{
        if(postValue){
            const postValues:any = {
                school_id:this.state.getCorrectSchoolId
            }
            this.props.getQuizzesReportData(postValues)
            history.push({
                pathname: `/quizzes_report/${postValue.quiz_id}`,
              });
        }   
    }
    public fetchMoreClassesReport = () => {
        const { getCorrectSchoolId, getCurrectYear } = this.state;
        if (this.state.hasMore === true) {
         if (Math.ceil(this.props.total / this.props.perPage) > this.props.pageNo) {   
            const postValue = {
              page_no: this.state.page + 1,
              search: this.state.search,
              sort_by: this.state.SortOrderData,
              order_by: this.state.OrderNameData,
              academic_year: getCurrectYear,
              school_id: getCorrectSchoolId
            }              
            this.props.getClassesReport(postValue);
            this.setState({
              page: this.state.page + 1
            }) 
          }
    
          if (Math.ceil(this.props.total / this.props.perPage) === this.props.pageNo) {
            this.setState({
              hasMore: false,
            })
          }
        }
      }
    render() {

        const { getSchoolList, ClassesReport, loading } = this.props;
        const { getCorrectSchoolId } = this.state;
        const loadingTextCSS = { display: loading ? "block" : "none" };
        let academicAdminUserTypes: any = false;
        const getUserType: any = this.props.loginProfile.usertype;
        if (getUserType === 1) {
            academicAdminUserTypes = true;
        }
        const getSchoolListData:any = getSchoolList;
        let SchoolListAdd:any;
        if(getSchoolList){
            SchoolListAdd = getSchoolListData;
        }
        return (
            <div>
                <div>
                    <div className="page-wrapper">
                        <div className="page-content-wrapper">
                            <BreadCrumb 
                            titleName={['Classes']} 
                            homeName={['Home']} 
                            url={['dashboard']}
                            mainPageTitle={['Classes']} />
                            {academicAdminUserTypes ?
                                <div className="row">
                                <div className="col-md-4"></div>
                                <div className="col-md-5"></div>
                                {SchoolListAdd?
                                    <div className="col-md-3 mb-4 pull-right">                                       
									<select name="classlist" className="form-control" value={getCorrectSchoolId} onChange={this.getfilterClasses}>
										{SchoolListAdd.map((items: any) => (
											<option value={items.id}> {items.school_name} </option>
										))}
									</select>
                                    </div>
                                    :null}
                                </div>
                                : null}
<>
                            <InfiniteScroll
                            dataLength={this.props.ClassesReport.length}
                            next={this.fetchMoreClassesReport}
                            hasMore={this.state.hasMore}
                            loader={<h4 style={loadingTextCSS}>Loading...</h4>}
                        >
                                {ClassesReport.length > 0?
                            <div className="row">
                                {
                                    ClassesReport.map((items: any) => (
                                        <div className="col-md-3">
                                                <div className="card card-topline-red">
                                                    <div className="m-3 mt-0 mb-0">
                                                        <div className="doctor-profile">
                                                            <div className="width100 text-center">
                                                                <button
                                                                className="btn red compose-btn btn-block m-0 mb-2 classtitlebtn" 
                                                                onClick={()=> this.getClassResport(items)}>
                                                                <h4><small className="font-color font14">{items.class_name}</small></h4>
                                                            </button>
                                                            </div>
                                                            <div className="classes-height">
                                                            <Link to={"#"} onClick={()=> this.getClassResport(items)}>
                                                                <h4 className="text-center"><strong>Recent Quizzes</strong></h4>
                                                                </Link>
                                                                {items.quizzes ?    
                                                            <Link to={"#"} >
                                                                <ul className="text-center">
                                                                    {items.quizzes.map((quizitems: any) => (
                                                                        <li onClick={()=> this.getOpenResport(quizitems)}>{quizitems.name}</li>
                                                                    ))}
                                                                </ul>
                                                            </Link>
                                                                :null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                        </div>
                                    ))}
                            </div>
                            :<CommonLoader/>}
                            </InfiniteScroll>
                            </>
                        </div>
                    </div>
                </div>
                <div style={loadingTextCSS}><SpinnerLoader /></div>
            </div>
        )
    }
}
interface OwnPropsParams {
    id: string;
}
const mapStateToProps = (state: any) => {
    return {
        getYear:state.profile.getYear,
        loginProfile: state.profile.profileData,
		stopYear:state.profile.yearStatusFails,
        getSchoolList:state.userManage.category,
        ClassesReport:state.dashboard.recordsClassesReport,
        total:state.dashboard.totalClassesReport,
        loading:state.dashboard.loading,
        perPage:state.dashboard.perPageClassesReport,
        pageNo:state.dashboard.pageClassesReport
    }
}

export default connect(mapStateToProps, {getAllSchoolNameList, 
    getClassesReport, getClassesReportData, stopYearData, fetchProfilePost,getQuizzesReportData,YearListGet})(Classes)
