import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getRecentQuizReport } from '../../store/question/Actions';
import CommonLoader from '../../components/CommonLoader';
import { Link } from "react-router-dom";
import { fetchGetAllClassList } from '../../store/diary/Actions';
import history from '../../History';
import { getAttendanceStudentView } from '../../store/profile/Actions';
import { stopYearData } from '../../store/profile/Actions'
import Cookies from 'universal-cookie';

export interface PostsQuizzesProps {
    getChangeYearData?:any;
	stopYearDate?:any;
    getRecentQuizReport:(postValue:any) => any;
    fetchGetAllClassList: (postPage: any) => any;
    getAttendanceStudentView:(postValue:any) => any;
    stopYearData:()=>any;
    loading?: boolean,
    loginProfile?:any,
    getQuizzesReport?:any,
    getTeacherId?:any
    getAllClassList?:any;
}
export class QuizReport extends Component<PostsQuizzesProps> {    
    cookies:any = new Cookies();
    componentDidMount(): void {
       this.UpdateYearDate()
    }
      
  componentWillUpdate() {
    const {stopYearDate} = this.props;
    if(stopYearDate === true){
        this.UpdateYearDate(this.props.getChangeYearData)
    }
}

componentDidUpdate() {
    const {stopYearDate} = this.props;
    if(stopYearDate === true){
        this.props.stopYearData()
    }
}

UpdateYearDate(getYear?:any){
    if(getYear && getYear !== undefined && getYear !== null){
        this.getQuizreportData(getYear)
        this.setState({academicYear:getYear})
    }else {
        this.getQuizreportData(this.props.getChangeYearData)
        this.setState({academicYear:this.props.getChangeYearData})
    }
}
getQuizreportData(getYear?:any){
    const { getTeacherId,loginProfile } = this.props;
    window.scrollTo(0, 0);
    this.commonSchoolDetails(getYear);
    let postValue={};
    if(loginProfile && loginProfile !== undefined && loginProfile !== null){
        const getUserType:any = loginProfile.usertype
        if(getUserType === 1){
            postValue = {
                academic_year:getYear,
                teacher_id: getTeacherId,
                school_id:this.cookies.get('ACADEMICSCHOOLID')
            }
        } else{
            postValue = {
                academic_year:getYear,
                teacher_id: getTeacherId
            }
        }
    }
    this.props.getRecentQuizReport(postValue);
}
    commonSchoolDetails(getYear?:any){
        let loadMoreType:any = {
              academic_year:getYear
            }
          this.props.fetchGetAllClassList(loadMoreType);
      }
      getQuizzesReport = (getValue:any) =>{      
        history.push({
			pathname: `/quizzes_report/${getValue.quiz_id}`,
          });
        this.props.getAttendanceStudentView(getValue)
    }
    render() {
        const { getQuizzesReport } = this.props;        
        return (
            <div>
                 <div className="row">
                    <div className="col-md-12">
                    <h4 className="mb-2">Recent Quiz</h4>
                        <div className="card card-topline-red">
                            <div className="card-body no-padding height-9">
                                <div className="table-responsive">
                                    <table className="table table-striped custom-table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Class Name</th>
                                                <th>Subject</th>
                                                <th>Performance (Average)</th>
                                                <th>Time</th>
                                            </tr>
                                        </thead>
                                        {getQuizzesReport? 
                                        <tbody>
                                            {
                                                getQuizzesReport.map((items: any, index: any) => (
                                                    <tr key={index}>
                                                        <td><Link to={`#`} onClick={()=> this.getQuizzesReport(items)}>{items.question_set_name} </Link></td>
                                                        <td><Link to={`#`} onClick={()=> this.getQuizzesReport(items)}>{items.quiz_class_name || '-'} </Link></td>
                                                        <td><Link to={`#`} onClick={()=> this.getQuizzesReport(items)}>{items.subject_name} </Link></td>
                                                        <td><Link to={`#`} onClick={()=> this.getQuizzesReport(items)}>{items.performance} </Link></td>
                                                        <td><Link to={`#`} onClick={()=> this.getQuizzesReport(items)}>{items.last_updated_time} </Link></td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                        :<CommonLoader/>}
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="pull-right">
                            <Link to={"/quizzes"}>
                            <button className="btn btn-pink">More...</button>
                            </Link>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        loginProfile:state.profile.profileData,
        getChangeYearData:state.profile.getYear,
        stopYearDate:state.profile.yearStatusFails,
        getAllClassList:state.diary.gradelist,
        getQuizzesReport:state.questionset.recentQuizReport,
        loading:state.questionset.loading
    }
}

export default connect(mapStateToProps, {getRecentQuizReport, fetchGetAllClassList, getAttendanceStudentView,
    stopYearData})(QuizReport)
