import Modal from 'react-bootstrap/Modal'
export const ModelImageView = (props: any) => {
  return (
    <div>
      <Modal show={props.onState}>
        <Modal.Header className="pb-0 pt-0">
          <Modal.Title>View Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-2">
        {props.image && <img src={props.image} alt="Selected" style={{ width: '400px', height: '400px' }} />} 
        </p>
        </Modal.Body>
        <Modal.Footer className="modelboxbutton">
          <button className="btn btn-default mr-1 ml-1 w-15" onClick={props.onCancel}>Close</button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
