export interface diaryState {
    loading: boolean;
    items: any;
    isAuthenticated: boolean | null;
    errors?:any
    page: number,
    per_page: number,
    records: any,
    total: number,
    studentList?:any,
    classList?:any,
    gradelist?:any,
    getImageURL?:any,
    getClassList?:any,
    getStudentList?:any,
    getDiaryDropDown?:any,
    updateRecord?:any,
    message?:any,
    diaryMessageDetails?:boolean,
    recordLoader?:boolean,
    isSubjectLoader?:boolean,
    isSubjectTeacherPerformanceLoader?:boolean,
    isBoardError?:boolean
}

export interface diaryList {
   classlist?:any,
   studentlist?:any,
   fruites?:any,
   checkedListAll?:any,
   ItemsChecked?:any,
   ShowStudentList?:any,
   selected?:any,
   setSelected?:any,
   images?:any,
   setImages?:any,
   maxNumber?:number,
   AllStudent?:any,
   getClass?:string, 
   search?: string,
   SortOrderData?:any,
   OrderNameData?:any,
   page?:any,
   studentList?:any,
   SelectValue?:string,
   standardlist?: any,
   allstudentslist?: any,
   currentCheckboxAllStudent?: boolean,
   currentSelectedStandard?: any,
   selectedDiariesList?: any,
   getDiaryImage?:any,
   hasMore?:boolean,
   responseData?:any,
   message?:any,
   diaryMessageDetails?:boolean,
   noticeBoardImage?:any,
   studentAutoCompleteData?:any,
   viewNoticeBoardForm?:any,
   viewNoticeboardData?:any,
   viewDiaryData?:any,
   academicYear?:any,
   isExpanded?:any
   }
//Diary fetch details
export enum DiaryActionTypes {
   FETCH_DIARY = 'FETCH_DIARY',
   FETCH_DIARY_SUCCESS = 'FETCH_DIARY_SUCCESS',
   FETCH_DIARY_FAIL = 'FETCH_DIARY_FAIL',
   FETCH_DIARY_ID = 'FETCH_DIARY_ID',
   FETCH_DIARY_SUCCESS_ID = 'FETCH_DIARY_SUCCESS_ID',
   FETCH_DIARY_FAIL_ID = 'FETCH_DIARY_FAIL_ID',
   FETCH_STUDENT_LIST_ID = 'FETCH_STUDENT_LIST_ID',
   FETCH_STUDENT_LISTSUCCESS_ID = 'FETCH_STUDENT_LIST_SUCCESS_ID',
   FETCH_STUDENT_LIST_FAIL_ID = 'FETCH_STUDENT_LIST_FAIL_ID',
   FETCH_ALLCLASS_LIST_ID = 'FETCH_ALLCLASS_LIST_ID',
   FETCH_ALLCLASS_LIST_SUCCESS_ID = 'FETCH_ALLCLASS_LIST_SUCCESS_ID',
   FETCH_ALLCLASS_LIST_FAIL_ID = 'FETCH_ALLCLASS_LIST_FAIL_ID',
   FETCH_SEARCH_STUDENT_LIST="FETCH_SEARCH_STUDENT_LIST",
   FETCH_SEARCH_STUDENT_LIST_SUCCESS="FETCH_SEARCH_STUDENT_LIST",
   FETCH_SEARCH_STUDENT_LIST_FAIL="FETCH_SEARCH_STUDENT_LIST",
   ADD_DIARY = 'ADD_DIARY',
   ADD_DIARY_SUCCESS = 'ADD_DIARY_SUCCESS',
   ADD_DIARY_FAIL = 'ADD_DIARY_FAIL',
   EDIT_DIARY = 'EDIT_DIARY',
   EDIT_DIARY_SUCCESS = 'EDIT_DIARY_SUCCESS',
   EDIT_DIARY_FAIL = 'EDIT_DIARY_FAIL',
   DELETE_DIARY = 'DELETE_DIARY',
   DELETE_DIARY_SUCCESS = 'DELETE_DIARY_SUCCESS',
   DELETE_DIARY_FAIL = 'DELETE_DIARY_FAIL',
   ADD_DIARY_IMAGE = 'DIARY_IMAGE_UPDATE',
   ADD_DIARY_IMAGE_SUCCESS = 'DIARY_IMAGE_UPDATE',
   ADD_DIARY_IMAGE_FAIL = 'DIARY_IMAGE_UPDATE',
   DIARY_DROP_DOWN = 'DIARY_DROP_DOWN',
   DIARY_DROP_DOWN_SUCCESS = ' DIARY_DROP_DOWN_SUCCESS',
   DIARY_DROP_DOWN_FAIL = ' DIARY_DROP_DOWN_FAIL',
   DIARY_REPLAY = 'DIARY_REPLAY',
   DIARY_REPLAY_SUCCESS = 'DIARY_REPLAY_SUCCESS',
   DIARY_REPLAY_FAIL = 'DIARY_REPLAY_FAIL',
   Reset_Diary_Details = 'Reset_Diary_Details',
   FETCH_ALLCLASS_LIST_TEACHER_PERFORMANCE='FETCH_ALLCLASS_LIST_TEACHER_PERFORMANCE',
   FETCH_ALLCLASS_LIST_TEACHER_PERFORMANCE_SUCCESS = 'FETCH_ALLCLASS_LIST_TEACHER_PERFORMANCE_SUCCESS',
   FETCH_ALLCLASS_LIST_TEACHER_PERFORMANCE_FAIL = 'FETCH_ALLCLASS_LIST_TEACHER_PERFORMANCE_FAIL'
}
