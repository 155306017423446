import { Reducer } from "redux";
import { ReportTypes, ReportState } from "./Types";

export const initialState: ReportState = {
    loading: false,
    errors: [],
    records: [],
    items: [],
    page: 1,
    per_page: 10,
    total: 0,
    isAuthenticated: null,
    teacherDropdown:{},
    subjectDropdown:{},
    subjectAnalysisReport: {},
    teacherPerformanceReport: {},
    teacherAttendaceReport: {},
    classAttendanceReport: {},
    subjectPerformanceReport: {},
    subjectAttendivenessReport: {},
    teacherOverallReport: {},
    page_Teacher_Engagement: 1,
    per_page_Teacher_Engagement: 10,
    records_Teacher_Engagement: [],
    total_Teacher_Engagement: 0,
    page_Teacher_Performance: 1,
    per_page_Teacher_Performance: 10,
    records_Teacher_Performance: [],
    total_Teacher_Performance: 0,
}

const reducer: Reducer<ReportState> = (state = initialState, action) => {
    switch (action.type) {
        case ReportTypes.TEACHER_DROPDOWN:
        case ReportTypes.SUBJECT_DROPDOWN:
        case ReportTypes.SUBJECT_ANALYSIS_REPORT:
        case ReportTypes.TEACHER_PERFORMANCE_REPORT:
        case ReportTypes.TEACHER_ENGAGEMENT_REPORT:
        case ReportTypes.TEACHER_ATTENDANCE_REPORT:
        case ReportTypes.CLASS_ATTENDANCE_REPORT:
        case ReportTypes.SUBJECT_PERFORMANCE_REPORT:
        case ReportTypes.SUBJECT_ATTENDIVENESS_REPORT:
        case ReportTypes.TEACHER_OVERALL_REPORT:
            return {
                ...state,
                isAuthenticated: false,
                loading: true,
                errors: [],
            }
        case ReportTypes.TEACHER_DROPDOWN_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                teacherDropdown:action.payload
                
            }
        case ReportTypes.SUBJECT_DROPDOWN_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                subjectDropdown:action.payload
            }
        case ReportTypes.TEACHER_ENGAGEMENT_REPORT_SUCCESS:
            const { records_Teacher_Engagement,
                per_page_Teacher_Engagement,
                page_Teacher_Engagement, total_Teacher_Engagement } = action;
            if (page_Teacher_Engagement === 1) {
                state.records_Teacher_Engagement = []
            }
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                page_Teacher_Engagement: page_Teacher_Engagement,
                per_page_Teacher_Engagement: per_page_Teacher_Engagement,
                records_Teacher_Engagement: [...state.records_Teacher_Engagement, ...records_Teacher_Engagement],
                total_Teacher_Engagement: total_Teacher_Engagement,
                totalPageTeacherEngagement: Math.ceil(total_Teacher_Engagement / per_page_Teacher_Engagement),
                teacherEngagementLoader: false
            };

        case ReportTypes.TEACHER_PERFORMANCE_REPORT_SUCCESS:
            const { records_Teacher_Performance,
                per_page_Teacher_Performance,
                page_Teacher_Performance,
                total_Teacher_Performance } = action;
            if (page_Teacher_Performance === 1) {
                state.records_Teacher_Performance = []
            }
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                page_Teacher_Performance: page_Teacher_Performance,
                per_page_Teacher_Performance: per_page_Teacher_Performance,
                records_Teacher_Performance: [...state.records_Teacher_Performance, ...records_Teacher_Performance],
                total_Teacher_Performance: total_Teacher_Performance,
                totalPageTeacherPerformance: Math.ceil(total_Teacher_Performance / per_page_Teacher_Performance),
                isTeacherPerformanceLoader: false
            };
        case ReportTypes.SUBJECT_ANALYSIS_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                subjectAnalysisReport: action.payload
            }
        case ReportTypes.TEACHER_ATTENDANCE_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                teacherAttendaceReport: action.payload
            }
        case ReportTypes.CLASS_ATTENDANCE_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                classAttendanceReport: action.payload
            }
        case ReportTypes.SUBJECT_PERFORMANCE_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                subjectPerformanceReport: action.payload
            }
        case ReportTypes.SUBJECT_ATTENDIVENESS_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                subjectAttendivenessReport: action.payload
            }
        case ReportTypes.TEACHER_OVERALL_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                teacherOverallReport: action.payload
            }
        case ReportTypes.TEACHER_DROPDOWN_FAIL:
        case ReportTypes.SUBJECT_DROPDOWN_FAIL:
        case ReportTypes.SUBJECT_ANALYSIS_REPORT_FAIL:
        case ReportTypes.TEACHER_PERFORMANCE_REPORT_FAIL:
        case ReportTypes.TEACHER_ENGAGEMENT_REPORT_FAIL:
        case ReportTypes.TEACHER_ATTENDANCE_REPORT_FAIL:
        case ReportTypes.CLASS_ATTENDANCE_REPORT_FAIL:
        case ReportTypes.SUBJECT_PERFORMANCE_REPORT_FAIL:
        case ReportTypes.SUBJECT_ATTENDIVENESS_REPORT_FAIL:
        case ReportTypes.TEACHER_OVERALL_REPORT_FAIL:
            return {
                ...state,
                loading: false,
                errors: []
            }
        default:
            return state;
    }
}

export { reducer as ReportReducer } 