import React, { Component } from 'react';
import { connect } from 'react-redux';
import BreadCrumb from '../../components/BreadCrumb';
import { RouteComponentProps } from 'react-router';
import { fetchClassrePort, getStudentClassrePort, getClassRecentFeedback, fetchClassRecentQuiz  } from '../../store/classes/Actions';
import { postTeacherEngagement } from '../../store/dashboard/Actions';
import CommonLoader from '../../components/CommonLoader';
import { Link } from 'react-router-dom';
import history from '../../History';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import { getAttendanceStudentView } from '../../store/profile/Actions';
import Cookies from 'universal-cookie';
import { fetchProfilePost } from '../../store/profile/Actions';
import { stopYearData } from '../../store/profile/Actions'
import {propsTypes} from './Types';
import ClassRecentFeedback from './ClassRecentFeedback'

interface propsQuizzesReportTypes extends RouteComponentProps<OwnPropsParams> {
    getQuizzes:any;
    getYear?:any,
    fetchClassrePort: (postValue: any) => any;
    postTeacherEngagement: (getValue: any) => any;
    getStudentClassrePort: (getStudent: any) => any;
    getAttendanceStudentView:(postValue:any) => any;
    fetchProfilePost:()=>any;
    getClassRecentFeedback:(postValue:any) => any;
    fetchClassRecentQuiz:(postValue:any) => any;
	stopYearData:() => any;
    ClassRecentFeedback?:any;
    ClassRecentQuiz?:any;
    loading?:any;
    getTeacherDetails?: any;
    getStudentDetails?: any;
    classSubjectList?: any[];
    loginProfile?: any;
    stopYear?:any,
}

export class QuizzesReport extends Component<propsQuizzesReportTypes, propsTypes> {
    cookies:any = new Cookies();
    getClassInfo: any;
    commonUser: any;
    adacemicAdmin: any;
    constructor(props: any) {
        super(props);
        this.state = {
            schoolId: this.props.match.params.id || null,
            subject_id:'',
            className:'',
            getCurrectYear:null
        }
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.getClassInfo = this.props.match.params.id;
        this.getQuizzFeedbackData()
    }
   
    componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYear} = this.props;
		if(stopYear === true){
            this.getQuizzFeedbackData(this.props.getYear)
		}
	}
	componentDidUpdate() {
		const {stopYear} = this.props;
		if(stopYear === true){
			this.props.stopYearData()
		}
	}

    getQuizzFeedbackData(getYear?:any){
        if(getYear && getYear !== undefined && getYear !== null){
          this.setState({getCurrectYear:getYear})
          this.getClassesRecentInit(getYear)
        }else {
          this.setState({getCurrectYear:this.props.getYear})
          this.getClassesRecentInit(this.props.getYear)
        }
      }

      getClassesRecentInit(getYearData?:any){
        this.props.fetchProfilePost().then((res:any)=>{
            const { loginProfile } = this.props;
            if(loginProfile && loginProfile !== null && loginProfile !== undefined){
                if (loginProfile.usertype === 1) {
                    this.getAdacemicAdminData(getYearData);
                    this.getClassesRecent(getYearData)
                    this.adacemicAdmin = true;
                    this.commonUser = false;
                } else  if (loginProfile.usertype === 2) {
                    this.getCommonData(getYearData);
                    this.getSubjectNameList(getYearData)
                    this.adacemicAdmin = false;
                    this.commonUser = true;
                }else if(loginProfile.usertype === 3){
                    this.getCommonData(getYearData);
                    this.getSubjectNameList(getYearData);
                    this.getClassesRecent(getYearData);
                    this.adacemicAdmin = true;
                    this.commonUser = false;
                }
            }
        })
    }
    getClassesRecent(getYear?:any){
        let viewQuizzeData:any = this.props.getQuizzes;
        if(viewQuizzeData){
            if(viewQuizzeData.classResport){
                let postValue:any = {
                    academic_year:getYear,
                    class_id:viewQuizzeData.classResport.id,
                    school_id: viewQuizzeData.school_id
                }
                this.props.getClassRecentFeedback(postValue);
                this.props.fetchClassRecentQuiz(postValue);
            }
        }
    }

    getCommonData(getYear?:any) {
        let getClassId: any = this.props.match.params.id;
        const {getCurrectYear} = this.state;
        if (getClassId) {
            let postValue: any = {
                academic_year:getYear,
                class_id: getClassId,
                page_no: 1
            }
            this.props.postTeacherEngagement(postValue)
            let classesList: any = {
                academic_year:getCurrectYear,
                class_id: getClassId
            }
            this.props.fetchClassrePort(classesList);
            this.setState({className:this.props.getQuizzes.classResport.class_name})
        }
    }
    // this is service use get subject list
    getSubjectNameList(getYear?:any){
        let getClassId: any = this.props.match.params.id;
        const { loginProfile } = this.props;
        if(loginProfile){
            let classesList: any = {
                academic_year:getYear,
                class_id: getClassId,
                school_id:loginProfile.school_id || undefined
            }
            this.props.getStudentClassrePort(classesList);
        }
        
        
    }
    getAdacemicAdminData(getYear?:any) {
        let getClassId: any = this.props.match.params.id;
        let viewQuizzeData:any = this.props.getQuizzes;
        if(viewQuizzeData){
            if(!viewQuizzeData.school_id){
                history.push('/classes');
            }
            let postValue: any = {
                academic_year:getYear,
                class_id: getClassId,
                school_id: viewQuizzeData.school_id,
                page_no: 1
            }
            this.props.postTeacherEngagement(postValue)
            let classesList: any = {
                academic_year:getYear,
                class_id: getClassId,
                school_id: viewQuizzeData.school_id
            }
            this.props.getStudentClassrePort(classesList);
            this.props.fetchClassrePort(classesList);
            let postValueTeacher: any = {
                academic_year:getYear,
                class_id: getClassId,
                page_no: 1,
                school_id: viewQuizzeData.school_id
            }
            this.props.postTeacherEngagement(postValueTeacher)
            this.setState({className:this.props.getQuizzes.classResport.class_name})
        }
    }
    postTeacherDetails = (getValue: any) => {
        history.push({
            pathname: `/teachers/${getValue.teacher_id}`,
        });
    }
    postStudentDetails = (getValue:any) => {
        const { getQuizzes } = this.props; 
        if(getValue){
            let getPostValue:any = {
                card_id:getValue.card_id, 
                class_id: this.props.match.params.id,
                school_id: getQuizzes.school_id,
                postURL:`classes_report/${this.props.getQuizzes.classResport.id}`,
                titleName:'Classes Report'
            };
            this.props.getAttendanceStudentView(getPostValue);
            history.push(`/student_view`)
        }
        
    }

    getStudentClasses = (e: any) => {
        const { value } = e.target;
        const {getCurrectYear} = this.state;
        let getClassId: any = this.props.match.params.id;
        if (value === 'Get All') {
            const postValue = {
                page_no: 1,
                academic_year:getCurrectYear,
                class_id: getClassId,
                school_id: this.state.schoolId
            }
            this.props.getStudentClassrePort(postValue);
            let postValueTeacher: any = {
                academic_year:getCurrectYear,
                class_id: getClassId,
                page_no: 1
            }
            this.props.postTeacherEngagement(postValueTeacher)
            let viewQuizzeData:any = this.props.getQuizzes;
            if(viewQuizzeData){
                if(viewQuizzeData.classResport){
                    let postValue:any = {
                        academic_year:getCurrectYear,
                        class_id:viewQuizzeData.classResport.id,
                        school_id: viewQuizzeData.school_id
                    }
                    this.props.getClassRecentFeedback(postValue);
                    this.props.fetchClassRecentQuiz(postValue);
                }
            }
        } else {
            const postValue = {
                page_no: 1,
                academic_year:getCurrectYear,
                class_id: getClassId,
                subject_id: value,
                school_id: this.state.schoolId
            }
            this.props.getStudentClassrePort(postValue);
            let postValueTeacher: any = {
                academic_year:getCurrectYear,
                class_id: getClassId,
                page_no: 1,
                subject_id: value
            }
            this.props.postTeacherEngagement(postValueTeacher)
            let viewQuizzeData:any = this.props.getQuizzes;
            if(viewQuizzeData){
                if(viewQuizzeData.classResport){
                    let postValue:any = {
                        academic_year:getCurrectYear,
                        class_id:viewQuizzeData.classResport.id,
                        school_id: viewQuizzeData.school_id,
                        subject_id: value
                    }
                    this.props.getClassRecentFeedback(postValue);
                    this.props.fetchClassRecentQuiz(postValue);
                }
            }
            this.setState({subject_id:value})
        }
    }
    addDefaultSrc= (ev:any) =>{
        ev.target.src = '../assets/img/user/teacher-profile.jpg'
      }
    render() {
        const { getTeacherDetails, classSubjectList, getStudentDetails, loading } = this.props;
        const {className} = this.state;
        const loadingTextCSS = { display: loading ? "block" : "none" };
        return (
            <div>
                <div className="page-wrapper">
                    <div className="page-content-wrapper">
                        <div className="page-content pt-3">
                            <BreadCrumb
                                titleName={[`${className}`]}
                                homeName={['Home']}
                                url={['dashboard']}
                                mainPageTitle={[`${className}`]}
                                baseName={['Classes']}
                                baseURL={['classes']} />
                            <div className="row mb-3">
                                <div className="col-md-3">
                                    <div>
                                        {classSubjectList && classSubjectList.length !==0?
                                            <select className="form-control" onChange={this.getStudentClasses}>
                                                <option value="" disabled selected hidden>All Subject</option>
                                                {classSubjectList.length > 0 && classSubjectList !== undefined && classSubjectList !== null?
                                                <option value="Get All" >All Subject</option>
                                                :null}
                                                {classSubjectList.map((items: any) => (
                                                    <option value={items.id}>{items.subject_name}</option>
                                                ))}
                                            </select>
                                            : null}
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: this.adacemicAdmin ? "block" : "none" }}>
                                <ClassRecentFeedback/>
                            </div>
                            <div className="row" style={{ display: this.commonUser ? "block" : "none" }}>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="card card-topline-red">
                                        <div className="card-head">
                                            <header>
                                                Teachers
                                            </header>
                                            <div className="tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <ul className="docListWindow
                                                small-slimscroll-style overflowBox">
                                                    <li className="title-sticky">
                                                        <div className="row">
                                                            <div className="col-md-6 col-xs-6"><strong>Teachers
                                                            </strong></div>
                                                            <div className="col-md-3 col-xs-3"><strong>Total Responses</strong></div>
                                                            <div className="col-md-3 col-xs-3"><strong>Performance</strong></div>
                                                        </div>
                                                    </li>
                                                    {getTeacherDetails && getTeacherDetails !== undefined && getTeacherDetails !== null && getTeacherDetails.length > 0 ?
                                                        getTeacherDetails.map((items: any) => (
                                                            <li>
                                                                <div className="row">
                                                                {items.teacher_profile?
                                                                           <div className="col-md-1
                                                                           col-sm-1">
                                                                        <div className="prog-avatar">
                                                                                <img onError={this.addDefaultSrc} src={`${process.env.REACT_APP_API_URL}${items.teacher_profile}`} 
                                                                                alt="Student Profile" width="40"
                                                                                height="40"/>
                                                                                           </div>
                                                                                       </div>
                                                                        :   <div className="col-md-1
                                                                        col-sm-1">
                                                                                        <div className="prog-avatar">
                                                                                            <button onClick={() => this.postTeacherDetails(items)}
                                                                                                className="mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab ml-3 btn-pink">
                                                                                                <span>{items.teacher_name.charAt(0)}</span>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                        }
                                                                    <div className="col-md-5
                                                            col-sm-5">
                                                                        <div
                                                                            className="details">
                                                                            <div
                                                                                className="title
                                                                    mt-2">
                                                                                <Link to={"#"} onClick={() => this.postTeacherDetails(items)}>{items.teacher_name}</Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3
                                                            col-sm-3
                                                            rating-style">
                                                                        <p>{items.total_responses}</p>
                                                                    </div>
                                                                    <div className="col-md-3
                                                            col-sm-3
                                                            rating-style">
                                                                        <p>{items.total_performance}</p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))
                                                        : <CommonLoader />}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="card card-topline-red">
                                        <div className="card-head">
                                            <header>
                                                Students
                                            </header>
                                            <div className="tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <ul className="docListWindow
                                                small-slimscroll-style overflowBox">
                                                    <li className="title-sticky">
                                                        <div className="row">
                                                            <div className="col-md-6 col-xs-6"><strong>Name
                                                            </strong></div>
                                                            <div className="col-md-3 col-xs-3"><strong>Overall Attendance</strong></div>
                                                            <div className="col-md-3 col-xs-3"><strong>Performance</strong></div>
                                                        </div>
                                                    </li>
                                                    {getStudentDetails && getStudentDetails !== null && getStudentDetails !== undefined && getStudentDetails.length > 0?
                                                        getStudentDetails.map((items: any) => (
                                                            <li>
                                                                <div className="row">
                                                                {items.profile_picture?
                                                                           <div className="col-md-1
                                                                           col-sm-1">
                                                                        <div className="prog-avatar">
                                                                                <img onError={this.addDefaultSrc} src={`${process.env.REACT_APP_API_URL}${items.profile_picture}`} 
                                                                                alt="Student Profile" width="40"
                                                                                height="40"/>
                                                                                           </div>
                                                                                       </div>
                                                                        :   <div className="col-md-1
                                                                        col-sm-1">
                                                                                        <div className="prog-avatar">
                                                                                            <button onClick={() => this.postStudentDetails(items)}
                                                                                                className="mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab ml-3 btn-pink">
                                                                                                <span>{items.student_name.charAt(0)}</span>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                        }
                                                                    <div className="col-md-5
                                                            col-sm-5">
                                                                        <div
                                                                            className="details">
                                                                            <div
                                                                                className="title
                                                                    mt-2">
                                                                                <Link to={"#"} onClick={() => this.postStudentDetails(items)}>{items.student_name}</Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3
                                                            col-sm-3
                                                            rating-style">
                                                                        <p>{items.attendance}</p>
                                                                    </div>
                                                                    <div className="col-md-3
                                                            col-sm-3
                                                            rating-style">
                                                                        <p>{items.performance}</p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))
                                                        : <CommonLoader />}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div style={loadingTextCSS}><SpinnerLoader /></div>
            </div>
        )
    }
}
interface OwnPropsParams {
    id: string;
}

const mapStateToProps = (state: any) => {
    return {
        getYear:state.profile.getYear,
        loading: state.classes.loading,
        getQuizzes:state.classes.classesReportDir,
        loginProfile: state.profile.profileData,
		stopYear:state.profile.yearStatusFails,
        ClassRecentFeedback:state.classes.getClassRecentFeedback,
        ClassRecentQuiz:state.classes.getClassRecentQuiz,
        getTeacherDetails: state.dashboard.recordsPostTeacherEngagement,
        classSubjectList: state.classes.getClassrePort,
        getStudentDetails: state.classes.getStudentClassrePort
    }
}


export default connect(mapStateToProps, { fetchClassrePort, postTeacherEngagement, getStudentClassrePort, getAttendanceStudentView, fetchProfilePost, getClassRecentFeedback, fetchClassRecentQuiz, stopYearData  })(QuizzesReport)
