import React, { Component } from 'react'
import { connect } from 'react-redux'
import CommonLoader from '../../components/CommonLoader';
import {fetchTodayAttendanceReport} from '../../store/dashboard/Actions';
import { stopYearData } from '../../store/profile/Actions';

interface PropsTypes {
	getChangeYearData?:any;
	stopYearDate?:any;
    fetchTodayAttendanceReport:(postValue:any) => any;
    stopYearData:()=>any;
    getTypeAttendance?:any;
    isAdminTodayAttendeanceLoader?:boolean;
}
interface PropStateType {
    academicYear?:any;
}
export class TodayAttendance extends Component<PropsTypes, PropStateType> {
    constructor(props: any) {
        super(props);
        this.state = {
			academicYear:null
        }
    }
    componentDidMount(): void {
        this.getCurrectYear()
    }
     // This is life cycle is initial load  component Will Update
     componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYearDate} = this.props;
		if(stopYearDate === true){
			this.getCurrectYear(this.props.getChangeYearData)
		}
	}
    // This is life cycle is initial load  component Did Update
	componentDidUpdate() {
		const { stopYearDate } = this.props;
        if(stopYearDate === true){
			this.props.stopYearData()
		}
	}
    // This is function is used get correct year
	getCurrectYear(getYear?:any){
		if(getYear && getYear !== undefined && getYear !== null){
			this.getTodayAttendanceReport(getYear)
			this.setState({academicYear:getYear})
		}else {
			this.getTodayAttendanceReport(this.props.getChangeYearData)
			this.setState({academicYear:this.props.getChangeYearData})
		}
	}
    getTodayAttendanceReport(getYear?:any){
        const postValue:any = {
            academic_year:getYear
        }
        this.props.fetchTodayAttendanceReport(postValue)
    }
    refreshTodayAttendance = () => {
        const {academicYear} = this.state;
        this.getCurrectYear(academicYear)
    }
    render() {
        const { getTypeAttendance, isAdminTodayAttendeanceLoader } = this.props;
		const loadingDashboard = { display: isAdminTodayAttendeanceLoader ? "block" : "none" };
        return (
            <div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="card card-topline-red">
                        <div className="overlay" style={loadingDashboard}><div className='custom-spinner-individual'></div></div>
                            <div className="card-head">
                                <header>Today Attendance</header>
                                <div className="tools">
								<div className="d-flex">
										<div className="ml-2">
											<span className="fa fa-repeat btn-color box-refresh pointer" onClick={this.refreshTodayAttendance}></span>
											<span className="t-collapse btn-color fa fa-chevron-up pointer" ></span>
										</div>
									</div>                              
								</div>
                            </div>
                            
                            <div className="card-body no-padding height-9">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 max-height-scroll">
                                    {getTypeAttendance && getTypeAttendance.length !==0 ?<table className="gradeTable mb-2 table table-bordered">
                                            <tbody style={{margin:"auto"}}>
                                            {getTypeAttendance && getTypeAttendance.length !==0?
                                                getTypeAttendance.map((items:any, index:any)=>(
                                                <tr key={index}>
                                                <td className="todayAttendanceName titlesidebar text-left">{items.school_name}</td>  
                                                {
                                                   items.class_list?
                                                   items.class_list.map((classList:any)=>(
                                                   <td  className="academicGradeTitle">
                                                        <div className="academicGrade status-color7 ">
                                                       <small>{classList.grade} {classList.percentage}</small>
                                                       </div>
                                                       <div className="academicGrade status-color7 ">
                                                           <small>Time : {classList.time}</small></div>
                                                           
                                                       
                                                    </td>
                                                    
                                                   ))
                                                   :null 
                                                }                                            
                                                </tr>
                                                ))
                                                :null}
                                            </tbody>
                                    </table>: <CommonLoader />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state:any) => {
    return{
        isAdminTodayAttendeanceLoader: state.dashboard.isAdminTodayAttendeanceLoader,
		getChangeYearData:state.profile.getYear,
		stopYearDate:state.profile.yearStatusFails,
        getTypeAttendance:state.dashboard.recordsTodayAttendanceReport
    }
}

export default connect(mapStateToProps, {fetchTodayAttendanceReport, stopYearData})(TodayAttendance)