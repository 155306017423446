import Modal from 'react-bootstrap/Modal'
export const ModalAlert = (props: any) => {
  return (
    <div>
      <Modal show={props.onState}>
        <Modal.Header className="pb-0 pt-0">
          <Modal.Title>Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-2">{props.message}</p>
        </Modal.Body>
        <Modal.Footer className="modelboxbutton">
          <button className="btn btn-default mr-1 ml-1 w-15" onClick={props.onCancel}>Close</button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
