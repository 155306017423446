
export interface PsychometricState {
    loading: boolean;
    items: any;
    isAuthenticated: boolean | null;
    modelPop?:boolean;
    errors?:any
    page: number,
    per_page: number,
    records: any,
    total: number,
    getSchoolEdit:any,
    schoolCategoryList?:any
    questionnaireitems: any;
    ageList: any;
    questionnaireMappingDetails: any;
    psychometricDataStorage?:any,
    psychometricReport?:any,
    activeClassTeacher?:any,
    addQuestionnaireMapping?:any,
    student_list?:any,
    questionnareLoading: boolean,
    questionnaireStudentRecords?:any,
    delteacheratt?:any,
    delstudatt?:any,
    deltodayquiz?:any,
    deltodaypsych?:any
}

export interface TokenType {
    token:string
}
//Schools fetch details
export enum PsychometricActionTypes {
    FETCH_AGE = 'FETCH_AGE',
    FETCH_AGE_SUCCESS = 'FETCH_AGE_SUCCESS',
    FETCH_AGE_FAIL = 'FETCH_AGE_FAIL',
    FETCH_QUESTIONNAIRE = 'FETCH_QUESTIONNAIRE',
    FETCH_QUESTIONNAIRE_SUCCESS = 'FETCH_QUESTIONNAIRE_SUCCESS',
    FETCH_QUESTIONNAIRE_FAIL = 'FETCH_QUESTIONNAIRE_FAIL',
    ADD_QUESTIONNAIRE = 'ADD_QUESTIONNAIRE',
    ADD_QUESTIONNAIRE_SUCCESS = 'ADD_QUESTIONNAIRE_SUCCESS',
    ADD_QUESTIONNAIRE_FAIL = 'ADD_QUESTIONNAIRE_FAIL',
    DELETE_QUESTIONNAIRE = 'DELETE_QUESTIONNAIRE',
    DELETE_QUESTIONNAIRE_SUCCESS = 'DELETE_QUESTIONNAIRE_SUCCESS',
    DELETE_QUESTIONNAIRE_FAIL = 'DELETE_QUESTIONNAIRE_FAIL',
    EDIT_QUESTIONNAIRE = 'EDIT_QUESTIONNAIRE',
    EDIT_QUESTIONNAIRE_SUCCESS = 'EDIT_QUESTIONNAIRE_SUCCESS',
    EDIT_QUESTIONNAIRE_FAIL = 'EDIT_QUESTIONNAIRE_FAIL',
    FETCH_QUESTIONNAIRE_MAPPING = 'FETCH_QUESTIONNAIRE_MAPPING',
    FETCH_QUESTIONNAIRE_MAPPING_SUCCESS = 'FETCH_QUESTIONNAIRE_MAPPING_SUCCESS',
    FETCH_QUESTIONNAIRE_MAPPING_FAIL = 'FETCH_QUESTIONNAIRE_MAPPING_FAIL',
    FETCH_QUESTIONNAIRE_MAPPING_BY_ID = 'FETCH_QUESTIONNAIRE_MAPPING_BY_ID',
    FETCH_QUESTIONNAIRE_MAPPING_BY_ID_SUCCESS = 'FETCH_QUESTIONNAIRE_MAPPING_BY_ID_SUCCESS',
    FETCH_QUESTIONNAIRE_MAPPING_BY_ID_FAIL = 'FETCH_QUESTIONNAIRE_MAPPING_BY_ID_FAIL',
    PSYCHOMETRIC_DATA_STORAGE = "PSYCHOMETRIC_DATA_STORAGE",
    PSYCHOMETRIC_DATA_STORAGE_SUCCESS = "PSYCHOMETRIC_DATA_STORAGE_SUCCESS",
    PSYCHOMETRIC_DATA_STORAGE_FAIL = "PSYCHOMETRIC_DATA_STORAGE_FAIL",
    FETCH_PSYCHOMETRIC_REPORT_BY_ID = 'FETCH_PSYCHOMETRIC_REPORT_BY_ID',
    FETCH_PSYCHOMETRIC_REPORT_BY_ID_SUCCESS = 'FETCH_PSYCHOMETRIC_REPORT_BY_ID_SUCCESS',
    FETCH_PSYCHOMETRIC_REPORT_BY_ID_FAIL = 'FETCH_PSYCHOMETRIC_REPORT_BY_ID_FAIL',
    FETCH_ACTIVE_CLASS_TEACHER = 'FETCH_ACTIVE_CLASS_TEACHER',
    FETCH_ACTIVE_CLASS_TEACHER_SUCCESS = 'FETCH_ACTIVE_CLASS_TEACHER_SUCCESS',
    FETCH_ACTIVE_CLASS_TEACHER_FAIL = 'FETCH_ACTIVE_CLASS_TEACHER_FAIL',
    FETCH_STUDENT_LIST_WITH_QUESTIONNAIRE = 'FETCH_STUDENT_LIST_WITH_QUESTIONNAIRE',
    FETCH_STUDENT_LIST_WITH_QUESTIONNAIRE_SUCCESS = 'FETCH_STUDENT_LIST_WITH_QUESTIONNAIRE_SUCCESS',
    FETCH_STUDENT_LIST_WITH_QUESTIONNAIRE_FAIL = 'FETCH_STUDENT_LIST_WITH_QUESTIONNAIRE_FAIL',
    FETCH_QUESTIONNAIRE_MAPPING_STUDENTS = 'FETCH_QUESTIONNAIRE_MAPPING_STUDENTS',
    FETCH_QUESTIONNAIRE_MAPPING_STUDENTS_SUCCESS = 'FETCH_QUESTIONNAIRE_MAPPING_STUDENTS_SUCCESS',
    FETCH_QUESTIONNAIRE_MAPPING_STUDENTS_FAIL = 'FETCH_QUESTIONNAIRE_MAPPING_STUDENTS_FAIL',

    DELETE_TEACHER_ATTENDANCE = 'DELETE_TEACHER_ATTENDANCE',
    DELETE_TEACHER_ATTENDANCE_SUCCESS = 'DELETE_TEACHER_ATTENDANCE_SUCCESS',
    DELETE_TEACHER_ATTENDANCE_FAIL = 'DELETE_TEACHER_ATTENDANCE_FAIL',

    DELETE_STUDENT_ATTENDANCE = 'DELETE_STUDENT_ATTENDANCE',
    DELETE_STUDENT_ATTENDANCE_SUCCESS = 'DELETE_STUDENT_ATTENDANCE_SUCCESS',
    DELETE_STUDENT_ATTENDANCE_FAIL = 'DELETE_STUDENT_ATTENDANCE_FAIL',

    DELETE_TODAY_QUIZ = 'DELETE_TODAY_QUIZ',
    DELETE_TODAY_QUIZ_SUCCESS = 'DELETE_TODAY_QUIZ_SUCCESS',
    DELETE_TODAY_QUIZ_FAIL = 'DELETE_TODAY_QUIZ_FAIL',

    DELETE_TODAY_PSYCH = 'DELETE_TODAY_PSYCH',
    DELETE_TODAY_PSYCH_SUCCESS = 'DELETE_TODAY_PSYCH_SUCCESS',
    DELETE_TODAY_PSYCH_FAIL = 'DELETE_TODAY_PSYCH_FAIL',



}


