import React, { Component } from "react";
import { connect } from "react-redux";
import CommonLoader from '../../components/CommonLoader';
import { Bar, Pie } from 'react-chartjs-2';
import { option, plugin, overalloption, horizontalBaroption, pieOption } from "./Types";

export type OwnReportProps = {
    classAttendanceReportData?: any;
    reportType?: any;
};

interface StateTypes {

}
export class ClassAttendanceViewReport extends Component<OwnReportProps, StateTypes>{
    formik: any
    constructor(props: any) {
        super(props);
        this.state = {
          
        }
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);
    }
    render() {
        const { classAttendanceReportData, reportType } = this.props;

       

        return (
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt-5">
                <div className={`card card-topline-red`}>
                    <div className="card-head">
                        <header>
                            {reportType == 'class' ? 'Class' : 'Student'} Attendance Ratio</header>
                    </div>
                    <div className="card-body no-padding height-9" >
                        {classAttendanceReportData && classAttendanceReportData !== undefined && classAttendanceReportData !== null  ?
                            (<div className="row">
                               <Pie
                               height={150}
                                data={{
                                  labels: ['Presence','Absence'],
                                  datasets: [
                                    {

                                      backgroundColor: ["#5FB13F","#EE5C32"],
                                      borderWidth: 1,
                                      label: ['Presence','Absence'],
                                      data: [classAttendanceReportData.present_percentage,classAttendanceReportData.absent_percentage]
                                    }
                                  ]
                                }} 
                                 options={pieOption}
                                 plugins={[plugin]}
                              />
                            </div>)
                            : <CommonLoader />}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

export default connect(mapStateToProps, {

})(ClassAttendanceViewReport)