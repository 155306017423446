export interface AssessmentState {
    loading: boolean;
    errors: any;
    records: any;
    items: any,
    page: number,
    per_page: number,
    total: number,
    isAuthenticated: boolean | null;    
    getAssessmentStudents: any;
    getAssessmentDetails: any;
}

export enum AssessmentTypes{
    FETCH_ASSESSMENT = 'FETCH_ASSESSMENT',
    FETCH_ASSESSMENT_SUCCESS = 'FETCH_ASSESSMENT_SUCCESS',
    FETCH_ASSESSMENT_FAIL = 'FETCH_ASSESSMENT_FAIL',
    CREATE_ASSESSMENT = 'CREATE_ASSESSMENT',
    CREATE_ASSESSMENT_SUCCESS = 'CREATE_ASSESSMENT_SUCCESS',
    CREATE_ASSESSMENT_FAIL = 'CREATE_ASSESSMENT_FAIL',
    EDIT_ASSESSMENT = 'EDIT_ASSESSMENT',
    EDIT_ASSESSMENT_SUCCESS = 'EDIT_ASSESSMENT_SUCCESS',
    EDIT_ASSESSMENT_FAIL = 'EDIT_ASSESSMENT_FAIL',
    DELETE_ASSESSMENT_BY_ID = 'DELETE_ASSESSMENT',
    DELETE_ASSESSMENT_SUCCESS_BY_ID = 'DELETE_ASSESSMENT_SUCCESS',
    DELETE_ASSESSMENT_FAIL_BY_ID = 'DELETE_ASSESSMENT_FAIL',
    FETCH_QUESTION_ASSESSMENT_BY_ID = 'FETCH_QUESTION_ASSESSMENT_BY_ID',
    FETCH_QUESTION_ASSESSMENT_BY_ID_SUCCESS = 'FETCH_QUESTION_ASSESSMENT_BY_ID_SUCCESS',
    FETCH_QUESTION_ASSESSMENT_BY_ID_FAIL = 'FETCH_QUESTION_ASSESSMENT_BY_ID_FAIL',
    FETCH_ASSESSMENT_STUDENTLIST = 'FETCH_ASSESSMENT_STUDENTLIST',
    FETCH_ASSESSMENT_STUDENTLIST_SUCCESS = 'FETCH_ASSESSMENT_STUDENTLIST_SUCCESS',
    FETCH_ASSESSMENT_STUDENTLIST_FAIL = 'FETCH_ASSESSMENT_STUDENTLIST_FAIL',
    GET_ASSESSMENT = 'GET_ASSESSMENT',
    GET_ASSESSMENT_SUCCESS = 'GET_ASSESSMENT_SUCCESS',
    GET_ASSESSMENT_FAIL = 'GET_ASSESSMENT_FAIL',
}