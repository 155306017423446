import React, { Component } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import { Link } from 'react-router-dom';
import { QuestionBank, StudentDetails } from '../../router/Roles';
import { Field, Formik, Form, FieldArray } from "formik";
import { Button, MenuItem, FormGroup } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { get } from "lodash";
import { fetchBluePrintByIdGet } from "../../store/QuestionBank/Actions";
import { RouteComponentProps } from 'react-router';
import Rating from '@mui/material/Rating';
import { GetStudentNameList } from "../../store/student/Actions";
import { fetchMySectionListGet } from "../../store/classes/Actions";
import { fetchAssessmentQuestionById, createAssessmentpost, fetchAssessmentStudentListGet } from "../../store/assessment/Action";
import { AssessmentSchema } from "./AssessmentValidation";
import { stopYearData } from "../../store/profile/Actions";
import SpinnerLoader from "../../components/spinner/SpinnerLoader";

interface OwnPropsParams {
    id: string;
}

export interface Props extends RouteComponentProps<OwnPropsParams> {
    loading: boolean;
    bluePrintById?: any;
    assessmentById?: any;
    ListGrade: any;
    ListStandard: any;
    getStudentDetails: any;
    getAssessmentStudents: any;
    getChangeYearData?: any;
    stopYearDate?: any;
    stopYearData: () => any;
    fetchMySectionListGet: (grade: any) => any;
    GetStudentNameList:(postValue?:any) => any;
    fetchAssessmentQuestionById: (data:any) => any;
    createAssessmentpost: (data:any) => any;
    fetchAssessmentStudentListGet: (questionPaperId:any, classId:any) => any;
}

interface StateTypes {
    records?: any;
    grade?: string;
    standard?: string;
    academicYear: any;
    assessmentValues?: any;
    hasMore?: boolean;
    assessmentDetails?: any;
    internalGradeName?: string;
    uid?: number;
    class_id?: any;
    student_id?: any;
    question_paper_id?: number;
    studentList?: any;
    assessmentStudents?: any;
    added_total_marks?: any;
    totalMarks?: any;
}

const initialValues = {
    class_id: "",
    student_id: "",
    question_paper_id: 0,
    standard: "",
}

export class AssessmentCreate extends Component<Props, StateTypes> {
    formik: any;
    constructor(props: any) {
        super(props);
        this.state = {
            records: [],
            grade: '',
            academicYear: null,
            assessmentDetails: null,
            internalGradeName: '',
            studentList: [],
            assessmentStudents: [],
            added_total_marks: 0,
            totalMarks: 0
        }
    }
    componentDidMount(): void {

        const savedAssessmentDetails = localStorage.getItem('assessmentDetails');
        if(savedAssessmentDetails){
            const assessmentDetails = JSON.parse(savedAssessmentDetails);
            this.setState({assessmentDetails:assessmentDetails,
                internalGradeName:assessmentDetails.internalGradeName,
                uid: assessmentDetails.uid,
                class_id: assessmentDetails.internalGradeId
            });
            const getClassName = {
                grade: assessmentDetails.internalGradeId
              }
              if (getClassName) {
                this.props.fetchMySectionListGet(getClassName)
              }

        this.props.fetchAssessmentQuestionById(assessmentDetails.uid).then((res: any) => {
            const { assessmentById } = this.props;
            const order: Record<string, number> = {
                    "MCQ": 1,
                    "FIB": 2,
                    "Regular-2 Marks": 3,
                    "Regular-5 Marks": 4,
                    "Regular-10 Marks": 5
                };
                const sortedQuestions = assessmentById.question_details.sort((a: any, b: any) => {
                    return order[a.db_question_type] - order[b.db_question_type];
                });
                const initializedData = sortedQuestions.map((group:any) => ({
                    ...group,
                    question: group.question.map((q:any) => ({
                      ...q,
                      currentRating: 0, // Default rating set to 0
                    })),
                  }));
        
                this.setState({assessmentValues:assessmentById, records: initializedData, totalMarks: assessmentById.total_marks });
                console.log(assessmentById.total_marks)
        }) 
        this.getCurrectYear();
        window.scrollTo(0, 0);
        }
    }

    componentWillUpdate(nextProps: any, nextState: any) {
        const { stopYearDate } = this.props;
        if (stopYearDate === true) {
            this.getCurrectYear(this.props.getChangeYearData);
        }
    }
    componentDidUpdate() {
        const { stopYearDate } = this.props;
        if (stopYearDate === true) {
            this.props.stopYearData();
        }
    }

    getCurrectYear(getYear?: any) {
        if (getYear && getYear !== undefined && getYear !== null) {
            this.getBluePrintDetails(getYear);
            this.setState({ academicYear: getYear });
        } else {
            this.getBluePrintDetails(this.props.getChangeYearData);
            this.setState({ academicYear: this.props.getChangeYearData });
        }
    }

    getBluePrintDetails(getYear?: any) {
        const getData = {
            academic_year: getYear,
        }
    }


    handleRating(rate:any, id:any) {
    const updatedQuestions = this.state.records.map((question:any) => ({
        ...question,
        question: question.question.map((q:any) => {
          if (q.uid === id) {
            return { ...q, currentRating: rate };
          }
          return q;
        }),
      }));
      this.setState({records: updatedQuestions})
      this.calculateTotalMarks(updatedQuestions);
    }

    getGradeOption = (e: any) => {
        const { academicYear } = this.state;
        const { value } = e.target;
        const getValue = value;
        this.setState({ grade: getValue, hasMore: true });
        const getClassName = {
          grade: getValue
        }
        if (getClassName) {
          this.props.fetchMySectionListGet(getClassName)
        }
    }

    getStandardOption = (e: any) => {
        const { academicYear,grade, class_id } = this.state;
        const {ListStandard} = this.props;
        const { value } = e.target;
        const getStandard = value;

        const selectedStandard = ListStandard.find((standard:any) => standard.value === getStandard);
        if(selectedStandard){
        const getSection = {
          academic_year: academicYear,
          class_id: selectedStandard.id,         
        }
        this.setState({ standard: getStandard, hasMore: true, class_id: selectedStandard.id });        
        this.props.GetStudentNameList(getSection).then((res:any) => {
            if (this.props.getStudentDetails) {
                const studentDetails = this.props.getStudentDetails || [];
                const questionPaperId = this.state.uid;
                const classId = this.state.class_id;
        
                this.props.fetchAssessmentStudentListGet(questionPaperId, classId).then((res:any) => {
                    const assessmentStudDetails = this.props.getAssessmentStudents;
                    if (assessmentStudDetails) {
                        const filteredStudents = studentDetails.filter((student:any) => 
                            !assessmentStudDetails.includes(student.student_id.toString())
                        );
                        this.setState({ studentList: filteredStudents });                        
                    } else {
                        this.setState({ studentList: studentDetails });
                    }
                });
            }
        });
        
        }
    }

    calculateTotalMarks(records:any){
        let totalmarks = 0;
        records.forEach((item:any) => {
          item.question.forEach((ques:any) => {
            totalmarks += ques.currentRating || 0;
          })  
        });
        this.setState({added_total_marks: totalmarks});
    }

    toRoman(num:any) {
        const roman = [
          { value: 1000, symbol: "M" },
          { value: 900, symbol: "CM" },
          { value: 500, symbol: "D" },
          { value: 400, symbol: "CD" },
          { value: 100, symbol: "C" },
          { value: 90, symbol: "XC" },
          { value: 50, symbol: "L" },
          { value: 40, symbol: "XL" },
          { value: 10, symbol: "X" },
          { value: 9, symbol: "IX" },
          { value: 5, symbol: "V" },
          { value: 4, symbol: "IV" },
          { value: 1, symbol: "I" },
        ];
      
        let result = "";
        for (let i = 0; i < roman.length; i++) {
          while (num >= roman[i].value) {
            result += roman[i].symbol;
            num -= roman[i].value;
          }
        }
        return result.toLowerCase(); // Convert to lowercase for i, ii, iii...
      }
      

    private renderQuestionSectionView(records: any) {
        let index = 1;
        return (
            <div>
                {records && records.length > 0 ?
                    records.map((items: any) => (
                        <div className="question_section">
                            <div className="question_section_title">
                                <div>
                                    {items.question_type_desc}
                                </div>
                                <div>
                                    {items.marks}
                                </div>
                            </div>
                            {items.question.length > 0 && items.question !== undefined ?
                                items.question.map((ques: any) => (
                                                                        
                                    <>
                                        {
                                            <div className="questions_block">
                                                <div className="question_name">{index++}.    {ques.question}</div >
                                                <div className="question_image">
                                                    {ques.question_image ?
                                                        <img src={ques.question_image} width={200} height={200}></img>
                                                        : null}
                                                </div>
                                                {
                                                    ques.sub_question_list ? (
                                                        <>
                                                            {ques.sub_question_list.length > 0 ? (
                                                                ques.sub_question_list.map((option: any, index: any) => (
                                                                    <>
                                                                        <div className="mt-4">{this.toRoman(index + 1)}.  {option.question}</div>
                                                                        {option.question_image ? <div className="question_image">
                                                                            <img src={option.question_image} width={200} height={200}></img>
                                                                        </div > : null}
                                                                    </>
                                                                ))
                                                            ) : null}
                                                        </>) : null
                                                }
                                                <div className="text-right mb-3 mr-2 mt-4">
                                                    <Rating name="customized-10" 
                                                    defaultValue={ques.currentRating} 
                                                    max={items.question_type_marks} 
                                                    precision={0.5}
                                                    size="large"
                                                    onChange={(event, newValue) => {
                                                        this.handleRating(newValue,ques.uid)
                                                    }}/>
                                                </div>
                                            </div>
                                        }
                                    </>
                                ))
                                : null}
                        </div >

                    ))
                    : null}
            </div >)
    }



    render() {
        const { records, class_id,student_id,question_paper_id, studentList,added_total_marks, standard, totalMarks} = this.state;
        const { loading } = this.props;
        const loadingQuestionFull = { display: loading ? "block" : "none" };
        let gradeList: any;
        if (this.props.ListGrade) {
        gradeList = this.props.ListGrade;
        }
        return (
            <div className="page-wrapper">
                <div className="page-content-wrapper">
                    <div className="page-content pt-3">
                        <BreadCrumb
                            titleName={['Assessment Create']}
                            homeName={['Home']}
                            url={['dashboard']}
                            mainPageTitle={['Assessment Create']} />

                        <div className="row">
                            <div className="col-md-12">

                                <div>
                                    <Formik
                                        ref={node => this.formik = node}
                                        initialValues={initialValues}
                                        validationSchema={AssessmentSchema}
                                        onSubmit={(values, {setSubmitting}) => {   
                                            const finalData :any = {};

                                            this.state.records.forEach((group:any) => {
                                              group.question.forEach((item:any) => {
                                                finalData[item.uid] = item.currentRating;
                                              });
                                            });
                                         
                                            const getAssessmentValues : any={
                                                class_id: this.state.class_id.toString(),
                                                student_id: values.student_id.toString(),
                                                question_paper_id: this.state.uid,
                                                assessment_details: finalData
                                            };
                                            this.props.createAssessmentpost(getAssessmentValues).then((res:any)=>{

                                            })
                                        }}
                                        >
                                        {({ values, errors, isSubmitting, isValidating, handleChange, dirty, touched, setFieldValue }) => (
                                            <Form>
                                                <div className="row">
                                                <div className="col-md-4 col-sm-4 col-4">
                                                <FormGroup>
                                                        <Field
                                                        label={<><span>Class</span><span className="formmandatorycolor"> *</span></>}
                                                        name="internalGradeId"
                                                        component={TextField}
                                                        disabled={false}  
                                                        value={this.state.internalGradeName}>                                                            
                                                        </Field>
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-4 col-sm-4 col-4">
                                                    <FormGroup>
                                                        <Field
                                                            label={<><span>Section</span><span className='formmandatorycolor'> *</span></>}
                                                            name="standard"
                                                            select
                                                            component={TextField}
                                                            disabled={false}
                                                            onChange={(e:any, value:any) =>{
                                                                this.getStandardOption(e);                                                          
                                                                setFieldValue("standard", e.target.value);  
                                                                setFieldValue("student_id", '');                                                           
                                                              }}
                                                            >
                                                            {this.props.ListStandard.map((item: any) => (
                                                                <MenuItem value={item.value}>{item.value}</MenuItem>
                                                                ))
                                                            }
                                                        </Field>
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-4 col-sm-4 col-4">
                                                    <FormGroup>
                                                        <Field
                                                            label={<><span>Student</span><span className='formmandatorycolor'> *</span></>}
                                                            name="student_id"
                                                            select
                                                            component={TextField}
                                                            disabled={false}                                                            
                                                            >
                                                            {studentList && studentList.map((items: any) =>(
                                                                <MenuItem value={items.student_id}>
                                                                {items.student_name}
                                                                </MenuItem>
                                                            ))} 
                                                        </Field>
                                                    </FormGroup>
                                                </div>
                                                
                                                </div>
                                                <div className="card-box mb-3 mr-2 mt-5">
                                                    <div className="card-head">
                                                        <header>Assessment Entry</header>
                                                    </div>
                                                    <div className="card-body">
                                                        <div>
                                                            {this.renderQuestionSectionView(records)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-right mb-3 mr-2 mt-4">
                                                    <div className="font-weight-bold mb-3 mr-2 mt-4">Total Marks : {added_total_marks} / {totalMarks}</div>
                                                    <Link to={"/assessment"}>
                                                        <Button className="btn btn-pink mr-1 ml-1">
                                                            Cancel
                                                        </Button>
                                                    </Link>
                                                        <Button className="btn btn-pink mr-1 ml-1" type="submit">
                                                            Submit
                                                        </Button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                                
                            </div>
                        </div>                        
                    </div>
                </div>
                <div style={loadingQuestionFull}><SpinnerLoader /></div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        loading: state.Assessment.loading,
        getYear: state.profile.getYear,
        stopYear: state.profile.yearStatusFails,
        getChangeYearData: state.profile.getYear,
        bluePrintById: state.QuestionBank.bluePrintById,
        ListGrade: state.classes.my_class,
        ListStandard: state.classes.my_section,
        getStudentDetails:state.student.getStudentNameList,
        assessmentById: state.Assessment.assessmentById,
        getAssessmentStudents: state.Assessment.getAssessmentStudents,
    }
}

export default connect(mapStateToProps, {
    stopYearData,
    fetchBluePrintByIdGet,
    fetchAssessmentQuestionById,
    fetchMySectionListGet,
    GetStudentNameList,
    createAssessmentpost,
    fetchAssessmentStudentListGet,
})(AssessmentCreate)