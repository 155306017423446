import React, { Component } from "react";
import { Button, MenuItem, FormGroup } from "@material-ui/core";
import { connect } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import {
  fetchGradePost,
  fetchMyClassListGet,
} from "../../store/classes/Actions";
import { Link } from "react-router-dom";
import { Field, Formik, Form, FieldArray } from "formik";
import { GetCorrectSubject } from "../../store/subject/Actions";
import { TextField } from "formik-material-ui";
import SpinnerLoader from "../../components/spinner/SpinnerLoader";
import {
  addQuestion,
  getTeacherSubjectList,
  getAdminSubjectList
} from "../../store/question/Actions";
import { validationSchemaTest } from "./QuestionValidation";
import { fetchGetAllClassList } from "../../store/diary/Actions";
import "./QuestionStyle.scss";
import { stopYearData } from "../../store/profile/Actions";
import {reqDownloadFile} from '../../store/question/Actions'

export type OwnQuestSetProps = {
  loading: boolean;
  getChangeYearData?: any;
  stopYearDate?: any;
  loadingQuestion: boolean;
  addQuestion: (postQuestion: any) => void;
  fetchGradePost: (postValue: any) => void;
  GetCorrectSubject: (postValue: any) => void;
  fetchGetAllClassList: (postValue: any) => any;
  getTeacherSubjectList: (getList: any) => any;
  getAdminSubjectList: (getList: any) => any;
  fetchMyClassListGet: () => any;
  reqDownloadFile:()=>any;
  stopYearData: () => any;
  schoolAdminClass: any;
  getClassList: any;
  getSubjectlist: any;
  getProfile: any;
  getCorrectClassList: any;
  getErrormessage: any;
  TeacherSubjectList: any;
  AdminSubjectList:any;
  getDownloadFile:any;
};
const initialValues = {
  question_name: "",
  select_class: "",
  select_subject: "",
  questionlist: [
    {
      question: "",
      add_topics: "",
      question_name_A: "",
      question_name_B: "",
      question_name_C: "",
      question_name_D: "",
      answer: "",
    },
  ],
};
interface StateTypes {
  SubjectList: any;
  ClassListSchoolAdmin: any;
  ClassListTeacher: any;
  classListStatus?: any;
  academicYear?: null;
  openCsvFileUpload?:any
}

export class AddQuestion extends Component<OwnQuestSetProps, StateTypes> {
  getQuestionList: any = [];
  formikQuestion: any;
  constructor(props: any) {
    super(props);
    this.state = {
      SubjectList: [],
      ClassListSchoolAdmin: [],
      ClassListTeacher: [],
      classListStatus: false,
      academicYear: null,
      openCsvFileUpload:false
    };
  }
  componentDidMount(): void {
    window.scrollTo(0, 0);
    this.getCurrectYear();
  }
  
  onDownloadFile = () =>{
    const FileDownload = require("js-file-download");
    this.props.reqDownloadFile().then(()=>{
      FileDownload(this.props.getDownloadFile, "question_template.csv");
    })
  }

  // This is life cycle is initial load  component Will Update
  componentWillUpdate(nextProps: any, nextState: any) {
    const { stopYearDate } = this.props;
    if (stopYearDate === true) {
      this.getCurrectYear(this.props.getChangeYearData);
    }
  }
  // This is life cycle is initial load  component Did Update
  componentDidUpdate() {
    const { stopYearDate } = this.props;
    if (stopYearDate === true) {
      this.props.stopYearData();
    }
  }
  // This is function is used get correct year
  getCurrectYear(getYear?: any) {
    if (getYear && getYear !== undefined && getYear !== null) {
      this.getAddQuestion(getYear);
      this.setState({ academicYear: getYear });
    } else {
      this.getAddQuestion(this.props.getChangeYearData);
      this.setState({ academicYear: this.props.getChangeYearData });
    }
  }

  getAddQuestion(getYear?: any) {
    const { getProfile } = this.props;
    const postValue = {
      academic_year: getYear,
    };
    this.props.fetchGradePost(postValue);
    if (getProfile) {
      if (getProfile.usertype === 2) {
        this.props.fetchMyClassListGet();
      } else if (getProfile.usertype === 3) {
        this.getCommonData(getYear);
      }
    }
    this.onShowUserBasedClass();
  }
  getCommonData(getYear?: any) {
    const { getProfile } = this.props;
    if (getProfile) {
      const postValue = {
        academic_year: getYear,
        school_id: getProfile.school_id,
      };
      this.props.fetchGetAllClassList(postValue).then((res: any) => { });
    }
  }
  getschoolAdmin(id: any) {
    const { getProfile } = this.props;
    const { academicYear } = this.state;
    if (getProfile) {
      if(getProfile.userType == 2){
        const postValue = {
          academic_year: academicYear,
          school_id: getProfile.school_id,
          grade_id: id,
        };
        this.props.getAdminSubjectList(postValue).then((res: any) => {
          const { AdminSubjectList } = this.props;
          if (
            AdminSubjectList &&
            AdminSubjectList.length > 0 &&
            AdminSubjectList !== undefined
          ) {
            this.setState({
              SubjectList: AdminSubjectList,
              classListStatus: false,
            });
            this.formikQuestion.setFieldValue("select_subject", "");
          } else {
            this.setState({ SubjectList: [], classListStatus: true });
            this.formikQuestion.setFieldError(
              "select_subject",
              "No matching records found"
            );
          }
        });
      }else{
      const postValue = {
        academic_year: academicYear,
        school_id: getProfile.school_id,
        class_id: id,
      };
      this.props.getTeacherSubjectList(postValue).then((res: any) => {
        const { TeacherSubjectList } = this.props;
        if (
          TeacherSubjectList &&
          TeacherSubjectList.length > 0 &&
          TeacherSubjectList !== undefined
        ) {
          this.setState({
            SubjectList: TeacherSubjectList,
            classListStatus: false,
          });
          this.formikQuestion.setFieldValue("select_subject", "");
        } else {
          this.setState({ SubjectList: [], classListStatus: true });
          this.formikQuestion.setFieldError(
            "select_subject",
            "No matching records found"
          );
        }
      });
    }
    }
  }
  getPostSubjectList = (e: any, getValue?: any) => {
    if (getValue) {
      this.getschoolAdmin(getValue);
    }
  };

  onShowUserBasedClass() {
    const getProfile = this.props.getProfile;
    if (getProfile) {
      if (getProfile.usertype === 1 || getProfile.usertype === 2) {
        this.setState({ ClassListSchoolAdmin: this.props.schoolAdminClass });
        if (this.state.SubjectList.length > 0) {
          this.setState({ classListStatus: false });
        } else {
          this.setState({ classListStatus: true });
        }
      } else if (getProfile.usertype === 3) {
        this.setState({ ClassListTeacher: this.props.schoolAdminClass });
        if (this.state.SubjectList.length > 0) {
          this.setState({ classListStatus: false });
        } else {
          this.setState({ classListStatus: true });
        }
      }
    }
  }

  onOpenUploadOption = () =>{
this.setState({openCsvFileUpload:!this.state.openCsvFileUpload})
  }
  render() {
    const { loading, loadingQuestion, getProfile } = this.props;

    const loadingQuestionFull = {
      display: loading || loadingQuestion ? "block" : "none",
    };
    const {
      SubjectList,
      ClassListSchoolAdmin,
      ClassListTeacher,
      classListStatus,
    } = this.state;
    return (
      <div>
        <div className="page-wrapper">
          <div className="page-content-wrapper">
            <div className="page-content pt-3">
              <BreadCrumb
                titleName={["New Question Set"]}
                homeName={["Home"]}
                url={["dashboard"]}
                mainPageTitle={["Add Question Set"]}
                baseName={["Question Set"]}
                baseURL={["question"]}
              />
              {SubjectList && getProfile && this.props.getClassList ? (
                <div className="row">
                  <div className="col-md-12">
                   
                    <Formik
                      ref={(node) => (this.formikQuestion = node)}
                      initialValues={initialValues}
                      validationSchema={validationSchemaTest}
                      onSubmit={(values, actions) => {
                        const { academicYear } = this.state;
                        let getQuestionValue: any = [];
                        let submitValue: any = [];
                        if (values) {
                          values.questionlist.forEach(
                            (item: any, index: any) => {
                              let optionA: any;
                              let optionB: any;
                              let optionC: any;
                              let optionD: any;
                              if (item.answer === "A") {
                                optionA = true;
                                optionB = false;
                                optionC = false;
                                optionD = false;
                              } else if (item.answer === "B") {
                                optionA = false;
                                optionB = true;
                                optionC = false;
                                optionD = false;
                              } else if (item.answer === "C") {
                                optionA = false;
                                optionB = false;
                                optionC = true;
                                optionD = false;
                              } else if (item.answer === "D") {
                                optionA = false;
                                optionB = false;
                                optionC = false;
                                optionD = true;
                              }

                              submitValue = {
                                serial_no: index + 1,
                                question: item.question,
                                question_picture: "",
                                topics: item.add_topics,
                                answers: [
                                  {
                                    serial_no: "A",
                                    choice: item.question_name_A,
                                    choice_url: "",
                                    is_correct_ans: optionA,
                                  },
                                  {
                                    serial_no: "B",
                                    choice: item.question_name_B,
                                    choice_url: "",
                                    is_correct_ans: optionB,
                                  },
                                  {
                                    serial_no: "C",
                                    choice: item.question_name_C,
                                    choice_url: "",
                                    is_correct_ans: optionC,
                                  },
                                  {
                                    serial_no: "D",
                                    choice: item.question_name_D,
                                    choice_url: "",
                                    is_correct_ans: optionD,
                                  },
                                ],
                              };
                              this.getQuestionList.push(submitValue);
                            }
                          );

                          if (this.getQuestionList.length > 0) {
                            const createrBy: any = `${getProfile.firstname} ${getProfile.lastname}`;
                            getQuestionValue = {
                              school_id: getProfile.school_id,
                              academic_year: academicYear,
                              grade_id: String(values.select_class),
                              subject_id: String(values.select_subject),
                              question_name: values.question_name,
                              posted_by: createrBy,
                              questions: this.getQuestionList,
                            };
                            this.props.addQuestion(getQuestionValue);
                          }
                        }
                      }}
                    >
                      {({
                        values,
                        errors,
                        isSubmitting,
                        isValidating,
                        dirty,
                        touched,
                        handleReset,
                        handleSubmit,
                        setFieldValue,
                      }) => {
                        // this is the add question set validation message
                        let getAnswerList: any;
                        values.questionlist.length > 0
                          ? (getAnswerList = true)
                          : (getAnswerList = false);
                        const isQuestionEmpty =
                          !values.question_name ||
                          !values.select_class ||
                          !values.select_subject;
                        return (
                          <Form>
                            <div className="row">
                              <div className="col-md-4">
                                <FormGroup>
                                  <Field
                                    label={<><span>Question Set Name</span><span className='formmandatorycolor'> *</span></>}
                                    type="text"
                                    name="question_name"
                                    component={TextField}
                                    className="textfield__input"
                                    disabled={false}
                                  />
                                </FormGroup>
                              </div>
                              <div className="col-md-4">
                                <FormGroup className="w-100 mb-4">
                                  {ClassListSchoolAdmin &&
                                    getProfile.usertype === 2 ? (
                                    <Field
                                      label={<><span>Select Class</span><span className='formmandatorycolor'> *</span></>}
                                      name="select_class"
                                      select
                                      component={TextField}
                                      disabled={false}
                                      onChange={(e: any, value: any) => {
                                        setFieldValue(
                                          "select_class",
                                          e.target.value
                                        );
                                        this.getPostSubjectList(e, e.target.value);
                                      }}
                                    >
                                      {ClassListSchoolAdmin.map((item: any) => (
                                        <MenuItem value={item.grade_id}>
                                          {item.value}
                                        </MenuItem>
                                      ))}
                                    </Field>
                                  ) : null}
                                  {ClassListTeacher &&
                                    getProfile.usertype === 3 ? (
                                    <Field
                                      label={<><span>Select Class</span><span className='formmandatorycolor'> *</span></>}
                                      name="select_class"
                                      select
                                      component={TextField}
                                      disabled={false}
                                      onChange={(e: any, value: any) => {
                                        setFieldValue(
                                          "select_class",
                                          e.target.value
                                        );
                                        this.getPostSubjectList(e, e.target.value);
                                      }}
                                    >
                                      {this.props.getCorrectClassList.map(
                                        (item: any) => (
                                          <MenuItem value={item.id}>
                                            {item.grade_standard}
                                          </MenuItem>
                                        )
                                      )}
                                    </Field>
                                  ) : null}
                                </FormGroup>
                              </div>

                              <div className="col-md-4">
                                <FormGroup className="w-100 mb-4">
                                  <Field
                                    label={<><span>Select Subject</span><span className='formmandatorycolor'> *</span></>}
                                    name="select_subject"
                                    select
                                    component={TextField}
                                    disabled={classListStatus}
                                  >
                                    {SubjectList.map((item: any) => (
                                      <MenuItem value={item.id}>
                                        {item.name}
                                      </MenuItem>
                                    ))}
                                  </Field>
                                </FormGroup>
                              </div>
                            </div>
                            <FieldArray
                              name="questionlist"
                              render={({ insert, remove, push }) => (
                                <>
                                  {values.questionlist.length > 0 &&
                                    values.questionlist.map(
                                      (friend: any, index: any) => (
                                        <div className="row" key={index}>
                                          <div className="col-md-12">
                                            <div className="card-box mt-4">
                                              <div className="card-body  pb-5">
                                                <div className="col-md-12 text-right ">
                                                  <button
                                                    className=""
                                                    type="button"
                                                    title="Delete Question"
                                                    onClick={() =>
                                                      remove(index)
                                                    }
                                                  >
                                                    <span className="deleteIcon">
                                                      <i
                                                        className="fa fa-trash"
                                                        aria-hidden="true"
                                                      ></i>
                                                    </span>
                                                  </button>
                                                </div>

                                                <div className="d-flex mt-1 w-100">
                                                  <div className="mr-3 mt-4">
                                                    {index + 1}.
                                                  </div>
                                                  <FormGroup className="w-100">
                                                    <Field
                                                      label={<><span>Write Question</span><span className='formmandatorycolor'> *</span></>}
                                                      type="text"
                                                      name={`questionlist.${index}.question`}
                                                      component={TextField}
                                                      className="textfield__input"
                                                      disabled={false}
                                                    />
                                                  </FormGroup>
                                                </div>
                                                <div className="col-md-12 pr-0 mt-3">
                                                  <FormGroup className="w-100">
                                                    <Field
                                                      label={<><span>Add Topics</span><span className='formmandatorycolor'> *</span></>}
                                                      type="text"
                                                      name={`questionlist.${index}.add_topics`}
                                                      component={TextField}
                                                      className="textfield__input"
                                                      disabled={false}
                                                    />
                                                  </FormGroup>
                                                </div>

                                                <div className="row">
                                                  <>
                                                    {errors.questionlist ? (
                                                      <div className="col-md-12 text-center mt-3">
                                                        {Object.keys(errors)
                                                          .length !== 0 &&
                                                          errors!.questionlist![
                                                            index
                                                          ]?.answer !==
                                                          undefined &&
                                                          touched &&
                                                          touched?.questionlist &&
                                                          touched?.questionlist
                                                            .length !== index ? (
                                                          <div className="errorcolor">
                                                            {
                                                              errors!
                                                                .questionlist![
                                                                index
                                                              ]?.answer!
                                                            }
                                                          </div>
                                                        ) : null}
                                                      </div>
                                                    ) : null}
                                                  </>
                                                  <div className="col-md-6">
                                                    <div className="col-md-12">
                                                      <div className="d-flex mt-3">
                                                        <div className="mt-4 mr-3 ml-2">
                                                          <Field
                                                            type="radio"
                                                            name={`questionlist.${index}.answer`}
                                                            color="success"
                                                            value="A"
                                                            className="bob-radio"
                                                          />
                                                        </div>
                                                        <div className="mr-3 mt-4">
                                                          A.
                                                        </div>
                                                        <FormGroup className="w-100">
                                                          <Field
                                                            label={<><span>Write Option A</span><span className='formmandatorycolor'> *</span></>}
                                                            type="text"
                                                            name={`questionlist.${index}.question_name_A`}
                                                            component={
                                                              TextField
                                                            }
                                                            className="textfield__input"
                                                            disabled={false}
                                                          />
                                                        </FormGroup>
                                                      </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                      <div className="d-flex mt-3">
                                                        <div className="mt-4 mr-3 ml-2">
                                                          <Field
                                                            type="radio"
                                                            name={`questionlist.${index}.answer`}
                                                            value="B"
                                                          />
                                                        </div>
                                                        <div className="mr-3 mt-4">
                                                          B.
                                                        </div>
                                                        <FormGroup className="w-100">
                                                          <Field
                                                            label={<><span>Write Option B</span><span className='formmandatorycolor'> *</span></>}
                                                            type="text"
                                                            name={`questionlist.${index}.question_name_B`}
                                                            component={
                                                              TextField
                                                            }
                                                            className="textfield__input"
                                                            disabled={false}
                                                          />
                                                        </FormGroup>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="col-md-12">
                                                      <div className="d-flex mt-3">
                                                        <div className="mt-4 mr-3 ml-2">
                                                          <Field
                                                            type="radio"
                                                            name={`questionlist.${index}.answer`}
                                                            value="C"
                                                          />
                                                        </div>
                                                        <div className="mr-3 mt-4">
                                                          C.
                                                        </div>
                                                        <FormGroup className="w-100">
                                                          <Field
                                                            label={<><span>Write Option C</span><span className='formmandatorycolor'> *</span></>}
                                                            type="text"
                                                            name={`questionlist.${index}.question_name_C`}
                                                            component={
                                                              TextField
                                                            }
                                                            className="textfield__input"
                                                            disabled={false}
                                                          />
                                                        </FormGroup>
                                                      </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                      <div className="d-flex mt-3">
                                                        <div className="mt-4 mr-3 ml-2">
                                                          <Field
                                                            type="radio"
                                                            name={`questionlist.${index}.answer`}
                                                            value="D"
                                                          />
                                                        </div>
                                                        <div className="mr-3 mt-4">
                                                          D.
                                                        </div>
                                                        <FormGroup className="w-100">
                                                          <Field
                                                            label={<><span>Write Option D</span><span className='formmandatorycolor'> *</span></>}
                                                            type="text"
                                                            name={`questionlist.${index}.question_name_D`}
                                                            component={
                                                              TextField
                                                            }
                                                            className="textfield__input"
                                                            disabled={false}
                                                          />
                                                        </FormGroup>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  <div className="col-md-12 text-center">
                                    <Link
                                      to={"#"}
                                      title="Add Question"
                                      className="mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab mdl-button--colored margin-right-10"
                                      data-upgraded=",MaterialButton"
                                      onClick={() =>
                                        push({
                                          question: "",
                                          add_topics: "",
                                          question_name_A: "",
                                          question_name_B: "",
                                          question_name_C: "",
                                          question_name_D: "",
                                        })
                                      }
                                    >
                                      <i className="material-icons">add</i>
                                    </Link>
                                  </div>
                                </>
                              )}
                            />
                            <div className="text-right mb-3 mr-2 mt-4">
                              <Button
                                className="btn btn-pink mr-1 ml-1"
                                type="submit"
                                disabled={
                                  isQuestionEmpty ||
                                  isValidating ||
                                  !dirty ||
                                  !!(
                                    errors.question_name &&
                                    touched.question_name
                                  ) ||
                                  !!(
                                    errors.select_class && touched.select_class
                                  ) ||
                                  !!(
                                    errors.select_subject &&
                                    touched.select_subject
                                  ) ||
                                  !getAnswerList
                                }
                              >
                                Submit
                              </Button>
                              <Link to={"/question"}>
                                <Button className="btn btn-default mr-1 ml-1 ">
                                  Cancel
                                </Button>
                              </Link>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              ) : (
                <SpinnerLoader />
              )}
            </div>
          </div>
        </div>
        <div style={loadingQuestionFull}>
          <SpinnerLoader />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    loading: state.classes.loading,
    getChangeYearData: state.profile.getYear,
    stopYearDate: state.profile.yearStatusFails,
    loadingQuestion: state.questionset.loading,
    getClassList: state.classes.gradelist,
    getSubjectlist: state.subjects.category,
    getProfile: state.profile.profileData,
    schoolAdminClass: state.classes.my_class,
    getCorrectClassList: state.diary.gradelist,
    TeacherSubjectList: state.questionset.getTeacherSubjectList,
    AdminSubjectList: state.questionset.getAdminSubjectList,
    getErrormessage: state.questionset.errors,
    getDownloadFile:state.questionset.downloadFiles
  };
};

export default connect(mapStateToProps, {
  fetchGradePost,
  GetCorrectSubject,
  addQuestion,
  fetchGetAllClassList,
  getTeacherSubjectList,
  getAdminSubjectList,
  fetchMyClassListGet,
  reqDownloadFile,
  stopYearData,
})(AddQuestion);
