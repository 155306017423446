import React, { Component } from "react";
import { connect } from "react-redux";
import CommonLoader from '../../components/CommonLoader';
import { Bar } from 'react-chartjs-2';

export type OwnReportProps = {
    teacherAttendaceReportData?: any;
    reportType?: any;
};

interface StateTypes {

}
export class TeacherAttendaceViewReport extends Component<OwnReportProps, StateTypes>{
    formik: any
    constructor(props: any) {
        super(props);
        this.state = {
          
        }
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);
    }
    render() {
        const { teacherAttendaceReportData } = this.props

        let labelsName: any;
        let backgroundColor: any;
        let dataCount: any;
        let present: any;
        let absent: any; 
        if(teacherAttendaceReportData !== undefined && teacherAttendaceReportData !== null){
			if(teacherAttendaceReportData){
				labelsName = [];
				backgroundColor = [];
				dataCount = [];
                present = [];
                absent = [];
				teacherAttendaceReportData.forEach((items:any)=>{
					labelsName.push(items.teacher_name);
                present.push(items.present)
                absent.push(items.absent)
				})


     dataCount = [
        {
            label: 'Presence',
          data: teacherAttendaceReportData.map((item:any) => item.present),
          color_code: '#369c38',
          backgroundColor: '#369c38',
        },
        {
            label: 'Absence',
            data: teacherAttendaceReportData.map((item:any) => item.absent),
          color_code: '#ea502e',
          backgroundColor: '#ea502e',
        }
      ];
			}
		}

        return (
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-5">
                                <div className={`card card-topline-red`}>
                                    <div className="card-head">
                                        <header>Teacher Attendance</header>
                                    </div>
                                    <div className="card-body no-padding height-9" >
                                    {teacherAttendaceReportData && teacherAttendaceReportData !== undefined && teacherAttendaceReportData !== null ?
                                            <div className="row">
                                                <Bar
                                                    data={{
                                                        labels:labelsName,
                                                        datasets:dataCount
                                                    }}
                                                    height={80}
                                                    options={{
                                                        legend: {
                                                            display: true
                                                        },
                                                        title: {
                                                            display: true,
                                                            text: ''
                                                        },
                                                        plugins: {
                                                            datalabels: {
                                                              display: true,
                                                              color: "black",
                                                              align: "end",
                                                              anchor: "end",
                                                              font: { size: "14" },
                                                              formatter: (val:any) => {
                                                                return val+'%';
                                                              },
                                                            }
                                                          },
                                                        scales: {
                                                            yAxes: [{
                                                                ticks: {
                                                                    beginAtZero: true,
                                                                    steps: 10,
                                                                    stepValue: 5,
                                                                    userCallback: function (label: any, index: any, labels: any) {
                                                                        if (Math.floor(label) === label) {
                                                                            return label;
                                                                        }
                                                                    }
                                                                },
                                                                gridLines: {
                                                                    color: 'rgba(170,170,170,1)',
                                                                    //lineWidth: 0.2
                                                                },
                                                                scaleLabel: {
                                                                    display: true,
                                                                    labelString: 'Performance (%)'
                                                                }
                                                            }],
                                                            xAxes: [{
                                                                //barPercentage: 0.1,
                                                                gridLines: {
                                                                    display: false
                                                                },
                                                                scaleLabel: {
                                                                    display: true,
                                                                    labelString: 'Teachers'
                                                                }
                                                            }]
                                                        }
                                                    }}
                                                    legend={{
                                                        display:true,
                                                        position:'top',
                                                        labels:{
                                                            boxHeight:100,
                                                        }
                                                     }}
                                                />
                                            </div>
                                            : <CommonLoader />}
                                    </div>
                                </div>
                            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

export default connect(mapStateToProps, {

})(TeacherAttendaceViewReport)