import * as yup from 'yup';
import { questionSetValidation } from '../../services/Constants';

    export const schema = yup.object().shape({
        select_age: yup.string().required('Please select age'),
        search_teacher_name: yup.array().when('is_otherteacher_enable', {
            is: (value) => value == true, 
            then:yup.array()
            .nullable()
            .min(1, 'This is required field'),
          }),
    });