// import React from 'react'
import React, { FC, useState, useEffect } from "react";

import Modal from 'react-bootstrap/Modal'
import { Formik, Form, Field } from "formik";
import { Button, MenuItem, FormGroup, Checkbox, Paper, Box, Divider } from "@material-ui/core";
import moment from 'moment';
import * as yup from 'yup';
import { TextField } from 'formik-material-ui';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Popper from '@material-ui/core/Popper';
import Switch from '@material-ui/core/Switch';
import CommonLoader from '../../components/CommonLoader';
import { FormControlLabel } from '@material-ui/core';

const schema = yup.object({
    event_desc: yup.string()
        .max(1000, "Description should not be more than 1000 characters")
        .required('Description is required'),
    event_type: yup.string()
        .required('Type is required'),
    start_date: yup.date()
        .required('Start Date is required')
        .nullable(),
    end_date: yup.date()
    .required('End date is required')
    .nullable()
    .min(
      yup.ref('start_date'),
      'End date cannot be earlier than the start date'
    ),
    grade: yup.array()
    .nullable()
    .min(1, 'Grade is required field'),
        
})

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CustomPopper = (props: any) => {
    return <Popper {...props} style={{ zIndex: 8000000000 }} />;
};

export const SchoolCalendarModel = (props: any) => {
    const [isWorkDay, setIsWorkDay] = useState(false);

    const onCancel=()=>{
        setIsWorkDay(false);
        props.onCancel()
     } 

     const onHandleSubmit=(values:any)=>{
        setIsWorkDay(false);
        props.handleFormSubmit(values)
     }

    let filteredGrade = []
    if (props.editing) {
        //const val = props.editingData?.grade_id.split(",").map(Number);
        const val = props.editingData?.grade_id;
        filteredGrade = props.gradeList.filter((grade: any) => val.includes(grade.id));
    }
    const handleToggleSelectAll = (e:any, setFieldValue: any) => {
        // setSelectAll((prev: any) => {
        //     if (!prev) 
        //     {
        //         setFieldValue('grade', [...props.gradeList]);
        //         setFieldValue('selectAll', true);
        //     }
        //     else{ 
        //         setFieldValue('grade', []);
        //         setFieldValue('selectAll', false);
        //     }
        //     return !prev;
        // });
        if(e.target.checked){
            setFieldValue('grade', [...props.gradeList]);
            setFieldValue('selectAll', true);
        }else{
            setFieldValue('grade', []);
            setFieldValue('selectAll', false);
        }
    };

    return (
        <div>
            <Modal show={props.showModal} dialogClassName="custom-dialog-view" style={{ zIndex: 1050 }}>
                <Modal.Header>
                    <Modal.Title>{props.editing ? "Edit Event" : "Add Event"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            start_date: props.editingData?.start_date ? props.editingData?.start_date : props.start_date,
                            end_date: props.editingData?.end_date ? props.editingData?.end_date : props.end_date,
                            event_type: props.editingData ? props.editingData?.event_type : "",
                            event_desc: props.editingData ? props.editingData?.title : "",
                            grade: props.editingData ? filteredGrade : [],
                            is_working_day: props.editingData ? props.editingData?.is_working_day : false,
                            selectAll:props.editingData ? props.editingData?.selectAll : false,
                        }}
                        onSubmit={onHandleSubmit}
                        validationSchema={schema}
                    >
                        {({ isSubmitting, values, errors, setFieldValue }) => (
                            <Form>
                                <div className="col-md-12 p-t-20">
                                    <FormGroup>
                                        <legend className="MuiFormLabel-root">
                                            <span>Start Date</span><span className='formmandatorycolor'> *</span>
                                        </legend>
                                        <Field
                                            type="date"
                                            name="start_date"
                                            component={TextField}
                                            disabled={false}

                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-md-12 p-t-20">
                                    <FormGroup>
                                        <legend className="MuiFormLabel-root">
                                            <span>End Date</span><span className='formmandatorycolor'> *</span>
                                        </legend>
                                        <Field
                                            type="date"
                                            name="end_date"
                                            component={TextField}
                                            disabled={false}

                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-md-12 p-t-20">
                                    <FormGroup>
                                        <Field
                                            label={<><span>Type</span><span className='formmandatorycolor'> *</span></>}
                                            name="event_type"
                                            select
                                            component={TextField}
                                            disabled={false}
                                            className={`custom-select-class`}
                                            style={{ zIndex: 1000000, position: 'relative' }}
                                            onChange={(e: any, value: any) => {
                                                setFieldValue(
                                                    "event_type",
                                                    e.target.value
                                                );
                                               
                                                if(e.target.value == "Holiday"){
                                                    setIsWorkDay(false)
                                                    setFieldValue('is_working_day', false);
                                                }
                                                
                                            }}

                                        >
                                            {props.eventTypes && props.eventTypes.length > 0 && props.eventTypes.map((item: any) => (
                                                <MenuItem value={item.name}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Field>

                                    </FormGroup>
                                </div>
                                <div className="col-md-12 p-t-20">
                                    <FormGroup>
                                        <Field
                                            label={<><span>Description</span><span className='formmandatorycolor'> *</span></>}
                                            type="text"
                                            name="event_desc"
                                            value={values.event_desc}
                                            component={TextField}
                                            className="textfield__input"
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-md-12 p-t-20">
                                    <FormGroup>
                                        <Autocomplete
                                            fullWidth
                                            multiple
                                            id="checkboxes-tags-demo"
                                            options={props.gradeList}
                                            disableCloseOnSelect
                                            noOptionsText={'No matching records found.'}
                                            getOptionLabel={(option: any) => option.grade}
                                            value={values.grade}
                                            onChange={(e, value, resonse) => {

                                                if (resonse === "clear" || resonse === "remove-option") {
                                                    setFieldValue('selectAll', false);
                                                }

                                                if (resonse === "select-option" && value.length === props.gradeList.length) {
                                                    setFieldValue('selectAll', true);
                                                    setFieldValue('grade', value);
                                                } else {
                                                    setFieldValue('grade', value);
                                                }


                                            }}
                                            renderOption={(option, { selected }) => {
                                                return (
                                                    <React.Fragment>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.grade}
                                                    </React.Fragment>
                                                )
                                            }}
                                            className="mb-0 mt-1"
                                            renderInput={(params: any) => (
                                                <Field
                                                    component={TextField}
                                                    name="grade"
                                                    error={!!errors.grade}
                                                    helperText={errors.grade}
                                                    {...params} label={<><span>Grades</span><span className='formmandatorycolor'> *</span></>}
                                                    onChange={(e: any) => {

                                                      }}
                                                />
                                            )}
                                            PaperComponent={(paperProps) => {
                                                const { children, ...restPaperProps } = paperProps;
                                                return (
                                                    <Paper {...restPaperProps}>
                                                        <Box
                                                            onMouseDown={(e) => e.preventDefault()} // prevent blur
                                                            pl={1.5}
                                                            py={0.5}
                                                        >
                                                            <FormControlLabel
                                                                onClick={(e) => {
                                                                    e.preventDefault(); // prevent blur
                                                                   // handleToggleSelectAll(e,setFieldValue);
                                                                }}
                                                                label="Select all"
                                                                control={
                                                                    <Checkbox id="select-all-checkbox" checked={values.selectAll}  onChange={(e) => {
                                                                        e.preventDefault(); // prevent blur
                                                                        handleToggleSelectAll(e,setFieldValue);
                                                                    }}/>
                                                                }
                                                            />
                                                        </Box>
                                                        <Divider />
                                                        {children}
                                                    </Paper>
                                                );
                                            }}

                                        />

                                    </FormGroup>
                                </div>
                                <div className="col-md-4 p-t-20">
                                    <FormGroup>
                                        <FormControlLabel
                                            value="end"
                                            control={<Switch
                                                checked={props.editing ? values.is_working_day : isWorkDay}
                                                onChange={
                                                    (e) => {
                                                        setFieldValue('is_working_day', e.target.checked);
                                                        setIsWorkDay(e.target.checked)
                                                    }
                                                }

                                                name="is_working_day"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            />}
                                            disabled={values.event_type == 'Holiday'}
                                            label="Working day(s)"
                                            labelPlacement="end"
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-md-12 p-t-20 d-flex">
                                    <div>
                                    {props.editing && <button type="button" className="btn btn-danger mr-1 ml-1 w-15" onClick={props.onDelete}>Delete</button>}
                                    </div>
                                    <div className="ml-auto">
                                    <button type="submit" className="btn btn-primary mr-1 ml-1 w-15" >Submit</button>
                                    <button type="button" className="btn btn-default mr-1 ml-1 w-15" onClick={onCancel}>Cancel</button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </div>
    )
}
