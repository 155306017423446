import { Reducer } from "redux";
import { AssessmentState, AssessmentTypes } from "./Types";

export const initialState: AssessmentState ={
    loading: false,
    isAuthenticated: null,
    errors: [],
    items: [],
    page: 1,
    per_page: 10,
    total: 0,
    records: [],
    getAssessmentStudents: [],
    getAssessmentDetails: [],
}

const reducer: Reducer<AssessmentState> = (state = initialState, action) => {
    switch(action.type) {
        case AssessmentTypes.FETCH_ASSESSMENT:
        case AssessmentTypes.FETCH_QUESTION_ASSESSMENT_BY_ID:
        case AssessmentTypes.CREATE_ASSESSMENT:
        case AssessmentTypes.EDIT_ASSESSMENT:
            return{
                ...state,
                isAuthenticated:false,
                loading: true,
                errors: [],
            }
        case AssessmentTypes.FETCH_ASSESSMENT_SUCCESS:
        case AssessmentTypes.CREATE_ASSESSMENT_SUCCESS:
            const { payload, records, per_page, page, total } = action;
            if(page===1) {
                state.records = []
            }
            return{
                ...state,
                loading: false,
                isAuthenticated: false,
                items: payload,
                records: [...state.records, ...records],
                page: page,
                per_page: per_page,
                total: total,
                totalPage: Math.ceil(total / per_page),
            }
        case AssessmentTypes.FETCH_ASSESSMENT_FAIL:
        case AssessmentTypes.CREATE_ASSESSMENT_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                errors: [],
                records: [],
                total: 0
            }
        case AssessmentTypes.EDIT_ASSESSMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                editAssessment: action.payload.data,
            }
        case AssessmentTypes.EDIT_ASSESSMENT_FAIL:
        case AssessmentTypes.FETCH_QUESTION_ASSESSMENT_BY_ID_FAIL:
        case AssessmentTypes.FETCH_ASSESSMENT_STUDENTLIST_FAIL:
        case AssessmentTypes.GET_ASSESSMENT_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                errors: action.payload,
            }
        case AssessmentTypes.FETCH_QUESTION_ASSESSMENT_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                assessmentById: action.payload.data,
            }
        case AssessmentTypes.FETCH_ASSESSMENT_STUDENTLIST:
        case AssessmentTypes.GET_ASSESSMENT:
            return {
                ...state,
                isAuthenticated: false,
                loading: true,
            }
        case AssessmentTypes.FETCH_ASSESSMENT_STUDENTLIST_SUCCESS:
            const { getAssessmentStudents } = action;
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                getAssessmentStudents: getAssessmentStudents
            }
        case AssessmentTypes.GET_ASSESSMENT_SUCCESS:
            const { getAssessmentDetails } = action;
            return{
                ...state,
                isAuthenticated: true,
                loading: false,
                getAssessmentDetails: getAssessmentDetails
            }            
        default:
            return state;
    }
}

export { reducer as AssessmentReducer }
