import React from 'react'

export const MinutesToHours = (props:any) => {
  const { minutes } = props;
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return (
    <>
    {minutes?
      <>
     <span>Time: {hours}:{remainingMinutes < 10 ? '0' : ''}{remainingMinutes} hrs</span>
      </>
    : null}
  </>
  )
}
export default MinutesToHours
