import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import { getAttendanceReport } from '../../store/dashboard/Actions';
import moment from 'moment';
import { fetchGetAllClassList } from '../../store/diary/Actions';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import CommonLoader from '../../components/CommonLoader';
import history from '../../History';
import styles from './style.module.css';
import { formValidationPatten } from '../../services/Constants';
import { getAttendanceStudentView, stopYearData, YearListGet } from '../../store/profile/Actions';
import { DateRangePicker } from 'react-date-range';
import {getAllSchoolNameList} from '../../store/usermanage/Actions';
import {ModalView} from '../../components/ModalView';
import { StudentAttendance } from './StudentAttendance';
import {postUpdateAttendance, fetchGetStudentByAcademicYear, postCreateAttendance} from '../../store/student/Actions';
import {STUDENTS} from '../../services/Config';
import {PropsAttendanceType} from './Types';

interface AttendanceProps {
    getYear?:any,
    fetchGetAllClassList: (postPage: any) => any;
    getAttendanceReport:(postValue:any) => any;
    getAttendanceStudentView:(postData:any) => any;
    getAllSchoolNameList: () => any;
    postUpdateAttendance:(postValue:any) => any;
	stopYearData:() => any;
    YearListGet: (postValue?: any) => any;
    fetchGetStudentByAcademicYear: (postPage: any) => any;
    postCreateAttendance:(postValue:any) => any;
    getSchoolNameList:any;
    getAttendanceDetails:any;
    getAllClassList?:any;
    getProfile?:any;
    totalReport?:any;
    loading?:any;
    UpdateAttendanceStatu?:any;
    stopYear?:any;
    updateAttendanceLoader?:boolean;
    studentloading?:any;
    AllStudentList?:any;
    onCreateAttendanceStatus?:any;
}

export class Attendance extends Component<AttendanceProps, PropsAttendanceType> {
    constructor(props: any) {
        super(props);
        this.state = {
            getClassName:'',
            getCurrectYear: null,
            page:1,
            search:'',
            startDate: new Date(),
            endDate: new Date(),
            key:'selection',
            schoolId:'',
            attendanceDetails:this.props.getAttendanceDetails,
            onUpdateAttendance:false,
            onConfirmPost:[],
            onUserActive:false,
            reasonStatu:true,
            reasonData:'',
            onShowAttendance:false,
            studentList:[]
        }
        
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.getUpdateYear();
    }
    componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYear} = this.props;
		if(stopYear === true){
            this.getUpdateYear(this.props.getYear)
		}
	}
	componentDidUpdate() {
		const {stopYear} = this.props;
		if(stopYear === true){
			this.props.stopYearData()
		}
	}
  getUpdateYear(getYearData?:any){
    if(getYearData && getYearData !== undefined && getYearData !== null){
      this.setState({getCurrectYear:getYearData})
      this.getAttebdanceData(getYearData)
    }else {
      this.setState({getCurrectYear:this.props.getYear})
      this.getAttebdanceData(this.props.getYear)
    }
  }

    getAttebdanceData(getYearData?:any){
        const getUserType:any = this.props.getProfile.usertype;
        if(getUserType === 1){
            this.setState({onUserActive:false})
            this.getSchoolName(getYearData)
        } else if(getUserType === 2) {
            this.setState({onUserActive:true})
            this.commonSchoolDetails(getYearData)
        } else if(getUserType === 3) {
            this.setState({onUserActive:false})
            this.commonSchoolDetails(getYearData)
        }
    }
    // This is action used to get student list
    getSchoolName(getYearData?:any){
        this.props.getAllSchoolNameList().then((res:any)=>{
            const {getSchoolNameList} = this.props
            if(getSchoolNameList.length > 0 && getSchoolNameList !== undefined){
                let postValue:any = {
                    school_id:getSchoolNameList[0].id,
                    update_year:getYearData
                }
                this.academicAdminList(postValue)
                this.props.YearListGet({school_id:getSchoolNameList[0].id});
                this.setState({schoolId:getSchoolNameList[0].id})
            }
        });
    }
    academicAdminList(getData:any){
        if(getData){
            const loadMoreType:any = {
                academic_year: getData.update_year,
                school_id: getData.school_id
              }
            this.props.fetchGetAllClassList(loadMoreType).then((res:any)=>{
                const { getAllClassList } = this.props;
                if(getAllClassList){
                    
                    if(getAllClassList.length > 0 && getAllClassList !== undefined &&  getData.school_id){
                        let postValue:any ={
                            class_id:getAllClassList[0].id,
                            school_id: getData.school_id,
                            update_year: getData.update_year
                        }
                        this.getAcademicAttendanceDetails(postValue);
                        this.setState({getClassName:getAllClassList[0].id})
                    }else{
                        this.setState({attendanceDetails:[]})
                    }
                    
                }
            });
        }
           
    }
    getAcademicAttendanceDetails(getvalue:any){
        
        const postValue:any = {
            page_no:1,
            academic_year: getvalue.update_year,
            class_id: getvalue.class_id,
            school_id: getvalue.school_id,
            from: moment(this.state.startDate).format('YYYY-MM-DD'),
            to: moment(this.state.endDate).format('YYYY-MM-DD'),
        }
        
        this.props.getAttendanceReport(postValue).then((res:any)=>{
            const {getAttendanceDetails} = this.props;
            if(getAttendanceDetails && getAttendanceDetails !== null && getAttendanceDetails !== undefined){
                this.setState({attendanceDetails:getAttendanceDetails})
            }else {
                this.setState({attendanceDetails:[]})
            }            
        })
    }
 // This is action used to get student list
 RefreshAttendanceDetails(){
     const {schoolId, getCurrectYear} = this.state;
    let postValue:any = {
        school_id:schoolId,
        update_year:getCurrectYear
    }
    this.setState({startDate:new Date(),endDate:new Date()})
    this.academicAdminList(postValue)
    }

    handleSelect = (ranges:any) =>{
        const rangeStartDate : any = String(moment(String((ranges as {selection: any}).selection.startDate as Date)).format("YYYY-MM-D"))
        const rangeEndDate : any = String(moment(String((ranges as {selection: any}).selection.endDate as Date)).format("YYYY-MM-D"))
        const { getClassName, getCurrectYear } = this.state;
        if(rangeStartDate && rangeEndDate){
            const postValue:any = {
                page_no:1,
                academic_year: getCurrectYear,
                class_id: getClassName,
                 from: rangeStartDate,
                 to: rangeEndDate,
                 school_id:this.state.schoolId || undefined
            }
            this.props.getAttendanceReport(postValue).then((res:any)=>{
                const {getAttendanceDetails} = this.props;
                this.setState({attendanceDetails:getAttendanceDetails})
            })
            this.setState({
            ...this.state,
            startDate:(ranges as {selection: any}).selection.startDate as Date,
            endDate: (ranges as {selection: any}).selection.endDate as Date
            })
        }
       
    }
    commonSchoolDetails(getYearData?:any){
        const loadMoreType:any = {
            academic_year: getYearData
          }
        this.props.fetchGetAllClassList(loadMoreType).then((res:any)=>{
            const { getAllClassList } = this.props;
            if(getAllClassList){
                if(getAllClassList.length > 0 && getAllClassList !== undefined){
                    const postValue:any = {
                        class_id:getAllClassList[0].id,
                        update_year:getYearData
                    }
                    this.getAttendanceDetails(postValue);
                    this.GetStudentList(getAllClassList[0].id, getYearData)
                    this.setState({getClassName:getAllClassList[0].id})
                }
            }
        });
    }
    getAttendanceDetails(getvalue:any){
        const postValue:any = {
            page_no:1,
            academic_year:getvalue.update_year,
            class_id: getvalue.class_id,
            from: moment(this.state.startDate).format('YYYY-MM-DD'),
            to: moment(this.state.endDate).format('YYYY-MM-DD')
        }
        this.props.getAttendanceReport(postValue).then((res:any)=>{
            const {getAttendanceDetails} = this.props;
            this.setState({attendanceDetails:getAttendanceDetails})
        })
    }
    getfilterClasses = (e:any) =>{
        const { value } = e.target;
        const { schoolId, getCurrectYear } = this.state;
        if(value){
            const postValue:any = {
                page_no:1,
                academic_year: getCurrectYear,
                class_id: value,
                school_id:schoolId || null,
                from: moment(this.state.startDate).format('YYYY-MM-DD'),
                to: moment(this.state.endDate).format('YYYY-MM-DD'), 
                
            }
            this.props.getAttendanceReport(postValue).then((res:any)=>{
                const {getAttendanceDetails} = this.props;
                this.setState({attendanceDetails:getAttendanceDetails})
            })
            this.GetStudentList(value)
            this.setState({getClassName:value})
        }
    }
    // This is function used to pull student view page
    getStudentDetails = (getValue:any) => {
        let getStudentId:any = getValue.card_id
        const {schoolId} = this.state;
        if(getStudentId){
            const getPostValue:any = {
                card_id:getValue.card_id, 
                class_id: getValue.class_id,
                school_id:schoolId || undefined,
                postURL:`attendance`,
                titleName:'Student Attendance'
            };
        if(getPostValue){
            this.props.getAttendanceStudentView(getPostValue).then((res:any)=>{
                history.push({
                    pathname: `/student_view`
                  });
              })
        }
              
        }
    }
    

handleInputAttendanceChange = (e: any) => {
    const { value } = e.target;
    const {getCurrectYear} = this.state
    const getSearchValue = value;
    var intRegex = formValidationPatten.alphanumericTest;
    if(intRegex.test(getSearchValue) || getSearchValue === ''){
       if (getSearchValue) {
      const postSearchValue = {
        page_no: 1,
        search: getSearchValue,
        class_id: this.state.getClassName,
        academic_year: getCurrectYear
      }
      this.props.getAttendanceReport(postSearchValue).then((res:any)=>{
        const {getAttendanceDetails} = this.props;
        this.setState({attendanceDetails:getAttendanceDetails})
    });
      this.setState({
        page: 1,
        search: getSearchValue
      })
    } else {
      const postSearchValue = {
        page_no: 1,
        search: '',
        class_id: this.state.getClassName,
        academic_year: getCurrectYear
      }      
      this.props.getAttendanceReport(postSearchValue).then((res:any)=>{
        const {getAttendanceDetails} = this.props;
        this.setState({attendanceDetails:getAttendanceDetails})
    });
      this.setState({
        page: 1,
        search: ''
      })
    }
    } else {
    }
   
  }
  getSchoolDetails = (e:any) =>{
    const { value } = e.target;   
    const {getCurrectYear} = this.state
    const loadMoreType:any = {
        academic_year: getCurrectYear,
        school_id:value
      }
      this.setState({schoolId:value})
      this.props.YearListGet({school_id:value});
    this.props.fetchGetAllClassList(loadMoreType).then((res:any)=>{
        const { getAllClassList } = this.props;
    if(getAllClassList){
        if(getAllClassList.length > 0 && getAllClassList !== undefined && value){
            const postValue:any = {
            page_no:1,
            academic_year:getCurrectYear,
            class_id: getAllClassList[0].id,
            school_id: value,
            from: moment(this.state.startDate).format('YYYY-MM-DD'),
            to: moment(this.state.endDate).format('YYYY-MM-DD'),
            }
        this.props.getAttendanceReport(postValue).then((res:any)=>{
            const {getAttendanceDetails} = this.props;
            this.setState({attendanceDetails:getAttendanceDetails})
        })
        this.setState({getClassName:getAllClassList[0].id})
        }else{
            this.setState({attendanceDetails:[]})
        }
    }
    })
   

  }
  onConfirm = (getValue:any) =>{
    const {onConfirmPost, reasonData} = this.state
    const postData:any = {
        card_id: onConfirmPost.card_id,
        class_id: onConfirmPost.class_id,
        date: onConfirmPost.date,
        attendance_value: onConfirmPost.attendance_value,
        academic_year:onConfirmPost.academic_year,
        reason:reasonData
    }
    let postValue:any;
    if(postData){
        postValue = {
            url:STUDENTS.onUpdateAttendance,
            postData:postData
        }
    }
    if(postValue){
        this.props.postUpdateAttendance(postValue).then((res:any)=>{
            this.setState({onUpdateAttendance:false})
            const {UpdateAttendanceStatu} = this.props;
            const { getClassName, schoolId, getCurrectYear } = this.state;
            if(UpdateAttendanceStatu === true){
                if(getClassName){
                    let postValue:any = {
                        page_no:1,
                        academic_year: getCurrectYear,
                        class_id: getClassName,
                        school_id:schoolId || undefined,
                        from: moment(this.state.startDate).format('YYYY-MM-DD'),
                        to: moment(this.state.endDate).format('YYYY-MM-DD'),
                    }
                    this.props.getAttendanceReport(postValue).then((res:any)=>{
                        const {getAttendanceDetails} = this.props;
                        this.setState({attendanceDetails:getAttendanceDetails})
                    })
            }
        }
        })
    }
  }
  onChangeInput=(getValue:any)=>{
      const {value} = getValue.target;
      if(value.length > 3){
          this.setState({reasonStatu:false, reasonData:value})
      } else {
        this.setState({reasonStatu:true})
      }
  }
  onCancel = () => {
      this.setState({onUpdateAttendance:false,onShowAttendance:false})
  }
  updateAttendance = (getValue:any, getStudent:any) =>{
      const {getCurrectYear} = this.state;
      let onAttendanceState:any;
      let postValue:any
      if(getValue.attendance_value === 'P'){
        onAttendanceState = 'Absent';
      }else if(getValue.attendance_value === 'A') {
        onAttendanceState = 'Present';
      }
      if(onAttendanceState){
        postValue = {
            card_id: getStudent.card_id,
            class_id: getStudent.class_id,
            date: getValue.date,
            attendance_value: onAttendanceState,
            academic_year:String(getCurrectYear)
        }
        this.setState({onUpdateAttendance:true, onConfirmPost:postValue})
      }
  }

  //This is function used filter tables
  refreshAttendanceTable = () =>{
    this.RefreshAttendanceDetails();
  }

  showAttendance = () =>{
    this.setState({onShowAttendance:true})
  }

  GetStudentList(value?: any, getYearData?:any) {
    const { getCurrectYear } = this.state
    const postData = {
        class_id: value,
        academic_year: getYearData ? getYearData : getCurrectYear,
    }
    if (value && getCurrectYear) {
        this.props.fetchGetStudentByAcademicYear(postData).then((res: any) => {
            const { AllStudentList } = this.props
            const students = AllStudentList && AllStudentList.map((item: any) => ({
                //...item,
                answer:"D",
                attendance: false,
                student_name: item.student_name,
                card_id: item.studentclass_details[0].card_id
            }))
            this.setState({studentList: students})
        })
    }
}

  attendanceSubmit = (data: any) =>{
    const { getCurrectYear,getClassName } = this.state
    const attendance_data = data && data.student.map((item: any) => ({
        ...item,
        attendance:item.attendance ? 1 : 0
    }))
    const postData = {
        attendance_details: attendance_data,
        academic_year: getCurrectYear,
        class_id: getClassName,
        status: true, 
        subject_id: "6"
    }
    if (data && getCurrectYear) {
        this.props.postCreateAttendance(postData).then((res: any) => {
            const { onCreateAttendanceStatus } = this.props
            console.log(onCreateAttendanceStatus)
        })
    }
    this.setState({onShowAttendance:false})
  }

    render() {
        const selectionRange = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            key: 'selection',
          }
        const { getAllClassList, totalReport, loading, getSchoolNameList,updateAttendanceLoader, studentloading } = this.props;
        const { attendanceDetails, onUpdateAttendance, onConfirmPost, onUserActive, reasonStatu, schoolId, onShowAttendance, studentList } = this.state;
        const loadingDiary = { display: loading || studentloading ? "block" : "none" };  
        let academicAdminUserTypes:any = false;
        const getUserType:any = this.props.getProfile.usertype;
        if(getUserType === 1){
            academicAdminUserTypes = true;
        }
        return (
            <div>
                <ModalView
                onState={onUpdateAttendance}
                 onConfirm={this.onConfirm}
                 onCancel={this.onCancel}
                 currentState={onConfirmPost}
                 onChangeValue={this.onChangeInput}
                 submitStatus={reasonStatu}
                 isLoaderVisible={updateAttendanceLoader}
                />
                 <StudentAttendance
                 onState={onShowAttendance}
                 onConfirm={this.onConfirm}
                 onCancel={this.onCancel}
                 student={studentList}
                 onSubmit={this.attendanceSubmit}
                 isLoaderVisible={updateAttendanceLoader}
                />
                <div className="page-wrapper">
                    <div className="page-content-wrapper">
                        <div className="page-content pt-3">
                            <BreadCrumb
                                titleName={['Students Attendance']}
                                homeName={['Home']}
                                url={['dashboard']}
                                mainPageTitle={['Students Attendance']} />
                                  {/* School name list */}
                                  {academicAdminUserTypes ?
                                <div className="row">
                                     <div className="col-md-4"></div>
                                    <div className="col-md-5"></div>
                                    {getSchoolNameList?
                                    <div className="col-md-3 mb-4 pull-right">                                       
									<select name="classlist" value={schoolId} className="form-control" onChange={this.getSchoolDetails}>
										{getSchoolNameList.map((items: any) => (
											<option value={items.id}> {items.school_name} </option>
										))}
									</select>
                                    </div>
                                    :null}
                                   
                                </div>
                                : null}
                            <div>
                            <div className="row">
                                <div className="col-md-2">
                                <div className="mt-2 mb-3">
                            </div>
                                </div>
                                <div className="col-md-10 mb-3">
                                <div className="mr-4">
                                    <h4 className="pull-right">Total: {totalReport}</h4>
                                </div>
                                </div>
                            </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card card-topline-red">
                                       
                                            <div className="card-head" >
                                            <header>
                                                   <div className='d-flex justify-content-between'>
                                                      <div className='mr-3'>
                                                        {getAllClassList && getAllClassList.length!==0?                                     
                                                            <select name="classlist" className="form-control" onChange={this.getfilterClasses}>
                                                                {getAllClassList.map((items: any) => (
                                                                    <option value={items.id}> {items.grade_standard} </option>
                                                                ))}
                                                            </select>
                                                            :null}
                                                        </div>
                                                        <div>
                                                        <button className="btn btn-pink" onClick={() => this.showAttendance()}>Create</button>
                                                        </div>
                                                    </div>
                                            </header>
                                                <div className="tools">
                                                <div className="d-flex">
									
                                        <div>
                                        <div className={styles.datePicker}>
                                                        <div style={{display:"flex", justifyContent:"space-evenly"}}> 
                                                            <p className={styles.date}>
                                                                {moment(String(selectionRange.startDate)).format("D-MMM-YYYY")}
                                                            </p>
                                                            <p> - </p>
                                                            <p className={styles.date}>
                                                                {moment(String(selectionRange.endDate)).format("D-MMM-YYYY")}
                                                            </p>
                                                        </div>
                                                        <div className={styles.active}>
                                                            <DateRangePicker
                                                                ranges={[selectionRange]}
                                                                onChange={this.handleSelect}
                                                                color="white"
                                                                maxDate= {new Date()}
                                                            />
                                                        </div>
                                                    </div>
                                        </div>
										<div className="ml-2 mt-1">
											<span className="fa fa-repeat btn-color box-refresh pointer" onClick={this.refreshAttendanceTable}></span>
											<span className="t-collapse btn-color fa fa-chevron-up pointer" ></span>
										</div>
									</div>
                                                </div>
                                            </div>
                                            <div className="card-body no-padding height-9">
                                                {attendanceDetails && attendanceDetails !== undefined && attendanceDetails !== null?
                                                <>
                                                <div className="table-responsive">
                                                    <div className="">
                                                        <div>
                                                        <div >
                                                        <div  className="attendance-flex-container">
                                                        <table className="table table-striped custom-table table-hover width1Table text-center">
                                                        <thead style={{height: attendanceDetails && attendanceDetails.student_detail &&  attendanceDetails.student_detail.length !==0? "120px" : 'initial'}}>
                                                            <tr>
                                                            <th className="text-left height60">Card Id</th>
                                                            <th className="text-left height60">Name</th>
                                                            <th className="text-left height60">Overall</th>
                                                            </tr>
                                                        </thead>
                                                            <tbody >
                                                                { attendanceDetails && attendanceDetails.student_detail &&  attendanceDetails.student_detail.length !==0?
                                                                attendanceDetails.student_detail.map((items:any, i:any)=>(
                                                                <tr key={i}>
                                                                    <td className="text-left">{items.card_id}</td>
                                                                    <td className="text-left titleCapitalize"><Link to={'#'} onClick={()=> this.getStudentDetails(items)}>{items.student_name}</Link></td>
                                                                    <td className="text-left">{items.percentage} %</td>
                                                                    
                                                                </tr>
                                                                ))
                                                                :<tr > <th colSpan={3}><CommonLoader/></th></tr>
                                                                }
                                                                
                                                            </tbody>
                                                            </table>
                                                            <div className="attendance-container">
                                                                <div className="attendance-flex-scroll">
                                                            <table className="table table-striped custom-table table-hover text-center">
                                                                <thead>
                                                                    <tr>
                                                            
                                                            {attendanceDetails && attendanceDetails.absent_data && attendanceDetails.absent_data.length !==0 ?
                                                            attendanceDetails.absent_data.map((monthList:any)=>{
                                                                return(
                                                                <th className="minwidth text-center paddingcommon" style={{height:"120px"}}>
                                                            {monthList.day}
                                                            <span> {moment().month(monthList.month - 1).format("-MMM-YYYY")}</span>
                                                            <span className="minwidth text-center paddingcommon">{monthList.time}</span>
                                                            <div className="absentbg">{monthList.absent_count} Absentees</div>
                                                                </th>
                                                            )}
                                                            ) :null}
                                                                
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                                { attendanceDetails && attendanceDetails.student_detail && attendanceDetails.student_detail.length !==0 ?
                                                                attendanceDetails.student_detail.map((items:any, i:any)=>{
                                                                    return(
                                                                        <tr>
                                                                            {items['attendance_details'] ?
                                                                            items['attendance_details'].map((attendItems:any)=>{
                                                                                if(onUserActive === true){
                                                                                    return(
                                                                                        <td style={{color:attendItems.color_code}} onClick={() => this.updateAttendance(attendItems, items)} className="pointer" title="Update Attendance">{attendItems.attendance_value} </td>
                                                                                    )
                                                                                }else {
                                                                                    return(
                                                                                        <td style={{color:attendItems.color_code}} >{attendItems.attendance_value} </td>
                                                                                    )
                                                                                }
                                                                               
                                                                            })
                                                                        :<CommonLoader/>}
                                                                        </tr>
                                                                        )
                                                                })
                                                                :null
                                                                }
                                                            </tbody>
                                                        </table>
                                                            </div>
                                                            </div>
                                                            </div>

                                                    </div>
                                                        
                                                        </div>
                                                    </div>
                                                </div>
                                                </>
                                                :null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={loadingDiary}><SpinnerLoader /></div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return{
        getYear:state.profile.getYear,
		stopYear:state.profile.yearStatusFails,
        loading: state.dashboard.loading,
		getAllClassList:state.diary.gradelist,
		getProfile:state.profile.profileData,
        getAttendanceDetails:state.dashboard.recordsAttendanceReport,
        totalReport:state.dashboard.totalAttendanceReport,
        getSchoolNameList:state.userManage.category,
        UpdateAttendanceStatu:state.student.onUpdateAttendanceStatu,
        updateAttendanceLoader:state.student.updateAttendanceLoader,
        AllStudentList: state.student.studentList,
        studentloading: state.student.loading,
        onCreateAttendanceStatus:state.student.onCreateAttendanceStatus,
    }
}

export default connect(mapStateToProps, {getAttendanceReport, fetchGetAllClassList, getAttendanceStudentView, stopYearData, getAllSchoolNameList, postUpdateAttendance, YearListGet, fetchGetStudentByAcademicYear, postCreateAttendance})(Attendance)
