import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, FormGroup, MenuItem } from '@material-ui/core';
import { Field, Formik, FormikProps } from 'formik';
import BreadCrumb from '../../components/BreadCrumb';
import { fetchTeacherPostId, fetchClassInchargeMappingPost, TeacherClassMappingPost } from '../../store/teacher/Actions';
import { EditClasslist } from '../../store/teacher/Type';
import { fetchGetAllClassList } from '../../store/diary/Actions';
import { TextField } from 'formik-material-ui';
import history from '../../History';
import {onGetData} from '../../store/classes/Actions';
import {onGetValue} from '../../services/Config';
import { stopYearData } from '../../store/profile/Actions'
import {stateTypes} from './Types';
import * as yup from 'yup';
import { userFormValidations } from '../../services/Constants';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';

const classInChargeValidation = yup.object({
    ClassList: yup.string()
        .required(userFormValidations.sectionRequiredMsg)
})

interface OwnSubjectMappingProps extends RouteComponentProps<OwnPropsParams> {   
    getChangeYearData?:any;
	stopYearDate?:any; 
    TeacherClassMappingPost: (teacherMap: any) => any;
    fetchGetAllClassList: (postValue:any) => any;
    fetchTeacherPostId: (id: string) => void;
    fetchClassInchargeMappingPost: (classIncharge: any) => void;
    onGetData:(postValue:any) => any;
    stopYearData:()=>any;
    loading: boolean;
    ClassNameList: any;
    records: any;
    page: number;
    per_page: number;
    totalPage: number;
    profileInfo:any;
    mappingClassStatu:any,
    mappingTeacherId:any
    getTeacherClassList:any
};

export class EditClassInCharge extends Component<OwnSubjectMappingProps, stateTypes> {
    getClassPropsValue:any;
    getClassIncharge: any = [];
    teacherId:any;
    constructor(props: any) {
        super(props);
        this.state = {
            profilePath:'',
            BreadCrumbPath:'',
            className:[],
            academicYear:null
        }
    }
    componentDidMount(): void {
      this.UpdateYearDate()
    }
    componentWillUpdate() {
        const {stopYearDate} = this.props;
        if(stopYearDate === true){
            this.UpdateYearDate(this.props.getChangeYearData)
        }
    }

    componentDidUpdate() {
        const {stopYearDate} = this.props;
        if(stopYearDate === true){
            this.props.stopYearData()
        }
    }

    UpdateYearDate(getYear?:any){
        if(getYear && getYear !== undefined && getYear !== null){
            this.getClassInchangeData(getYear)
            this.setState({academicYear:getYear})
        }else {
            this.getClassInchangeData(this.props.getChangeYearData)
            this.setState({academicYear:this.props.getChangeYearData})
        }
    }
    getClassInchangeData(getYear?:any){
        window.scrollTo(0, 0);
        const { profileInfo } = this.props;
        if(profileInfo && profileInfo !== undefined && profileInfo !== null){
            if(profileInfo.usertype === 2) {
                let teacherEditDetails:any = this.props.history.location.state;
                this.setState({profilePath:`/teacher/${teacherEditDetails.teacherId}`, BreadCrumbPath:`teacher/${teacherEditDetails.teacherId}`})
                this.getClassEdit(getYear)
            } else if(profileInfo.usertype === 3) {
                this.getClassTeacherEdit(getYear)
                this.setState({profilePath:'/teachers_profile', BreadCrumbPath:'teachers_profile'})
            }
        }
    }
    getClassEdit(getYear?:any){
        this.props.fetchTeacherPostId(String(this.props.match.params.id));
        const postValue: any = {
            academic_year:getYear
        }
        this.props.fetchGetAllClassList(postValue).then((res:any)=>{
            const {ClassNameList} = this.props
            this.setState({className:ClassNameList})
        });
        this.getTeacherDetails(getYear);
        this.getClassPropsValue = this.props.history.location.state;    
        if(this.getClassPropsValue) {
            this.teacherId = this.getClassPropsValue.teacherId
        }
    }
    getClassTeacherEdit(getYear?:any){
        this.props.fetchTeacherPostId(String(this.props.match.params.id));
        const postValue: any = {
            academic_year:getYear
        }
        let getData:any = {
            data:postValue,
            URL:onGetValue.getClassList
        }
        this.props.onGetData(getData).then((res:any)=>{
            const {getTeacherClassList} = this.props
            this.setState({className:getTeacherClassList})
        });
        this.getTeacherDetails(getYear);
        this.getClassPropsValue = this.props.history.location.state;    
        if(this.getClassPropsValue) {
            this.teacherId = this.getClassPropsValue.teacherId
        }
    }
    getTeacherDetails(getYear?:any) {
        if (this.props.match.params.id) {
            const postValue: any = {
                page_no: 1,
                academic_year:getYear,
                teacher_id: this.props.match.params.id
            }
            this.props.fetchClassInchargeMappingPost(postValue);
        }
    }
   
    onSubmitClassIncharge = (values: any) => {
        const {academicYear} = this.state;
        let classIncharge:any = [];
        if(values){
            classIncharge = [values.ClassList]
        }
        if(classIncharge.length) {
            const ClassIncharge: any = {
                academic_year:academicYear,
                teacher_id: this.teacherId,
                class_incharge: classIncharge
            }
            this.props.TeacherClassMappingPost(ClassIncharge).then((res:any)=>{
                const { mappingClassStatu } = this.props;
                const { profileInfo } = this.props;
                if(mappingClassStatu === true){
                    if(profileInfo && profileInfo !== undefined && profileInfo !== null){
                        if(profileInfo.usertype === 2) {
                            history.push(this.state.profilePath);
                           
                        } else if(profileInfo.usertype === 3) {
                            history.push(this.state.profilePath);
                           
                        }
                    }
                }
            })
        }
    }

    render() {
        const {className} = this.state;
        const { loading } = this.props;
        const loadingTeacher = { display: loading  ? "block" : "none" };
        let getClassNames:any
        if(this.props.records){
            this.props.records.forEach((items:any)=>{
                    if (className.length  > 0 && className !== undefined) {                            
                        let getClassList =  className.find((item:any)=> item.id === parseInt(items.class_id)) 
                        if(getClassList){
                            items['class_name'] = getClassList.grade_standard;
                        }                          
                      } 
            })
        }
        if(this.getClassPropsValue){
            getClassNames = parseInt(this.getClassPropsValue.class_id);
        }
        const initialEditClassValues:EditClasslist = {
            ClassList:getClassNames
        }
        return (
            <div>
                <div className="page-wrapper">
                    <div className="page-content-wrapper">
                        <div className="page-content pt-3">
                            <BreadCrumb
                                titleName={['Teacher']}
                                homeName={['Home']}
                                url={['dashboard']}
                                baseName={['Teacher']}
                                baseURL={[this.state.BreadCrumbPath]}
                                mainPageTitle={['Update Class In-Charge']} />
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card-box">
                                        <div className="card-head">
                                            <header>Update Class In-Charge</header>
                                            <div className="tools">
                                            </div>
                                        </div>
                                        { getClassNames ?
                                        <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Formik
                                                initialValues={initialEditClassValues}
                                                onSubmit={this.onSubmitClassIncharge}
                                                validationSchema={classInChargeValidation}
                                                render={({
                                                    values, errors, isSubmitting, isValidating, dirty, touched, handleSubmit, setFieldValue
                                                }: FormikProps<EditClasslist>) => (
                                                    <form onSubmit={handleSubmit} className="ui form">  
                                                    <div className="row">      
                                                            <div className="col-md-12 p-t-20">
                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                <FormGroup>
                                                                {className?
                                                                <FormGroup>
                                                                <Field 
                                                                label={<><span>Class</span><span className='formmandatorycolor'> *</span></>}
                                                                name="ClassList"  
                                                                select
                                                                component={TextField}        
                                                                disabled={false}  
                                                                onChange={(e:any) => {
                                                                    setFieldValue('ClassList', e.target.value)
                                                                  }} 
                                                                >
                                                                    {
                                                                        className.map( (item:any) => (
                                                                            <MenuItem value={item.id}>{item.grade_standard}</MenuItem>
                                                                        ))
                                                                    }
                                                                    
                                                                </Field>                         
                                                                </FormGroup>
                                                                 
                                                                    :null}
                                                                </FormGroup> 
                                                                </div>
                                                            </div>
                                                        </div>         
                                                        <div className="text-right mb-3 mr-2 mt-4">
                                                            <Button className="btn btn-pink mr-1 ml-1" type="submit"
                                                            disabled={!values.ClassList 
                                                                || isValidating || !dirty || !!(errors.ClassList && touched.ClassList) }>Submit</Button>
                                                            <Link to={this.state.profilePath}><Button className="btn btn-default mr-1 ml-1">Cancel</Button></Link>
                                                        </div>
                                                    </form>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                :null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={loadingTeacher}><SpinnerLoader/></div>
            </div>
        )
    }
}
interface OwnPropsParams {
    id: string;
}
const mapStateToProps = (state: any, ownProps: RouteComponentProps<OwnPropsParams>) => {
    return {
        loading: state.teacher.loading,
        getChangeYearData:state.profile.getYear,
        stopYearDate:state.profile.yearStatusFails,
        errorMessage: state.teacher.errors,
        getTeacherid: state.teacher.TeacherId,
        records: state.teacher.classRecords,
        ClassNameList: state.diary.gradelist,
        profileInfo:state.profile.profileData,
        mappingClassStatu:state.teacher.mappingClassIncharge,
        mappingTeacherId:state.teacher.Add_Mapping_Teacher_id,
        getTeacherClassList:state.classes.OnGetData
    };
}

export default connect(mapStateToProps, { fetchGetAllClassList, fetchTeacherPostId, fetchClassInchargeMappingPost, TeacherClassMappingPost, onGetData, stopYearData })(EditClassInCharge)
