import React, { Component } from 'react';
import { ExceptionErrorMsg, noDataMsg } from '../services/Constants';

export default class CommonLoader extends Component<any,any> {
    constructor(props: any) {
        super(props);
        this.state = {
            errorMsg: this.props.isException && this.props.isException === true ? ExceptionErrorMsg : noDataMsg,
        }
    }
    render() {

        return (
            <div className="m-3">
                <h5>{this.state.errorMsg}</h5>
            </div>
        );        
    }
}
