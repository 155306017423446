import React, { Component } from 'react'
import { connect } from 'react-redux'
import CommonLoader from '../../components/CommonLoader';
import { Link } from 'react-router-dom';
import history from '../../History';
import { Doughnut } from 'react-chartjs-2';
import { fetchQuestionReportView } from '../../store/question/Actions';
import { getAttendanceStudentView, stopYearData, stopSubComponentYear } from '../../store/profile/Actions';
import BreadCrumb from '../../components/BreadCrumb';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import {optionView} from './Types';

interface PropsTypes {
    getChangeYearData?:any;
	stopYearDate?:any;
	stopYearData:()=>any;
	stopSubComponentYear:(postValue:any) => any;
    getQuestion:any;
    fetchQuestionReportView:(postValue:any) => any;
    getAttendanceStudentView:(postData:any) => any;
    getQuestionReport:any;
    loading:any;
}
interface StatusTypes {
    quizzesUrl?:any
}
export class SingleQuizzesView extends Component<PropsTypes, StatusTypes> {
    constructor(props:any){
        super(props);
        this.state = {
            quizzesUrl:''
        }
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);    
        this.getCurrectYear();  
    }
    componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYearDate} = this.props;
		if(stopYearDate === true){
			this.getCurrectYear(this.props.getChangeYearData)
			const postValue:any = {
				status:true
			}
			this.props.stopSubComponentYear(postValue)
		}
	}
	componentDidUpdate() {
		const {stopYearDate} = this.props;
		if(stopYearDate === true){
			this.props.stopYearData()
		}
	}
	getCurrectYear(getYear?:any){
		if(getYear && getYear !== undefined && getYear !== null){
			this.getQuestionReport(getYear)
		}else {
			this.getQuestionReport(this.props.getChangeYearData)
		}
	}
    addDefaultSrc= (ev:any) =>{
        ev.target.src = '../assets/img/user/teacher-profile.jpg'
      }
    getQuestionReport(getYear?:any){
        const {getQuestion} = this.props;
        const postValue:any = {
            question_id: getQuestion.question_id,
            quiz_id: getQuestion.quiz_id,
            academic_year: getYear
        }
        this.props.fetchQuestionReportView(postValue);
        this.setState({quizzesUrl:`quizzes_report/${getQuestion.quiz_id}`})
    }

    // This is function used to pull student view page
    getStudentDetails = (getValue:any, getAll?:any) => {
        const {quizzesUrl} = this.state;
    let getStudentId:any = getValue.card_id
    if(getStudentId){
        const getPostValue:any = {
            card_id:getValue.card_id, 
            class_id: getAll.class_id,
            school_id: getAll.school_id,
            postURL:quizzesUrl,
            titleName:'View Quizzes'
        };
        history.push(`/student_view`);
            this.props.getAttendanceStudentView(getPostValue)
    }
}
    render() {
        const {getQuestionReport, loading} = this.props;
        const {quizzesUrl} = this.state;
        const loadingTextCSS = { display: loading ? "block" : "none" };
        return (
            <div>
                <BreadCrumb
                titleName={['View Responses']}
                homeName={['Home']}
                url={['dashboard']}
                mainPageTitle={['View Responses']}
                baseName={['View Quizzes']}
                baseURL={[quizzesUrl]} />
                  <div className="row">
                            <div className="col-md-12">
                                {getQuestionReport.question_data ?
                                        <div className="card card-topline-red">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="pl-3 pt-3"><strong>{getQuestionReport.question_data.question_id}. {getQuestionReport.question_data.question}</strong></p>
                                                    <div className="col-md-2 mt-3 pr-2">
                                                        <button className="btn btn-xs btn-circle btn-pink cursorPoint">{getQuestionReport.question_data.question_topic}</button>
                                                    </div>
                                                    <hr className="m-0 mt-2" />
                                                    

                                                </div>
                                            </div>

                                            <div className="row p-3">
                                                <div className="col-md-7">
                                                    {getQuestionReport.question_data.options ?
                                                        getQuestionReport.question_data.options.map((optionList: any) => (
                                                            <div className="row">
                                                                <div className="col-md-10 col-sm-10 col-8 mb-3">
                                                                    <p><strong  style={{color:optionList.color_code}}>{optionList.option}. </strong> <span>{optionList.value}</span></p>
                                                                </div>
                                                                <div className="col-md-2 col-sm-2 col-4 mb-3">
                                                                    <div className="btn btn-xs btn-circle countBtn"
                                                                    style={{background:optionList.color_code, color:'#ffffff'}}>{optionList.count}</div>
                                                                </div>
                                                            </div>
                                                        ))
                                                        : null}
                                                </div>
                                                <div className="col-md-5">
                                                    {getQuestionReport.question_data.chart_data ?
                                                        <Doughnut
                                                            data={{
                                                                labels: getQuestionReport.question_data.chart_data.name,
                                                                datasets: [{
                                                                    data: getQuestionReport.question_data.chart_data.count,
                                                                    backgroundColor: getQuestionReport.question_data.chart_data.color_code
                                                                }]
                                                            }}
                                                            height={100}
                                                            options={optionView}
                                                        />
                                                        : null}

                                                </div>
                                            </div>
                                        </div>
                                    : null}
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-md-6">
                                <div className="card card-topline-red">
                                    <div className="card-body">
                                        {getQuestionReport.student_data?
                                        <div className="row">
                                            <ul className="docListWindow
                                                small-slimscroll-style overflowBox">
                                                <li className="title-sticky">
                                                    <div className="row">
                                                        <div className="col-md-12"><strong>A - ({
                                                        getQuestionReport.student_data.A?
                                                        getQuestionReport.student_data.A.length
                                                    :0})
                                                        </strong></div>
                                                    </div>
                                                </li>
                                                {getQuestionReport.student_data?
                                                <>
                                                    {getQuestionReport.student_data.A ?
                                                    getQuestionReport.student_data.A.map((items:any)=>(
                                                        <li>
                                                        <div className="row">
                                                        {items.profile?
                                                                        <div className="col-md-1
                                                                          col-sm-1 col-2"> 
                                                                        <div className="prog-avatar">
                                                                                <img onError={this.addDefaultSrc} src={`${process.env.REACT_APP_API_URL}${items.profile}`} 
                                                                                alt="Student Profile" width="40"
                                                                                height="40"/>
                                                                                           </div>
                                                                        </div>
                                                                        :   
                                                                        <div className="col-md-1
                                                            col-sm-1 col-2">
                                                                    <div className="prog-avatar">
                                                                    <button
                                                                        className="mdl-button 
                                                                        mdl-js-button mdl-button--fab mdl-button--mini-fab 
                                                                        ml-3 btn-pink" 
                                                                        onClick={()=> this.getStudentDetails(items, getQuestionReport)}>
                                                                        <span>{items.name.charAt(0)}</span>
                                                                        </button>
                                                                </div>
                                                                </div>
                                                                        }
                                                            <div className="col-md-11
                                                                col-sm-11  col-10">
                                                                <div
                                                                    className="details">
                                                                    <div
                                                                        className="title
                                                                        mt-2 ml-3">
                                                                        <Link to="#"  onClick={()=> this.getStudentDetails(items, getQuestionReport)}>{items.name}</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    ))
                                                    :<CommonLoader/> }
                                                    </>
                                                    :null}
                                            </ul>
                                        </div>
                                        :null}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card card-topline-red">
                                    <div className="card-body">
                                    {getQuestionReport.student_data?
                                        <div className="row">
                                            <ul className="docListWindow
                                                small-slimscroll-style overflowBox">
                                                <li className="title-sticky">
                                                    <div className="row">
                                                        <div className="col-md-12"><strong>B - ({getQuestionReport.student_data.B?
                                                        getQuestionReport.student_data.B.length
                                                        :0})
                                                        </strong></div>
                                                    </div>
                                                </li>
                                                {getQuestionReport.student_data?
                                                <>
                                            {getQuestionReport.student_data.B?
                                                getQuestionReport.student_data.B.map((items:any)=>(
                                                    <li>
                                                    <div className="row">
                                                    {items.profile?
                                                                      <div className="col-md-1
                                                                      col-sm-1 col-2">    
                                                                        <div className="prog-avatar">
                                                                                <img onError={this.addDefaultSrc} src={`${process.env.REACT_APP_API_URL}${items.profile}`} 
                                                                                alt="Student Profile" width="40"
                                                                                height="40"/>
                                                                                           </div>
                                                                        </div>               
                                                                        :   
                                                                        <div className="col-md-1
                                                            col-sm-1 col-2">
                                                                                        <div className="prog-avatar">
                                                                    <button
                                                                        className="mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab ml-3 btn-pink" onClick={()=> this.getStudentDetails(items, getQuestionReport)}>
                                                                        <span>{items.name.charAt(0)}</span>											</button>
                                                                </div>
                                                                </div>
                                                                        }
                                                        <div className="col-md-11
                                                            col-sm-11 col-10">
                                                            <div
                                                                className="details">
                                                                <div
                                                                    className="title
                                                                    mt-2 ml-3">
                                                                    <Link to="#" onClick={()=> this.getStudentDetails(items, getQuestionReport)} >
                                                                        {items.name}</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                ))
                                                :<CommonLoader/>}
                                                </>
                                                :null}
                                            </ul>
                                        </div>
                                        :null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-md-6">
                                <div className="card card-topline-red">
                                    <div className="card-body">
                                    {getQuestionReport.student_data?
                                        <div className="row">
                                            <ul className="docListWindow
                                                small-slimscroll-style overflowBox">
                                                <li className="title-sticky">
                                                    <div className="row">
                                                        <div className="col-md-12"><strong>C - ({
                                                        getQuestionReport.student_data.C?
                                                        getQuestionReport.student_data.C.length
                                                        :0})
                                                        </strong></div>
                                                    </div>
                                                </li>
                                                {getQuestionReport.student_data?
                                                <>
                                                {getQuestionReport.student_data.C?
                                                getQuestionReport.student_data.C.map((items:any)=>(
                                                    <li>
                                                    <div className="row">
                                                    {items.profile?
                                                                 <div className="col-md-1
                                                                 col-sm-1 col-2">          
                                                                        <div className="prog-avatar">
                                                                                <img onError={this.addDefaultSrc} src={`${process.env.REACT_APP_API_URL}${items.profile}`} 
                                                                                alt="Student Profile" width="40"
                                                                                height="40"/>
                                                                                           </div>
                                                                                           </div>
                                                                        :  
                                                                        <div className="col-md-1
                                                            col-sm-1 col-2"> 
                                                                                        <div className="prog-avatar">
                                                                    <button
                                                                        className="mdl-button mdl-js-button 
                                                                        mdl-button--fab mdl-button--mini-fab ml-3 btn-pink" 
                                                                        onClick={()=> this.getStudentDetails(items, getQuestionReport)}>
                                                                        <span>{items.name.charAt(0)}</span>	
                                                                    </button>
                                                                </div>
                                                                </div>
                                                                        }
                                                        <div className="col-md-11
                                                            col-sm-11 col-10">
                                                            <div
                                                                className="details">
                                                                <div
                                                                    className="title
                                                                    mt-2 ml-3">
                                                                    <Link to="#"  
                                                                    onClick={()=> this.getStudentDetails(items, getQuestionReport)}>
                                                                        {items.name}
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                ))
                                                :<CommonLoader/>}
                                                </>
                                                :null}
                                            </ul>
                                        </div>
                                        :null}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card card-topline-red">
                                    <div className="card-body">
                                    {getQuestionReport.student_data?
                                        <div className="row">
                                            <ul className="docListWindow
                                                small-slimscroll-style overflowBox">
                                                <li className="title-sticky">
                                                    <div className="row">
                                                        <div className="col-md-12"><strong>D - ({
                                                        getQuestionReport.student_data.D?
                                                        getQuestionReport.student_data.D.length
                                                    :0})
                                                        </strong></div>
                                                    </div>
                                                </li>
                                                {getQuestionReport.student_data?
                                                <>
                                                {getQuestionReport.student_data.D ?
                                                getQuestionReport.student_data.D.map((items:any)=>(
                                                <li>
                                                    <div className="row">
                                                    {items.profile?
                                                                         <div className="col-md-1
                                                                         col-sm-1 col-2"> 
                                                                        <div className="prog-avatar">
                                                                                <img onError={this.addDefaultSrc} src={`${process.env.REACT_APP_API_URL}${items.profile}`} 
                                                                                alt="Student Profile" width="40"
                                                                                height="40"/>
                                                                                           </div>
                                                                                           </div>
                                                                        :   
                                                                        <div className="col-md-1
                                                            col-sm-1 col-2">
                                                                                        <div className="prog-avatar">
                                                                    <button
                                                                        className="mdl-button mdl-js-button 
                                                                        mdl-button--fab mdl-button--mini-fab ml-3 btn-pink" 
                                                                        onClick={()=> this.getStudentDetails(items, getQuestionReport)}>
                                                                        <span>{items.name.charAt(0)}</span>	
                                                                    </button>
                                                                </div>
                                                                </div>
                                                                        }
                                                        <div className="col-md-11
                                                            col-sm-11 col-10">
                                                            <div
                                                                className="details">
                                                                <div
                                                                    className="title
                                                                    mt-2 ml-3">
                                                                    <Link to="#"  
                                                                    onClick={()=> this.getStudentDetails(items, getQuestionReport)}>
                                                                        {items.name}
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                ))
                                                :<CommonLoader/>}
                                                </>
                                                :null}
                                            </ul>
                                        </div>
                                        :null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                        <div className="col-md-6">
                                <div className="card card-topline-red">
                                    <div className="card-body">
                                    {getQuestionReport.student_data?
                                        <div className="row">
                                            <ul className="docListWindow
                                                small-slimscroll-style overflowBox">
                                                <li className="title-sticky">
                                                    <div className="row">
                                                        
                                                        <div className="col-md-12"><strong>Not Attempted ({
                                                        getQuestionReport.student_data['Not Answered']?
                                                        getQuestionReport.student_data['Not Answered'].length
                                                    :0})
                                                        </strong></div>
                                                    </div>
                                                </li>
                                                {getQuestionReport.student_data?
                                                <>
                                                {getQuestionReport.student_data['Not Answered']?
                                                getQuestionReport.student_data['Not Answered'].map((items:any)=>(
                                                <li>
                                                    <div className="row">
                                                    {items.profile?
                                                                      <div className="col-md-1
                                                                      col-sm-1 col-2">     
                                                                        <div className="prog-avatar">
                                                                                <img onError={this.addDefaultSrc} 
                                                                                src={`${process.env.REACT_APP_API_URL}${items.profile}`} 
                                                                                alt="Student Profile" width="40"
                                                                                height="40"/>
                                                                                           </div>
                                                                                           </div>
                                                                        :   
                                                                        <div className="col-md-1
                                                                        col-sm-1 col-2">
                                                                                      <div className="prog-avatar">
                                                                    <button
                                                                        className="mdl-button mdl-js-button mdl-button--fab 
                                                                        mdl-button--mini-fab ml-3 btn-pink" 
                                                                        onClick={()=> this.getStudentDetails(items, getQuestionReport)}>
                                                                        <span>{items.name.charAt(0)}</span>	
                                                                    </button>
                                                                </div>
                                                                </div>
                                                                        }
                                                        <div className="col-md-10
                                                            col-sm-11 col-11">
                                                            <div
                                                                className="details">
                                                                <div
                                                                    className="title
                                                                    mt-2 ml-3">
                                                                    <Link to="#"  
                                                                    onClick={()=> this.getStudentDetails(items, getQuestionReport)}>
                                                                        {items.name}
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                ))
                                                :<CommonLoader/>}
                                                </>
                                                :null}
                                            </ul>
                                        </div>
                                        :null}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card card-topline-red">
                                    <div className="card-body">
                                    {getQuestionReport.student_data?
                                        <div className="row">
                                            <ul className="docListWindow
                                                small-slimscroll-style overflowBox">
                                                <li className="title-sticky">
                                                    <div className="row">
                                                        <div className="col-md-12"><strong>Absentee ({getQuestionReport.student_data.Absent?
                                                        getQuestionReport.student_data.Absent.length
                                                        :0})
                                                        </strong></div>
                                                    </div>
                                                </li>
                                                {getQuestionReport.student_data?
                                                <>
                                                {getQuestionReport.student_data.Absent?
                                                getQuestionReport.student_data.Absent.map((items:any)=>(
                                                <li>
                                                    <div className="row">
                                                    {items.profile?
                                                                       <div className="col-md-1
                                                                       col-sm-1 col-2">   
                                                                        <div className="prog-avatar">
                                                                                <img onError={this.addDefaultSrc} 
                                                                                src={`${process.env.REACT_APP_API_URL}${items.profile}`} 
                                                                                alt="Student Profile" width="40"
                                                                                height="40"/>
                                                                                           </div>
                                                                                           </div>
                                                                        :  
                                                                        <div className="col-md-1
                                                            col-sm-1 col-2">
                                                                                        <div className="prog-avatar">
                                                                    <button
                                                                        className="mdl-button mdl-js-button 
                                                                        mdl-button--fab mdl-button--mini-fab ml-3 btn-pink"
                                                                        onClick={()=> this.getStudentDetails(items, getQuestionReport)}>
                                                                        <span>{items.name.charAt(0)}</span>	
                                                                    </button>
                                                                </div>
                                                                </div>
                                                                        }
                                                        <div className="col-md-11
                                                            col-sm-11 col-10">
                                                            <div
                                                                className="details">
                                                                <div
                                                                    className="title
                                                                    mt-2 ml-3">
                                                                    <Link to="#"  
                                                                    onClick={()=> this.getStudentDetails(items, getQuestionReport)}>
                                                                        {items.name}
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                ))
                                                :<CommonLoader/>}
                                                </>
                                                :null}
                                            </ul>
                                        </div>
                                        :null}
                                    </div>
                                </div>
                            </div>
                        </div>
                <div style={loadingTextCSS}><SpinnerLoader /></div>
            </div>
        )
    }
}

const mapStateToProps = (state:any) => ({
    getChangeYearData:state.profile.getYear,
    stopYearDate:state.profile.yearStatusFails,
    loading:state.questionset.loading,
    getQuestion:state.questionset.studentViewAnswers,
    getQuestionReport: state.questionset.questionReportView,

})


export default connect(mapStateToProps, {fetchQuestionReportView, 
    getAttendanceStudentView, stopYearData, stopSubComponentYear})(SingleQuizzesView)
