import { Reducer } from 'redux';
import { StudentState, StudentActionTypes } from './Types';

export const initialState: StudentState = {
    items: [],
    loading: false,
    isAuthenticated: false,
    getParentStatus:false,
    errors: null,
    page: 1,
    per_page: 10,
    records: [],
    total: 0,
    parentDetails:[],
    parentList:false,
    ImageURL:[],
    getStudentEditResponse:[],
    imageUploadstatus:false,
    studentProfile:[],
    studentProfileTopic:[],
    studentProfileSubject:[],
    studentProfileLine:[],
    getStudentNameList:[],
    onUpdateAttendanceStatu:false,
    newlyCreatedStudentInfo:{},
    updateAttendanceLoader:false,
    studentList:[],
    studentNextClassDetail: [],
    onCreateAttendanceStatus:{},
}

const reducer: Reducer<StudentState> = (state = initialState, action) => {
    switch (action.type) {
        
        case StudentActionTypes.DELETE_STUDENT:
        case StudentActionTypes.FETCH_STUDENT:
        case StudentActionTypes.FETCH_STUDENT_ID:
        case StudentActionTypes.FETCH_STUDENT_PROFILE:
        case StudentActionTypes.Student_Profile_Subject:
        case StudentActionTypes.Student_Profile_Topic:
        case StudentActionTypes.CREATE_ATTENDANCE:
            return { 
                ...state, 
                loading: true,
                isAuthenticated: false,
                imageUploadstatus: true,
                getStudentEditResponse:[],
                parentDetails:[],
                getParentStatus:true,
                ImageURL:[]
            };
        case StudentActionTypes.Update_Attendance:
                return { 
                    ...state, 
                    updateAttendanceLoader: true,
                    isAuthenticated: false,
                    imageUploadstatus: true,
                    getStudentEditResponse:[],
                    parentDetails:[],
                    getParentStatus:true,
                    ImageURL:[]
                };
            case StudentActionTypes.Student_Profile_Line:
                return { 
                    ...state, 
                    loading: true,
                    studentProfileLine:[]
                };
            case StudentActionTypes.Removed_Image_Upload:
                return { 
                    ...state, 
                    loading: true,
                    isAuthenticated: false,
                    ImageURL:[]
                };
            case StudentActionTypes.Removed_Image_Upload_Success:
                return { 
                    ...state, 
                    loading: false,
                    isAuthenticated: true,
                    ImageURL: []
                };
                case StudentActionTypes.Student_Name_List:
                    return { 
                        ...state, 
                        loading: true,
                        isAuthenticated: false,
                        ImageURL:[]
                    };
            case StudentActionTypes.Student_Name_List_Success:
                const {getStudentNameList } = action
                return { 
                    ...state, 
                    loading: false,
                    isAuthenticated: true,
                    getStudentNameList:getStudentNameList,
                    ImageURL: []
                };
            case StudentActionTypes.FETCH_PARENT:
                return { 
                    ...state, 
                    loading: true,
                    isAuthenticated: false,
                    parentDetails:[],
                    getParentStatus:true
                };
        case StudentActionTypes.ADD_STUDENT:
        case StudentActionTypes.EDIT_STUDENT:
                return { 
                    ...state, 
                    loading: true,
                    isAuthenticated: false,
                    errors:null,
                    newlyCreatedStudentInfo:{}
                };
            case StudentActionTypes.FETCH_STUDENT_IMAPGE:
                return { 
                    ...state, 
                    loading: true,
                    isAuthenticated: false,
                    imageUploadstatus: true,
                    getParentStatus:true
                };
            case StudentActionTypes.STUDENT_PROMOTION:
            case StudentActionTypes.FETCH_STUDENT_ACADEMIC_YEAR:
            case StudentActionTypes.STUDENT_NEXT_CLASS:
                return{
                    ...state, 
                    loading: true,
                    isAuthenticated: false,
                }
        case StudentActionTypes.ADD_STUDENT_SUCCESS:
            return { 
                ...state, 
                loading: false,
                isAuthenticated: true,
                onUpdateAttendanceStatu: true,
                errors:null,
                newlyCreatedStudentInfo:action.payload
            };
        case StudentActionTypes.Update_Attendance_Success:
            return { 
                ...state, 
                loading: false,
                isAuthenticated: true,
                onUpdateAttendanceStatu: true,
                errors:null,
                updateAttendanceLoader: false
            }; 
        case StudentActionTypes.FETCH_STUDENT_IMAPGE_SUCCESS:
            const { ImageURL } = action;
            return { 
                ...state, 
                loading: false,
                isAuthenticated: true,
                items: action.payload,
                ImageURL: ImageURL,
                imageUploadstatus: true
            }; 
        case StudentActionTypes.FETCH_STUDENT_SUCCESS:
            const { payload, records, per_page, page, total } = action;
            if(page === 1) {
                state.records = []
            }            
            return {
                ...state, 
                loading: false, 
                items: payload,
                page: page,
                per_page: per_page,
                records: page === 1 ? records : [...state.records, ...records],
                total: total,
                totalPage: Math.ceil(total / per_page),
                isAuthenticated: false,
            }

            case StudentActionTypes.FETCH_STUDENT_SUCCESS_ID: 
            const { getStudentEditResponse } = action;
            return {
                ...state, 
                loading: false, 
                items: action.payload,
                isAuthenticated: false,
                getStudentEditResponse: getStudentEditResponse.data
            };
            case StudentActionTypes.FETCH_PARENT_SUCCESS:     
            const { parentDetails } = action;  
            return {
                ...state, 
                loading: false, 
                items: action.payload,
                isAuthenticated: false,
                parentDetails: parentDetails,
                getParentStatus:true
            };
            case StudentActionTypes.DELETE_STUDENT_SUCCESS:
                return {
                    ...state,
                    items: action.payload,
                    loading: false,
                    isAuthenticated: true
                };
            case StudentActionTypes.FETCH_STUDENT_PROFILE_SUCCESS:
                const { studentProfile } = action;  
                return {
                    ...state,
                    items: action.payload,
                    loading: false,
                    isAuthenticated: true,
                    studentProfile:studentProfile
                };
            case StudentActionTypes.Student_Profile_Line_Success:
                const { studentProfileLine } = action;  
                return {
                    ...state,
                    items: action.payload,
                    loading: false,
                    isAuthenticated: true,
                    studentProfileLine:studentProfileLine
                };
            case StudentActionTypes.Student_Profile_Subject_Success:
                const { studentProfileSubject } = action;  
                return {
                    ...state,
                    items: action.payload,
                    loading: false,
                    isAuthenticated: true,
                    studentProfileSubject:studentProfileSubject
                };
            case StudentActionTypes.Student_Profile_Topic_Success:
                const { studentProfileTopic } = action;  
                return {
                    ...state,
                    items: action.payload,
                    loading: false,
                    isAuthenticated: true,
                    studentProfileTopic:studentProfileTopic
                };
            case StudentActionTypes.STUDENT_PROMOTION_SUCCESS:
                return{
                    ...state,
                    loading: false,
                    isAuthenticated: true,
                }
            case StudentActionTypes.STUDENT_NEXT_CLASS_SUCCESS:
                return{
                    ...state,
                    loading: false,
                    isAuthenticated: true,
                    studentNextClassDetail: action.payload.data
                }
            case StudentActionTypes.CREATE_ATTENDANCE_SUCCESS:
                return{
                ...state,
                loading: false,
                isAuthenticated: true,
                onCreateAttendanceStatus: action.payload.data
            }
            case StudentActionTypes.ADD_STUDENT_FAIL:
            case StudentActionTypes.DELETE_STUDENT_FAIL:
            case StudentActionTypes.EDIT_STUDENT_FAIL:                            
            case StudentActionTypes.FETCH_STUDENT_FAIL_ID:
            case StudentActionTypes.FETCH_STUDENT_IMAPGE_FAIL:
            case StudentActionTypes.FETCH_STUDENT_PROFILE_FAIL:
            case StudentActionTypes.Student_Profile_Subject_Fail:
            case StudentActionTypes.Student_Profile_Topic_Fail:
            case StudentActionTypes.Student_Name_List_Fail:
            case StudentActionTypes.CREATE_ATTENDANCE_FAIL:
            return {
                ...state,
                errors: action.payload,
                loading: false,
                isAuthenticated: false,
                parentDetails:[],
                ImageURL:[]
            };

            case StudentActionTypes.Student_Profile_Line_Fail:
                return {
                    ...state,
                    errors: action.payload,
                    loading: false,
                    isAuthenticated: false,
                    parentDetails:[],
                    ImageURL:[],
                    studentProfileLine:[]
                };
            case StudentActionTypes.FETCH_PARENT_FAIL:
                return {
                    ...state,
                    errors: action.payload,
                    loading: false,
                    isAuthenticated: false,
                    getParentStatus:false,
                    parentDetails:[]
                };
            case StudentActionTypes.FETCH_STUDENT_FAIL:  
            case StudentActionTypes.Update_Attendance_Fail:  
            return {
                ...state,
                errors: action.payload,
                loading: false,
                isAuthenticated: false,
                records:[],
                onUpdateAttendanceStatu:false,
                updateAttendanceLoader: false
            };
            case StudentActionTypes.FETCH_STUDENT_ACADEMIC_YEAR_SUCCESS:
            return {
                ...state,
                loading: false,
                studentList: action.payload.data
            };
            case StudentActionTypes.STUDENT_PROMOTION_FAIL:
            case StudentActionTypes.FETCH_STUDENT_ACADEMIC_YEAR_FAIL:
            case StudentActionTypes.STUDENT_NEXT_CLASS_FAIL:
                return{
                    ...state,
                    loading: false,
                    isAuthenticated: false,
                    studentNextClassDetail:[]
                }
            case StudentActionTypes.RESET_DROPDOWN_DATA:  
            return {
                ...state,
                newlyCreatedStudentInfo: {},
            };
            case StudentActionTypes.Reset_Student_Details:
                return initialState;

        default:
            return state;        
    }
}

export { reducer as studentReducer }