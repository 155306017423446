import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchDashboard } from '../../store/dashboard/Actions';
import { stopYearData } from '../../store/profile/Actions';

export interface PostsTeacherProps {
	getChangeYearData?: any;
	fetchDashboard: (postValue: any) => any;
	stopYearData: () => any;
	getTeacherCountList: any;
	getCurrectYear?: any;
	dashboardCountLoader?:boolean;
}
interface StateTypes {
	TeacherCount: any,
	getYear: any
}
export class TeacherCount extends Component<PostsTeacherProps, StateTypes> {
	public state = {
		getYear: null,
		TeacherCount: []
	}
	componentDidMount(): void {
		this.getDashboardCount()
	}

	getDashboardCount(getYearList?: any) {
		if (getYearList && getYearList !== undefined && getYearList !== null) {
			this.getDashboardData(getYearList)
			this.setState({ getYear: getYearList })
		} else {
			this.getDashboardData(this.props.getChangeYearData)
			this.setState({ getYear: this.props.getChangeYearData })
		}
	}
	getDashboardData(getYear?: any) {
		window.scrollTo(0, 0);
		if (getYear) {
			this.setState({ TeacherCount: [] })
			const postValue = {
				academic_year: getYear
			}
			this.props.fetchDashboard(postValue).then((res: any) => {
				this.getTeacherCount()
			});
		}
	}
	// This is life cycle is initial load  component Will Update
	componentWillUpdate(nextProps: any, nextState: any) {
		const { getCurrectYear } = this.props;
		if (getCurrectYear === true) {
			this.getDashboardCount(this.props.getChangeYearData)
		}
	}
	componentDidUpdate() {
		const { getCurrectYear } = this.props;
		if (getCurrectYear === true) {
			this.props.stopYearData()
		}
	}
	getTeacherCount() {
		const { getTeacherCountList } = this.props;
		if (getTeacherCountList && getTeacherCountList !== undefined && getTeacherCountList !== null) {
			getTeacherCountList.forEach((items: any) => {
				if (items.title === 'Attendance') {
					const getAttendance: any = getTeacherCountList.find((item: any) => item.title === 'Attendance')
					if (getAttendance.title === 'Attendance') {
						items['bg_class'] = "bg-blue";
						items['icon_class'] = "fa fa fa-bar-chart";
					}
				} else if (items.title === 'Quizzes') {
					const getQuizzes: any = getTeacherCountList.find((item: any) => item.title === 'Quizzes')
					if (getQuizzes) {
						items['bg_class'] = "bg-orange";
						items['icon_class'] = "fa fa-file-text";
					}

				} else if (items.title === 'Instant Feedback') {
					const getInstantFeedback: any = getTeacherCountList.find((item: any) => item.title === 'Instant Feedback')
					if (getInstantFeedback) {
						items['bg_class'] = "bg-success";
						items['icon_class'] = "fa fa-pie-chart";
					}
				} else if (items.title === 'Engagements') {
					const getInstantFeedback: any = getTeacherCountList.find((item: any) => item.title === 'Engagements')
					if (getInstantFeedback) {
						items['bg_class'] = "bg-purple";
						items['icon_class'] = "fa-solid fa-chalkboard-user";
					}
				}

			})
			this.setState({ TeacherCount: getTeacherCountList })
		} else {
			this.setState({ TeacherCount: [] })
		}
	}
	render() {
		const { TeacherCount } = this.state;
		const {dashboardCountLoader} = this.props;
		const loaderDashboardCount = { display: dashboardCountLoader ? "block" : "block", borderRadius:"25px"  };
		return (
			<div>
				<div className="state-overview">
					<div className="row">
						{
							TeacherCount && TeacherCount !== undefined && TeacherCount !== null && TeacherCount.length !== 0 ? TeacherCount.map((items: any) => (
								<div className="col-xl-3 col-md-6 col-12">
									<div className={`info-box p-0 pl-3 ${items.bg_class}`}>
										<span className="info-box-icon push-bottom">
											<i className={items.icon_class} aria-hidden="true"></i>
										</span>
										<div className="info-box-content">
											<span className="info-box-text">{items.title}</span>
											<span className="info-box-number">{items.count}</span>
											<div className="progress">
												<div className="progress-bar width-60"></div>
											</div>
											<span className="progress-description">
												<span>Total {items.title}</span>
											</span>
										</div>
										{/* info-box-content */}
									</div>
									{/* info-box */}
								</div>
							)) :
								<div className="col-12 mb-3" >
									<div className={`info-box`} style={{ marginBottom: 0 }}>
										<div className="overlay" style={loaderDashboardCount}><div className='custom-spinner-individual'></div></div>
									</div>
								</div>
						}
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state: any) => {
	return {
		getChangeYearData: state.profile.getYear,
		getCurrectYear: state.profile.yearStatusFails,
		getTeacherCountList: state.dashboard.DashboardCount,
		dashboardCountLoader:state.dashboard.dashboardCountLoader
	}
}

export default connect(mapStateToProps, { fetchDashboard, stopYearData })(TeacherCount)
