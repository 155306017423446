import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchGradeComparisonReport, fetchGradeSubjectList } from '../../store/dashboard/Actions';
import CommonLoader from '../../components/CommonLoader';
import { stopYearData } from '../../store/profile/Actions';
import InfiniteScroll from 'react-infinite-scroll-component';
import { fetchFeedbackQuestionResponses } from '../../store/dashboard/Actions';
import {SubjectListGet} from '../../store/profile/Actions';

interface PropsGradeType {
	getChangeYearData?:any;
	stopYearDate?:any;
    fetchGradeComparisonReport: (postValue: any) => any;
    fetchGradeSubjectList:() => any;
    stopYearData:()=>any;
    SubjectListGet:()=>any;
    getGradeComparisonValue?: any;
    getGradeSubjectList?:any;
    loading?:boolean;
    getGradeComparisonReport?: any;
    getGradeComparisonHeader?: any;
    pageGradeComparison?: any;
    perPageGradeComparison?: any;
    totalGradeComparison?: any;
    getfetchFeedbackDetails:any;
    getSubjectsList:any;
    // getDurationList:any;
    fetchFeedbackQuestionResponses:(postValue:any) => any;
}

interface PropStateType {
	feedbackFilter:any,
	feedbackData?:any,
	academicYear?:any,
    subject:any,
    slectSubjectId:'',
            hasMore: any,
            prev: Number,
            next: Number,
            page: any,
            per_page: Number,
            records: any,
            total: Number,
            totalPage: Number
}


export class GradeComparison extends Component<PropsGradeType, PropStateType> {
    constructor(props: any) {
        super(props);
        this.state = {
            feedbackFilter:'',
            feedbackData:'',
            subject:'',
            slectSubjectId:'',
			academicYear:null,
            hasMore: true,
            prev: 0,
            next: 10,
            page: 1,
            per_page: 10,
            records: [],
            total: 0,
            totalPage: 0
        }
    }
    componentDidMount(): void {
        this.props.SubjectListGet()
        this.props.fetchGradeSubjectList().then((res:any)=>{
            this.getCurrectYear()
            // this.getSubjectsList()
            // this.fetchMoreGradeComparison()
        })
    }

    getSubjectsList(getSubjects?:any){
		if(getSubjects && getSubjects !== undefined && getSubjects !== null){
			this.setState({academicYear:getSubjects})
			this.getFetchQuizChartBar('')
		}
        else {
			this.setState({academicYear:this.props.getChangeYearData})
			this.getFetchQuizChartBar('')
		}
	}

     // This is life cycle is initial load  component Will Update
     componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYearDate} = this.props;
		if(stopYearDate === true){
			this.getCurrectYear(this.props.getChangeYearData)
		}
	}
    // This is life cycle is initial load  component Did Update
	componentDidUpdate() {
		const { stopYearDate } = this.props;
        if(stopYearDate === true){
			this.props.stopYearData()
		}
	}
    // This is function is used get correct year
	getCurrectYear(getYear?:any){
		if(getYear && getYear !== undefined && getYear !== null){
			this.geGradeComparisonData(getYear)
			this.setState({academicYear:getYear})
		}else {
			this.geGradeComparisonData(this.props.getChangeYearData)
			this.setState({academicYear:this.props.getChangeYearData})
		}
	}
    geGradeComparisonData(getYear?:any) {
            this.setState({feedbackFilter:'',page:1,hasMore:true})
            const postValue: any = {
                academic_year: getYear,
                page_no:1,
                subject_id: ''
            }
            this.props.fetchGradeComparisonReport(postValue).then((res:any)=>{
                window.scrollTo(0, 0);
                this.setState({page:1, hasMore:true})
            });
    }
    getSubjectFilterDetails = (event:any) =>{
        const {academicYear} = this.state;
        const getValue = event.target.value;
        this.setState({ slectSubjectId: event.target.value });
        if(getValue){
            const postValue: any = {
                academic_year: academicYear,
                subject_id: getValue

            }
            this.props.fetchGradeComparisonReport(postValue);  
        }
    }
    
    onFilterSubjectName = (e:any)=> {
        const { value } = e.target;
		const {academicYear} = this.state;
		if(value){
            this.setState({feedbackFilter:value,page:1,hasMore:true})
			const loadMoreType = {
				subject_id: value,
				academic_year: academicYear,
                page_no:1
			}
			this.props.fetchGradeComparisonReport(loadMoreType).then((res:any)=>{
				const {getfetchFeedbackDetails} = this.props;
				// if(getfetchFeedbackDetails && getfetchFeedbackDetails !== undefined && getfetchFeedbackDetails !== null && getfetchFeedbackDetails.length > 0){
				// 	this.getFeedbackRecord(getfetchFeedbackDetails)
				//   }else {
				// 	this.setState({feedbackData:[]})
				//   }
			});
			
		}
	}
    refreshFeedbackTable = () => {
		const {academicYear} = this.state;
		this.getFetchQuizChartBar(academicYear);
	}
    getFetchQuizChartBar(getYear?:any){
		const loadMoreType = {
			filter: '',
			academic_year: getYear
		  }
		  this.props.fetchFeedbackQuestionResponses(loadMoreType).then((res:any)=>{
			  const {getfetchFeedbackDetails} = this.props;
			  if(getfetchFeedbackDetails && getfetchFeedbackDetails !== undefined && getfetchFeedbackDetails !== null && getfetchFeedbackDetails.length > 0){
				this.getFeedbackRecord(getfetchFeedbackDetails)
				this.setState({feedbackFilter:''})
			  }else {
				this.setState({feedbackData:[]})
				this.setState({feedbackFilter:''})
			  }
			  
		  });
	}

    getFeedbackRecord(getfetchFeedbackDetails:any){
		let getSchoolName:any = [];
			  let getColorCode:any = [];
			  let getFeedbackResponse:any = [];
			  if(getfetchFeedbackDetails){
				  getSchoolName = [];
				  getColorCode = [];
				  getFeedbackResponse = [];
				  getfetchFeedbackDetails.forEach((feedback:any, index:any)=>{
					  getSchoolName.push(feedback.school_name);
					  getColorCode.push(feedback.color_code);
					  getFeedbackResponse.push(feedback.feedback_response);
				  })
				  const postValue:any = {
					getSchoolName:getSchoolName,
					getColorCode:getColorCode,
					getFeedbackResponse:getFeedbackResponse
				  }
				  if(postValue){
					  this.setState({feedbackData:postValue})
				  }
			  }
	}

  public fetchMoreGradeComparison = () => {
      const {academicYear} = this.state;
    if (this.state.hasMore === true) {
     if (Math.ceil(this.props.totalGradeComparison / this.props.perPageGradeComparison) > this.props.pageGradeComparison) {   
        const postValue:any = {
          page_no: this.state.page + 1,
          academic_year:academicYear,
          subject_id:this.state.feedbackFilter
        }              
        this.props.fetchGradeComparisonReport(postValue);
        this.setState({
          page: this.state.page + 1
        }) 
      }

      if (Math.ceil(this.props.totalGradeComparison / this.props.perPageGradeComparison) === this.props.pageGradeComparison) {
        this.setState({
          hasMore: false,
        })
      }
    }
  }
    refreshGradeComparisonTable = () => {
        const {academicYear} = this.state;
        this.geGradeComparisonData(academicYear)
    }
    render() {
        const { getGradeComparisonValue, getSubjectsList, loading, getGradeComparisonReport, getGradeComparisonHeader } = this.props;
		const loadingDashboard = { display: loading ? "block" : "none" };
        return (
            <div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="card card-topline-red">
                        <div className="overlay" style={loadingDashboard}><div className='custom-spinner-individual'></div></div>
                            <div className="card-head">
                                <header>Grade Comparison</header>
                                {getSubjectsList ?
								<div className="tools">
								<div className="d-flex">
										<div>
										{getSubjectsList?                                      
									<select name="classlist" value={this.state.feedbackFilter} className="form-control" onChange={this.onFilterSubjectName}>
										<option value="">Select Subject</option>
                                        {getSubjectsList.map((items: any) => (
											<option value={items.id}> {items.name} </option>
										))}
									</select>
									:null}
										</div>
										<div className="ml-2 mt-2">
											<span className="fa fa-repeat btn-color box-refresh pointer" onClick={this.refreshGradeComparisonTable}></span>
											<span className="t-collapse btn-color fa fa-chevron-up pointer" ></span>
										</div>
									</div>                              
								</div>
								:null}
                            </div>
                            <div className="card-body no-padding height-9">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 max-height-scroll">
                                    {getGradeComparisonReport && getGradeComparisonReport.length ?<InfiniteScroll
                                        dataLength={getGradeComparisonReport.length}
                                        next={this.fetchMoreGradeComparison}
                                        hasMore={this.state.hasMore}
                                        loader={<h4 style={loadingDashboard}>Loading...</h4>}
                                        >
                                        <div className="gradeTableScroll">
                                        <table className="gradeTable mb-2 table table-bordered">
                                            <thead>
                                            <tr>
                                            <td className="academicGradeTitle titleColorGrade" >School Name</td>
                                                            {getGradeComparisonHeader.length !== 0 
                                                            ? getGradeComparisonHeader.map(
                                                                (headerName:any, headerIndex:any) => (
                                            <td className="academicGradeTitle titleColorGrade text-center" key={headerIndex}> {headerName}</td>
                                                                )
                                                                )
                                                            : null}
                                            </tr>
                                            </thead>
                                            <tbody>
                                                        {getGradeComparisonReport &&
                                                        getGradeComparisonReport.length !== 0
                                                            ? getGradeComparisonReport.map(
                                                                (schoolData:any, schoolIndex:any) => (
                                            <tr key={schoolIndex}>
                                            <td className="academicGradeTitle titlesidebar text-left">{schoolData.name}</td>
                                                                    {getGradeComparisonHeader.map(
                                                                    (headerName:any, headerIndex:any) => (
                                            <td className="academicGrade"
                                                                        key={headerIndex}>
                                                                        {schoolData[headerName].map(
                                                                            (gradeData:any, gradeIndex:any) => (
                                            // <div className="chip">
                                            //                                     {gradeData?.standard}
                                            // </div>
                                            <div className="academicGrade" style={{ background: gradeData?.color_code }} key={gradeIndex}>
                                                                                    <small>{gradeData?.standard} {gradeData?.performance}</small></div>
                                                                            )
                                                                        )}
                                            </td>
                                                                    )
                                                                    )}
                                            </tr>
                                                                )
                                                            )
                                                            : null}
                                            </tbody>
                                        </table>
                                        </div>
                                        </InfiniteScroll> : <CommonLoader />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
		getChangeYearData:state.profile.getYear,
		stopYearDate:state.profile.yearStatusFails,
        getGradeComparisonValue:state.dashboard.getGradeComparisonReport,
        getGradeSubjectList:state.dashboard.gradeSujectList,
		loading: state.dashboard.isAdminGradeComparsionLoader,
        getGradeComparisonReport:state.dashboard.getGradeComparisonReport, 
        getGradeComparisonHeader:state.dashboard.getGradeComparisonHeader,
        pageGradeComparison:state.dashboard.pageGradeComparison,
        perPageGradeComparison:state.dashboard.perPageGradeComparison,
        totalGradeComparison:state.dashboard.totalGradeComparison,
        // getDurationList:state.profile.getDuration,
        getSubjectsList:state.profile.getSubjects,
    }
}

export default connect(mapStateToProps, { fetchFeedbackQuestionResponses, 
    fetchGradeComparisonReport, fetchGradeSubjectList, stopYearData, SubjectListGet })(GradeComparison)
