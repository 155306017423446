import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, FormGroup, MenuItem, Checkbox } from '@material-ui/core';
import { Field, Formik, FormikProps } from 'formik';
import BreadCrumb from '../../components/BreadCrumb';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { fetchGetAllClassList } from '../../store/diary/Actions';
import { TeacherSubjectMappingPost } from '../../store/teacher/Actions';
import { GetCorrectMappedSubject, GetTeacherSubjectMap } from '../../store/subject/Actions';
import { TextField } from 'formik-material-ui';
import { TeacherSubjectValidation } from './TeacherValidation';
import { studentValida } from '../../services/Constants';
import history from '../../History';
import {onGetData} from '../../store/classes/Actions';
import {onGetValue} from '../../services/Config';
import { stopYearData } from '../../store/profile/Actions'
import {ValidationMessage} from './Types';

export type OwnTeacherFormProps = { 
    getChangeYearData?:any;
	stopYearDate?:any;
    TeacherSubjectMappingPost: (teacherMap: any) => any;    
    fetchGetAllClassList: (postData:any) => any; // This is the service get all class list 
    GetCorrectMappedSubject: () => any;
    GetTeacherSubjectMap:() => any; 
    onGetData:(postValue:any) => any;
    stopYearData:()=>any;
    errorMessage?: any;
    loading: boolean; 
    gradeStandard:any;
    getTeacherViewData:any;
    schoolIdDetails:any,
    SubjectList:any;
    loader:any
    getTeacherMappingSubject:any,
    profileInfo:any
    mappingClassStatu:any,
    mappingTeacherId:any;
    getTeacherClassList:any
};
export interface addSubject {
    subjectName?:any,
    classNameId?:any,
    classInchanger?:any,
    addClassMapping?:any,
    subjectList?:any,
    profilePath?:string,
    BreadCrumbPath?:string,
    classNameList?:any,
    academicYear?:any,
    selectClassName?:any
}
const initialSubjectValues:addSubject = {
    subjectName:'',
    classNameId:''
}
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export class AddSubject extends React.Component<OwnTeacherFormProps, addSubject> {
    teacherId:any;
    getClassList:any = [];
    getSubject:any;
    constructor(props: any) {
        super(props);
        this.state = {
            addClassMapping:[],
            subjectList:[],
            profilePath:'',
            BreadCrumbPath:'',
            classNameList:[],
            academicYear:null,
            selectClassName:true
        }
    }
    componentDidMount(): void {
        this.UpdateYearDate()       
    }
    componentWillUpdate() {
        const {stopYearDate} = this.props;
        if(stopYearDate === true){
            this.UpdateYearDate(this.props.getChangeYearData)
        }
    }

    componentDidUpdate() {
        const {stopYearDate} = this.props;
        if(stopYearDate === true){
            this.props.stopYearData()
        }
    }

    UpdateYearDate(getYear?:any){
        if(getYear && getYear !== undefined && getYear !== null){
            this.getUserTypesValidation(getYear)
            this.setState({academicYear:getYear})
        }else {
            this.getUserTypesValidation(this.props.getChangeYearData)
            this.setState({academicYear:this.props.getChangeYearData})
        }
    }

    getUserTypesValidation(getYear?:any){
        window.scrollTo(0, 0);
        const { profileInfo } = this.props;
        if(profileInfo && profileInfo !== undefined && profileInfo !== null){
            if(profileInfo.usertype === 2) {
                this.setState({profilePath:`/teacher/${this.props.getTeacherViewData.ldap_id}`, BreadCrumbPath:`teacher/${this.props.getTeacherViewData.ldap_id}`})
                this.MappingSchoolTeacherSubject(getYear)
            } else if(profileInfo.usertype === 3) {
                this.MappingTeacherSubject(getYear)
                this.setState({profilePath:'/teachers_profile', BreadCrumbPath:'teachers_profile'})
            }
        }
    }

    MappingSchoolTeacherSubject(getYear?:any){
        const postSubject:any = {
            academic_year:getYear
        }
        this.props.fetchGetAllClassList(postSubject).then((res:any)=>{
            const {gradeStandard} = this.props;
            this.setState({classNameList:gradeStandard})
        }); // This is the service get all class list
        this.props.GetCorrectMappedSubject().then((res:any)=>{
            const { SubjectList } = this.props;
            if(SubjectList.length > 0 && SubjectList !== undefined) {
                this.setState({subjectList:SubjectList})
            }
        });
    }
    MappingTeacherSubject(getYear?:any){
        const postSubject:any = {
            academic_year:getYear
        }
        let getData:any = {
            data:postSubject,
            URL:onGetValue.getClassList
        }
        this.props.fetchGetAllClassList(postSubject); // This is the service get all class list
        this.props.onGetData(getData).then((res:any)=>{
            const {getTeacherClassList} = this.props;
            this.setState({classNameList:getTeacherClassList})
        });
        this.props.GetTeacherSubjectMap().then((res:any)=>{
            const {getTeacherMappingSubject} = this.props;
            if(getTeacherMappingSubject.length > 0 && getTeacherMappingSubject !== undefined){
                this.setState({subjectList:getTeacherMappingSubject})
            }
        });
    }

    getSubjectDetails(getEvent:any, getValue:any) {
        const { value } = getEvent.target;
        this.getSubject = value;
    }
    getClassMappingList(getValue?:any){     
        this.getClassList = [];
        if(getValue.length > 0){
            getValue.forEach((items:any)=>{
                const getClassList = String(items.id);  
                this.getClassList.push(getClassList)                
            })
            this.setState({selectClassName:true})
        }else {
            this.setState({selectClassName:false})
        }
    }

 onSubmit = (values: addSubject) => {
     const {academicYear} = this.state;
    const teacherMap:any = {
        academic_year:academicYear,
        teacher_id: this.props.getTeacherViewData.ldap_id,
        subject_id: values.subjectName,
        class_ids:this.getClassList
    }
    this.props.TeacherSubjectMappingPost(teacherMap).then((res:any)=>{
        const { mappingClassStatu } = this.props;
        const { profileInfo } = this.props;
        if(mappingClassStatu === true){
            if(profileInfo && profileInfo !== undefined && profileInfo !== null){
                if(profileInfo.usertype === 2) {
                    history.push(`${this.state.profilePath}`);
                   
                } else if(profileInfo.usertype === 3) {
                    history.push(`${this.state.profilePath}`);
                   
                }
            }
        }
      
    })
}

    render() {
        const { loading, loader } = this.props;
        const {subjectList, BreadCrumbPath, classNameList, selectClassName } = this.state;
        const loadingTeacher = { display: loading || loader ? "block" : "none" };
        return (
            <div>
                <div className="page-wrapper">
                <div className="page-content-wrapper">
                    <div className="page-content pt-3">
                        <BreadCrumb 
                        titleName={['Teacher']} 
                        homeName={['Home']} 
                        url={['dashboard']} 
                        baseName={['Teacher']} 
                        baseURL={[`${BreadCrumbPath}`]} 
                        mainPageTitle={['Map Subject & Class Mapping']}/>
                        {subjectList && classNameList ?
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card-box">
                                <div className="card-head">
                                    <header>Add Subject & Class Mapping</header>
                                    <div className="tools">
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Formik
                                                ref={node=>this.teacherId = node}
                                                initialValues={initialSubjectValues}
                                                validationSchema={TeacherSubjectValidation}
                                                onSubmit={this.onSubmit}
                                                render={({
                                                    values, errors, isSubmitting, isValidating, dirty, touched, handleSubmit, setFieldValue
                                                }: FormikProps<any>) => {
                                                    let getClassList:any = false;
                                                    const isEmptySubject = (!values.subjectName);
                                                        this.getClassList.length > 0?
                                                        getClassList = true
                                                        :getClassList = false
                                                return(
                                                    <form onSubmit={handleSubmit} className="ui form">  
                                                    <div className="row"> 
                                                        <div className="col-md-6 p-t-20">
                                                            <div className="mdl-textfield mdl-js-textfield  mdl-textfield--floating-label txt-full-width pt-0">
                                                                <FormGroup>
                                                            <Field
                                                                label={<><span>Subject</span><span className='formmandatorycolor'> *</span></>}
                                                                name="subjectName"
                                                                select
                                                                component={TextField}
                                                                className="textfield__input mt-1"
                                                                fullwidth="true"
                                                                disabled={false}
                                                                onChange={(e:any, value:any) => {
                                                                    setFieldValue('subjectName', e.target.value)
                                                                    this.getSubjectDetails(e, value)
                                                                  }} 
                                                            >
                                                                {subjectList.map((item: any) => (
                                                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                                                ))}
                                                            </Field>                                                      
                                                                </FormGroup>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 p-t-20">
                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                <FormGroup>
                                                                    {classNameList?
                                                                <Autocomplete
                                                                    fullWidth
                                                                    multiple
                                                                    id="checkboxes-tags-demo"
                                                                    options={classNameList}
                                                                    disableCloseOnSelect
                                                                    noOptionsText={studentValida.noRecordData}
                                                                    getOptionLabel={(option:any) => option.grade_standard}
                                                                    onChange={(e:any, getvalue:any) => {
                                                                        setFieldValue('classNameId', e.target.value)
                                                                       this.getClassMappingList(getvalue)
                                                                    }}
                                                                    renderOption={(option, { selected }) => (
                                                                        <React.Fragment>
                                                                        <Checkbox
                                                                            icon={icon}
                                                                            checkedIcon={checkedIcon}
                                                                            style={{ marginRight: 8 }}
                                                                            checked={selected}
                                                                        />
                                                                        {option.grade_standard}
                                                                        </React.Fragment>
                                                                    )}
                                                                    className="mb-0 mt-1"
                                                                    renderInput={(params) => {
                                                                        return(
                                                                        <Field component={TextField} {...params} label={<><span>Search Class Name</span><span className='formmandatorycolor'> *</span></>} name="classNameId"/>
                                                                    )}}
                                                                    />
                                                                    :null}                                                             {selectClassName?
                                                                        null
                                                                    :<span className="diaryerrorcolor">{ValidationMessage.select_class_name}</span>}       
                                                                </FormGroup> 
                                                                </div>
                                                            </div>
                                                        </div>         
                                                        <div className="text-right mb-3 mr-2 mt-4">
                                                            <Button className="btn btn-pink mr-1 ml-1" disabled={isEmptySubject || isValidating || !dirty 
                                                                ||  !!(errors.subjectName && touched.subjectName) || !getClassList} type="submit">Submit</Button>
                                                            <Link to={`${this.state.profilePath}`}><Button className="btn btn-default mr-1 ml-1">Cancel</Button></Link>
                                                        </div>
                                                    </form>
                                                )}
                                            }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                :<SpinnerLoader/>}
                    </div>
                    
                </div>
            </div>
            <div style={loadingTeacher}><SpinnerLoader/></div>
            </div>
        )
    }
}

const mapStateToProps = (state:any) => {
    return {
        loading:state.student.loading,
        loader:state.teacher.loading,
        getChangeYearData:state.profile.getYear,
        stopYearDate:state.profile.yearStatusFails,
        errorMessage: state.teacher.errors,
        gradeStandard:state.diary.gradelist,
        getTeacherViewData:state.teacher.GetTeacherProfile,
        schoolIdDetails:state.profile.schoolId,
        SubjectList:state.subjects.GetCorrectSubject,
        profileInfo:state.profile.profileData,
        getTeacherMappingSubject:state.subjects.getTeacherMapSubject,
        mappingClassStatu:state.teacher.mappingClassIncharge,
        mappingTeacherId:state.teacher.Add_Mapping_Teacher_id,
        getTeacherClassList:state.classes.OnGetData
    };
  };

export default connect(mapStateToProps,{TeacherSubjectMappingPost, GetCorrectMappedSubject, fetchGetAllClassList, GetTeacherSubjectMap, onGetData, stopYearData})(AddSubject)
