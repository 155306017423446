import Modal from 'react-bootstrap/Modal'
import { Formik, Form } from 'formik';
export const StudentAttendance = (props: any) => {
  return (
    <div>
      <Modal show={props.onState}>
        <Modal.Header className="pb-0 pt-0">
          <Modal.Title>Student Attendance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Formik 
        initialValues={{
          student: props.student
        }} 
        onSubmit={props.onSubmit}>
      {({ values, setFieldValue }) => (
        <Form>
          <>
            <table className="table table-striped custom-table table-hover">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
              {values.student.map((item: any, index: number) => (
                  <tr key={index}>
                    <td><label>{item.card_id}</label></td>
                    <td><label>{item.student_name}</label></td>
                    <td>
                      <div className="form-check mt-4">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name={`student[${index}].attendance`}
                          id={`student[${index}].attendance`}
                          checked={item.attendance}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue(`student[${index}].attendance`, e.target.checked)
                          }
                        />
                        <label className="form-check-label" htmlFor={`student[${index}].attendance`}>Present</label>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="col-md-12 p-t-20 d-flex">
                   
                                    <div className="ml-auto">
                                    <button type="submit" className="btn btn-primary mr-1 ml-1 w-15" >Submit</button>
                                    <button type="button" className="btn btn-default mr-1 ml-1 w-15" onClick={props.onCancel}>Cancel</button>
                                    </div>
                                </div>
            </>
        </Form>
      )}
    </Formik>
         
        </Modal.Body>

      </Modal>
    </div>
  )
}
