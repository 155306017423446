import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getRecentFeedbackList } from '../../store/question/Actions';
import CommonLoader from '../../components/CommonLoader';
import { Link } from "react-router-dom";
import history from '../../History';
import { getAttendanceStudentView } from '../../store/profile/Actions';
import Cookies from 'universal-cookie';
import { stopYearData } from '../../store/profile/Actions'

export interface PostsFeedbackProps {
    getChangeYearData?:any;
	stopYearDate?:any;
    getRecentFeedbackList:(postValue:any) => any;
    getAttendanceStudentView:(postValue:any) => any;
    stopYearData:()=>any;
    loading?: boolean,
    loginProfile:any,
    getRecentFeedback?:any,
    getTeacherId?:any

}
export class RecentFeedback extends Component<PostsFeedbackProps> {
    cookies:any = new Cookies();
    componentDidMount(): void {
       this.UpdateYearDate()
    }

  componentWillUpdate() {
    const {stopYearDate} = this.props;
    if(stopYearDate === true){
        this.UpdateYearDate(this.props.getChangeYearData)
    }
}

componentDidUpdate() {
    const {stopYearDate} = this.props;
    if(stopYearDate === true){
        this.props.stopYearData()
    }
}

UpdateYearDate(getYear?:any){
    if(getYear && getYear !== undefined && getYear !== null){
        this.getRecentFeedback(getYear)
    }else {
        this.getRecentFeedback(this.props.getChangeYearData)
    }
}

getRecentFeedback(getYear?:any){
    const { getTeacherId,loginProfile } = this.props;
    window.scrollTo(0, 0);
    let postValue={};

    if(loginProfile && loginProfile !== undefined && loginProfile !== null){
        const getUserType:any = loginProfile.usertype
        if(getUserType === 1){
            postValue = {
                academic_year: getYear,
                teacher_id: getTeacherId,
                school_id:this.cookies.get('ACADEMICSCHOOLID')
            }
        } else{
            postValue = {
                academic_year: getYear,
                teacher_id: getTeacherId
            }
        }
    }
    this.props.getRecentFeedbackList(postValue);
}

    getInstantFeedBackResult = (getValue:any) =>{
        if(getValue){
            let postValue:any ={ 
                class_id:getValue.class_id,
                school_id: getValue.school_id
            }
            history.push({
                pathname: `/view_instant_feedback/${getValue.id}`,
              });
              this.cookies.set('VIEWINTANTFEEDBACK', postValue)
              this.props.getAttendanceStudentView(postValue);
        }
    }
    render() {
        const { getRecentFeedback } = this.props;
        return (
            <div>
               <div className="row">
                    <div className="col-md-12">
                    <h4 className="mb-2">Recent Feedback</h4>
                        <div className="card card-topline-red">
                            <div className="card-body no-padding height-9">
                                <div className="table-responsive">
                                    <table className="table table-striped custom-table table-hover">
                                        <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Performance (Average)</th>
                                            <th>Time</th>
                                        </tr>
                                        </thead>
                                        {getRecentFeedback? 
                                        <tbody>
                                            {
                                                getRecentFeedback.map((items: any, index: any) => (
                                                    <tr key={index}>
                                                        <td className="titleCapitalize"><Link to={`#`} onClick={()=> this.getInstantFeedBackResult(items)}>{items.name}</Link></td>
                                                        <td><Link to={`#`} onClick={()=> this.getInstantFeedBackResult(items)}>{items.performance}</Link></td>
                                                        <td><Link to={`#`} onClick={()=> this.getInstantFeedBackResult(items)}>{items.created_at}</Link></td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                        :<CommonLoader/>}
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="pull-right">
                            <Link to={"/instant_feedback"}>
                            <button className="btn btn-pink">More...</button>
                            </Link>
                        </div>
                        
                    </div>
                </div> 
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        loginProfile:state.profile.profileData,
        getChangeYearData:state.profile.getYear,
        stopYearDate:state.profile.yearStatusFails,
        getRecentFeedback:state.questionset.recentFeedbackList,
        loading:state.questionset.loading
    }
}

export default connect(mapStateToProps, {getRecentFeedbackList, getAttendanceStudentView,
    stopYearData})(RecentFeedback)
