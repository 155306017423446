import React from 'react';
import { connect } from 'react-redux';
import BreadCrumb from '../../components/BreadCrumb'
import Schoolstatuschart from './SchoolStatusChart';
import Teacherupdatedetails from './TeacherDetails';
import Totalcount from './TotalCount';
import AttendanceUsage from '../admindashboard/AttendanceUsage';
import QuizQuestionResponses from '../admindashboard/QuizQuestionResponses';
import FeedbackQuestionResponses from '../admindashboard/FeedbackQuestionResponses';
import './dashboardStyle.scss';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import TeacherCount from './TeacherCount';
import AcademicAdminCount from './AcademicAdminCount';
import TeacherDashboard from '../teacherDashboard/TeacherDashboard';
import TeacherSubject from '../teacherDashboard/TeacherSubject';
import GradeComparison from '../admindashboard/GradeComparison';
import TodayAttendance from '../admindashboard/TodayAttendance';
import { DurationListGet, getStudentClassesData, fetchProfilePost, stopProfileYearData, YearListGet } from '../../store/profile/Actions';
import { fetchLicenseValidateGet } from '../../store/license/Actions';
import { CommonModel } from '../../components/CommonModel';
import { UserRoles } from '../../services/Constants';

export interface PostsDiaryProps {
    DurationListGet:()=>any;
    getStudentClassesData:()=>any;
    fetchProfilePost:()=>any;
    YearListGet: () => any;
    fetchLicenseValidateGet: () => any;
    license?: any;
    loading: boolean;
    loginProfile:any;
    loadingProfile:any;
    getYear?:any;
    getCurrectYear?:any;
    stopProfileYearData:()=>any;
}
interface StateTypes {
    academicAdminUserTypes:boolean,
    schoolAdminUserTypes:boolean,
    teacherUserTypes:boolean,
    isYearUpdated:boolean,
    showLicense:boolean,
    licenseDetails:any;
}
class DashboardIndexPage extends React.Component<PostsDiaryProps, StateTypes>{
    constructor(props:any){
        super(props)
        this.state = {
            academicAdminUserTypes:false,
            schoolAdminUserTypes:false,
            teacherUserTypes:false,
            isYearUpdated:false,
            showLicense:false,
            licenseDetails:[]
        }
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.props.getStudentClassesData();
        this.props.DurationListGet().then((res:any) =>{
            this.findUserTypes();
        });
        const {  loginProfile } = this.props;
        if(loginProfile && loginProfile !== undefined && loginProfile !== null){
            const getUserType:any = loginProfile.usertype
            if (getUserType !== parseInt(UserRoles.acadamicAdmin)) {
            this.props.fetchLicenseValidateGet().then((res: any) => {
                const { license } = this.props;
                if(license.status == false && license?.license_status == false){
                    let postValue: any = {
                        type: 'logout',
                        title: 'License',
                        action_status: 'Invalid License'
                    }
                    if (postValue) {
                        this.setState({ showLicense: true, licenseDetails: postValue })
                    }
                }
            })
        }
    }
    }
    findUserTypes(){
        const {loginProfile} = this.props;
        if(loginProfile && loginProfile !== undefined && loginProfile !== null){
            const getUserType:any = loginProfile.usertype
            if(getUserType === 1){
                this.setState({academicAdminUserTypes:true})
                this.props.YearListGet();
            } else if(getUserType === 2){
                this.setState({schoolAdminUserTypes:true})
            } else if(getUserType === 3){ 
                this.setState({teacherUserTypes:true})
            }
        }
    }

    componentWillUpdate(nextProps:any, nextState:any) {
		const {loadingProfile} = this.props;
		if(loadingProfile === true){
            this.findUserTypes();
		}
	}
	componentDidUpdate() {
		const {loadingProfile} = this.props;
		if(loadingProfile === true){
			this.props.stopProfileYearData();
		}
	}

    public closeLicenseStatus = () => {
		this.setState({ showLicense: false });
	}
  
    render(){
        const {  loginProfile } = this.props;
        const {academicAdminUserTypes, schoolAdminUserTypes, teacherUserTypes, showLicense, licenseDetails} = this.state;
        return(
            <div>
                <CommonModel
                onState={showLicense}
                currentState={licenseDetails}
                onConfirm={this.closeLicenseStatus}
                onCancel={this.closeLicenseStatus}
                />
                <div className="page-wrapper">
                <div className="page-content-wrapper">
                <BreadCrumb titleName={['Dashboard']} homeName={['Home']} url={['dashboard']}/>
                {loginProfile && loginProfile !== undefined && loginProfile !== null? 
                <>{
                    academicAdminUserTypes? 
                    <>
                <AcademicAdminCount/>
                <AttendanceUsage/>
                <QuizQuestionResponses/>
                <FeedbackQuestionResponses/>
                <GradeComparison/>
                <TodayAttendance/>
                    </>
                    :null
                }
                {schoolAdminUserTypes? 
                <>
                <Totalcount/>
                <Schoolstatuschart/>
                <Teacherupdatedetails/>
                </>
                :null
                }
                {teacherUserTypes? 
                <>
                <TeacherCount/>
                <TeacherDashboard/>
                <TeacherSubject/>
                </>
                :null}
                
                </>
                :null}
                
                </div>
                </div>
				{/* <div style={loadingDashboard}><SpinnerLoader /></div> */}
            </div>
    )}
}

const mapStateToProps = (state: any) => {
    return {
        loadingProfile: state.profile.profileServiceLoader,
        loginProfile:state.profile.profileData,
        getYear: state.profile.getYear,
        getCurrectYear:state.profile.yearStatusFails,
        license: state.license.license,
    }
}

export default connect(mapStateToProps, {DurationListGet, getStudentClassesData, fetchProfilePost,stopProfileYearData, YearListGet, fetchLicenseValidateGet})(DashboardIndexPage)
