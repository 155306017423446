import * as yup from 'yup';
import { formValidationPatten, FormInvalidMessage, formValidationSize } from '../../services/Constants';

export const UserManageValidation = yup.object({
    old_password:
        yup.string()
            .required(FormInvalidMessage.newpassword)
            .min(formValidationSize.minlengthpassword, FormInvalidMessage.incalidpassPatten)
            .max(formValidationSize.maxlengthpassword, FormInvalidMessage.incalidpassPatten)
            .matches(formValidationPatten.passwordPatten, FormInvalidMessage.incalidpassPatten
            ),
    new_password:
        yup.string()
            .required(FormInvalidMessage.newpassword)
            .min(formValidationSize.minlengthpassword, FormInvalidMessage.incalidpassPatten)
            .max(formValidationSize.maxlengthpassword, FormInvalidMessage.incalidpassPatten)
            .matches(formValidationPatten.passwordPatten, FormInvalidMessage.incalidpassPatten
            ),
    confirmpassword:
        yup.string()
            .required(FormInvalidMessage.Confirmpassword)
            .oneOf([yup.ref("new_password")], FormInvalidMessage.confirmMessage)
})