import React from 'react'
import { connect } from 'react-redux';
import { VerifyOtpRegister, SendOtpRegister } from '../../store/authentication/Actions'
import { Box, Button, TextField } from '@material-ui/core';
import { Field, Formik, Form } from 'formik';
import { object, string } from 'yup';
import { Link } from 'react-router-dom';
import './Authentication.scss'
import SpinnerLoader from '../../components/spinner/SpinnerLoader'
import { RootState } from '../../store/Index';
import { VerifiyOtpType } from '../../store/authentication/Types';
import { formValidationPatten, FormInvalidMessage, FormvalidationMessage, 
    formValidationSize, formValidationSizeMsg } from '../../services/Constants';
import history from '../../History';

const initialOTPValues: VerifiyOtpType = {
    otp: '',
    username: localStorage.getItem('notregister')
}

const OtpValidation = (props?: any) => {
    const [showOTP, setShowOTP] = React.useState('password')
    const [showOTPInput, setShowOTPInput] = React.useState(true)
    const [timer, setTimer] = React.useState(60);
    const timeOutCallback = React.useCallback(() => setTimer(currTimer => currTimer - 1), []);
    const { loading } = props;
    let formikValid: any;
    let isBackButtonClicked: any;

    React.useEffect(() => {
        window.history.pushState(null, "null", window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        timer > 0 && setTimeout(timeOutCallback, 1000);
    }, [timer, timeOutCallback]);


    const resetTimer = function () {
        if (!timer) {
            setTimer(60);
        }
    };

    const onBackButtonEvent = (e: any) => {
        e.preventDefault();
        if (!isBackButtonClicked) {
            window.history.pushState(null, "null", window.location.pathname);
            isBackButtonClicked = false;
        }
    }

    const resetOTP = () => {
        resetTimer()
        const resetSendOTP: any = {
            username: localStorage.getItem('notregister')
        }
        props.SendOtpRegister(resetSendOTP);
        formikValid.resetForm();
    }

    const onBackRegister = () => {
        localStorage.setItem('notregister', '');
        history.push("/register");
    }

    const onShowOTP = (getValue: any) => {
        setShowOTP(getValue.type)
        setShowOTPInput(getValue.show)
    }

    const onHideOTP = (getValue: any) => {
        setShowOTP(getValue.type)
        setShowOTPInput(getValue.show)
    }
    return (
        <div>
            <div className="limiter">
                <div className="container-login100">
                    <div className="wrap-login100  formboxsize">
                        <Formik
                            ref={node => formikValid = node}
                            validationSchema={
                                object({
                                    otp: string()
                                        .required(FormvalidationMessage.otpCode)
                                        .min(formValidationSize.otpMin, formValidationSizeMsg.otpMixMsg)
                                        .max(formValidationSize.otpMax, formValidationSizeMsg.otpMaxMsg)
                                        .matches(formValidationPatten.numberPatten, FormInvalidMessage.otpInvalid)
                                })
                            }
                            initialValues={initialOTPValues} onSubmit={(values, formikHelpers) => {
                                const initialOTPSend: VerifiyOtpType = {
                                    otp: values.otp,
                                    username: localStorage.getItem('notregister')
                                }
                                props.VerifyOtpRegister(initialOTPSend);
                            }}>
                            {({ values, errors, isSubmitting, isValidating, dirty, touched }) => (
                                <Form>
                                    <span className="login100-form-logo logoprofileicon">
                                        <i className="fa fa-user-o" aria-hidden="true"></i>
                                    </span>
                                    <span className="login100-form-title p-b-20 p-t-20 mb-2 formtitlesize">
                                        Enter OTP
                                    </span>
                                    <div className="wrap-input100 validate-input mt-4 mb-0" data-validate="Enter username">
                                        <Field
                                            type={showOTP}
                                            name="otp"
                                            as={TextField}
                                            placeholder="OTP*"
                                            className="input100"
                                        />
                                        <span className="focus-input100" data-placeholder="&#xf191;"></span>
                                    </div>
                                    {showOTPInput ? <div className="passwordopen"
                                        onClick={() => onShowOTP({ type: 'text', show: false })} title="Show OTP">
                                        <i className="fa fa-eye" aria-hidden="true"></i></div>
                                        : <div className="passwordopen"
                                            onClick={() => onHideOTP({ type: 'password', show: true })} title="Hidden OTP">
                                            <i className="fa fa-eye-slash" aria-hidden="true"></i></div>}
                                    {errors.otp && touched.otp ? (
                                        <div className="errorlogin">{errors.otp}</div>
                                    ) : null}

                                    <div className="container-login100-form-btn mt-5">
                                        <div>
                                            <Button className="login100-form-btn " type="submit" 
                                            disabled={!isValidating && !dirty}>Submit</Button>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="text-left p-t-30 text-center">
                                            {
                                                !timer ?
                                                    <Link to={'#'} className="txt1" onClick={() => resetOTP()}>
                                                        Resend OTP
                                                    </Link>
                                                    : <Box className="txt1">Resend OTP in 
                                                    <Box sx={{fontSize:26, color:'yellow'}}>{timer}</Box> seconds</Box>
                                            }
                                        </div>
                                        <div className="text-left p-t-30 text-center">
                                            <Link to={'#'} className="txt1" onClick={() => onBackRegister()}>
                                                Back
                                            </Link>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            <div style={{ display: loading ? "block" : "none" }}><SpinnerLoader /></div>
        </div>
    )
}

const mapStateToProps = ({ login }: RootState) => {
    return {
        loading: login.loading,
        reOpenAction: login.reOpenaction
    }
}

const mapDispatchToProps = (dispatch?: any) => {
    return {
        VerifyOtpRegister: (reqProgram?: any) => dispatch(VerifyOtpRegister(reqProgram)),
        SendOtpRegister: (reqProgram?: any) => dispatch(SendOtpRegister(reqProgram))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OtpValidation);
