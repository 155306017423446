import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSubjectPerformance } from '../../store/teacher/Actions';
import Cookies from 'universal-cookie';
import CommonSubjectList from '../../components/CommonSubjectList';
import { stopYearData } from '../../store/profile/Actions'

interface PropsSubject {
	getChangeYearData?:any;
    getSubjectPerformance:(postValue:any) => any;
    stopYearData:()=>any;
    loginProfile:any,
    getTeacherInfo?:any;
    getTeacherDetails?:any;
	stopYearDate?:any
}
interface StatuTypes {
    schoolId:any;
    academicYear:any;
}

class SubjectFeedBack extends Component<PropsSubject, StatuTypes> {
    cookies:any = new Cookies();
    constructor(props: any) {
        super(props);
        this.state = {
            schoolId: this.cookies.get('ACADEMICSCHOOLID') || null,
			academicYear:null
        }
    }
    // This is life cycle is initial load component
    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.getCurrectYear()
    }
    // This is life cycle is initial load  component Will Update
    componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYearDate} = this.props;
		if(stopYearDate === true){
			this.getCurrectYear(this.props.getChangeYearData)
		}
	}
    // This is life cycle is initial load  component Did Update
	componentDidUpdate() {
		const { stopYearDate } = this.props;
        if(stopYearDate === true){
			this.props.stopYearData()
		}
	}
    // This is function is used get correct year
	getCurrectYear(getYear?:any){
		if(getYear && getYear !== undefined && getYear !== null){
			this.getSubjectFeedback(getYear)
			this.setState({academicYear:getYear})
		}else {
			this.getSubjectFeedback(this.props.getChangeYearData)
			this.setState({academicYear:this.props.getChangeYearData})
		}
	}
    // This is function is used get Subject Feedback
    getSubjectFeedback(getYear?:any){
        const { getTeacherInfo } = this.props;
        const { schoolId } = this.state;
        if(schoolId){
            const postValue:any = {
                academic_year: getYear,
                teacher_id: getTeacherInfo,
                school_id:schoolId
            }
            this.props.getSubjectPerformance(postValue)
    }else{
        if(getTeacherInfo){
            const postValue:any = {
                academic_year: getYear,
                teacher_id: getTeacherInfo
            }
            this.props.getSubjectPerformance(postValue)
        }
    }
    }
    render() {
        const { getTeacherDetails, getTeacherInfo } = this.props;
        const { schoolId } = this.state;
        const postValue:any = {
            teacher_id:getTeacherInfo,
            school_id:schoolId
        }
        return (
            <div>
                    {/* <!-- start bar chart --> */}
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="mb-2">Subject Performance</h4>
                            {getTeacherDetails && getTeacherDetails !== undefined?
                           <CommonSubjectList
                           getData={getTeacherDetails}
                           onInfo={postValue}
                           />
                           :null}
                        </div>
                    </div>
            </div>
        );
    }
} 

const mapStateToProps = (state: any) => {
    return{
        loginProfile:state.profile.profileData,
		getChangeYearData:state.profile.getYear,
		stopYearDate:state.profile.yearStatusFails,
        getTeacherDetails:state.teacher.getSubjectPerformance
    }
}

export default connect(mapStateToProps, {getSubjectPerformance, stopYearData})(SubjectFeedBack);
